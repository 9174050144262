import useStore from 'Hooks/useStore';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { useAvailability } from './useAvailability';
import { useIsPaxSpaceExceeded } from './useIsPaxSpaceExceeded';
import { useIsSoldOut } from './useIsSoldOut';
import { useIsTrailerSpaceExceeded } from './useIsTrailerSpaceExceeded';
import { useIsVehicleSpaceExceeded } from './useIsVehicleSpaceExceeded';

export const useLegErrorMessage = (
	wizardData: BookingWizardData,
	trip: TicketsTabTrip,
	legType: 'departure' | 'return',
) => {
	const store = useStore();
	const availability = useAvailability(trip);
	const isSoldOut = useIsSoldOut(wizardData.tabSelected, trip.passengerSpacesAvailable, trip.vehicleSpacesAvailable);
	const isPaxSpaceExceeded = useIsPaxSpaceExceeded(trip.passengerSpacesAvailable, wizardData);
	const isVehicleSpaceExceeded = useIsVehicleSpaceExceeded(trip.vehicleSpacesAvailable, wizardData);
	const isTrailerSpaceExceeded = useIsTrailerSpaceExceeded(trip.trailerSpotsAvailable, wizardData);

	if (isSoldOut) {
		return 'SOLD OUT';
	}

	if (wizardData.tabSelected === 'vehicle') {
		if (wizardData.trailerCheckboxSelected && wizardData.trailerLengthId && isTrailerSpaceExceeded) {
			if (availability.measurementType === 'LENGTH') {
				return 'TRAILER LENGTH EXCEEDS SPACES AVAILABLE';
			}
			if (availability.measurementType === 'WEIGHT') {
				return 'TRAILER WEIGHT EXCEEDS SPACES AVAILABLE';
			}
		}
		if (isVehicleSpaceExceeded) {
			if (availability.measurementType === 'LENGTH') {
				return 'VEHICLE LENGTH EXCEEDS SPACES AVAILABLE';
			}
			if (availability.measurementType === 'WEIGHT') {
				return 'VEHICLE WEIGHT EXCEEDS SPACES AVAILABLE';
			}
		}
	}

	if (isPaxSpaceExceeded) {
		return 'NO SPACES AVAILABLE';
	}

	if (!store.isStaff) {
		// Only visitor/customer users can see the following messages
		if (trip.departureDateTime < new Date()) {
			return 'DEPARTED';
		}
		if (trip.isClosed) {
			return 'CLOSED';
		}
	}

	if (legType === 'departure') {
		if (
			wizardData.returningJourney?.tripId === trip.id
			&& wizardData.returningJourney?.startStopId === trip.startStopId
			&& wizardData.returningJourney?.endStopId === trip.endStopId
		) {
			return 'Cannot book on same returning trip';
		}
		if (
			wizardData.returningJourney?.departureDateTime
			&& trip.departureDateTime >= wizardData.returningJourney.departureDateTime
			&& trip.id !== wizardData.returningJourney.tripId
		) {
			return 'This time is after your return date';
		}
	}

	if (legType === 'return') {
		if (
			wizardData.departureTrip !== false // altering only return leg of two way booking uses departing journey
			&& wizardData.departingJourney?.tripId === trip.id
			&& wizardData.departingJourney?.startStopId === trip.startStopId
			&& wizardData.departingJourney?.endStopId === trip.endStopId
		) {
			return 'Cannot book on same departing trip';
		}
		if (
			wizardData.departingJourney?.departureDateTime
			&& trip.departureDateTime <= wizardData.departingJourney.departureDateTime
			&& trip.id !== wizardData.departingJourney.tripId
		) {
			return 'This time is before your departure date';
		}
	}

	return undefined;
};
