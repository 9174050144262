import React, { useEffect } from 'react';
import { History } from 'history';
import { useHistory } from 'react-router';
import { runInAction } from 'mobx';
import { confirmModal, IConfirmModalOptions } from '../../Views/Components/Modal/ModalUtils';

const defaultTitle = 'You have unsaved changes';
const defaultMessage = <div>Are you sure you want to leave?</div>;

interface LeavePageConfirmationOptions extends IConfirmModalOptions {
	allowList?: string[];
}

/**
 * A hook to render a confirmation modal when the cantLeave state is True.
 * This function will set cantLeave to false (as a MobX action) when the user confirms to leave.
 *
 * @param cantLeave is True when the user needs to be prompted before leaving the page
 * @param title of the modal which will default to the defaultTitle
 * @param message within the modal which will default to the defaultMessage
 * @param options to configure the text within in the modals buttons
 */
export function useLeavePageConfirmation(
	cantLeave: boolean,
	title?: string,
	message?: React.ReactNode,
	options: LeavePageConfirmationOptions = {},
) {
	const history = useHistory();
	useEffect(() => {
		return leavePageConfirmation(cantLeave, history, title, message, options);
	}, [cantLeave, title, message, options, history, history.location]);
}

function beforeUnload(event: BeforeUnloadEvent) {
	if (event) {
		event.returnValue = 'Sure?';
	}
	return 'Sure?';
}

/**
 * Helper function to handle the navigation between pages.
 */
export function leavePageConfirmation(
	cantLeave: boolean,
	history: History,
	title?: string,
	message?: React.ReactNode,
	options: LeavePageConfirmationOptions = {},
) {
	let block = cantLeave;

	if (!block) {
		return;
	}

	let unblock = () => {};

	const reblock = () => {
		unblock();
		unblock = history.block(handleBlock);
	};

	const handleBlock = (tx: any) => {
		const isAllowedUrl = !!options.allowList && options.allowList.some(x => tx.pathname.includes(x));

		if (isAllowedUrl && block) {
			unblock();
			history.push(tx.pathname);
			reblock();
			return;
		}

		if (!block) {
			unblock();
			history.push(tx.pathname);
			runInAction(() => {
				block = false;
			});
			return false;
		}
		confirmModal(title ?? defaultTitle, message ?? defaultMessage, options)
			.then(() => {
				unblock();
				history.push(tx.pathname);
				runInAction(() => {
					block = false;
				});
			}).catch(() => {});

		return false;
	};

	unblock = history.block(handleBlock);

	if (block) {
		window.addEventListener('beforeunload', beforeUnload);
	} else {
		window.removeEventListener('beforeunload', beforeUnload);
	}

	return () => {
		unblock();
		window.removeEventListener('beforeunload', beforeUnload);
	};
}

export interface LeavePageConfirmationProps {
	/** True if user can't leave the page, false otherwise */
	cantLeave: boolean;
	/** The title of the confirmation modal */
	title?: string;
	/** The inner message of the confirmation modal */
	message?: React.ReactNode;
	/** Custom options to pass to modal */
	options?: LeavePageConfirmationOptions;
	/** Children */
	children?: React.ReactNode;
}

/**
 * A wrapper to bring the confirmationModal to a class component.
 *
 * @param props to pass to the confirmation modal
 * @returns child components
 */
export function LeavePageConfirmation(props: LeavePageConfirmationProps) {
	const {
		cantLeave,
		title,
		message,
		options,
		children,
	} = props;

	useLeavePageConfirmation(cantLeave, title, message, options);
	return <>{children}</>;
}
