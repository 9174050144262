/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Cookies from 'js-cookie';
import Admin from './Views/Admin';
import Frontend from './Views/Frontend';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { store, StoreContext } from 'Models/Store';
import {
	ApolloProvider,
	ApolloClient,
	InMemoryCache,
	HttpLink,
	from,
	ApolloLink,
} from '@apollo/client';
import { SERVER_URL } from 'Constants';
import { isServerError } from 'Util/GraphQLUtils';
import { configure } from 'mobx';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import GlobalModal from './Views/Components/Modal/GlobalModal';
import { configureAuthenticator2fa } from 'Services/TwoFactor/Authenticator';
import { TwoFactorContext, TwoFactorMethods } from 'Services/TwoFactor/Common';
import { configureEmail2fa } from 'Services/TwoFactor/Email';
import { onError } from '@apollo/client/link/error';
import { logout } from 'Util/NavigationUtils';
// % protected region % [Add extra page imports here] on begin
import { themeStore } from './Models/ThemeStore';
import { VEHICLE_ICON_DARK_URL, VEHICLE_ICON_LIGHT_URL } from 'Constants';
import passengerTypeStore from 'Models/PassengerTypeStore';
import addOnStore from 'Models/AddOnStore';
import { isNullOrUndefined, stringNotEmpty } from './Util/TypeGuards';
import { IntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import Modal from './Views/Components/Modal/Modal';
import Icon from './Views/Components/_HumanWritten/Icon/Icon';
import translations from './english.json';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from './Views/Components/Button/Button';
import locationAndRoutesStore from './Models/LocationAndRoutesStore';
import paymentStore from './Models/PaymentStore';
import { setupJourneyElementsAsync } from 'Global/JourneyElements/setupJourneyElementsAsync';
import axios, { AxiosResponse } from 'axios';
import { logErrorOnServer } from './Services/Api/_HumanWritten/ClientsideErrorLoggingService';
import { SERVER_VERSION_HASH } from 'Constants';
import { runInAction } from 'mobx';
import {
	checkCheckInCacheOnLoad,
} from './Views/Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInOffline/OfflineCheckInPageLoad';

const OutdatedAppModal = observer((props: any) => {
	return (
		<Modal className="version-modal__container" isOpen={store.hasServerVersionMismatch} label="Server Version Updated">
			<div className="version-modal__body">
				<Icon name="warning" />
				<div className="version-modal__content">
					<h5>We&apos;ve released a new version 🎉</h5>
					<p>Your page will be refreshed to apply the update.</p>
				</div>
			</div>
			<Button
				onClick={() => window.location.reload()}
				colors={Colors.Secondary}
				display={Display.Solid}
				sizes={Sizes.Medium}
			>
				Okay
			</Button>
		</Modal>
	);
});

// % protected region % [Add extra page imports here] end

export default class App extends React.Component {
	twoFactorMethods: TwoFactorMethods = {};
	locale: string = 'en';
	messages: Record<string, { defaultMessage: string; description: string }> = translations;

	constructor(props: any, context: any) {
		super(props, context);

		// % protected region % [Customise router history here] off begin
		store.routerHistory = createBrowserHistory();
		// % protected region % [Customise router history here] end

		// % protected region % [Customise apollo client here] on begin
		store.apolloClient = new ApolloClient({
			link: from([
				this.apolloVersionLink,
				this.apolloXsrfLink,
				this.apolloErrorLink,
				new HttpLink({ uri: `${SERVER_URL}/api/graphql` }),
			]),
			cache: new InMemoryCache(),

		});
		// % protected region % [Customise apollo client here] end

		// % protected region % [Customise mobx configuration here] off begin
		// All state changes should be run in an action so enforce that
		configure({ enforceActions: 'observed' });
		// % protected region % [Customise mobx configuration here] end

		// % protected region % [Customise resize listener here] off begin
		// For cross platform mobile responsiveness
		const defineViewportHeight = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};
		defineViewportHeight();
		window.addEventListener('resize', () => defineViewportHeight());
		// % protected region % [Customise resize listener here] end

		// % protected region % [Customise default two factor methods here] off begin
		configureAuthenticator2fa(this.twoFactorMethods);
		configureEmail2fa(this.twoFactorMethods);
		// % protected region % [Customise default two factor methods here] end

		// % protected region % [Add extra constructor logic here] on begin
		this.setupAxiosInterceptors();

		// For some versions of iOS Chrome, 'resize' event is triggered when rotating screen,
		// but the window.innerHeight is not updated...
		//
		// So orientationchange event listener is born
		window.addEventListener('orientationchange', () => defineViewportHeight());

		// Update theming
		const properties = [
			{
				property: '--colour-brand-primary',
				value: themeStore.config.brandColourPrimary,
			},
			{
				property: '--colour-brand-primary-2',
				value: themeStore.config.brandColourPrimary2,
			},
			{
				property: '--colour-brand-secondary',
				value: themeStore.config.brandColourSecondary,
			},
			{
				property: '--colour-brand-secondary-2',
				value: themeStore.config.brandColourSecondary2,
			},
			{
				property: '--colour-booking-wizard-backgrounds',
				value: themeStore.config.bookingWizardBackgroundsColour,
			},
			{
				property: '--text-on-primary',
				value: themeStore.textColourOnPrimary,
			},
			{
				property: '--text-on-secondary',
				value: themeStore.textColourOnSecondary,
			},
			{
				// Add a variable of the url to the custom vehicle icon
				property: '--data-dark-vehicle-icon',
				value: `url(${VEHICLE_ICON_DARK_URL})`,
			},
			{
				// Add a variable of the url to the custom vehicle icon
				property: '--data-light-vehicle-icon',
				value: `url(${VEHICLE_ICON_LIGHT_URL})`,
			},
			// Trip card colours
			{
				property: '--high-availability-trip-card-colour',
				value: stringNotEmpty(themeStore.config.highAvailabilityColour)
					? themeStore.config.highAvailabilityColour
					: '#C9E879',
			},
			{
				property: '--low-availability-trip-card-colour',
				value: stringNotEmpty(themeStore.config.lowAvailabilityColour)
					? themeStore.config.lowAvailabilityColour
					: '#ffe074',
			},
			{
				property: '--no-availability-trip-card-colour',
				value: stringNotEmpty(themeStore.config.noAvailabilityColour)
					? themeStore.config.noAvailabilityColour
					: '#9D9D9D',
			},
			{
				property: '--hidden-trip-card-colour',
				value: stringNotEmpty(themeStore.config.hiddenTripCardColour)
					? themeStore.config.hiddenTripCardColour
					: '#FF6D76',
			},
			{
				property: '--event-trip-card-colour',
				value: stringNotEmpty(themeStore.config.eventTripCardColour)
					? themeStore.config.eventTripCardColour
					: '#FF6D76',
			},
			// Tag colours
			{
				property: '--paid-tag-colour',
				value: stringNotEmpty(themeStore.config.paidTagColour)
					? themeStore.config.paidTagColour
					: '#C9E879',
			},
			{
				property: '--text-on-paid-tag',
				value: themeStore.textOnPaidTag,
			},
			{
				property: '--cancelled-tag-colour',
				value: stringNotEmpty(themeStore.config.cancelledTagColour)
					? themeStore.config.cancelledTagColour
					: '#FF6D76',
			},
			{
				property: '--text-on-cancelled-tag',
				value: themeStore.textOnCancelledTag,
			},
			{
				property: '--awaiting-tag-colour',
				value: stringNotEmpty(themeStore.config.awaitingTagColour)
					? themeStore.config.awaitingTagColour
					: '#FFE074',
			},
			{
				property: '--text-on-awaiting-tag',
				value: themeStore.textOnAwaitingTag,
			},
			{
				property: '--invoiced-tag-colour',
				value: stringNotEmpty(themeStore.config.invoicedTagColour)
					? themeStore.config.invoicedTagColour
					: '#0180b5',
			},
			{
				property: '--text-on-invoiced-tag',
				value: themeStore.textOnInvoicedTag,
			},
			{
				property: '--new-tag-colour',
				value: stringNotEmpty(themeStore.config.newTagColour)
					? themeStore.config.newTagColour
					: themeStore.config.brandColourPrimary,
			},
			{
				property: '--text-on-new-tag',
				value: themeStore.textOnNewTag,
			},
		];

		for (const item of properties) {
			document.documentElement.style.setProperty(item.property, item.value);
		}

		// Start the fetching process
		setupJourneyElementsAsync();
		passengerTypeStore.retrieveAsync(true);
		addOnStore.retrieveAddOnsAsync(true);
		locationAndRoutesStore.retrieveAsync(true);
		paymentStore.retrieveAsync(true);
		// % protected region % [Add extra constructor logic here] end
	}

	public render() {
		// % protected region % [Override render here] on begin
		const flattenedMessages: Record<string, string> = Object.fromEntries(
			Object.entries(this.messages).map(([key, value]) => [key, value.defaultMessage]),
		);

		return (
			<IntlProvider locale="en" messages={flattenedMessages}>
				<ApolloProvider client={store.apolloClient}>
					<TwoFactorContext.Provider value={this.twoFactorMethods}>
						<StoreContext.Provider value={store}>
							<OutdatedAppModal />
							<Provider store={store}>
								<>
									<Router history={store.routerHistory}>
										<ToastContainer limit={3} className="frontend" />
										<Switch>
											<Route path="/admin" component={Admin} />
											<Route path="/" component={Frontend} />
										</Switch>
									</Router>
									<GlobalModal
										ref={ref => {
											if (ref) {
												store.modal = ref;
											}
										}}
									/>
								</>
							</Provider>
						</StoreContext.Provider>
					</TwoFactorContext.Provider>
				</ApolloProvider>
			</IntlProvider>
		);
		// % protected region % [Override render here] end
	}

	/**
	 * Request handler for the apollo client
	 */
	private apolloXsrfLink = new ApolloLink((operation, next) => {
		operation.setContext({
			headers: {
				'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
			},
		});
		return next(operation);
	});

	/**
	 * Error Handler for the apollo client
	 */
	private apolloErrorLink = onError(({ networkError }) => {
		if (isServerError(networkError) && networkError.statusCode === 401) {
			logout(store.routerHistory.location.pathname);
		}
	});

	// % protected region % [Add extra methods here] on begin
	componentDidMount(): void {
		checkCheckInCacheOnLoad();
	}

	private apolloVersionLink = new ApolloLink((operation, forward) => {
		return forward(operation).map(response => {
			const context = operation.getContext();
			const serverVersion = context.response.headers.get('X-Version');

			this.checkServerVersion(serverVersion);

			return response;
		});
	});

	private setupAxiosInterceptors = () => {
		const onResponse = (response: AxiosResponse): AxiosResponse => {
			const serverVersion = response.headers['x-version'];

			this.checkServerVersion(serverVersion);

			return response;
		};

		axios.interceptors.response.use(onResponse);
	};

	private checkServerVersion = (version: string) => {
		const versionTime = 'new_version_time';
		const versionCount = 'new_version_count';

		const time = Number(localStorage.getItem(versionTime) ?? 0);
		const count = Number(localStorage.getItem(versionCount) ?? 0);

		if (version !== SERVER_VERSION_HASH) {
			if (new Date().getTime() - time < 60 * 60 * 1000) {
				localStorage.setItem(versionCount, String(count + 1));

				if (count === 5) {
					this.sendBrowserVersionToServer();
				}

				return;
			}

			// Reset the values in localStorage
			localStorage.setItem(versionTime, new Date().getTime().toString());
			localStorage.setItem(versionCount, '0');

			runInAction(() => {
				if (isNullOrUndefined(store.versionPopUpTimestamp)) {
					store.hasServerVersionMismatch = true;
				}
			});
		}
	};

	private sendBrowserVersionToServer = () => {
		const browserVersion = this.getCurrentBrowserVersion();

		console.log(browserVersion);

		// SEND THE REQUEST HERE
		logErrorOnServer({
			loggedInUserId: store.userId,
			currentLocation: document.location.href.toString(),
			previousLocation: document.referrer,
			message: 'Version pop-up has appeared 5+ times for the logged in user.',
			stack: `Browser version: ${browserVersion.toString()}`,
		}).then(() => {
			// Do nothing (the error will be logged in the server)
		});
	}

	private getCurrentBrowserVersion = (): string => {
		const ua = navigator.userAgent;
		let tem;
		let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return `IE ${tem[1] || ''}`;
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
			if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		tem = ua.match(/version\/(\d+)/i);
		if (tem != null) M.splice(1, 1, tem[1]);
		return M.join(' ');
	}
	// % protected region % [Add extra methods here] end
}
