import { LocationEntity } from 'Models/Entities';
import { journeyElementsStore } from '../JourneyElementsStore';
import { sortByOrder } from './sortByOrder';

export const filterDestinations = (departureLocationId: string): LocationEntity[] => {
	const possibleDestinations = new Set<LocationEntity>();

	// Iterate through all routes
	for (const route of journeyElementsStore.getState().routes) {
		let foundSelectedStop = false;

		for (const stop of route.stops.slice().sort(sortByOrder())) {
			// If the stop matches the selected location, mark it as the starting point
			if (!foundSelectedStop && stop.location.id === departureLocationId) {
				foundSelectedStop = true;
			} else if (foundSelectedStop) {
				// If the selected location has been found, collect all stops that follow
				possibleDestinations.add(stop.location);
			}
		}
	}

	return Array.from(possibleDestinations.values());
};
