import React from 'react';
import useStore from 'Hooks/useStore';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { CheckInBottomBar } from '../CheckInBottomBar';
import CustomerSelection from 'Views/Components/_HumanWritten/CustomerSelection/CustomerSelection';
import { useHistory } from 'react-router';
import { CustomLocationState, isRegisterCustomerLocationState } from 'Models/_HumanWritten/LocationState';
import useCheckInStore from 'Hooks/useCheckInStore';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { DisableContinueState } from '../CheckInUtils';
import { Colors } from 'Views/Components/Button/Button';
import useScreenSize from 'Hooks/useScreenSize';
import classNames from 'classnames';

function CheckInAddSelectUser() {
	const history = useHistory();
	const store = useStore();
	const checkInStore = useCheckInStore();
	const routes = useCheckInRoutes();
	const errors = useLocalStore<{ userId?: string }>(() => ({}));
	const state = useLocalStore<DisableContinueState>(() => ({
		continue: false,
	}));
	const { isIpadMiniPortrait, isMobile } = useScreenSize();

	const locationState = history.location.state as CustomLocationState;

	// Check if the previous route was from RegisterCustomer component
	//
	// It is important to assign the userId directly without useEffect so that this userId
	// value will be used as part of the initialOptions for the combobox
	runInAction(() => {
		if (locationState && isRegisterCustomerLocationState(locationState) && locationState.userId) {
			checkInStore.formState.userId = locationState.userId;
		}
	});

	const onContinue = () => {
		if (checkInStore.formState.userId === undefined || checkInStore.formState.userId === '') {
			runInAction(() => {
				errors.userId = 'Required';
				state.continue = true;
			});
		} else {
			store.routerHistory.push(routes.add);
		}
	};

	return (
		<>
			<div className="check-in__body pr-xs pl-xs">
				<h4 className="check-in__body__title">
					Who are you booking for?
				</h4>
				<div
					className={classNames(
						'check-in__body__card customer-selection',
						{
							'pt-sm': isIpadMiniPortrait,
							'p-mdlg': !isIpadMiniPortrait,
							'p-xs': isMobile,
						},
					)}
				>
					<CustomerSelection
						model={checkInStore.formState}
						modelProperty="userId"
						backUrl={routes.current}
						// never need to disable this as you won't see it in edit mode
						disabled={false}
						onSelect={customer => {
							checkInStore.formState.user.firstName = customer.firstName;
							checkInStore.formState.user.lastName = customer.lastName;
							checkInStore.formState.user.phone = customer.phone;
							checkInStore.formState.user.email = customer.email;
							checkInStore.formState.driverFirstName = customer.firstName;
							checkInStore.formState.driverLastName = customer.lastName;
							checkInStore.formState.driverPhone = customer.phone;
							checkInStore.formState.email = customer.email;
							runInAction(() => {
								state.continue = false;
							});
						}}
						combobox={{
							errors: errors.userId,
							onAfterChange: (e, d) => {
								runInAction(() => {
									errors.userId = undefined;
								});
							},
						}}
					/>
				</div>
			</div>
			<CheckInBottomBar
				closeBtnProps={{
					content: 'Back',
					onClick: () => store.routerHistory.replace(routes.base),
				}}
				confirmBtnProps={{
					content: (
						<>
							Continue
							<Icon name="arrow-right" />
						</>
					),
					onClick: onContinue,
					disabled: state.continue,
					colors: state.continue ? Colors.Disabled : Colors.Primary,
				}}
			/>
		</>
	);
}

export default observer(CheckInAddSelectUser);
