import React from 'react';
import { observer } from 'mobx-react';
import useCheckInStore from 'Hooks/useCheckInStore';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import passengerTypeStore from 'Models/PassengerTypeStore';
import PassengerInputCounterStateful from 'Views/Components/_HumanWritten/PassengerAmount/PassengerInputCounterStateful';

export interface BookingFormEditPassengersProps {
	resetContinue: (errorKey?: string | string[]) => void;
}

function BookingFormEditPassengers({ resetContinue }: BookingFormEditPassengersProps) {
	usePassengerTypes();
	const { formState } = useCheckInStore();

	return (
		<>
			{passengerTypeStore.passengerTypes.map(x => {
				const key = x.passengerTypeKey;
				const modelProperty = passengerTypeStore.getBookingDtoProperty(key);
				const title = passengerTypeStore.getPluralName(key);
				const subtitle = passengerTypeStore.getDescription(key);
				const tooltip = passengerTypeStore.getTooltip(key);

				return (
					<PassengerInputCounterStateful
						key={x.id}
						passengerTypeKey={key}
						model={formState}
						modelProperty={modelProperty}
						title={title}
						subtitle={subtitle}
						tooltip={tooltip}
						onAfterChange={() => resetContinue()}
						disabled={false}
					/>
				);
			})}
		</>
	);
}

export default observer(BookingFormEditPassengers);
