import * as React from 'react';
import { observer } from 'mobx-react';
import { FEATURE_IMAGE_1_URL, VEHICLE_ICON_DARK_URL } from 'Constants';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import { clearFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	clearBookingWizardData,
	clearOldBookingWizardData,
	getBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import If from '../../If/If';
import Icon from '../Icon/Icon';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { FormattedMessage } from 'react-intl';
import { appConfigStore } from '../../../../Models/AppConfigStore';
import { VehicleBookingButton } from './VehicleBookingButton';
import { PassengerBookingButton } from './PassengerBookingButton';
import classNames from 'classnames';

export interface HomeProps {
	contactUs: boolean;
}

function Home({ contactUs }: HomeProps) {
	const { isMobile } = useDeviceDetect();

	const onScheduleClick = () => {
		clearBookingWizardData();
		store.routerHistory.push('/ferry-schedule');
	};

	const onEventsCatalogueClick = () => {
		store.routerHistory.push('/upcoming-events');
	};

	const onSearch = (type: 'passenger' | 'vehicle') => {
		const wizardData = getBookingWizardData({
			type: type,
		});
		if (wizardData.wizardMode === 'ALTERATION') {
			clearBookingWizardData();
			clearOldBookingWizardData();
			clearFerryBookingTransactionIdFromStorage();
		}
	};

	// Show intercom if the user has entered the application from a contact us link,
	// an assumption is that they have just navigated to the page.
	if (contactUs && !!(window as any).Intercom) {
		(window as any).Intercom('show');
	}

	// Get the booking buttons in order of priority and depending on the config (whether they are enabled or not)
	const bookingButtons = [];

	if (whiteLabelStore.defaultBookingType === 'vehicle') {
		if (appConfigStore.vehicleEnabled) {
			bookingButtons.push(<VehicleBookingButton key="vehicle-booking-button" onSearch={onSearch} />);
		}
		bookingButtons.push(<PassengerBookingButton key="passenger-booking-button" onSearch={onSearch} />);
	} else if (whiteLabelStore.defaultBookingType === 'passenger') {
		bookingButtons.push(<PassengerBookingButton key="passenger-booking-button" onSearch={onSearch} />);
		if (appConfigStore.vehicleEnabled) {
			bookingButtons.push(<VehicleBookingButton key="vehicle-booking-button" onSearch={onSearch} />);
		}
	}

	const showEventsCatalogue = !whiteLabelStore.config.hideEvents;

	return (
		<>
			<div className="homepage-background">
				<img className="background-img" src={FEATURE_IMAGE_1_URL} alt="bg-img" />
				<div className="colour-overlay" />
			</div>
			<div className="home-page-container">
				<div className="begin-booking__container glass">
					<p className="white">
						<FormattedMessage
							id="homepage__begin-booking__label"
							defaultMessage="BEGIN BOOKING"
						/>

					</p>
					<h4 className="white">
						<FormattedMessage
							id="homepage__select-booking-type__sub-label"
							defaultMessage="Select your ferry booking type"
						/>
					</h4>
					<div className={classNames('booking-type-buttons__container', { 'fill-row': bookingButtons.length === 1 })}>
						{bookingButtons}
					</div>

				</div>
				<div className="see-schedule__container glass">
					<p className="white">
						<FormattedMessage
							id="homepage__not-ready-to-book__label"
							defaultMessage="NOT READY TO BOOK?"
						/>
					</p>
					<h4 className="white">
						<FormattedMessage
							id="homepage__check-schedule__sub-label"
							defaultMessage="Check schedule"
						/>
					</h4>
					<Button
						className="check-ferry-schedule__button icon-arrow-right icon-right hide-underline"
						onClick={onScheduleClick}
						display={Display.Solid}
						sizes={Sizes.Large}
						colors={Colors.Primary}
					>
						<FormattedMessage
							id="homepage__check-schedule__button"
							defaultMessage="Check ferry schedule"
						/>
					</Button>
					<If condition={showEventsCatalogue}>
						<Button
							className="events-catalogue__button icon-arrow-right icon-right hide-underline"
							onClick={onEventsCatalogueClick}
							display={Display.Solid}
							sizes={Sizes.Large}
							colors={Colors.Primary}
						>
							<FormattedMessage
								id="homepage__events-catalogue__button"
								defaultMessage="Events catalogue"
							/>
						</Button>
					</If>
				</div>
			</div>
		</>
	);
}

export default observer(Home);
