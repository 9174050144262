/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable indent,react/jsx-indent */
import * as React from 'react';
import { action } from 'mobx';
import { store } from 'Models/Store';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';
import * as Pages from './Pages';
import Logout from './Components/Logout/Logout';
import Auth from './Components/Auth/Auth';
import PageNotFound from './Pages/PageNotFound';
import PageAccessDenied from './Pages/PageAccessDenied';
import Topbar from './Components/Topbar/Topbar';
// % protected region % [Add any extra imports here] on begin
import { ElementsPage } from './Pages';
import ErrorBoundary from './ErrorBoundary';
import { InvalidToken } from './Components/ErrorHandling/InvalidToken';
// % protected region % [Add any extra imports here] end

export default class Frontend extends React.Component<RouteComponentProps> {
	@action
	private setAppLocation = () => {
		store.appLocation = 'frontend';
	}

	public componentDidMount() {
		this.setAppLocation();
	}

	public render() {
		const { match } = this.props;
		const path = match.path === '/' ? '' : match.path;

		// % protected region % [Add any extra render logic here] off begin
		// % protected region % [Add any extra render logic here] end

		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] off begin
					}
					<Topbar currentLocation="frontend" />
					{
					// % protected region % [Modify Topbar] end
					}
					{
					// % protected region % [Modify Frontend] on begin
					}
					<div className="frontend">
					{
					// % protected region % [Modify Frontend] end
					}
						{
						// % protected region % [Add any header content here] off begin
						}
						{
						// % protected region % [Add any header content here] end
						}
						<ErrorBoundary>
							<Switch>
								{/* Public routes */}
								{
								// % protected region % [customize the universal public routes] on begin
								}
								<Route path="/login" component={Pages.LoginRoutes} />
								<Route path="/logout" component={Logout} />
								<Route path="/register" component={Pages.RegistrationPage} />
								<Route path="/confirm-email" component={Pages.RegistrationConfirmPage} />
								<Route path="/reset-password-request" component={Pages.ResetPasswordRequestPage} />
								<Route path="/reset-password" component={Pages.ResetPasswordPage} />
								{process.env.NODE_ENV === 'development' && (
								<Route path="/elements" component={ElementsPage} />
								)}
								{
								// % protected region % [customize the universal public routes] end
								}
								{
								// % protected region % [customize the Home public routes] on begin
								}
								<Route exact path="/home" component={Pages.HomePage} />
								<Route path="/home/search" component={Pages.HomeSearchPage} />
								<Redirect exact from="/" to={`${path}/home`} />
								{
								// % protected region % [customize the Home public routes] end
								}
								{
								// % protected region % [customize the BookingWizard public routes] on begin
								}
								<Route path="/booking-wizard" component={Pages.BookingWizardPage} />
								<Route path="/event-booking-wizard" component={Pages.EventBookingWizardPage} />
								{
								// % protected region % [customize the BookingWizard public routes] end
								}
								{
								// % protected region % [customize the FerrySchedule public routes] on begin
								}
								<Route path="/ferry-schedule" component={Pages.FerrySchedulePage} />
								<Route path="/remove-from-waitlist" component={Pages.RemoveFromWaitlistPage} />
								<Route path={`${path}/upcoming-events`} component={Pages.EventListView} />
								<Route path={`${path}/event-details/:id?`} component={Pages.EventsPage} />
								{
								// % protected region % [customize the FerrySchedule public routes] end
								}

								<Route path={`${path}/404`} component={PageNotFound} />
								<Route path={`${path}/403`} component={PageAccessDenied} />

								{
								// % protected region % [Add additional routes here] on begin
								}
								<Route path={`${path}/invalid-token`} component={InvalidToken} />
								{
								// % protected region % [Add additional routes here] end
								}

								<Auth {...this.props}>
									<Switch>
										{/* These routes require a login to view */}
										{/* Pages from the ui model */}
										{
										// % protected region % [customize the page routes] on begin
										}
										<Route
											path={`${path}/account-details/:tab?`}
											component={Pages.AccountDetailsPage}
										/>
										<Route
											path={`${path}/staff-account-details/:id`}
											component={Pages.StaffVisibleAccountDetailsPage}
										/>
										<Route path={`${path}/staff-bookings`} component={Pages.StaffBookingsPage} />
										<Route path={`${path}/bookings/:id?`} component={Pages.BookingsPage} />
										<Route path={`${path}/reports`} component={Pages.ReportsPage} />
										{
										// % protected region % [customize the page routes] end
										}
										{
										// % protected region % [Add any extra page routes here] on begin
										}
										<Route path="/update-password" component={Pages.UpdatePasswordPage} />
										<Route path="/register-customer" component={Pages.RegisterCustomerPage} />
										<Route path="/booking-success" component={Pages.BookingSuccessPage} />
										<Route path="/event-booking-success" component={Pages.EventBookingSuccessPage} />
										<Route
											path="/booking-cancelled-success"
											component={Pages.BookingCancelSuccessPage}
										/>
										<Route path="/check-in/:id" component={Pages.FerryCheckInPage} />
										<Route path="/event-check-in/:id" component={Pages.EventCheckInPage} />
										<Route path="/qr-code/booking/:id" component={Pages.QrCodeRedirectPage} />
										<Route path="/search-results/:id" component={Pages.SearchResultsPage} />
										<Route path="/change-email-request" component={Pages.ChangeEmailRequestPage} />
										<Route path="/change-email" component={Pages.ChangeEmailPage} />
										<Route path="/awaiting-payment" component={Pages.AwaitEwayPaymentPage} />
										<Route path="/gift-payment/:id" component={Pages.PaymentPage} />
										<Route path="/trip-insight/:tripId" component={Pages.TripInsight} />
										{
										// % protected region % [Add any extra page routes here] end
										}

										<Route component={PageNotFound} />
									</Switch>
								</Auth>
							</Switch>
						</ErrorBoundary>
						{
						// % protected region % [Add any footer content here] off begin
						}
						{
						// % protected region % [Add any footer content here] end
						}
					</div>
				</div>
			</>
		);
	}
}
