/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminTripStopEntity from 'Models/Security/Acl/AdminTripStopEntity';
import FixedPriceUserTripStopEntity from 'Models/Security/Acl/FixedPriceUserTripStopEntity';
import InvoicedUserTripStopEntity from 'Models/Security/Acl/InvoicedUserTripStopEntity';
import ManagerTripStopEntity from 'Models/Security/Acl/ManagerTripStopEntity';
import StaffTripStopEntity from 'Models/Security/Acl/StaffTripStopEntity';
import UserTripStopEntity from 'Models/Security/Acl/UserTripStopEntity';
import VisitorsTripStopEntity from 'Models/Security/Acl/VisitorsTripStopEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITripStopEntityAttributes extends IModelAttributes {
	arrivalDateTime: Date;
	departureDateTime: Date;

	ferryTripId: string;
	ferryTrip: Models.FerryTripEntity | Models.IFerryTripEntityAttributes;
	stopId: string;
	stop: Models.StopEntity | Models.IStopEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TripStopEntity', 'Trip Stop')
// % protected region % [Customise your entity metadata here] end
export default class TripStopEntity extends Model
	implements ITripStopEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminTripStopEntity(),
		new FixedPriceUserTripStopEntity(),
		new InvoicedUserTripStopEntity(),
		new ManagerTripStopEntity(),
		new StaffTripStopEntity(),
		new UserTripStopEntity(),
		new VisitorsTripStopEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Arrival Date Time'] off begin
	@observable
	@attribute<TripStopEntity, Date>()
	@CRUD({
		name: 'Arrival Date Time',
		displayType: 'datetimepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public arrivalDateTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Arrival Date Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Departure Date Time'] off begin
	@observable
	@attribute<TripStopEntity, Date>()
	@CRUD({
		name: 'Departure Date Time',
		displayType: 'datetimepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public departureDateTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Departure Date Time'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] off begin
		name: 'Ferry Trip',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.FerryTripEntity,
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] end
	})
	public ferryTripId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public ferryTrip: Models.FerryTripEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Stop'] off begin
		name: 'Stop',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.StopEntity,
		// % protected region % [Modify props to the crud options here for reference 'Stop'] end
	})
	public stopId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public stop: Models.StopEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITripStopEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITripStopEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.arrivalDateTime !== undefined) {
				if (attributes.arrivalDateTime === null) {
					this.arrivalDateTime = attributes.arrivalDateTime;
				} else {
					this.arrivalDateTime = moment(attributes.arrivalDateTime).toDate();
				}
			}
			if (attributes.departureDateTime !== undefined) {
				if (attributes.departureDateTime === null) {
					this.departureDateTime = attributes.departureDateTime;
				} else {
					this.departureDateTime = moment(attributes.departureDateTime).toDate();
				}
			}
			if (attributes.ferryTripId !== undefined) {
				this.ferryTripId = attributes.ferryTripId;
			}
			if (attributes.ferryTrip !== undefined) {
				if (attributes.ferryTrip === null) {
					this.ferryTrip = attributes.ferryTrip;
				} else if (attributes.ferryTrip instanceof Models.FerryTripEntity) {
					this.ferryTrip = attributes.ferryTrip;
					this.ferryTripId = attributes.ferryTrip.id;
				} else {
					this.ferryTrip = new Models.FerryTripEntity(attributes.ferryTrip);
					this.ferryTripId = this.ferryTrip.id;
				}
			}
			if (attributes.stopId !== undefined) {
				this.stopId = attributes.stopId;
			}
			if (attributes.stop !== undefined) {
				if (attributes.stop === null) {
					this.stop = attributes.stop;
				} else if (attributes.stop instanceof Models.StopEntity) {
					this.stop = attributes.stop;
					this.stopId = attributes.stop.id;
				} else {
					this.stop = new Models.StopEntity(attributes.stop);
					this.stopId = this.stop.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		ferryTrip {
			${Models.FerryTripEntity.getAttributes().join('\n')}
			${Models.FerryTripEntity.getFiles().map(f => f.name).join('\n')}
		}
		stop {
			${Models.StopEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TripStopEntity.prototype, 'created');
CRUD(modifiedAttr)(TripStopEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
