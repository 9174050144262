import { store } from 'Models/Store';
import CheckInCache from '../context/CheckInCache';
import { reaction } from 'mobx';

const checkForOfflineCheckIn = async () => {
	if (!store.isStaff) {
		return;
	}

	const checkInCache = CheckInCache.getInstance(false);

	await checkInCache.connect();

	if (!checkInCache.isConnected) {
		return;
	}

	await checkInCache.handlePageReload();
};

export const checkCheckInCacheOnLoad = reaction(() => store.isStaff, checkForOfflineCheckIn, { fireImmediately: true });
