import { doesLocationExists } from 'Global/JourneyElements/Utils/doesLocationExists';
import { AppError } from 'Validators/AppError';

const message = 'handleChangeEndLocation: there is no location found using endLocationId.';

export const handleChangeEndLocation = (endLocationId: string) => {
	if (!doesLocationExists(endLocationId)) {
		throw new AppError(message, { endLocationId });
	}

	return { endLocationId };
};
