import axios from 'axios';

import { JOURNEY_API } from 'Constants';
import useAsync from 'Hooks/useAsync';
import { buildUrl } from 'Util/FetchUtils';
import { JourneySchedule } from '../Types/JourneySchedule';
import { Journey } from '../Types/Journey';

export const useJourneySchedule = (journey: Journey) => {
	return useAsync(async () => {
		if (!journey) {
			return null;
		}
		const { tripId, startStopId, endStopId } = journey;
		const results = await axios.get<JourneySchedule>(
			buildUrl(`${JOURNEY_API}/schedule`, { tripId, startStopId, endStopId }),
		);
		return {
			departureDateTime: new Date(results.data.departureDateTime),
			arrivalDateTime: new Date(results.data.arrivalDateTime),
		};
	}, [journey?.tripId, journey?.startStopId, journey?.endStopId]);
};
