import * as React from 'react';
import Collection from 'Views/Components/Collection/Collection';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { observer } from 'mobx-react';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import useStore from 'Hooks/useStore';
import { isNotNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import alertToast from 'Util/ToastifyUtils';
import useEventCheckInStore from 'Hooks/useEventCheckInStore';
import { EventCheckInOpenClose } from '../CheckInList/EventCheckInOpenClose';
import {
	EventCheckInSorter, firstLetterLastName,
	sortByFullName,
	transformCheckInStatus,
	transformFullName,
	transformMobileNumber, transformPassengerTicketHeader, transformPassengerTickets,
} from '../EventCheckInUtils';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { whiteLabelStore } from '../../../../../../Models/WhiteLabelStore';
import { NoneCheckedIn } from '../../FerryCheckIn/CheckInEmpty/NoneCheckedIn';
import { AllCheckedIn } from '../../FerryCheckIn/CheckInEmpty/AllCheckedIn';
import { EventCheckInOrUndoButton } from '../EventCheckInButtons/EventCheckInOrUndoButton';
import { confirmModal } from '../../../../Modal/ModalUtils';
import { GetModalContentForEventCheckIn } from '../CheckInList/EventCheckInListRow';
import { useSearchTerm } from '../../FerryCheckIn/CheckInView';
import If from '../../../../If/If';
import { filterEventBookings } from '../Helpers/FilterEventBookings';
import { EventCheckInSearch } from '../EventCheckInSearch/EventCheckInSearch';
import { EventCheckInSearchEmpty } from '../EventCheckInSearch/EventCheckInSearchEmpty';

export interface EventCheckInTableProps {
	/**
	 * True displays bookings for check in, false displays checked-in booking and undo buttons.
	 */
	forCheckIn?: boolean;
}

function EventCheckInTable(props: EventCheckInTableProps) {
	const {
		forCheckIn = true,
	} = props;

	const store = useStore();
	const eventCheckInStore = useEventCheckInStore();
	const { eventId, bookings } = eventCheckInStore;

	const [bookingSorter, setSort] = React.useState<EventCheckInSorter>(EventCheckInSorter.Default);

	const {
		model,
	} = useSearchTerm(x => ({
		model: x.model,
	}));

	/**
	 * We need both useState and useRef to display the filtered list.
	 *
	 * useState
	 *     To apply and render the list by descending
	 *
	 * useRef
	 *     To apply the correct arrow icon in the headers (useState does not work due to state not updated by the
	 *     time value is passed in the header)
	 */
	const [descending, setDescending] = React.useState<boolean>(false);
	const descendingRef = React.useRef<boolean>(false);

	const headers = React.useMemo(() => {
		let result: ICollectionHeaderProps<EventCheckInBookingOverviewDto>[] = [
			{
				name: 'check-in-status',
				className: 'check-in-status-col',
				displayName: ' ',
				transformItem: transformCheckInStatus,
			},
			{
				name: 'name',
				displayName: 'Name',
				transformItem: transformFullName,
				sortable: true,
				nullValue: '-',
				sortClicked: (): IOrderByCondition<EventCheckInBookingOverviewDto> => {
					setSort(EventCheckInSorter.Fullname);
					setDescending(state => !state);
					descendingRef.current = !descendingRef.current;
					return {
						path: 'name',
						descending: descendingRef.current,
					};
				},
			},
			{
				name: 'phone',
				displayName: 'Mobile',
				transformItem: transformMobileNumber,
				nullValue: '-',
			},
			{
				name: 'passengers',
				displayName: transformPassengerTicketHeader,
				transformItem: transformPassengerTickets,
				nullValue: '-',
			},
		];

		if (!whiteLabelStore.config.trailersEnabled) {
			result = result.filter(x => x.name !== 'trailer-length');
		}

		return result;
	}, []);

	// The extra header count is for the action column
	const headerCount = React.useMemo(() => headers.length + 1, [headers]);

	const beforeRow = (item: EventCheckInBookingOverviewDto, index: number, arr: EventCheckInBookingOverviewDto[]): React.ReactNode => {
		if (index === 0
				|| firstLetterLastName(item)?.toUpperCase() !== firstLetterLastName(arr[index - 1])?.toUpperCase()) {
			return (
				<tr key={`subheader-${item.id}`} className="subheader">
					<td colSpan={headerCount}>{firstLetterLastName(item)?.toUpperCase()}</td>
				</tr>
			);
		}
		return null;
	};

	const onRowClick = (item: EventCheckInBookingOverviewDto) => {
		store.routerHistory.push(`/event-check-in/${eventId}/booking/${item.id}`);
	};

	const onCheckIn = async (booking: EventCheckInBookingOverviewDto) => {
		if (isNotNullOrUndefined(booking)) {
			if (!booking.checkedIn) {
				await confirmModal(
					'Confirm booking?',
					GetModalContentForEventCheckIn(booking),
					{
						shouldCloseOnOverlayClick: false,
					},
				)
					.then(async () => {
						await checkIn(booking);
					})
					.catch(() => {
						// do nothing
					});
			} else {
				await checkIn(booking);
			}
		}
	};
	const checkIn = async (booking: CheckInBookingOverviewDto | EventCheckInBookingOverviewDto) => {
		try {
			await eventCheckInStore.checkInBooking(booking.id, !booking.checkedIn);
			if (booking?.checkedIn) {
				PlayCheckInAudio();
			}
		} catch (e) {
			console.error(e);
			alertToast('Check in unsuccessful', 'error');
		}
	};

	const customCheckinBtn = (booking: EventCheckInBookingOverviewDto) => {
		const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();
			onCheckIn(booking);
		};
		return (
			<EventCheckInOrUndoButton
				forCheckIn={forCheckIn}
				id={booking.id}
				className={forCheckIn ? 'check-in__btn--green' : 'check-in__btn--grey'}
				onClick={onClick}
			/>
		);
	};

	// ================================================== Render =================================================== //

	const displayedBookings = filterEventBookings({
		bookings,
		forCheckIn,
		searchTerm: model.searchTerm,
	});

	if (displayedBookings.length === 0 && stringIsEmpty(model.searchTerm)) {
		if (forCheckIn) {
			return (
				<div className="all-checked-in__container">
					<AllCheckedIn />
					<EventCheckInOpenClose
						ferryTripId={eventCheckInStore.eventDetails.ferryTripId}
						eventId={eventCheckInStore.eventId}
						checkInClosed={eventCheckInStore.eventDetails.closed}
						tripDeparted={eventCheckInStore.eventDetails.startDateTime < new Date()}
					/>
				</div>
			);
		}
		return (
			<div className="none-checked-in__container">
				<NoneCheckedIn />
				<EventCheckInOpenClose
					ferryTripId={eventCheckInStore.eventDetails.ferryTripId}
					eventId={eventCheckInStore.eventId}
					checkInClosed={eventCheckInStore.eventDetails.closed}
					tripDeparted={eventCheckInStore.eventDetails.startDateTime < new Date()}
				/>
			</div>
		);
	}

	switch (bookingSorter) {
		case EventCheckInSorter.Fullname:
			displayedBookings.sort(sortByFullName(descending));
			break;
		default:
	}

	return (
		<>
			<EventCheckInSearch />
			<If condition={displayedBookings.length === 0}>
				<EventCheckInSearchEmpty />
			</If>
			<If condition={displayedBookings.length > 0}>
				<Collection<EventCheckInBookingOverviewDto>
					className="check-in__table"
					collection={displayedBookings}
					headers={headers}
					beforeRow={beforeRow}
					onRowClick={onRowClick}
					actions={[
						{
							label: 'check-in',
							customButton: customCheckinBtn,
							// Action is handled in the custom button
							action: () => {},
						},
					]}
					hidePagination
					perPage={bookings.length}
				/>
				<EventCheckInOpenClose
					ferryTripId={eventCheckInStore.eventDetails.ferryTripId}
					eventId={eventCheckInStore.eventId}
					checkInClosed={eventCheckInStore.eventDetails?.closed}
					tripDeparted={eventCheckInStore.eventDetails?.startDateTime < new Date()}
				/>
			</If>
		</>
	);
}

export default observer(EventCheckInTable);
