import { doesLocationExists } from 'Global/JourneyElements/Utils/doesLocationExists';
import { filterDestinations } from 'Global/JourneyElements/Utils/filterDestinations';
import { getDefaultLocationIds } from 'Global/JourneyElements/Utils/getDefaultLocationIds';
import { isTravelPossible } from 'Global/JourneyElements/Utils/isTravelPossible';
import { AppError } from 'Validators/AppError';

const message = 'handleResetLocations should not be called when default locations are is not ready';

export const handleResetLocations = (startLocationId: string, endLocationId: string) => {
	const defaultLocationIds = getDefaultLocationIds();

	if (!defaultLocationIds) {
		throw new AppError(message, { defaultLocationIds, startLocationId, endLocationId });
	}

	if (!doesLocationExists(startLocationId)) {
		const { startLocationId: defaultStart, endLocationId: defaultEnd } = defaultLocationIds;
		return {
			startLocationId: defaultStart,
			endLocationId: defaultEnd,
			possibleDestinationIds: filterDestinations(defaultStart).map(x => x.id),
		};
	}

	const possibleDestinationIds = filterDestinations(startLocationId).map(x => x.id);

	if (!isTravelPossible(startLocationId, endLocationId)) {
		return { startLocationId, endLocationId: possibleDestinationIds[0], possibleDestinationIds };
	}

	return { startLocationId, endLocationId, possibleDestinationIds };
};
