import React from 'react';

import useStore from 'Hooks/useStore';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { RenderAddToWaitListModal } from 'Views/Components/_HumanWritten/Waitlist/AddToWaitListModal';

import { Button, Colors, Display } from 'Views/Components/Button/Button';

interface WaitlistButtonProps {
	isWaitlisted?: boolean;
	trip: TicketsTabTrip;
	wizardData: BookingWizardData;
	refresh?: () => void;
}

export default function WaitlistButton({
	isWaitlisted,
	trip,
	wizardData,
	refresh,
}: WaitlistButtonProps) {
	const store = useStore();

	if (isWaitlisted) {
		return <p className="waitlisted-text">Waitlisted</p>;
	}

	return (
		<Button
			display={Display.Outline}
			colors={Colors.Secondary}
			className="add-to-waitlist__button"
			onClick={() => RenderAddToWaitListModal(
				trip.id,
				trip.vehicleSpacesTotal > 0,
				wizardData,
				!store.isStaff ? store.userId : undefined,
				!store.isStaff ? store.email : '',
				refresh,
			)}
		>
			Add to waitlist
		</Button>
	);
}
