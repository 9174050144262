/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsTicketEntity from 'Models/Security/Acl/VisitorsTicketEntity';
import UserTicketEntity from 'Models/Security/Acl/UserTicketEntity';
import StaffTicketEntity from 'Models/Security/Acl/StaffTicketEntity';
import ManagerTicketEntity from 'Models/Security/Acl/ManagerTicketEntity';
import AdminTicketEntity from 'Models/Security/Acl/AdminTicketEntity';
import InvoicedUserTicketEntity from 'Models/Security/Acl/InvoicedUserTicketEntity';
import FixedPriceUserTicketEntity from 'Models/Security/Acl/FixedPriceUserTicketEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface ITicketEntityAttributes extends IModelAttributes {
	price: number;
	amount: number;

	additionalBookingOptionId?: string;
	additionalBookingOption?: Models.AdditionalBookingOptionEntity | Models.IAdditionalBookingOptionEntityAttributes;
	alterationId?: string;
	alteration?: Models.AlterationEntity | Models.IAlterationEntityAttributes;
	cargoDetailId?: string;
	cargoDetail?: Models.CargoDetailEntity | Models.ICargoDetailEntityAttributes;
	customTicketTypeId?: string;
	customTicketType?: Models.CustomTicketTypeEntity | Models.ICustomTicketTypeEntityAttributes;
	freightDetailId?: string;
	freightDetail?: Models.FreightDetailEntity | Models.IFreightDetailEntityAttributes;
	passengerDetails: Array<
		| Models.PassengerDetailTicket
		| Models.IPassengerDetailTicketAttributes
	>;
	towOnDetailId?: string;
	towOnDetail?: Models.TowOnDetailEntity | Models.ITowOnDetailEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TicketEntity', 'Ticket')
// % protected region % [Customise your entity metadata here] end
export default class TicketEntity extends Model
	implements ITicketEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsTicketEntity(),
		new UserTicketEntity(),
		new StaffTicketEntity(),
		new ManagerTicketEntity(),
		new AdminTicketEntity(),
		new InvoicedUserTicketEntity(),
		new FixedPriceUserTicketEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<TicketEntity, number>()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Amount'] off begin
	/**
	 * Quantity
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<TicketEntity, number>()
	@CRUD({
		name: 'Amount',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public amount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Amount'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Option'] off begin
		name: 'Additional Booking Option',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.AdditionalBookingOptionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Option'] end
	})
	public additionalBookingOptionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public additionalBookingOption: Models.AdditionalBookingOptionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] off begin
		name: 'Alteration',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.AlterationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alterationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public alteration: Models.AlterationEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo Detail'] off begin
		name: 'Cargo Detail',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.CargoDetailEntity,
		// % protected region % [Modify props to the crud options here for reference 'Cargo Detail'] end
	})
	public cargoDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public cargoDetail: Models.CargoDetailEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Custom Ticket Type'] off begin
		name: 'Custom Ticket Type',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.CustomTicketTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Custom Ticket Type'] end
	})
	public customTicketTypeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public customTicketType: Models.CustomTicketTypeEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Detail'] off begin
		name: 'Freight Detail',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.FreightDetailEntity,
		// % protected region % [Modify props to the crud options here for reference 'Freight Detail'] end
	})
	public freightDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public freightDetail: Models.FreightDetailEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Passenger Detail'] off begin
		name: 'Passenger Detail',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PassengerDetailTicket,
		optionEqualFunc: makeJoinEqualsFunc('passengerDetailId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'ticketEntity',
			oppositeEntityName: 'passengerDetailEntity',
			relationName: 'ticket',
			relationOppositeName: 'passengerDetail',
			entity: () => Models.TicketEntity,
			joinEntity: () => Models.PassengerDetailTicket,
			oppositeEntity: () => Models.PassengerDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Passenger Detail'] end
	})
	public passengerDetails: Models.PassengerDetailTicket[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Detail'] off begin
		name: 'Tow On Detail',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.TowOnDetailEntity,
		// % protected region % [Modify props to the crud options here for reference 'Tow On Detail'] end
	})
	public towOnDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public towOnDetail: Models.TowOnDetailEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITicketEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITicketEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.amount !== undefined) {
				this.amount = attributes.amount;
			}
			if (attributes.additionalBookingOptionId !== undefined) {
				this.additionalBookingOptionId = attributes.additionalBookingOptionId;
			}
			if (attributes.additionalBookingOption !== undefined) {
				if (attributes.additionalBookingOption === null) {
					this.additionalBookingOption = attributes.additionalBookingOption;
				} else if (attributes.additionalBookingOption instanceof Models.AdditionalBookingOptionEntity) {
					this.additionalBookingOption = attributes.additionalBookingOption;
					this.additionalBookingOptionId = attributes.additionalBookingOption.id;
				} else {
					this.additionalBookingOption = new Models.AdditionalBookingOptionEntity(attributes.additionalBookingOption);
					this.additionalBookingOptionId = this.additionalBookingOption.id;
				}
			}
			if (attributes.alterationId !== undefined) {
				this.alterationId = attributes.alterationId;
			}
			if (attributes.alteration !== undefined) {
				if (attributes.alteration === null) {
					this.alteration = attributes.alteration;
				} else if (attributes.alteration instanceof Models.AlterationEntity) {
					this.alteration = attributes.alteration;
					this.alterationId = attributes.alteration.id;
				} else {
					this.alteration = new Models.AlterationEntity(attributes.alteration);
					this.alterationId = this.alteration.id;
				}
			}
			if (attributes.cargoDetailId !== undefined) {
				this.cargoDetailId = attributes.cargoDetailId;
			}
			if (attributes.cargoDetail !== undefined) {
				if (attributes.cargoDetail === null) {
					this.cargoDetail = attributes.cargoDetail;
				} else if (attributes.cargoDetail instanceof Models.CargoDetailEntity) {
					this.cargoDetail = attributes.cargoDetail;
					this.cargoDetailId = attributes.cargoDetail.id;
				} else {
					this.cargoDetail = new Models.CargoDetailEntity(attributes.cargoDetail);
					this.cargoDetailId = this.cargoDetail.id;
				}
			}
			if (attributes.customTicketTypeId !== undefined) {
				this.customTicketTypeId = attributes.customTicketTypeId;
			}
			if (attributes.customTicketType !== undefined) {
				if (attributes.customTicketType === null) {
					this.customTicketType = attributes.customTicketType;
				} else if (attributes.customTicketType instanceof Models.CustomTicketTypeEntity) {
					this.customTicketType = attributes.customTicketType;
					this.customTicketTypeId = attributes.customTicketType.id;
				} else {
					this.customTicketType = new Models.CustomTicketTypeEntity(attributes.customTicketType);
					this.customTicketTypeId = this.customTicketType.id;
				}
			}
			if (attributes.freightDetailId !== undefined) {
				this.freightDetailId = attributes.freightDetailId;
			}
			if (attributes.freightDetail !== undefined) {
				if (attributes.freightDetail === null) {
					this.freightDetail = attributes.freightDetail;
				} else if (attributes.freightDetail instanceof Models.FreightDetailEntity) {
					this.freightDetail = attributes.freightDetail;
					this.freightDetailId = attributes.freightDetail.id;
				} else {
					this.freightDetail = new Models.FreightDetailEntity(attributes.freightDetail);
					this.freightDetailId = this.freightDetail.id;
				}
			}
			if (attributes.passengerDetails !== undefined && Array.isArray(attributes.passengerDetails)) {
				for (const model of attributes.passengerDetails) {
					if (model instanceof Models.PassengerDetailTicket) {
						this.passengerDetails.push(model);
					} else {
						this.passengerDetails.push(new Models.PassengerDetailTicket(model));
					}
				}
			}
			if (attributes.towOnDetailId !== undefined) {
				this.towOnDetailId = attributes.towOnDetailId;
			}
			if (attributes.towOnDetail !== undefined) {
				if (attributes.towOnDetail === null) {
					this.towOnDetail = attributes.towOnDetail;
				} else if (attributes.towOnDetail instanceof Models.TowOnDetailEntity) {
					this.towOnDetail = attributes.towOnDetail;
					this.towOnDetailId = attributes.towOnDetail.id;
				} else {
					this.towOnDetail = new Models.TowOnDetailEntity(attributes.towOnDetail);
					this.towOnDetailId = this.towOnDetail.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		passengerDetails {
			${Models.PassengerDetailTicket.getAttributes().join('\n')}
			passengerDetail {
				${Models.PassengerDetailEntity.getAttributes().join('\n')}
			}
		}
		additionalBookingOption {
			${Models.AdditionalBookingOptionEntity.getAttributes().join('\n')}
			${Models.AdditionalBookingOptionEntity.getFiles().map(f => f.name).join('\n')}
		}
		alteration {
			${Models.AlterationEntity.getAttributes().join('\n')}
		}
		cargoDetail {
			${Models.CargoDetailEntity.getAttributes().join('\n')}
		}
		customTicketType {
			${Models.CustomTicketTypeEntity.getAttributes().join('\n')}
		}
		freightDetail {
			${Models.FreightDetailEntity.getAttributes().join('\n')}
		}
		towOnDetail {
			${Models.TowOnDetailEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			passengerDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'passengerDetails',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TicketEntity.prototype, 'created');
CRUD(modifiedAttr)(TicketEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
