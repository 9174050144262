import classNames from 'classnames';
import React from 'react';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

interface StopSeriesVisualiserProps {
	numberOfStops?: number;
	variant?: 'white' | 'contrast';
	/**
	 * Hide stop indicators below the number of stop label.
	 */
	intermediateStopsDisabled?: boolean;
	hideStopCountLabel?: boolean;
}

export default function StopSeriesVisualiser({
	numberOfStops,
	variant = 'contrast',
	intermediateStopsDisabled = false,
	hideStopCountLabel = false,
}: StopSeriesVisualiserProps) {
	const showIntermediateStops = React.useMemo(() => {
		return !intermediateStopsDisabled && !!numberOfStops && numberOfStops > 0 && numberOfStops < 10;
	}, [intermediateStopsDisabled, numberOfStops]);

	return (
		<div className="leg-option__stops-container">
			{!hideStopCountLabel && !!numberOfStops && (
				// Show text label when there is at least one stop
				<div className="leg-option__number-of-stops">
					{numberOfStops} stop{numberOfStops > 1 && 's'}
				</div>
			)}
			<div className="leg-option__stop-series-container">
				<StopDepartureIndicator variant={variant} />
				{showIntermediateStops && !!numberOfStops && (
					<>
						<RouteLine variant={variant} />
						<StopOutlineIndicator variant={variant} />
						{numberOfStops > 1 && (
							<>
								{Array.from({ length: numberOfStops - 1 }, (_, index) => (
									<React.Fragment key={index}>
										<RouteLine variant={variant} isSegment />
										<StopOutlineIndicator variant={variant} />
									</React.Fragment>
								))}
							</>
						)}
					</>
				)}
				<RouteLine variant={variant} />
				<StopDestinationIndicator variant={variant} />
			</div>
		</div>
	);
}

interface StopIndicatorProps {
	variant?: 'white' | 'contrast';
}

function StopOutlineIndicator({ variant }: StopIndicatorProps) {
	return (
		<div
			className={classNames('leg-option__stop-indicator', {
				'leg-option__stop-indicator--outline-white': variant === 'white',
				'leg-option__stop-indicator--outline-black': variant === 'contrast',
			})}
		/>
	);
}

function StopDepartureIndicator({ variant }: StopIndicatorProps) {
	return (
		<div
			className={classNames('leg-option__stop-indicator-symbol leg-option__stop-indicator-symbol--start', {
				'leg-option__stop-indicator-symbol--solid-white': variant === 'white',
				'leg-option__stop-indicator-symbol--solid-primary': variant === 'contrast',
			})}
		>
			<Icon name="ship" />
		</div>
	);
}

function StopDestinationIndicator({ variant }: StopIndicatorProps) {
	return (
		<div
			className={classNames('leg-option__stop-indicator-symbol leg-option__stop-indicator-symbol--end', {
				'leg-option__stop-indicator-symbol--solid-white': variant === 'white',
				'leg-option__stop-indicator-symbol--solid-primary': variant === 'contrast',
			})}
		>
			<Icon name="location-pin" />
		</div>
	);
}

interface RouteLineProps {
	variant?: 'white' | 'contrast';
	isSegment?: boolean;
	hasArrow?: 'right';
}

function RouteLine({ variant, isSegment }: RouteLineProps) {
	return (
		<div
			className={classNames('leg-option__route-line', {
				'leg-option__route-line--segment': isSegment,
				'leg-option__route-line--white': variant === 'white',
			})}
		/>
	);
}
