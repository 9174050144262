import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const CENTER_INDEX = 2;

/**
 * Generates an array of formatted date strings from 2 days before to 2 days after the given date.
 *
 * @param date - The base date from which the range is calculated.
 * @returns An array of date strings in 'YYYY-MM-DD' format, representing the range.
 *
 * Example:
 * Input: new Date('2024-11-28')
 * Output: ["2024-11-26", "2024-11-27", "2024-11-28", "2024-11-29", "2024-11-30"]
 */
function getSurroundingDates(date: Date): string[] {
	const baseDate = dayjs(date).startOf('day');
	const dates = [];

	// Generate array of date strings from -2 days to +2 days
	for (let i = -2; i <= 2; i++) {
		dates.push(baseDate.add(i, 'day').format('YYYY-MM-DD'));
	}

	return dates;
}

interface TripSelectionCalendarTabState {
	departingCurrentTab: number;
	returningCurrentTab: number;
	setDepartingCurrentTab: (tabNumber: number) => void;
	setReturningCurrentTab: (tabNumber: number) => void;
}

/**
 * Tracks the tab state of the departing and returning calendar tabs.
 *
 * If the selected trip is within range (-2 and +2 days), tab index is set accordingly.
 * If outside of range, it defaults to the center tab.
 *
 * @param filterStartDate The depart date in the filters.
 * @param filterEndDate The return date in the filters.
 * @param selectedDepartingDate The departure date time of the selected departing journey.
 * @param selectedReturningDate The departure date time of the selected returning journey.
 * @param selectedDepartingId The trip id of the selected departing journey.
 * @param selectedReturningId The trip id of the selected returning journey.
 */
export const useTripSelectionCalendarTabState = (
	filterStartDate: Date,
	filterEndDate: Date,
	selectedDepartingDate?: Date,
	selectedReturningDate?: Date,
	selectedDepartingId?: string,
	selectedReturningId?: string,
): TripSelectionCalendarTabState => {
	const [departingCurrentTab, setDepartingCurrentTab] = useState(CENTER_INDEX);
	const [returningCurrentTab, setReturningCurrentTab] = useState(CENTER_INDEX);

	const departingCalendarDates = getSurroundingDates(filterStartDate);
	const returningCalendarDates = getSurroundingDates(filterStartDate);

	useEffect(() => {
		if (selectedDepartingDate) {
			const index = departingCalendarDates.findIndex(
				x => x === dayjs(selectedDepartingDate).format('YYYY-MM-DD'),
			);
			if (index > -1) {
				setDepartingCurrentTab(index);
				return;
			}
		}
		if (selectedDepartingId !== '') {
			setDepartingCurrentTab(CENTER_INDEX);
		}
	}, [filterStartDate, selectedDepartingDate]);

	useEffect(() => {
		if (selectedReturningDate) {
			const index = returningCalendarDates.findIndex(
				x => x === dayjs(selectedReturningDate).format('YYYY-MM-DD'),
			);
			if (index > -1) {
				setReturningCurrentTab(index);
				return;
			}
		}
		if (selectedReturningId !== '') {
			setReturningCurrentTab(CENTER_INDEX);
		}
	}, [filterEndDate, selectedReturningDate]);

	return {
		departingCurrentTab,
		returningCurrentTab,
		setDepartingCurrentTab,
		setReturningCurrentTab,
	};
};
