/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsConfigThemeEntity from 'Models/Security/Acl/VisitorsConfigThemeEntity';
import UserConfigThemeEntity from 'Models/Security/Acl/UserConfigThemeEntity';
import StaffConfigThemeEntity from 'Models/Security/Acl/StaffConfigThemeEntity';
import ManagerConfigThemeEntity from 'Models/Security/Acl/ManagerConfigThemeEntity';
import AdminConfigThemeEntity from 'Models/Security/Acl/AdminConfigThemeEntity';
import InvoicedUserConfigThemeEntity from 'Models/Security/Acl/InvoicedUserConfigThemeEntity';
import FixedPriceUserConfigThemeEntity from 'Models/Security/Acl/FixedPriceUserConfigThemeEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { Colour } from '../../Validators';
// % protected region % [Add any further imports here] end

export interface IConfigThemeEntityAttributes extends IModelAttributes {
	featureImage1Id: string;
	featureImage1: Blob;
	featureImage2Id: string;
	featureImage2: Blob;
	featureImage3Id: string;
	featureImage3: Blob;
	featureImage4Id: string;
	featureImage4: Blob;
	featureImage5Id: string;
	featureImage5: Blob;
	featureImage6Id: string;
	featureImage6: Blob;
	featureImage7Id: string;
	featureImage7: Blob;
	brandColourPrimary: string;
	brandColourPrimary2: string;
	brandColourSecondary: string;
	brandColourSecondary2: string;
	brandLogoPrimaryId: string;
	brandLogoPrimary: Blob;
	brandLogoSecondaryId: string;
	brandLogoSecondary: Blob;
	brandFaviconId: string;
	brandFavicon: Blob;
	textOnPrimaryColour: Enums.fontColour;
	textOnSecondaryColour: Enums.fontColour;
	marketingSiteURL: string;
	vehicleIconDarkId: string;
	vehicleIconDark: Blob;
	vehicleIconLightId: string;
	vehicleIconLight: Blob;
	brandLogoPrimaryLightId: string;
	brandLogoPrimaryLight: Blob;
	bookingWizardBackgroundsColour: string;
	highAvailabilityColour: string;
	lowAvailabilityColour: string;
	noAvailabilityColour: string;
	eventTripCardColour: string;
	hiddenTripCardColour: string;
	paidTagColour: string;
	invoicedTagColour: string;
	awaitingTagColour: string;
	cancelledTagColour: string;
	newTagColour: string;
	textOnPaidTag: Enums.fontColour;
	textOnCancelledTag: Enums.fontColour;
	textOnInvoicedTag: Enums.fontColour;
	textOnAwaitingTag: Enums.fontColour;
	textOnNewTag: Enums.fontColour;
	hazardousTripCardColour: string;
	passengerLabel: string;
	defaultFerryTripNoteIconId: string;
	defaultFerryTripNoteIcon: Blob;
	freightIconId: string;
	freightIcon: Blob;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ConfigThemeEntity', 'Config Theme')
// % protected region % [Customise your entity metadata here] end
export default class ConfigThemeEntity extends Model
	implements IConfigThemeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsConfigThemeEntity(),
		new UserConfigThemeEntity(),
		new StaffConfigThemeEntity(),
		new ManagerConfigThemeEntity(),
		new AdminConfigThemeEntity(),
		new InvoicedUserConfigThemeEntity(),
		new FixedPriceUserConfigThemeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 1'] on begin
	@observable
	@attribute({ file: 'featureImage1' })
	@CRUD({
		name: 'Feature image 1 - Recommended dimensions 770x950px',
		description: 'This feature image is used on the homepage of the application. Must be a high quality image '
			+ 'that is not too large.',
		displayType: 'file',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage1',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage1Id: string;

	@observable
	public featureImage1: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 2'] on begin
	@observable
	@attribute({ file: 'featureImage2' })
	@CRUD({
		name: 'Feature image 2 - Recommended dimensions 1170x730px',
		description: 'This feature image is in the navbar in the booking wizard and on the reset password page. '
			+ 'Must be a high quality image that is not too large.',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage2',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage2Id: string;

	@observable
	public featureImage2: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 3'] on begin
	@observable
	@attribute({ file: 'featureImage3' })
	@CRUD({
		name: 'Feature image 3 - Recommended dimensions 960x1000px',
		description: 'This feature image is used on the login and registration pages. '
			+ 'Must be a high quality image that is not too large.',
		displayType: 'file',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage3',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage3Id: string;

	@observable
	public featureImage3: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 3'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 4'] on begin
	@observable
	@attribute({ file: 'featureImage4' })
	@CRUD({
		name: 'Feature image 4 - Recommended dimensions 420x250px',
		description: 'This feature image is used on the something went wrong page. '
			+ 'Must be a high quality image that is not too large.',
		displayType: 'file',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage4',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage4Id: string;

	@observable
	public featureImage4: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 4'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 5'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'featureImage5' })
	@CRUD({
		name: 'Feature image 5',
		description: 'This feature image is used on the booking success page and the cancel booking success page. '
			+ 'Must be a high quality image that is not too large.',
		displayType: 'file',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage5',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage5Id: string;

	@observable
	public featureImage5: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 5'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 6'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'featureImage6' })
	@CRUD({
		name: 'Feature image 6',
		description: 'This feature image is used on the page not found page. '
			+ 'Must be a high quality image that is not too large.',
		displayType: 'file',
		order: 60,
		searchable: true,
		headerColumn: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage6',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage6Id: string;

	@observable
	public featureImage6: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 6'] end

	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 7'] off begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'featureImage7' })
	@CRUD({
		name: 'Feature Image 7',
		displayType: 'file',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'featureImage7',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public featureImage7Id: string;

	@observable
	public featureImage7: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Feature Image 7'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Primary'] on begin
	@observable
	@Colour()
	@attribute()
	@CRUD({
		name: 'Brand colour (primary 1)',
		description: 'The primary brand colour of the app. Note: this should be darker than primary 2, but a similar '
			+ 'colour as it will be a gradient.',
		displayType: 'textfield',
		order: 130,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public brandColourPrimary: string;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Primary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Primary 2'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Brand colour (primary 2)',
		description: 'The primary brand colour of the app. Note: this should be light than primary 1, but a similar '
			+ 'colour as it will be a gradient.',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public brandColourPrimary2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Primary 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Secondary'] on begin
	@observable
	@Colour()
	@attribute()
	@CRUD({
		name: 'Brand colour (secondary 1)',
		description: 'The secondary brand colour of the app. Note: this should be darker than secondary 2, but a '
			+ 'similar colour as it will be a gradient.',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public brandColourSecondary: string;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Secondary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Secondary 2'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Brand colour (secondary 2)',
		description: 'The secondary brand colour of the app. Note: this should be lighter than secondary 1, but a '
			+ 'similar colour as it will be a gradient.',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public brandColourSecondary2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Colour Secondary 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Logo Primary'] on begin
	@observable
	@attribute({ file: 'brandLogoPrimary' })
	@CRUD({
		name: 'Brand logo primary (dark) - Recommended dimensions 570x270px',
		description: 'The dark version of the logo primary.',
		displayType: 'file',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'brandLogoPrimary',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public brandLogoPrimaryId: string;

	@observable
	public brandLogoPrimary: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Logo Primary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Logo Secondary'] on begin
	@observable
	@attribute({ file: 'brandLogoSecondary' })
	@CRUD({
		name: 'Brand logo secondary - Recommended dimensions 130x50px',
		description: 'The second brand logo.',
		displayType: 'file',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'brandLogoSecondary',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public brandLogoSecondaryId: string;

	@observable
	public brandLogoSecondary: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Logo Secondary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Favicon'] on begin
	@observable
	@attribute({ file: 'brandFavicon' })
	@CRUD({
		name: 'Brand favicon - Recommended dimensions 48x48px',
		description: 'The icon that will appear in the tabs in a web browser. Must be very small to suit this use.',
		displayType: 'file',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
			contentType: '.ico, .png',
		},
		fileAttribute: 'brandFavicon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public brandFaviconId: string;

	@observable
	public brandFavicon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Favicon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text on Primary Colour'] on begin
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on primary brand colour',
		description: 'The colour of the text that will appear on the primary brand colour.',
		displayType: 'enum-combobox',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnPrimaryColour: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text on Primary Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text on Secondary Colour'] on begin
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on secondary brand colour',
		description: 'The colour of the text that will appear on the secondary brand colour.',
		displayType: 'enum-combobox',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnSecondaryColour: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text on Secondary Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Marketing Site URL'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Marketing site URL',
		description: 'The URL of the businesses marketing site.',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public marketingSiteURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Marketing Site URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Icon Dark'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'vehicleIconDark' })
	@CRUD({
		name: 'Cargo icon (dark)',
		description: 'A dark version of the cargo icon to display on light backgrounds around the application.',
		displayType: 'file',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'vehicleIconDark',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public vehicleIconDarkId: string;

	@observable
	public vehicleIconDark: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Icon Dark'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Icon Light'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'vehicleIconLight' })
	@CRUD({
		name: 'Cargo icon (light)',
		description: 'A light version of the cargo icon to display on dark backgrounds around the application.',
		displayType: 'file',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'vehicleIconLight',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public vehicleIconLightId: string;

	@observable
	public vehicleIconLight: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Icon Light'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand Logo Primary Light'] on begin
	@observable
	@attribute({ file: 'brandLogoPrimaryLight' })
	@CRUD({
		name: 'Brand logo primary (light) - Recommended dimensions 570x270px',
		description: 'The light version of the logo primary.',
		displayType: 'file',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'brandLogoPrimaryLight',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public brandLogoPrimaryLightId: string;

	@observable
	public brandLogoPrimaryLight: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Brand Logo Primary Light'] end

	// % protected region % [Modify props to the crud options here for attribute 'Booking Wizard Backgrounds Colour'] on begin
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Image loading background colour',
		description: 'The colour that will appear while an image is loading (if the internet connection is slow).',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public bookingWizardBackgroundsColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Booking Wizard Backgrounds Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'High Availability Colour'] on begin
	/**
	 * The colour that will appears when there is high availability on a trip card.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'High availability trip card colour',
		description: 'The colour that will be used on high availability trip cards (50% or more available) '
			+ 'on the ferry schedule and in the booking wizard.',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public highAvailabilityColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'High Availability Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Low Availability Colour'] on begin
	/**
	 * The colour that will appears when there is low availability on a trip card.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Low availability trip card colour',
		description: 'The colour that will be used on low availability trip cards (less than 50% available) '
			+ 'on the ferry schedule and in the booking wizard.',
		displayType: 'textfield',
		order: 250,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lowAvailabilityColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Low Availability Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'No Availability Colour'] on begin
	/**
	 * The colour that will appears when there is no availability on a trip card.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'No availability trip card colour',
		description: 'The colour that will be used on no availability trip cards (sold out) '
			+ 'on the ferry schedule and in the booking wizard.',
		displayType: 'textfield',
		order: 270,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public noAvailabilityColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'No Availability Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Event Trip Card Colour'] on begin
	/**
	 * The colour that will appear on event trip cards.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Event trip card colour',
		displayType: 'hidden',
		order: 290,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public eventTripCardColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Event Trip Card Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hidden Trip Card Colour'] on begin
	/**
	 * The colour of a hidden trip card.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Hidden trip card colour',
		description: 'The colour that will be used on hidden trip cards (only staff can see these trips) on the '
			+ 'ferry schedule and in the booking wizard.',
		displayType: 'textfield',
		order: 310,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public hiddenTripCardColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Hidden Trip Card Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Paid Tag Colour'] on begin
	/**
	 * The colour that paid tags use throughout the application.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Paid tag colour',
		description: 'The colour for `Paid` or `Booked` booking status tags.',
		displayType: 'textfield',
		order: 330,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public paidTagColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Paid Tag Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Invoiced Tag Colour'] on begin
	/**
	 * The colour that invoiced tags use throughout the application.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Invoiced tag colour',
		description: 'The colour for `Invoiced` booking status tags.',
		displayType: 'textfield',
		order: 350,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public invoicedTagColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Invoiced Tag Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Awaiting Tag Colour'] on begin
	/**
	 * The awaiting tag colour that will appear throughout the application.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Awaiting tag colour',
		description: 'The colour for `Awaiting` booking status tags.',
		displayType: 'textfield',
		order: 370,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public awaitingTagColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Awaiting Tag Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cancelled Tag Colour'] on begin
	/**
	 * The cancelled tag colour that will appear throughout the application.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Cancelled tag colour',
		description: 'The colour for `Cancelled` booking status tags.',
		displayType: 'textfield',
		order: 390,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cancelledTagColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Cancelled Tag Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'New Tag Colour'] on begin
	/**
	 * The colour of the new tag that appears around the application.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'New tag colour',
		description: 'The colour for `New` tags in check-in.',
		displayType: 'textfield',
		order: 410,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public newTagColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'New Tag Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text On Paid Tag'] on begin
	/**
	 * The text colour that appears on the paid tag colour.
	 */
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on paid tag',
		description: 'The colour of the text on `Paid` tags throughout the application.',
		displayType: 'enum-combobox',
		order: 340,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnPaidTag: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text On Paid Tag'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text On Cancelled Tag'] on begin
	/**
	 * The text colour that appears on the cancelled tag colour.
	 */
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on cancelled tag',
		description: 'The colour of the text on `Cancelled` tags throughout the application.',
		displayType: 'enum-combobox',
		order: 400,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnCancelledTag: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text On Cancelled Tag'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text On Invoiced Tag'] on begin
	/**
	 * The text colour that appears on the invoiced tag colour.
	 */
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on invoiced tag',
		description: 'The colour of the text on `Invoiced` tags throughout the application.',
		displayType: 'enum-combobox',
		order: 360,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnInvoicedTag: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text On Invoiced Tag'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text On Awaiting Tag'] on begin
	/**
	 * The text colour that appears on the awaiting tag colour.
	 */
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on awaiting tag',
		description: 'The colour of the text on `Awaiting` tags throughout the application.',
		displayType: 'enum-combobox',
		order: 380,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnAwaitingTag: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text On Awaiting Tag'] end

	// % protected region % [Modify props to the crud options here for attribute 'Text On New Tag'] on begin
	/**
	 * The text colour that appears on the new tag colour.
	 */
	@observable
	@attribute<ConfigThemeEntity, Enums.fontColour>()
	@CRUD({
		name: 'Text on new tag',
		description: 'The colour of the text on `New` tags throughout the application.',
		displayType: 'enum-combobox',
		order: 420,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.fontColourOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.fontColourOptions),
		displayFunction: (attr: Enums.fontColour) => Enums.fontColourOptions[attr],
	})
	public textOnNewTag: Enums.fontColour;
	// % protected region % [Modify props to the crud options here for attribute 'Text On New Tag'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hazardous Trip Card Colour'] on begin
	/**
	 * The colour of a hazardous ferry trip card.
	 */
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Hazardous trip card colour',
		description: 'The colour that will be used on hazardous trip cards on the ferry schedule and in the '
			+ 'booking wizard.',
		displayType: 'textfield',
		order: 360,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public hazardousTripCardColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Hazardous Trip Card Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Label'] off begin
	@observable
	@attribute<ConfigThemeEntity, string>()
	@CRUD({
		name: 'Passenger Label',
		displayType: 'textfield',
		order: 380,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public passengerLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Default Ferry Trip Note Icon'] off begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'defaultFerryTripNoteIcon' })
	@CRUD({
		name: 'Default Ferry Trip Note Icon',
		displayType: 'file',
		order: 390,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'defaultFerryTripNoteIcon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public defaultFerryTripNoteIconId: string;

	@observable
	public defaultFerryTripNoteIcon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Default Ferry Trip Note Icon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Freight Icon'] off begin
	@observable
	@attribute<ConfigThemeEntity, string>({ file: 'freightIcon' })
	@CRUD({
		name: 'Freight Icon',
		displayType: 'file',
		order: 400,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'freightIcon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Config Theme" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public freightIconId: string;

	@observable
	public freightIcon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Freight Icon'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IConfigThemeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IConfigThemeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.featureImage1 !== undefined) {
				this.featureImage1 = attributes.featureImage1;
			}
			if (attributes.featureImage1Id !== undefined) {
				this.featureImage1Id = attributes.featureImage1Id;
			}
			if (attributes.featureImage2 !== undefined) {
				this.featureImage2 = attributes.featureImage2;
			}
			if (attributes.featureImage2Id !== undefined) {
				this.featureImage2Id = attributes.featureImage2Id;
			}
			if (attributes.featureImage3 !== undefined) {
				this.featureImage3 = attributes.featureImage3;
			}
			if (attributes.featureImage3Id !== undefined) {
				this.featureImage3Id = attributes.featureImage3Id;
			}
			if (attributes.featureImage4 !== undefined) {
				this.featureImage4 = attributes.featureImage4;
			}
			if (attributes.featureImage4Id !== undefined) {
				this.featureImage4Id = attributes.featureImage4Id;
			}
			if (attributes.featureImage5 !== undefined) {
				this.featureImage5 = attributes.featureImage5;
			}
			if (attributes.featureImage5Id !== undefined) {
				this.featureImage5Id = attributes.featureImage5Id;
			}
			if (attributes.featureImage6 !== undefined) {
				this.featureImage6 = attributes.featureImage6;
			}
			if (attributes.featureImage6Id !== undefined) {
				this.featureImage6Id = attributes.featureImage6Id;
			}
			if (attributes.featureImage7 !== undefined) {
				this.featureImage7 = attributes.featureImage7;
			}
			if (attributes.featureImage7Id !== undefined) {
				this.featureImage7Id = attributes.featureImage7Id;
			}
			if (attributes.brandColourPrimary !== undefined) {
				this.brandColourPrimary = attributes.brandColourPrimary;
			}
			if (attributes.brandColourPrimary2 !== undefined) {
				this.brandColourPrimary2 = attributes.brandColourPrimary2;
			}
			if (attributes.brandColourSecondary !== undefined) {
				this.brandColourSecondary = attributes.brandColourSecondary;
			}
			if (attributes.brandColourSecondary2 !== undefined) {
				this.brandColourSecondary2 = attributes.brandColourSecondary2;
			}
			if (attributes.brandLogoPrimary !== undefined) {
				this.brandLogoPrimary = attributes.brandLogoPrimary;
			}
			if (attributes.brandLogoPrimaryId !== undefined) {
				this.brandLogoPrimaryId = attributes.brandLogoPrimaryId;
			}
			if (attributes.brandLogoSecondary !== undefined) {
				this.brandLogoSecondary = attributes.brandLogoSecondary;
			}
			if (attributes.brandLogoSecondaryId !== undefined) {
				this.brandLogoSecondaryId = attributes.brandLogoSecondaryId;
			}
			if (attributes.brandFavicon !== undefined) {
				this.brandFavicon = attributes.brandFavicon;
			}
			if (attributes.brandFaviconId !== undefined) {
				this.brandFaviconId = attributes.brandFaviconId;
			}
			if (attributes.textOnPrimaryColour !== undefined) {
				this.textOnPrimaryColour = attributes.textOnPrimaryColour;
			}
			if (attributes.textOnSecondaryColour !== undefined) {
				this.textOnSecondaryColour = attributes.textOnSecondaryColour;
			}
			if (attributes.marketingSiteURL !== undefined) {
				this.marketingSiteURL = attributes.marketingSiteURL;
			}
			if (attributes.vehicleIconDark !== undefined) {
				this.vehicleIconDark = attributes.vehicleIconDark;
			}
			if (attributes.vehicleIconDarkId !== undefined) {
				this.vehicleIconDarkId = attributes.vehicleIconDarkId;
			}
			if (attributes.vehicleIconLight !== undefined) {
				this.vehicleIconLight = attributes.vehicleIconLight;
			}
			if (attributes.vehicleIconLightId !== undefined) {
				this.vehicleIconLightId = attributes.vehicleIconLightId;
			}
			if (attributes.brandLogoPrimaryLight !== undefined) {
				this.brandLogoPrimaryLight = attributes.brandLogoPrimaryLight;
			}
			if (attributes.brandLogoPrimaryLightId !== undefined) {
				this.brandLogoPrimaryLightId = attributes.brandLogoPrimaryLightId;
			}
			if (attributes.bookingWizardBackgroundsColour !== undefined) {
				this.bookingWizardBackgroundsColour = attributes.bookingWizardBackgroundsColour;
			}
			if (attributes.highAvailabilityColour !== undefined) {
				this.highAvailabilityColour = attributes.highAvailabilityColour;
			}
			if (attributes.lowAvailabilityColour !== undefined) {
				this.lowAvailabilityColour = attributes.lowAvailabilityColour;
			}
			if (attributes.noAvailabilityColour !== undefined) {
				this.noAvailabilityColour = attributes.noAvailabilityColour;
			}
			if (attributes.eventTripCardColour !== undefined) {
				this.eventTripCardColour = attributes.eventTripCardColour;
			}
			if (attributes.hiddenTripCardColour !== undefined) {
				this.hiddenTripCardColour = attributes.hiddenTripCardColour;
			}
			if (attributes.paidTagColour !== undefined) {
				this.paidTagColour = attributes.paidTagColour;
			}
			if (attributes.invoicedTagColour !== undefined) {
				this.invoicedTagColour = attributes.invoicedTagColour;
			}
			if (attributes.awaitingTagColour !== undefined) {
				this.awaitingTagColour = attributes.awaitingTagColour;
			}
			if (attributes.cancelledTagColour !== undefined) {
				this.cancelledTagColour = attributes.cancelledTagColour;
			}
			if (attributes.newTagColour !== undefined) {
				this.newTagColour = attributes.newTagColour;
			}
			if (attributes.textOnPaidTag !== undefined) {
				this.textOnPaidTag = attributes.textOnPaidTag;
			}
			if (attributes.textOnCancelledTag !== undefined) {
				this.textOnCancelledTag = attributes.textOnCancelledTag;
			}
			if (attributes.textOnInvoicedTag !== undefined) {
				this.textOnInvoicedTag = attributes.textOnInvoicedTag;
			}
			if (attributes.textOnAwaitingTag !== undefined) {
				this.textOnAwaitingTag = attributes.textOnAwaitingTag;
			}
			if (attributes.textOnNewTag !== undefined) {
				this.textOnNewTag = attributes.textOnNewTag;
			}
			if (attributes.hazardousTripCardColour !== undefined) {
				this.hazardousTripCardColour = attributes.hazardousTripCardColour;
			}
			if (attributes.passengerLabel !== undefined) {
				this.passengerLabel = attributes.passengerLabel;
			}
			if (attributes.defaultFerryTripNoteIcon !== undefined) {
				this.defaultFerryTripNoteIcon = attributes.defaultFerryTripNoteIcon;
			}
			if (attributes.defaultFerryTripNoteIconId !== undefined) {
				this.defaultFerryTripNoteIconId = attributes.defaultFerryTripNoteIconId;
			}
			if (attributes.freightIcon !== undefined) {
				this.freightIcon = attributes.freightIcon;
			}
			if (attributes.freightIconId !== undefined) {
				this.freightIconId = attributes.freightIconId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ConfigThemeEntity.prototype, 'created');
CRUD(modifiedAttr)(ConfigThemeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
