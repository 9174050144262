import { createContext, useContext } from 'react';

import { AvailabilityStatus } from 'Modules/MultiStopWizard/Types/AvailabilityStatus';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';

export interface LegOptionContextProps {
	// Legacy approach
	wizardData: BookingWizardData;
	trip: TicketsTabTrip;

	// Loading
	isLoading?: boolean;

	// Booking details
	legType: 'departure' | 'return';

	// Selection details
	isSelected?: boolean;
	isSelectable?: boolean;
	isVisuallyDisabled?: boolean;

	// Route details
	departureTime: Date;
	arrivalTime: Date;
	numberOfStopsBetween?: number;

	// Price details
	price?: number;

	// Space details
	availabilityStatus?: AvailabilityStatus;
	paxSpaces: number;
	cargoSpaces: number;

	// State details
	errorMessage?: string;

	// Waitlist
	isWaitlisted?: boolean;
	refreshTrips?: () => void;

	// Actions
	onClick?: () => void;
}

export const LegOptionContext = createContext<LegOptionContextProps | undefined>(undefined);

export const useLegOptionContext = () => {
	return useContext(LegOptionContext);
};
