import React from 'react';
import { LegOptionContext } from './Contexts/LegOptionContext';
import { Button } from 'Views/Components/Button/Button';

export default function LegOptionChangeSelection() {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionChangeSelection');
		return null;
	}

	const { isSelected } = context;

	if (!isSelected) {
		return <></>;
	}

	const { legType, onClick } = context;

	const departure = legType === 'departure';

	return (
		<div className={`${departure ? 'departure-ticket' : 'return-ticket'} change-selected-ticket`}>
			<Button onClick={onClick}>Change {departure ? 'departing' : 'returning'} trip time</Button>
		</div>
	);
}
