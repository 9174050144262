/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminStopEntity from 'Models/Security/Acl/AdminStopEntity';
import FixedPriceUserStopEntity from 'Models/Security/Acl/FixedPriceUserStopEntity';
import InvoicedUserStopEntity from 'Models/Security/Acl/InvoicedUserStopEntity';
import ManagerStopEntity from 'Models/Security/Acl/ManagerStopEntity';
import StaffStopEntity from 'Models/Security/Acl/StaffStopEntity';
import UserStopEntity from 'Models/Security/Acl/UserStopEntity';
import VisitorsStopEntity from 'Models/Security/Acl/VisitorsStopEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import locationAndRoutesStore from 'Models/LocationAndRoutesStore';
// % protected region % [Add any further imports here] end

export interface IStopEntityAttributes extends IModelAttributes {
	order: number;

	endAlterations: Array<
		| Models.AlterationEntity
		| Models.IAlterationEntityAttributes
	>;
	endSegments: Array<
		| Models.SegmentEntity
		| Models.ISegmentEntityAttributes
	>;
	startAlterations: Array<
		| Models.AlterationEntity
		| Models.IAlterationEntityAttributes
	>;
	startSegments: Array<
		| Models.SegmentEntity
		| Models.ISegmentEntityAttributes
	>;
	locationId: string;
	location: Models.LocationEntity | Models.ILocationEntityAttributes;
	routeId: string;
	route: Models.RouteEntity | Models.IRouteEntityAttributes;
	tripStops: Array<
		| Models.TripStopEntity
		| Models.ITripStopEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('StopEntity', 'Stop')
// % protected region % [Customise your entity metadata here] end
export default class StopEntity extends Model
	implements IStopEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminStopEntity(),
		new FixedPriceUserStopEntity(),
		new InvoicedUserStopEntity(),
		new ManagerStopEntity(),
		new StaffStopEntity(),
		new UserStopEntity(),
		new VisitorsStopEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Order'] off begin
	@Validators.Integer()
	@observable
	@attribute<StopEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'End Alteration'] on begin
		name: 'End Alterations',
		order: 20,
		referenceTypeFunc: () => Models.AlterationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'endAlterations',
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// Custom
		displayType: 'hidden',
		// % protected region % [Modify props to the crud options here for reference 'End Alteration'] end
	})
	public endAlterations: Models.AlterationEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'End Segment'] on begin
		name: 'End Segments',
		order: 30,
		referenceTypeFunc: () => Models.SegmentEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'endSegments',
			oppositeEntity: () => Models.SegmentEntity,
		}),
		// Custom
		displayType: 'hidden',
		// % protected region % [Modify props to the crud options here for reference 'End Segment'] end
	})
	public endSegments: Models.SegmentEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Start Alteration'] on begin
		name: 'Start Alterations',
		order: 40,
		referenceTypeFunc: () => Models.AlterationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'startAlterations',
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// Custom
		displayType: 'hidden',
		// % protected region % [Modify props to the crud options here for reference 'Start Alteration'] end
	})
	public startAlterations: Models.AlterationEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Start Segment'] on begin
		name: 'Start Segments',
		order: 50,
		referenceTypeFunc: () => Models.SegmentEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'startSegments',
			oppositeEntity: () => Models.SegmentEntity,
		}),
		// Custom
		displayType: 'hidden',
		// % protected region % [Modify props to the crud options here for reference 'Start Segment'] end
	})
	public startSegments: Models.SegmentEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Location'] on begin
		name: 'Location',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.LocationEntity,
		// Custom
		headerColumn: true,
		displayFunction: locationId => {
			const location = locationAndRoutesStore.locations.find(x => x.id === locationId);
			return location ? location.name : '';
		},
		// % protected region % [Modify props to the crud options here for reference 'Location'] end
	})
	public locationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public location: Models.LocationEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Route'] on begin
		name: 'Route',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.RouteEntity,
		// Custom
		headerColumn: true,
		displayFunction: routeId => {
			const route = locationAndRoutesStore.routes.find(x => x.id === routeId);
			return route ? route.name : '';
		},
		// % protected region % [Modify props to the crud options here for reference 'Route'] end
	})
	public routeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public route: Models.RouteEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Trip Stop'] on begin
		name: 'Trip Stops',
		order: 80,
		referenceTypeFunc: () => Models.TripStopEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tripStops',
			oppositeEntity: () => Models.TripStopEntity,
		}),
		// Custom
		displayType: 'hidden',
		// % protected region % [Modify props to the crud options here for reference 'Trip Stop'] end
	})
	public tripStops: Models.TripStopEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IStopEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStopEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.endAlterations !== undefined && Array.isArray(attributes.endAlterations)) {
				for (const model of attributes.endAlterations) {
					if (model instanceof Models.AlterationEntity) {
						this.endAlterations.push(model);
					} else {
						this.endAlterations.push(new Models.AlterationEntity(model));
					}
				}
			}
			if (attributes.endSegments !== undefined && Array.isArray(attributes.endSegments)) {
				for (const model of attributes.endSegments) {
					if (model instanceof Models.SegmentEntity) {
						this.endSegments.push(model);
					} else {
						this.endSegments.push(new Models.SegmentEntity(model));
					}
				}
			}
			if (attributes.startAlterations !== undefined && Array.isArray(attributes.startAlterations)) {
				for (const model of attributes.startAlterations) {
					if (model instanceof Models.AlterationEntity) {
						this.startAlterations.push(model);
					} else {
						this.startAlterations.push(new Models.AlterationEntity(model));
					}
				}
			}
			if (attributes.startSegments !== undefined && Array.isArray(attributes.startSegments)) {
				for (const model of attributes.startSegments) {
					if (model instanceof Models.SegmentEntity) {
						this.startSegments.push(model);
					} else {
						this.startSegments.push(new Models.SegmentEntity(model));
					}
				}
			}
			if (attributes.locationId !== undefined) {
				this.locationId = attributes.locationId;
			}
			if (attributes.location !== undefined) {
				if (attributes.location === null) {
					this.location = attributes.location;
				} else if (attributes.location instanceof Models.LocationEntity) {
					this.location = attributes.location;
					this.locationId = attributes.location.id;
				} else {
					this.location = new Models.LocationEntity(attributes.location);
					this.locationId = this.location.id;
				}
			}
			if (attributes.routeId !== undefined) {
				this.routeId = attributes.routeId;
			}
			if (attributes.route !== undefined) {
				if (attributes.route === null) {
					this.route = attributes.route;
				} else if (attributes.route instanceof Models.RouteEntity) {
					this.route = attributes.route;
					this.routeId = attributes.route.id;
				} else {
					this.route = new Models.RouteEntity(attributes.route);
					this.routeId = this.route.id;
				}
			}
			if (attributes.tripStops !== undefined && Array.isArray(attributes.tripStops)) {
				for (const model of attributes.tripStops) {
					if (model instanceof Models.TripStopEntity) {
						this.tripStops.push(model);
					} else {
						this.tripStops.push(new Models.TripStopEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		endSegments {
			${Models.SegmentEntity.getAttributes().join('\n')}
		}
		startSegments {
			${Models.SegmentEntity.getAttributes().join('\n')}
		}
		location {
			${Models.LocationEntity.getAttributes().join('\n')}
		}
		route {
			${Models.RouteEntity.getAttributes().join('\n')}
			${Models.RouteEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			// tripStops: {},
			// startAlterations: {},
			// endAlterations: {},
			// endSegments: {},
			// startSegments: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'endAlterations',
							'startAlterations',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return `${this.order} ${this.location.name} | ${this.route.name}`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StopEntity.prototype, 'created');
CRUD(modifiedAttr)(StopEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
