import React from 'react';

import { LegOptionContext } from './Contexts/LegOptionContext';
import WaitlistButton from './WaitlistButton';
import useStore from 'Hooks/useStore';

export default function LegOptionWaitlist() {
	const store = useStore();
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionWeb');
		return null;
	}

	const {
		trip,
		wizardData,
		isWaitlisted,
		refreshTrips,
	} = context;

	if (store.isStaff) {
		return <></>;
	}

	if (context.errorMessage?.includes('This time is')) {
		// Hide when error message is about ticket being before/after return/departure date
		return <></>;
	}

	if (context.availabilityStatus === 'departed') {
		// Hide when trip already departed
		return <></>;
	}

	// Only visitor/customer users can see the waitlist
	return (
		<WaitlistButton isWaitlisted={isWaitlisted} trip={trip} wizardData={wizardData} refresh={refreshTrips} />
	);
}
