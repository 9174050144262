import useStore from 'Hooks/useStore';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { unselectInvalidDateTicket } from 'Util/_HumanWritten/TicketSelectionValidationUtils';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export const useLegOptionClick = (
	legType: 'departure' | 'return',
	wizardData: BookingWizardData,
	trip: TicketsTabTrip,
	isSelectable: boolean,
	saveChanges: (newData: BookingWizardData) => void,
) => {
	const store = useStore();

	const { id: tripId, startStopId, endStopId } = trip;

	const selectTicket = () => {
		const newData = { ...wizardData };
		// Only staff members can execute logic for any ferry trips
		// Otherwise, only execute logic when ferry trip is selectable
		if (!isSelectable && !store.isStaff) {
			return;
		}

		const {
			departureTrip,
			departingJourney,
			returningJourney,
			departureTicketId,
			returningTicketId,
		} = newData;

		// Conditions:
		// - for departing ferry trips
		//   - if id is empty, then assign new ticket
		//   - if id is NOT empty, make id empty
		// - for returning ferry trips
		//   - if id is empty, then assign new ticket
		//   - if id is NOT empty, make id empty

		if (legType === 'departure') {
			// Select or deselect departure ticket
			if (departureTrip === true || departureTrip === undefined) {
				if (departingJourney || departureTicketId) {
					newData.departingJourney = undefined;
					newData.departureTicketId = '';
				} else {
					newData.departingJourney = { tripId, startStopId, endStopId };
					newData.departureTicketId = trip.id;
					newData.ticketSelectionStartDate = trip.departureDateTime;
				}
			}

			saveChanges(newData);
			return;
		}

		if (legType === 'return') {
			if (departureTrip === false) {
				// False means we are editing only the return leg of a booking,
				// and we are storing it in the departureTicketId (departingJourney)
				if (departingJourney || departureTicketId) {
					newData.departingJourney = undefined;
					newData.departureTicketId = '';
				} else {
					newData.departingJourney = { tripId, startStopId, endStopId };
					newData.departureTicketId = trip.id;
					newData.ticketSelectionEndDate = trip.departureDateTime;
				}
			}
			if (departureTrip === undefined) {
				if (returningJourney || returningTicketId) {
					newData.returningJourney = undefined;
					newData.returningTicketId = '';
				} else {
					newData.returningJourney = { tripId, startStopId, endStopId };
					newData.returningTicketId = trip.id;
					newData.ticketSelectionEndDate = trip.departureDateTime;
				}
			}
		}

		saveChanges(newData);

		// if (departureTicketId === '' && (departure || (wizardMode === 'ALTERATION' && departureTrip !== false))) {
		// 	newData.departureTicketId = trip.id;
		// 	// Set the departure date to the selected ferry ticket date and set the destination date if it is before
		// 	// the selected tickets departure date
		// 	unselectInvalidDateTicket(newData);
		// } else if (departureTicketId === '' && wizardMode === 'ALTERATION' && departureTrip === false) {
		// 	// This is used for when the user passes the return ticket of a two-way trip into the wizard
		// 	// Because this scenrio will not be able to enter the above IF statement
		// 	newData.departureTicketId = trip.id;

		// 	// Set the departure date to the selected ferry ticket date
		// 	newData.endDate = trip.startDate;
		// } else if (!!departureTicketId && (departure || (wizardMode === 'ALTERATION' && departureTrip === false))) {
		// 	newData.ticketSelectionStartDate = newData.startDate;
		// 	newData.departureTicketId = '';
		// } else if (!departure && returningTicketId === '') {
		// 	newData.returningTicketId = trip.id;
		// } else if (!departure && returningTicketId !== '' && returningTicketId !== undefined) {
		// 	newData.ticketSelectionEndDate = newData.endDate;
		// 	newData.returningTicketId = '';
		// }

		// saveChanges(newData);
	};

	return selectTicket;
};
