import { useEffect } from 'react';

import { getStopById } from 'Global/JourneyElements/Utils/getStopById';
import { devLog } from 'Util/_HumanWritten/ConsoleLogUtils';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * Handles ticket deselection when ferry filter start/end locations are changed.
 * Ensures the selected ticket aligns with the selected locations.
 */
export const useTicketDeselectionForFilterLocations = (
	wizardData: BookingWizardData,
	saveChanges: (newData: BookingWizardData) => void,
) => {
	useEffect(() => {
		const newData = { ...wizardData };
		let changeDetected = false;

		if (wizardData.departingJourney?.startStopId) {
			if (getStopById(wizardData.departingJourney?.startStopId)?.locationId !== wizardData.fromLocationId) {
				newData.departureTicketId = '';
				newData.departingJourney = undefined;
				changeDetected = true;
				devLog('DEV0005: Deselect departing ticket');
			}
		}

		if (wizardData.departingJourney?.endStopId) {
			if (getStopById(wizardData.departingJourney?.endStopId)?.locationId !== wizardData.toLocationId) {
				newData.departureTicketId = '';
				newData.departingJourney = undefined;
				changeDetected = true;
				devLog('DEV0006: Deselect departing ticket');
			}
		}

		if (wizardData.returningJourney?.startStopId) {
			if (getStopById(wizardData.returningJourney?.startStopId)?.locationId !== wizardData.toLocationId) {
				newData.returningTicketId = '';
				newData.returningJourney = undefined;
				changeDetected = true;
				devLog('DEV0007: Deselect returning ticket');
			}
		}
		if (wizardData.returningJourney?.endStopId) {
			if (getStopById(wizardData.returningJourney?.endStopId)?.locationId !== wizardData.fromLocationId) {
				newData.returningTicketId = '';
				newData.returningJourney = undefined;
				changeDetected = true;
				devLog('DEV0008: Deselect returning ticket');
			}
		}

		if (changeDetected) {
			saveChanges(newData);
		}
	}, [wizardData.fromLocationId, wizardData.toLocationId]);
};
