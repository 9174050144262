import { fetchJourneyElements } from 'Global/JourneyElements/Api/JourneyService';
import { journeyElementsStore } from './JourneyElementsStore';

export const setupJourneyElementsAsync = async () => {
	try {
		const elements = await fetchJourneyElements();
		journeyElementsStore.getState().setElements(elements);
	} catch (errors: any) {
		journeyElementsStore.getState().setErrors(errors);
	}
};
