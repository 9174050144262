// Allow for async generators to be used in this file without requiring them to have a yield
// eslint-disable-file require-yield

import { action, observable, runInAction } from 'mobx';
import { CheckInStore } from './CheckInContext';
import { Model } from 'Models/Model';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import {
	AdditionalBookingOptionEntity,
	AdditionalBookingSectionEntity,
	CargoTypeEntity,
	TowOnTypeEntity,
} from 'Models/Entities';
import * as uuid from 'uuid';
import axios from 'axios';
import { ReturnToCheckInPageModal } from '../Helpers/OfflineModals';
import { store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import { serialiseDateTime } from 'Util/AttributeUtils';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { IFerryTripEntityAttributes } from 'Models/Entities/FerryTripEntity';
import { FerryTripDto } from '../CheckInEntities/FerryTripDto';
import alertToast from 'Util/ToastifyUtils';

const DATABASE_NAME = 'offline-storage';
const DATABASE_VERSION = 1; // Increment this when the database schema changes

const HOUR = 60 * 60 * 1000; // 1 hour (in milliseconds)

// Expiry time after the ferry trip has arrived at its destination
const TRIP_EXPIRY_TIME = HOUR; // 1 hour (in milliseconds)

// Expiry time after the cache has been created (and the ferry trip expiry has passed)
const CACHE_EXPIRY_TIME = 4 * HOUR; // 4 hours (in milliseconds)

const enum DB_TABLES {
	ferryTrips = 'ferryTrips',
	bookings = 'bookings',
	cargoTypes = 'cargoTypes',
	towTypes = 'towTypes',
	additionalBookingSections = 'additionalBookingSections',
	additionalBookOptions = 'additionalBookOptions',
	bookingStatusChanges = 'bookingStatusChanges',
}

const getAllTables = () => {
	return [
		DB_TABLES.ferryTrips,
		DB_TABLES.bookings,
		DB_TABLES.cargoTypes,
		DB_TABLES.towTypes,
		DB_TABLES.additionalBookingSections,
		DB_TABLES.additionalBookOptions,
		DB_TABLES.bookingStatusChanges,
	];
};

const addTable = (
	db: IDBDatabase,
	table: DB_TABLES,
	keyPath: string = 'id',
	additionalChanges?: (objectStore: IDBObjectStore) => void,
) => {
	if (!db.objectStoreNames.contains(table)) {
		const objectStore = db.createObjectStore(table, { keyPath });

		if (additionalChanges) {
			additionalChanges(objectStore);
		}
	}
};

const handleDbRequest = <T, >(request: IDBRequest<T>): Promise<T> => {
	return new Promise((resolve, reject) => {
		request.onsuccess = () => {
			resolve(request.result);
		};

		request.onerror = () => {
			reject(request.error);
		};
	});
};

/**
 * Create a transaction and executes each operation in parallel (it does not wait for each operation to finish before
 * executing the next one). This is useful for read operations but may not be suitable for write operations.
 *
 * @param db to perform the transaction on
 * @param tables that will be accessed by the transaction
 * @param transactionMode whether the transaction is read-only or read-write
 * @param operations an async function that executes the operations in the transaction. The function should not include
 *                      any await statements
 */
const createTransactionParallel = async (
	db: IDBDatabase,
	tables: DB_TABLES[],
	transactionMode: IDBTransactionMode = 'readonly',
	operations: (t: IDBTransaction) => AsyncGenerator<IDBRequest<any>>,
) => {
	const transaction = db.transaction(tables, transactionMode);
	const promises: Promise<any>[] = [];

	try {
		for await (const op of operations(transaction)) {
			promises.push(handleDbRequest(op));
		}

		await Promise.all(promises);

		transaction.commit();
	} catch (err) {
		transaction.abort();

		throw err;
	}
};

/**
 * Create a transaction and executes each operation sequentially. This should be more reliable than running the operations
 * in parallel but may be slower. (Use this if you are unsure)
 *
 * @param db to perform the transaction on
 * @param tables that will be accessed by the transaction
 * @param transactionMode whether the transaction is read-only or read-write
 * @param operations an async function that executes the operations in the transaction. The function should not include
 *                      any await statements
 */
const createTransaction = async <T, >(
	db: IDBDatabase,
	tables: DB_TABLES[],
	transactionMode: IDBTransactionMode = 'readonly',
	operations: (t: IDBTransaction) => AsyncGenerator<IDBRequest<any>, T, any>,
): Promise<T> => {
	const transaction = db.transaction(tables, transactionMode);

	try {
		const generator = operations(transaction);
		let result = await generator.next();

		while (!result.done) {
			// eslint-disable-next-line no-await-in-loop
			const value = await handleDbRequest(result.value);

			// eslint-disable-next-line no-await-in-loop
			result = await generator.next(value);
		}

		transaction.commit();

		return await result.value;
	} catch (err) {
		transaction.abort();

		throw err;
	}
};

const toDbObject = (x: any) => x instanceof Model ? x.getObject() : Model.getObject(x);

const fromDbObject = <T, >(x: any, Type: { new(a: Partial<T>): T }) => new Type(x);

interface BookingStatusChange {
	id: string;
	bookingId: string;
	ferryTripId: string;
	checkedIn: boolean;
	modified: number;
}

export default class CheckInCache {
	private static instance?: CheckInCache;

	@observable
	public isOnline: boolean = true;

	public isConnected: boolean = false;
	public isConnecting: boolean = false;
	private database: IDBDatabase;

	private ferryTripIds: string[] | undefined;

	private constructor() {
		// Get the initial state of the browser's connection status
		this.isOnline = navigator.onLine;

		window.addEventListener('online', this.handleOnlineChange);
		window.addEventListener('offline', this.handleOfflineChange);
	}

	public handleOnlineChange = action((): void => {
		this.isOnline = true;
	});

	public handleOfflineChange = action((): void => {
		this.isOnline = false;
	});

	public connect = (): Promise<void> => {
		if (this.isConnected) {
			return Promise.resolve();
		}

		return new Promise((resolve, reject) => {
			this.isConnecting = true;

			const request = indexedDB.open(DATABASE_NAME, DATABASE_VERSION);

			request.onupgradeneeded = (e: IDBVersionChangeEvent) => {
				const db = (e.target as IDBOpenDBRequest).result;
				this.updateSchema(db, e.oldVersion);
			};

			request.onerror = e => reject(e);

			request.onsuccess = e => {
				this.database = (e.target as IDBOpenDBRequest).result;
				this.isConnected = true;
				this.isConnecting = false;
				resolve();
			};
		});
	}

	/**
	 * Store the data locally in the cache
	 *
	 * @param checkInStore the store holding the data to be cached
	 */
	public cacheData = async (checkInStore: CheckInStore) => {
		if (!this.isConnected) {
			throw new Error('Database is not connected');
		}
		const towOnTypes = await checkInStore.getAllTowOnTypes();
		const additionalBookingSections = await checkInStore.getAllAdditionalBookingSections();
		const cargoTypes = await checkInStore.getAllVehicleTypes();

		await createTransaction(this.database, getAllTables(), 'readwrite', async function* (transaction) {
			// For now we only support one ferry trip in the cache
			const ferryTrips = await handleDbRequest(transaction.objectStore(DB_TABLES.ferryTrips).getAll());
			if (ferryTrips.length > 0) {
				throw new Error('There is already ferry trips in the cache');
			}

			// Add the ferry trip
			const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
			const ferryTrip = toDbObject(checkInStore.ferryTrip);
			// Update the created date for the ferry trip so it can be determined when the cache was created
			ferryTrip.created = Date.now();
			yield ferryTripStore.add(ferryTrip);

			// Add all the bookings
			const bookingStore = transaction.objectStore(DB_TABLES.bookings);
			for (const booking of checkInStore.bookings) {
				yield bookingStore.add(toDbObject(booking));
			}

			// Add all cargo types
			const cargoTypeStore = transaction.objectStore(DB_TABLES.cargoTypes);
			for (const cargoType of cargoTypes) {
				yield cargoTypeStore.add(toDbObject(cargoType));
			}

			// Add all tow on types
			const towOnTypeStore = transaction.objectStore(DB_TABLES.towTypes);
			for (const towOnType of towOnTypes) {
				yield towOnTypeStore.add(toDbObject(towOnType));
			}

			// Add all additional booking options and sections
			const additionalBookingSectionStore = transaction.objectStore(DB_TABLES.additionalBookingSections);
			for (const additionalBookingSection of additionalBookingSections) {
				yield additionalBookingSectionStore.add(toDbObject(additionalBookingSection));
			}

			if (!!checkInStore.allAdditionalBookingOptions) {
				const additionalBookingOptionStore = transaction.objectStore(DB_TABLES.additionalBookOptions);
				for (const additionalBookingOption of checkInStore.allAdditionalBookingOptions) {
					yield additionalBookingOptionStore.add(toDbObject(additionalBookingOption));
				}
			}
		});

		// Clear the ferry trip ids
		this.ferryTripIds = undefined;
	}

	/**
	 * Load the data from the cache into the store
	 *
	 * @param checkInStore
	 */
	public loadCachedData = async (checkInStore: CheckInStore) => {
		if (!this.isConnected) {
			throw new Error('Database is not connected');
		}

		let ferryTrip: FerryTripDto;
		let bookings: CheckInBookingOverviewDto[];
		let vehicleTypes: CargoTypeEntity[];
		let towOnTypes: TowOnTypeEntity[];
		let additionalBookingSections: AdditionalBookingSectionEntity[];
		let additionalBookingOptions: AdditionalBookingOptionEntity[];

		await createTransaction(this.database, getAllTables(), 'readonly', async function* (transaction) {
			const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
			const ferryTrips = yield ferryTripStore.getAll();
			ferryTrip = fromDbObject(ferryTrips[0], FerryTripDto);

			const bookingStore = transaction.objectStore(DB_TABLES.bookings);
			bookings = (yield bookingStore.getAll())
				.map((x: any) => fromDbObject(x, CheckInBookingOverviewDto));

			const cargoTypeStore = transaction.objectStore(DB_TABLES.cargoTypes);
			vehicleTypes = (yield cargoTypeStore.getAll())
				.map((x: any) => fromDbObject(x, CargoTypeEntity));

			const towOnTypeStore = transaction.objectStore(DB_TABLES.towTypes);
			towOnTypes = (yield towOnTypeStore.getAll())
				.map((x: any) => fromDbObject(x, TowOnTypeEntity));

			const additionalBookingSectionStore = transaction.objectStore(DB_TABLES.additionalBookingSections);
			additionalBookingSections = (yield additionalBookingSectionStore.getAll())
				.map((x: any) => fromDbObject(x, AdditionalBookingSectionEntity));

			const additionalBookingOptionStore = transaction.objectStore(DB_TABLES.additionalBookOptions);
			additionalBookingOptions = (yield additionalBookingOptionStore.getAll())
				.map((x: any) => fromDbObject(x, AdditionalBookingOptionEntity));
		});

		runInAction(() => {
			if (!!ferryTrip) {
				checkInStore.ferryTrip = ferryTrip;
			}

			if (!!bookings) {
				checkInStore.bookings = bookings;
			}

			if (!!vehicleTypes) {
				checkInStore.allVehicleTypes = vehicleTypes;
			}

			if (!!towOnTypes) {
				checkInStore.allTowOnTypes = towOnTypes;
			}

			if (!!additionalBookingOptions) {
				checkInStore.allAdditionalBookingOptions = additionalBookingOptions;
			}

			if (!!additionalBookingSections) {
				checkInStore.allAdditionalBookingSections = additionalBookingSections;
			}
		});
	}

	/**
	 * Clear the data from the cache. Except for any booking status changes
	 *
	 * For now, we only support one ferry trip in the cache, this method will need to be updated when we support multiple
	 */
	public clearCachedData = async () => {
		if (!this.isConnected) {
			throw new Error('Database is not connected');
		}

		await createTransaction(this.database, getAllTables(), 'readwrite', async function* (transaction) {
			const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
			yield ferryTripStore.clear();

			const bookingStore = transaction.objectStore(DB_TABLES.bookings);
			yield bookingStore.clear();

			const cargoTypeStore = transaction.objectStore(DB_TABLES.cargoTypes);
			yield cargoTypeStore.clear();

			const towOnTypeStore = transaction.objectStore(DB_TABLES.towTypes);
			yield towOnTypeStore.clear();

			const additionalBookingSectionStore = transaction.objectStore(DB_TABLES.additionalBookingSections);
			yield additionalBookingSectionStore.clear();

			const additionalBookingOptionStore = transaction.objectStore(DB_TABLES.additionalBookOptions);
			yield additionalBookingOptionStore.clear();
		});

		this.ferryTripIds = undefined;
	}

	public syncChangedData = async (ferryTripId: string) => {
		const bookingStatusChanges: BookingStatusChange[] = await createTransaction(
			this.database,
			[DB_TABLES.bookingStatusChanges],
			'readonly',
			async function* (transaction) {
				const bookingStatusChangeStore = transaction.objectStore(DB_TABLES.bookingStatusChanges);
				return yield bookingStatusChangeStore.getAll();
			});

		if (bookingStatusChanges.length === 0) {
			return;
		}

		try {
			await axios.post('/api/check-in/sync-check-in-data', {
				ferryTripId: ferryTripId,
				checkInData: bookingStatusChanges
					.map(x => ({ ...x, modified: serialiseDateTime(new Date(x.modified)) })),
			});
		} catch (err) {
			alertToast('There was an error syncing the check in data', 'error');
			console.error(err);

			return; // Do not delete the booking changes from the cache
		}

		// Delete all the booking changes from the cache
		await createTransaction(
			this.database,
			[DB_TABLES.bookingStatusChanges],
			'readwrite',
			async function* (transaction) {
				const bookingStatusChangeStore = transaction.objectStore(DB_TABLES.bookingStatusChanges);
				yield bookingStatusChangeStore.clear();
			});
	};

	/**
	 * Update the booking in the cache.
	 *
	 * This is used when the user is still online and they have updated the booking (or received a booking update via
	 * sockets) and we need to update the cache with the new data.
	 *
	 * @param booking
	 */
	public updateBooking = async (booking: CheckInBookingOverviewDto) => {
		const ferryTripId = booking.bookedSummary?.ferryTrip?.id;
		if (!ferryTripId) {
			throw new Error('Booking does not have a ferry trip');
		}

		const ferryTripIsCached = await this.isFerryTripCached(ferryTripId);
		if (!ferryTripIsCached) {
			throw new Error('Ferry trip is not cached');
		}

		await createTransaction(this.database, [DB_TABLES.bookings], 'readwrite', async function* (transaction) {
			const bookingStore = transaction.objectStore(DB_TABLES.bookings);
			yield bookingStore.put(toDbObject(booking));
		});
	}

	/**
	 * Set a local change to the booking check in status. In the future this
	 * @param bookingId the id of the booking to update in the cache
	 * @param checkedIn
	 */
	public setBookingCheckInStatus = async (bookingId: string, checkedIn: boolean) => {
		await createTransaction(
			this.database,
			[DB_TABLES.bookingStatusChanges, DB_TABLES.bookings],
			'readwrite',
			async function* (transaction) {
				const bookingStore = transaction.objectStore(DB_TABLES.bookings);
				const booking = yield bookingStore.get(bookingId);

				// Ignore setting the check in status if the booking is not found
				if (booking === undefined) {
					return;
				}

				const bookingChangeStore = transaction.objectStore(DB_TABLES.bookingStatusChanges);
				const bookingChange: BookingStatusChange = {
					id: uuid.v4(),
					bookingId,
					ferryTripId: booking.bookedSummary.ferryTrip.id,
					checkedIn,
					modified: Date.now(),
				};

				yield bookingChangeStore.add(bookingChange);
			});
	}

	public hasCheckInChanges = async (): Promise<boolean> => {
		if (!this.isConnected) {
			await this.connect();
		}

		return createTransaction(
			this.database,
			[DB_TABLES.bookingStatusChanges],
			'readonly',
			async function* (transaction) {
				const bookingStatusChangeStore = transaction.objectStore(DB_TABLES.bookingStatusChanges);
				const bookingStatusChanges = yield bookingStatusChangeStore.getAll();

				return bookingStatusChanges.length !== 0;
			});
	};

	public dispose(): void {
		window.removeEventListener('online', this.handleOnlineChange);
		window.removeEventListener('offline', this.handleOfflineChange);

		// Disconnect from the database
		this.database.close();
		this.isConnected = false;

		CheckInCache.instance = undefined;
	}

	/**
	 * If the page has been reloaded, the user may not be on the correct page
	 */
	public handlePageReload = async (): Promise<boolean> => {
		const { isStaff } = store;
		if (!isStaff) {
			return false;
		}

		// Always check if we can evict data from the cache
		await this.checkForCacheEviction();

		if (this.isOnline) {
			return false;
		}

		const hasCheckInCached = await this.hasCheckInCached();
		if (!hasCheckInCached) {
			return false;
		}

		// If the user is already on the check in page, do nothing
		const checkInUrl = await this.getCheckInPageForCache();
		const currentUrl = store.routerHistory.location.pathname;
		if (currentUrl.includes(checkInUrl)) {
			return false;
		}

		if (await ReturnToCheckInPageModal()) {
			store.routerHistory.push(checkInUrl);
			return true;
		}

		return false;
	}

	/**
	 * Redirect the user to the correct page for the check in page
	 */
	public getCheckInPageForCache = async (): Promise<string> => {
		if (!this.isConnected) {
			await this.connect();
		}

		const transaction = this.database.transaction(getAllTables(), 'readonly');

		const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
		const ferryTrips = await handleDbRequest(ferryTripStore.getAll());
		const ferryTripId = ferryTrips[0].id;

		return `${SERVER_URL}/check-in/${ferryTripId}`;
	}

	public hasCheckInCached = async (): Promise<boolean> => {
		return createTransaction(this.database, [DB_TABLES.ferryTrips], 'readonly', async function* (transaction) {
			const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
			const ferryTrips = yield ferryTripStore.getAll();

			return ferryTrips.length !== 0;
		});
	}

	/**
	 * Check if any data in the cach has expired and can be removed
	 */
	public checkForCacheEviction = async () => {
		// Do nothing if we are offline
		if (!this.isOnline) {
			return;
		}

		await this.connect();

		const expiredFerryTripIds = await createTransaction(
			this.database,
			getAllTables(),
			'readonly',
			async function* (transaction) {
				const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
				const bookingUpdateStore = transaction.objectStore(DB_TABLES.bookingStatusChanges);

				const ferryTrips: IFerryTripEntityAttributes[] = yield ferryTripStore.getAll();
				const ferryTripIds = await Promise.all(ferryTrips.map(async ferryTrip => {
					// Do nothing if the ferry trip is still departing
					const now = Date.now();

					// Only clear the ferry trip if:
					// 1. It has been more than an hour since the ferry trip departed
					// 2. It has been more than an hour since the cache for the ferry trip was created
					const hasNotArrived = ferryTrip.arrivalDateTime != null
						&& +ferryTrip.arrivalDateTime + TRIP_EXPIRY_TIME > now;
					const cachedRecently = ferryTrip.created != null
						&& +ferryTrip.created + CACHE_EXPIRY_TIME > now;
					if (hasNotArrived || cachedRecently) {
						return;
					}

					// Check if there are any changes to the ferry trip
					const bookingsUpdated = await handleDbRequest(bookingUpdateStore.index('ferryTripId')
						.count(ferryTrip.id));
					if (bookingsUpdated > 0) {
						return;
					}

					return ferryTrip.id;
				}));

				return ferryTripIds.filter(x => isNotNullOrUndefined(x));
			});

		// If any ferry trips were returned from above, clear the cache (in the future we will need to delete specific
		// ferry trips)
		if (expiredFerryTripIds.length !== 0) {
			await this.clearCachedData();
		}
	}

	public isFerryTripCached = async (ferryTripId?: string): Promise<boolean> => {
		if (this.ferryTripIds === undefined) {
			this.ferryTripIds = await createTransaction<string[]>(
				this.database,
				[DB_TABLES.ferryTrips],
				'readonly',
				async function* (transaction) {
					const ferryTripStore = transaction.objectStore(DB_TABLES.ferryTrips);
					const ferryTrips: IFerryTripEntityAttributes[] = yield ferryTripStore.getAll();

					return ferryTrips
						.filter(x => isNotNullOrUndefined(x.id))
						.map(x => x.id!);
				});
		}

		if (isNullOrUndefined(ferryTripId)) {
			return this.ferryTripIds.length !== 0;
		}

		return this.ferryTripIds.find(x => x === ferryTripId) !== undefined;
	}

	public static getInstance(connect: boolean = true): CheckInCache {
		if (!CheckInCache.instance) {
			CheckInCache.instance = new CheckInCache();

			if (connect) {
				CheckInCache.instance.connect();
			}
		}
		return CheckInCache.instance;
	}

	/**
	 * Update the database schema
	 *
	 * @param db the connection to the database
	 * @param oldVersion version of the database before the update. If we need to rebuild particular tables based on the
	 * previous version, we can do that here (for example if we need to add a new index to a table)
	 */
	private updateSchema = (db: IDBDatabase, oldVersion: number) => {
		addTable(db, DB_TABLES.ferryTrips);
		addTable(db, DB_TABLES.bookings, 'id', s => {
			s.createIndex('ferryTripId', 'bookedSummary.ferryTrip.id', { unique: false });
		});
		addTable(db, DB_TABLES.cargoTypes);
		addTable(db, DB_TABLES.towTypes);
		addTable(db, DB_TABLES.additionalBookingSections);
		addTable(db, DB_TABLES.additionalBookOptions);
		addTable(db, DB_TABLES.bookingStatusChanges, 'id', s => {
			s.createIndex('bookingId', 'bookingId', { unique: false });
			s.createIndex('ferryTripId', 'ferryTripId', { unique: false });
		});
	}
}
