import React, { useEffect, useState } from 'react';
import If from 'Views/Components/If/If';
import { PassengerTypeKey } from 'Models/Enums';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { useLocalStore } from 'mobx-react';
import { PassengerDetailForm } from './PassengerDetailForm';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import {
	FetchPassengerTabData,
} from 'Services/Api/_HumanWritten/BookingWizardDataService';
import {
	BookingWizardData,
	PassengersInfo,
	WizardErrors,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	combineAllPassengerTickets,
	completedPassengerTickets,
	incompletePassengerTickets,
	validatePassengers,
	PassengerError,
} from './PassengerTabHelpers';
import { IBookingFormState } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/BookingFormState';
import { isNullOrUndefined } from 'Util/TypeGuards';

interface PreviousPassengerSelectionState {
	previousPassengersSelected: string;
}

export interface PassengerDetailsListProps {
	forCheckIn?: boolean;
	className?: string;
	wizardData: BookingWizardData | IBookingFormState;
	onUpdateWizardData?: (wizardData: BookingWizardData) => void;
	errors: WizardErrors<BookingWizardData>;
	onPreviousPassengerSelect: (passenger: PassengersInfo, incompletePassengers: PassengersInfo[]) => void;
	onClearForm: (passenger: PassengersInfo) => void;
	resetContinue?: () => void;
}

export function PassengerDetailsList({
	forCheckIn = false,
	className,
	wizardData,
	onUpdateWizardData,
	errors,
	onPreviousPassengerSelect,
	onClearForm,
	resetContinue,
}: PassengerDetailsListProps) {
	const [fetched, setFetched] = useState<boolean>(false);
	const [expandedAreas, setExpandedAreas] = useState<string[]>([]);
	const [previousPassengers, setPreviousPassengers] = useState<PassengersInfo[]>([]);
	const [completedPassengers, setCompletedPassengers] = useState<PassengersInfo[]>([]);
	const [incompletePassengers, setIncompletePassengers] = useState<PassengersInfo[]>([]);
	const [passengersErrors, setPassengersErrors] = useState<PassengerError[]>([]);

	const combinedPassengerTickets = combineAllPassengerTickets(wizardData);

	useEffect(() => {
		if (!fetched) {
			FetchPassengerTabData(wizardData.userId).then(x => {
				if (x !== null) {
					setPreviousPassengers((x ?? []) as PassengersInfo[]);
					setFetched(true);
				}
			});
		}
	}, [wizardData, fetched]);

	useEffect(() => {
		setCompletedPassengers(completedPassengerTickets(combinedPassengerTickets));
		setIncompletePassengers(incompletePassengerTickets(combinedPassengerTickets));
	}, [expandedAreas]);

	useEffect(() => {
		if (incompletePassengers.length > 0 && expandedAreas.length === 0) {
			toggleExpanded(incompletePassengers[0].id);
		}
	}, [incompletePassengers]);

	const toggleExpanded = (id: string, clearExpanded: boolean = true) => {
		setExpandedAreas((prev: string[]) => {
			// Check if the index is already expanded
			const isExpanded = prev.includes(id);
			if (isExpanded) {
				// Only update state if there's a change
				getPassengerErrors();
				if (isNullOrUndefined(passengersErrors.find(x => x.id === id))) {
					return prev.filter(i => i !== id);
				}
			}
			if (clearExpanded) {
				return [id];
			}
			return [...prev, id];
		});
	};

	const state = useLocalStore<PreviousPassengerSelectionState>(() => ({
		previousPassengersSelected: '',
	}));

	const getPassengerErrors = () => {
		setPassengersErrors(validatePassengers(combinedPassengerTickets) ?? []);
	};

	useEffect(() => {
		getPassengerErrors();
	}, [errors]);

	useEffect(() => {
		if (passengersErrors !== null && passengersErrors.length > 0 && Object.keys(errors).length > 0) {
			setExpandedAreas([passengersErrors[0]?.id]); // Expand the first error passenger
			ScrollToError('end');
		}
	}, [errors]);

	const filteredPreviousPassengers = previousPassengers
		?.filter(x => !combinedPassengerTickets
			.map(y => y.id).includes(x.id))
		?.filter(x => incompletePassengers
			.map(y => y.key).includes(x.key));

	return (
		<>
			<If condition={previousPassengers.length > 0}>
				<Combobox
					model={state}
					modelProperty="previousPassengersSelected"
					label="Select from previous passengers"
					className="previous-passengers__combobox"
					placeholder={
						filteredPreviousPassengers?.length > 0
							? 'Select previous passengers'
							: 'No previous passengers to select'
					}
					options={filteredPreviousPassengers?.map(x => {
						return {
							display: `${x.firstName} ${x.lastName}`,
							description: passengerTypeStore.getSingularName(x.key as PassengerTypeKey),
							value: x.id,
						};
					}) ?? []}
					onChange={(e, data) => {
						if (data.value) {
							const passenger = previousPassengers
								.find(x => x.id === data.value);
							if (passenger) {
								onPreviousPassengerSelect(passenger, incompletePassengers);
								setExpandedAreas([]);
							}
						}
					}}
					isDisabled={filteredPreviousPassengers?.length === 0}
				/>
			</If>
			<div className="passenger-details-list__container">
				<If condition={incompletePassengers.length > 0}>
					<div className="incomplete-passengers">
						<p className="sorted-passenger-section__header">
							Pending information ({incompletePassengers.length})
						</p>
						{incompletePassengers.map(x => {
							const isExpanded = expandedAreas.includes(x.id);
							const index = combinedPassengerTickets.findIndex(y => y.id === x.id);
							return (
								<PassengerDetailForm
									key={x.id}
									count={index + 1}
									passenger={x}
									forCheckIn={forCheckIn}
									wizardData={wizardData}
									onUpdateWizardData={onUpdateWizardData}
									onClearForm={() => onClearForm(x)}
									expanded={isExpanded}
									setExpanded={() => toggleExpanded(x.id)}
									usingExistingPassenger={previousPassengers.some(y => y.id === x.id)}
									showErrors={Object.keys(errors).length > 0}
									errors={passengersErrors}
									setErrors={(e: PassengerError[]) => setPassengersErrors(e)}
									resetContinue={resetContinue}
								/>
							);
						})}
					</div>
				</If>
				<If condition={completedPassengers.length > 0}>
					<div className="completed-passengers">
						<p className="sorted-passenger-section__header">
							Completed ({completedPassengers.length})
						</p>
						{completedPassengers.map(x => {
							const isExpanded = expandedAreas.includes(x.id);
							const index = combinedPassengerTickets
								.findIndex(y => y.id === x.id);
							return (
								<PassengerDetailForm
									key={x.id}
									count={incompletePassengers.length + index + 1}
									passenger={x}
									forCheckIn={forCheckIn}
									wizardData={wizardData}
									onUpdateWizardData={onUpdateWizardData}
									onClearForm={() => onClearForm(x)}
									expanded={isExpanded}
									setExpanded={(id, clearExpanded) => toggleExpanded(id, clearExpanded)}
									usingExistingPassenger={previousPassengers.some(y => y.id === x.id)}
									showErrors={Object.keys(errors).length > 0}
									errors={passengersErrors}
									setErrors={(e: PassengerError[]) => setPassengersErrors(e)}
									resetContinue={resetContinue}
								/>
							);
						})}
					</div>
				</If>
			</div>
		</>
	);
}
