import axios, { AxiosResponse } from 'axios';
import gql from 'graphql-tag';
import { SERVER_URL } from 'Constants';
import { EmailTemplateEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { buildUrl } from 'Util/FetchUtils';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { isNullOrUndefined } from 'Util/TypeGuards';

export const EMAIL_TEMPLATE_API_URL = `${SERVER_URL}/api/entity/EmailTemplateEntity`;

export async function getTripNewDepartureEmailBody(ferryTripId: string): Promise<string | null> {
	try {
		const response = await axios.get<any, AxiosResponse<string>>(
			buildUrl(`${EMAIL_TEMPLATE_API_URL}/trip-new-departure-email-body`, { ferryTripId }),
		);

		return response.data.trim();
	} catch (e) {
		console.error(e);
		return null;
	}
}

export async function getTripNewDepartureEmailSubjectName() {
	const where: IWhereCondition<EmailTemplateEntity>[] = [
		{ path: 'Key', comparison: 'equal', value: 'Mia.Assets.Emails.TripNewDepartureEmailBody' },
	];

	const response = await store.apolloClient.query<{ emailTemplateEntity: EmailTemplateEntity }, any>({
		query: gql`
			query tripNewDepartureSubjectName($where: [WhereExpressionGraph]) {
				# ${new Date()}
				emailTemplateEntity(where: $where) {
					subjectName
				}
			}
		`,
		fetchPolicy: 'network-only',
		variables: { where },
	});

	const { subjectName } = response?.data?.emailTemplateEntity;

	if (response.error || response.errors || isNullOrUndefined(subjectName)) {
		console.error('Something went wrong while checking if transaction only has new bookings.');
		return null;
	}

	return subjectName;
}

export async function getContactUsEmailAddress() {
	return axios.get(
		buildUrl(`${EMAIL_TEMPLATE_API_URL}/contact-us-email-address`),
	).then(x => x.data as string);
}
