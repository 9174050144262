import { ApolloQueryResult, gql } from '@apollo/client';
import { store } from 'Models/Store';
import { AdditionalBookingSectionEntity, CargoTypeEntity, TowOnTypeEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import { HasCondition } from 'Views/Components/ModelCollection/ModelQuery';

interface VehicleTypesOptimizedResult {
	cargoTypeEntitys: CargoTypeEntity[];
}

interface TowOnTypesOptimizedResult {
	towOnTypeEntitys: TowOnTypeEntity[];
}

/**
 * Returns list of all vehicle type entities with limited accessible properties.
 *
 * These include id, cargoMake and cargoModel.
 */
export async function getCargoTypesOptimized(): Promise<CargoTypeEntity[]> {
	const response = await store.apolloClient.query<VehicleTypesOptimizedResult, any>({
		query: gql`
			query cargoTypes {
				cargoTypeEntitys {
					id
					cargoMake
					cargoModel
					minimumLengthId
					minimumWeightId
				}
			}
		`,
		fetchPolicy: 'network-only', // TODO: This could be changed to cached
	});

	if (response.error || response.errors) {
		alertToast('Something went wrong while fetching cargo types');
		return [];
	}

	return response.data.cargoTypeEntitys.map(x => new CargoTypeEntity(x));
}

/**
 * Returns list of all tow on type entities with limited accessible properties.
 *
 * These include id, label, minimumLengthId
 */
export async function getTowOnTypesOptimized(): Promise<TowOnTypeEntity[]> {
	const response = await store.apolloClient.query<TowOnTypesOptimizedResult, any>({
		query: gql`
			query towOnTypes {
				towOnTypeEntitys {
					id
					label
					minimumLengthId
					minimumWeightId
				}
			}
		`,
		fetchPolicy: 'network-only', // TODO: This could be changed to cached
	});

	if (response.error || response.errors) {
		alertToast('Something went wrong while fetching tow on types');
		return [];
	}

	return response.data.towOnTypeEntitys.map(x => new TowOnTypeEntity(x));
}

/**
 * Returns list of all additional booking section entities with limited accessible properties.
 *
 * BookingSections include id, name, information, bookingOptions.
 *
 * BookingOptions include id, name, isSingular, isvehicleonly, disabled, removable.
 */
export async function getAdditionalBookingSections(config: {
	ferryIds?: string[];
	routeIds?: string[];
}): Promise<AdditionalBookingSectionEntity[]> {
	const hasConditions: HasCondition<AdditionalBookingSectionEntity>[][] = [
		[
			{
				path: 'ferriess',
				conditions: [
					config.ferryIds?.map(x => ({ path: 'ferriesId', comparison: 'equal', value: x })) ?? [],
				],
			},
			{
				path: 'routess',
				conditions: [
					config.routeIds?.map(x => ({ path: 'routesId', comparison: 'equal', value: x })) ?? [],
				],
			},
		],
	];

	const response = await store.apolloClient.query<
		{
			additionalBookingSectionEntitys: AdditionalBookingSectionEntity[];
		},
		{
			has: HasCondition<AdditionalBookingSectionEntity>[][];
		}
	>({
		query: gql`
			query addOnsWhere($has: [[HasConditionType]]) {
				# ferries-${config.ferryIds?.join(',')}
				# routess-${config.routeIds?.join(',')}
				additionalBookingSectionEntitys(has: $has) {
					id
					name
					information
					order
					additionalBookingOptions {
						id
						name
						isSingular
						isvehicleonly
						disabled
						removable
						order
						abbreviation
						additionalBookingSection {
							id
							name
							information
							order
							routess {
							  id
							  routes {
							    id
							    name
							  }
							}
							ferriess {
							  id
							  ferries {
							    id
							    name
							  }
							}
						}
					}
				}
			}
		`,
		variables: {
			has: hasConditions,
		},
		fetchPolicy: 'network-only', // TODO: This could be changed to cached
	});

	if (response.error || response.errors) {
		alertToast('Something went wrong while fetching vehicle types');
		return [];
	}

	return response.data.additionalBookingSectionEntitys.map(x => new AdditionalBookingSectionEntity(x));
}
