import * as React from 'react';
import { showModal } from './Base/BaseModalContents';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';

export type CheckInDepartedModalContentsProps = Pick<DualActionModalContentProps, 'onConfirm'>;

export function CheckInDepartedModalContents({ onConfirm }: CheckInDepartedModalContentsProps) {
	const message = React.useMemo(() => {
		return 'This trip has departed. Do you still want to check in?';
	}, []);

	return (
		<DualActionModalContent
			title="Departed trip"
			body={message}
			onConfirm={onConfirm}
			confirmContent="Continue"
			fixedCancelWidth
		/>
	);
}

export function showCheckInDepartedModal(props: CheckInDepartedModalContentsProps) {
	showModal({
		key: 'boarding-departed-trip',
		content: <CheckInDepartedModalContents {...props} />,
		modalProps: {
			shouldCloseOnOverlayClick: false,
		},
	});
}
