import { confirmModal } from 'Views/Components/Modal/ModalUtils';

export async function CheckInBookingConfirmationModal(
	message: JSX.Element[],
	onConfirm: () => void,
	onCancel?: () => void,
	confirmText: string = 'Check-in',
	cancelText: string = 'Close',
	resolveOnClose: boolean = false,
) {
	await confirmModal(
		'Confirm booking?',
		message,
		{
			confirmText,
			cancelText,
			shouldCloseOnOverlayClick: false,
			resolveOnClose: resolveOnClose,
		},
	)
		.then(async result => {
			if (result) {
				await onConfirm();
			}
		})
		.catch(() => {
			if (onCancel) {
				onCancel();
			}
		});
}
