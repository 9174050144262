import { useLocationById } from 'Global/JourneyElements/Hooks/useLocationById';
import useAddOnsContext from 'Hooks/useAddOnsContext';

export const useAddOnRouteTitle = () => {
	const { wizardData } = useAddOnsContext();
	const startLocation = useLocationById(wizardData.fromLocationId);
	const endLocation = useLocationById(wizardData.toLocationId);

	if (!startLocation || !endLocation) {
		throw new Error(
			`Locations are missing fromLocationId: ${wizardData.fromLocationId}, toLocationId: ${wizardData.fromLocationId}`,
		);
	}

	const departingName = `${startLocation.name} to ${endLocation.name}`;
	const returningName = `${endLocation.name} to ${startLocation.name}`;

	return { departingName, returningName };
};
