import * as React from 'react';
import { observer } from 'mobx-react';
import InputCounter from './InputCounter';
import { action, runInAction } from 'mobx';
import {
	getDefaultPassengerInfo,
	PassengersInfo,
} from '../FerryTripBookingWizard/BookingWizardData';
import { stringNotEmpty } from 'Util/TypeGuards';
import {
	RenderPassengerRemovalModal,
} from '../FerryTripBookingWizard/Sidebar/FerryFilters/PassengerDetails/Modals/PassengerRemovalModal';
import { passengerTicketIsEmpty } from '../FerryTripBookingWizard/WizardSteps/Passengers/PassengerTabHelpers';
import passengerTypeStore from '../../../../Models/PassengerTypeStore';
import { PassengerTypeKey } from '../../../../Models/Enums';

export interface InputCounterStatefulProps {
	model: any;
	modelProperty: string;
	passengerTypeKey: PassengerTypeKey;
	title: string;
	subtitle?: string;
	tooltip?: string;
	onAfterChange?: () => void;
	minCount?: number;
	maxCount?: number;
	disabled: boolean;
}

function PassengerInputCounterStateful({
	model,
	modelProperty,
	passengerTypeKey,
	title,
	subtitle,
	tooltip,
	onAfterChange,
	minCount = 0,
	maxCount,
	disabled,
}: InputCounterStatefulProps) {
	const onAdd = action(() => {
		const passengerTypeCount = passengerTypeStore.getQuantity(passengerTypeKey);

		for (let i = 0; i < passengerTypeCount; i++) {
			model[modelProperty].push(getDefaultPassengerInfo(passengerTypeKey));
		}

		if (onAfterChange) {
			onAfterChange();
		}
	});

	const onSubtract = action(() => {
		const passengers = model[modelProperty] as PassengersInfo[];

		// We will automatically remove passengers if they have no data assigned to them
		const emptyPassengers = passengers.filter(x => {
			return passengerTicketIsEmpty(x);
		});

		if (emptyPassengers.length === 0 && passengers.length > 1) {
			// Do a pop-up here to search through the passengers and choose who to remove
			RenderPassengerRemovalModal(passengers).then(id => {
				if (stringNotEmpty(id)) {
					runInAction(() => {
						model[modelProperty] = passengers.filter((y: PassengersInfo) => y.id !== id);
						if (onAfterChange) {
							onAfterChange();
						}
					});
				}
			});
		} else {
			// Remove the last item
			runInAction(() => {
				passengers.pop();
				model[modelProperty] = passengers;
				if (onAfterChange) {
					onAfterChange();
				}
			});
		}
	});

	return (
		<div className="input-counter--box">
			<InputCounter
				title={title}
				subtitle={subtitle}
				tooltip={tooltip}
				amount={model[modelProperty].length / passengerTypeStore.getQuantity(passengerTypeKey)}
				subtractDisabled={disabled || model[modelProperty].length <= minCount}
				addDisabled={disabled
					|| (maxCount !== undefined
						? model[modelProperty].length >= maxCount
						: false)}
				onAdd={() => onAdd()}
				onSubtract={() => onSubtract()}
			/>
		</div>
	);
}

export default observer(PassengerInputCounterStateful);
