import React from 'react';
import { observer } from 'mobx-react';

const FerryScheduleMultiStop = React.lazy(() => import('../FerryScheduleMultiStop/FerryScheduleMultiStop'));

function FerrySchedule() {
	return (
		<React.Suspense fallback={<div />}>
			<FerryScheduleMultiStop />
		</React.Suspense>
	);
}

export default observer(FerrySchedule);
