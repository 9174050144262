import * as React from 'react';
import passengerTypeStore from 'Models/PassengerTypeStore';
import InputCounter from '../../../../PassengerAmount/InputCounter';
import {
	BookingWizardData,
	getDefaultPassengerInfo,
	PassengersInfo,
} from '../../../BookingWizardData';
import { PassengerTypeKey } from 'Models/Enums';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { UpdateWizardData } from './FerryFilterPassengers';
import { isNullOrUndefined, stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import { RenderPassengerRemovalModal } from './Modals/PassengerRemovalModal';

export interface PassengerTypeInputsProps {
	wizardData: BookingWizardData;
	onUpdateFilters: UpdateWizardData;
}

export function PassengerTypeInputs({
	wizardData,
	onUpdateFilters,
}: PassengerTypeInputsProps) {
	return (
		<>
			{passengerTypeStore.passengerTypes.map(x => {
				const key = x.passengerTypeKey;
				const propertyName = passengerTypeStore.getWizardDataProperty(key);

				const title = passengerTypeStore.getPluralName(key);
				const subtitle = passengerTypeStore.getDescription(key);
				const passengerTypeQuantity = passengerTypeStore.getQuantity(key);
				const amount = wizardData[propertyName].length / passengerTypeQuantity;
				const tooltip = passengerTypeStore.getTooltip(key);
				const subtractDisabled = isSubtractDisabled(wizardData, key);

				return (
					<InputCounter
						key={x.id}
						className={key}
						title={title}
						subtitle={subtitle}
						tooltip={tooltip}
						amount={amount}
						subtractDisabled={subtractDisabled}
						onSubtract={() => onPassengerSubtract(wizardData, onUpdateFilters, key)}
						onAdd={() => onPassengerAdd(wizardData, onUpdateFilters, key)}
					/>
				);
			})}
		</>
	);
}

function isSubtractDisabled(wizardData: BookingWizardData, key: PassengerTypeKey) {
	const { tabSelected } = wizardData;
	const propertyName = passengerTypeStore.getWizardDataProperty(key);
	const passengerCount = wizardData[propertyName].length;

	if (key === 'A' && tabSelected === 'vehicle') {
		return passengerCount <= whiteLabelStore.minAdultsForVehicle;
	}

	return passengerCount <= 0;
}

function onPassengerSubtract(wizardData: BookingWizardData, onUpdateFilters: UpdateWizardData, key: PassengerTypeKey) {
	const newData: BookingWizardData = { ...wizardData };

	const propertyName = passengerTypeStore.getWizardDataProperty(key);
	const passengers = getWizardDataPassengerTickets(newData, propertyName);
	const passengerTypeCount = passengerTypeStore.getQuantity(key);

	// We will automatically remove passengers if they have no data assigned to them
	const filteredPassengers = passengers.filter(x => {
		return (
			stringIsEmpty(x.firstName)
			&& stringIsEmpty(x.lastName)
			&& stringIsEmpty(x.phone)
			&& stringIsEmpty(x.email)
			&& stringIsEmpty(x.address)
			&& stringIsEmpty(x.gender)
			&& isNullOrUndefined(x.age)
			&& isNullOrUndefined(x.dateOfBirth)
		);
	});

	if (filteredPassengers.length === 0 && passengers.length > 1 && passengerTypeCount === 1) {
		// Do a pop-up here to search through the passengers and choose who to remove
		RenderPassengerRemovalModal(passengers).then(id => {
			if (stringNotEmpty(id)) {
				newData[propertyName] = passengers.filter(y => y.id !== id);
				onUpdateFilters(newData);
			}
		});
	} else {
		// Remove the last item
		for (let i = 0; i < passengerTypeCount; i++) {
			passengers.pop();
		}
		newData[propertyName] = passengers;
		onUpdateFilters(newData);
	}
}

function onPassengerAdd(wizardData: BookingWizardData, onUpdateFilters: UpdateWizardData, key: PassengerTypeKey) {
	const newData: BookingWizardData = { ...wizardData };

	const propertyName = passengerTypeStore.getWizardDataProperty(key);
	const passengers = getWizardDataPassengerTickets(newData, propertyName);
	const passengerTypeCount = passengerTypeStore.getQuantity(key);

	for (let i = 0; i < passengerTypeCount; i++) {
		passengers.push(getDefaultPassengerInfo(key));
	}
	newData[propertyName] = passengers;

	onUpdateFilters(newData);
}

function getWizardDataPassengerTickets(wizardData: BookingWizardData, propertyName: string): PassengersInfo[] {
	return wizardData[propertyName];
}
