import { FetchGivenFerryTrips } from 'Util/_HumanWritten/FerryTrips/FetchGivenTrips';
import { FerryTripEntity } from 'Models/Entities';
import { BookingWizardPageParams } from 'Views/Pages/BookingWizardPage';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { store } from 'Models/Store';
import { getStoredEventWizardData, getStoredWizardData } from 'Services/Api/_HumanWritten/BookingWizardCacheService';
import { SelectedTrips } from './BookingWizardWrap';
import { ParsedUrlQuery } from 'querystring';
import {
	BookingWizardData,
	getBookingWizardData,
	getBookingWizardDataCacheToken,
	removeBookingWizardDataCacheToken,
	saveBookingWizardDataToLocalStorage,
} from './BookingWizardData';
import {
	EventBookingWizardData,
	getEventBookingWizardData,
	getEventBookingWizardDataCacheToken,
	removeEventBookingWizardDataCacheToken,
	saveEventBookingWizardDataToLocalStorage,
} from '../EventsBookingWizard/EventsBookingWizardData';

export function fetchTripsForWizard(
	departingTripId: string,
	returningTripId: string,
	selectedTrips: SelectedTrips | null,
	setSelectedTrips: (trips: SelectedTrips) => void,
	userId: string | null = null,
) {
	const idsToFetch: string[] = [];
	const updatedSelectedTrips: SelectedTrips = {};

	/*
		Cases to cover here:
			* Id doesn't exist, trip does exist
				set trip to undefined
			* Id doesn't exist, trip doesn't exist
				set trip to undefined
			* Id does exist, trip doesn't exist
				fetch the trip associated with the id
			* Id does exist, trip does exist and is the same trip
				preserve the existing status
			* Id does exist, trip does exist and is a different trip
				fetch the trip associated with the id
	 */
	if (departingTripId === '') {
		updatedSelectedTrips.departingTrip = undefined;
	}
	if (departingTripId !== '') {
		if (departingTripId !== (selectedTrips?.departingTrip?.id ?? null)) {
			idsToFetch.push(departingTripId);
		} else {
			idsToFetch.push(departingTripId);
		}
	}

	if (returningTripId === '') {
		updatedSelectedTrips.returningTrip = undefined;
	}
	if (returningTripId !== '') {
		if (returningTripId !== (selectedTrips?.returningTrip?.id ?? null)) {
			idsToFetch.push(returningTripId);
		} else {
			idsToFetch.push(returningTripId);
		}
	}

	if (idsToFetch.length > 0) {
		FetchGivenFerryTrips(idsToFetch, null, userId).then(fetchedTrips => {
			if (idsToFetch.includes(departingTripId)) {
				updatedSelectedTrips.departingTrip = fetchedTrips.find(trip => trip.id === departingTripId);
			}

			if (idsToFetch.includes(returningTripId)) {
				updatedSelectedTrips.returningTrip = fetchedTrips.find(trip => trip.id === returningTripId);
			}

			setSelectedTrips(updatedSelectedTrips);
		});
	} else if (selectedTrips !== updatedSelectedTrips) {
		setSelectedTrips(updatedSelectedTrips);
	}
}

export function fetchBulkBookingTrips(tripIds: string[], setFerryTrips: (trips: FerryTripEntity[]) => void) {
	FetchGivenFerryTrips(tripIds).then(x => {
		setFerryTrips(x);
	});
}

export function getBookingWizardParameters(parsedParams?: ParsedUrlQuery): BookingWizardPageParams | null {
	if (!parsedParams) {
		return null;
	}

	if (getBookingWizardDataCacheToken() !== null) {
		return null;
	}

	const {
		date,
		// Caution: from/to url parameters are is still being used
		from,
		to,
		// Ideally use fromLocation/toLocation
		fromLocation,
		toLocation,
		route,
		type,
		ferryTripId,
		fromLocationName,
		toLocationName,
		bookingId,
		AccessCode,
		ErrorCode,
		redirectedFromEmail,
	} = parsedParams;

	if (isNotNullOrUndefined(AccessCode) || isNotNullOrUndefined(ErrorCode)) {
		// AccessCode is a query parameter which is supplied by stripe when we complete a payment. Stripe will navigate us to
		// a page of our choosing and give us the access code for us to verify the bookings. If AccessCode is supplied then
		// previously we would be navigated to the tickets page when we got that parameter, because there were parameters,
		// and the parameters did not fulfil the validation of earlier steps
		return null;
	}

	if (redirectedFromEmail === 'true') {
		store.setRedirectedFromWaitlistEmail(true);
	}

	let departureDate: Date | undefined;
	if (date) {
		departureDate = new Date(Date.parse(date as string));
	}

	// Feature: Multi-stop - parsing departing/returning journey params
	const departingJourneyParams = parseDepartingJourneyParams(parsedParams);
	const returningJourneyParams = parseReturningJourneyParams(parsedParams);

	return {
		date: departureDate,
		fromLocation: (from ?? fromLocation) as string,
		toLocation: (to ?? toLocation) as string,
		routeId: route as string,
		type: type && parsedParams.type === 'passenger' ? 'passenger' : 'vehicle',
		ferryTripId: ferryTripId as string,
		fromLocationName: fromLocationName as string,
		toLocationName: toLocationName as string,
		bookingId: bookingId as string,
		...departingJourneyParams,
		...returningJourneyParams,
	};
}

export async function getCachedBookingWizardData(): Promise<BookingWizardData> {
	const token = getBookingWizardDataCacheToken();
	if (token != null) {
		const wizardData = await getStoredWizardData(token);
		saveBookingWizardDataToLocalStorage(wizardData);
		removeBookingWizardDataCacheToken();
	}
	return getBookingWizardData(null);
}

export async function getCachedEventBookingWizardData(): Promise<EventBookingWizardData | null> {
	const token = getEventBookingWizardDataCacheToken();
	if (token != null) {
		const wizardData = await getStoredEventWizardData(token);
		saveEventBookingWizardDataToLocalStorage(wizardData);
		removeEventBookingWizardDataCacheToken();
	}
	return getEventBookingWizardData(undefined);
}

function parseDepartingJourneyParams(
	parsedParams: ParsedUrlQuery,
): Pick<BookingWizardPageParams, 'departingTripId' | 'departingStartStopId' | 'departingEndStopId'> | null {
	const { departingTripId, departingStartStopId, departingEndStopId } = parsedParams;

	const isTripIdValid = typeof departingTripId === 'string';
	const isStartStopIdValid = typeof departingStartStopId === 'string';
	const isEndStopIdValid = typeof departingEndStopId === 'string';
	const isAllString = isTripIdValid && isStartStopIdValid && isEndStopIdValid;

	if (isAllString && departingTripId && departingStartStopId && departingEndStopId) {
		// This confirms that the params are not empty strings
		const params = {
			departingTripId,
			departingStartStopId,
			departingEndStopId,
		};
		if (process.env.NODE_ENV === 'development') {
			console.log('WIZARD: Successfully parsed a departing trip from url query', params);
		}
		return params;
	}

	if (process.env.NODE_ENV === 'development') {
		console.warn('WIZARD: Attempted to define a departing trip, but had missing info.', {
			departingTripId,
			departingStartStopId,
			departingEndStopId,
		});
	}

	return null;
}

function parseReturningJourneyParams(
	parsedParams: ParsedUrlQuery,
): Pick<BookingWizardPageParams, 'returningTripId' | 'returningStartStopId' | 'returningEndStopId'> | null {
	const { returningTripId, returningStartStopId, returningEndStopId } = parsedParams;

	const isTripIdValid = typeof returningTripId === 'string';
	const isStartStopIdValid = typeof returningStartStopId === 'string';
	const isEndStopIdValid = typeof returningEndStopId === 'string';
	const isAllString = isTripIdValid && isStartStopIdValid && isEndStopIdValid;

	if (isAllString && returningTripId && returningStartStopId && returningEndStopId) {
		// This confirms that the params are not empty strings
		const params = {
			returningTripId,
			returningStartStopId,
			returningEndStopId,
		};
		if (process.env.NODE_ENV === 'development') {
			console.log('WIZARD: Successfully selected a returning trip from url query', params);
		}
		return params;
	}

	if (process.env.NODE_ENV === 'development') {
		console.warn('WIZARD: Attempted to define a returning trip, but had missing info.', {
			returningTripId,
			returningStartStopId,
			returningEndStopId,
		});
	}

	return null;
}
