import { create } from 'zustand';

import LocationEntity from 'Models/Entities/LocationEntity';
import RouteEntity from 'Models/Entities/RouteEntity';
import SegmentEntity from 'Models/Entities/SegmentEntity';
import StopEntity from 'Models/Entities/StopEntity';
import { JourneyElementsDto } from './Types/JourneyElementsDto';

interface JourneyElementsStore {
	// states
	isLoading: boolean;
	errors?: any;

	// entities
	locations: LocationEntity[];
	routes: RouteEntity[];
	segments: SegmentEntity[];
	stops: StopEntity[];

	// functions
	setElements(elements: JourneyElementsDto): void;
	setErrors(errors: any): void;
}

export const journeyElementsStore = create<JourneyElementsStore>(set => ({
	isLoading: true,
	errors: {},
	locations: [],
	routes: [],
	segments: [],
	stops: [],
	setElements(elements: JourneyElementsDto) {
		set({
			...linkJourneyElementAssociations(elements),
			isLoading: false,
		});
	},
	setErrors(errors: any) {
		set({ errors, isLoading: false });
	},
}));

const linkJourneyElementAssociations = ({
	locations,
	routes,
	segments,
	stops,
}: JourneyElementsDto) => {
	for (const route of routes) {
		const departure = locations.find(x => x.id === route.departureId);
		if (departure) {
			route.departure = departure;
			if (!departure.departureRoutes.includes(route)) {
				departure.departureRoutes.push(route);
			}
		}
		const destination = locations.find(x => x.id === route.destinationId);
		if (destination) {
			route.destination = destination;
			if (!destination.destinationRoutes.includes(route)) {
				destination.destinationRoutes.push(route);
			}
		}
	}

	for (const segment of segments) {
		const route = routes.find(x => x.id === segment.routeId);
		if (route) {
			segment.route = route;
			route.segments.push(segment);
		}
		const startStop = stops.find(x => x.id === segment.startStopId);
		if (startStop) {
			segment.startStop = startStop;
		}
		const endStop = stops.find(x => x.id === segment.endStopId);
		if (endStop) {
			segment.endStop = endStop;
		}
	}

	for (const stop of stops) {
		const route = routes.find(x => x.id === stop.routeId);
		if (route) {
			stop.route = route;
			route.stops.push(stop);
		}
		const location = locations.find(x => x.id === stop.locationId);
		if (location) {
			stop.location = location;
			stop.location.stops.push(stop);
		}
	}

	return {
		locations,
		routes,
		segments,
		stops,
	};
};
