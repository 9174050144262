import { useMemo } from 'react';

import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { calculateAvailabilityFromTicketTabTrip } from 'Util/_HumanWritten/CapacityCalculationUtils';

export const useAvailability = (trip: TicketsTabTrip) => {
	return useMemo(() => {
		return calculateAvailabilityFromTicketTabTrip(trip);
	}, [trip]);
};
