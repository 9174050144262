import React from 'react';

import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { TicketSelectionTab } from './TicketSelectionTab';

export interface TicketsTabProps {
	wizardData: BookingWizardData;
	onUpdateWizardData: (data: BookingWizardData) => void;
	selectedTrips: SelectedTrips;
}

export function TicketsTab({ wizardData, onUpdateWizardData, selectedTrips }: TicketsTabProps) {
	return (
		<TicketSelectionTab
			wizardData={wizardData}
			saveChanges={onUpdateWizardData}
			selectedTrips={selectedTrips}
		/>
	);
}
