import { createContext } from 'react';
import {
	action, observable, runInAction,
} from 'mobx';
import * as CheckInUtils from '../CheckInUtils';
import { store } from 'Models/Store';
import { CheckInLocationState } from 'Models/_HumanWritten/LocationState';
import { getBooking, updateCheckedInStatus } from 'Services/Api/_HumanWritten/CheckInService';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { BookingFormState, type IBookingFormState } from './BookingFormState';
import { FerryTripDto } from '../CheckInEntities/FerryTripDto';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { type BookingFormModeMutation } from '../BookingForm/BookingFormEdit/BookingFormEdit';
import { transformBookingToBookingFormState } from '../BookingForm/BookingFormUtils';
import FerryCheckInSocket from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/FerryCheckInSocket';
import {
	AdditionalBookingSectionEntity,
	FerryTripEntity,
	CargoTypeEntity,
	TowOnTypeEntity,
	AdditionalBookingOptionEntity,
} from 'Models/Entities';
import {
	getAdditionalBookingSections,
	getCargoTypesOptimized,
	getTowOnTypesOptimized,
} from 'Util/_HumanWritten/OptimizedQueriesUtils';
import CheckInCache from './CheckInCache';
import alertToast from '../../../../../../Util/ToastifyUtils';

export interface checkInBookingOptions {
	refresh?: boolean;
	triggerAlert?: boolean;

	// Skip sending a request to the server to update the booking (e.g. when only the client needs to update the display)
	skipUpdateServer?: boolean;
}

export interface ICheckInStore {
	ferryTripId: string;
	ferryTrip: FerryTripDto;
	bookings: CheckInBookingOverviewDto[];
	formState: IBookingFormState;
	showFilters: boolean;
	/**
	 * True if the loaded ferry trip has not departed based on the trip's departure time OR the staff member has
	 * confirmed that it is ok to board a departed trip. When false, a warning popup will appear when
	 * 'Scan QR code' button is clicked.
	 */
	skipFerryHasDepartedModal: boolean;
	usingAddFlow: boolean;

	/**
	 * Load the ferry trip and booking entities. This is usually only triggered once during start of CheckInPage.
	 * @param id The ferry trip id.
	 */
	loadFerryTrip: (id: string) => Promise<void>;
	setBookings: (x: CheckInBookingOverviewDto[]) => void;
	/**
	 * Update `skipFerryHasDepartedModal` to true so that the popup doesn't appear again.
	 */
	confirmCheckInForDepartedTrip: () => void;
	/**
	 * Process the form data to...
	 * Then redirect user to review page.
	 * @param mode Process the form as Add or Edit booking
	 * @param applyToReturn Only applicable for Edit booking where return trip is to be updated.
	 */
	reviewBooking: (
		mode: BookingFormModeMutation,
		formState: IBookingFormState,
		hasVehicle: boolean,
		hasTowOn: boolean,
		hasAddOns: boolean,
		applyToReturn?: boolean,
	) => void;
	/**
	 * Updates booking.checkedIn, re-renders bookings, and display check-in alert.
	 * May display error if booking not found from list.
	 */
	checkInBooking: (id: string, checkedIn: boolean, options?: checkInBookingOptions) => Promise<void>;
	/**
	 * If no booking is provided, a blank booking will be used with ferry trip pre-filled.
	 * Otherwise, the given booking will be used to construct the form state.
	 * Unless, the email is same as booking, then the existing form state is preserved.
	 * @param booking The booking to be transformed into the formState.
	 */
	setFormState: (booking?: CheckInBookingOverviewDto) => void;
	setShowFilters: (showFilters: boolean) => void;
	/**
	 * Access to all vehicle types is frequently required. We will cache after the first request.
	 */
	getAllVehicleTypes: () => Promise<CargoTypeEntity[]>;
	/**
	 * Access to all tow on types is frequently required. We will cache after the first request.
	 */
	getAllTowOnTypes: () => Promise<TowOnTypeEntity[]>;
	/**
	 * Access to all additional booking sections and options is frequently required.
	 * We will cache after the first request.
	 */
	getAllAdditionalBookingSections: () => Promise<AdditionalBookingSectionEntity[]>;
	allAdditionalBookingOptions?: AdditionalBookingOptionEntity[];

	getCheckInConnection: () => FerryCheckInSocket;

	getCheckInCache: () => CheckInCache;
	isOffline: () => boolean;
}

export class CheckInStore implements ICheckInStore {
	allVehicleTypes?: CargoTypeEntity[];
	allTowOnTypes?: TowOnTypeEntity[];
	allAdditionalBookingSections?: AdditionalBookingSectionEntity[];

	@observable
	public allAdditionalBookingOptions?: AdditionalBookingOptionEntity[];

	@observable
	ferryTripId: string;

	@observable
	ferryTrip: FerryTripDto;

	@observable
	bookings: CheckInBookingOverviewDto[];

	@observable
	formState: IBookingFormState;

	@observable
	skipFerryHasDepartedModal: boolean;

	@observable
	usingAddFlow: boolean;

	@observable
	showFilters: boolean = false;

	private checkInSocket: FerryCheckInSocket;
	private checkInCache: CheckInCache = CheckInCache.getInstance();

	public getCheckInCache = () => {
		return this.checkInCache;
	};

	public isOffline = (): boolean => {
		return !this.getCheckInCache().isOnline;
	}

	public isFerryTripCached = async (): Promise<boolean> => {
		return this.getCheckInCache().isFerryTripCached(this.ferryTripId);
	}

	public getCheckInConnection() {
		return this.checkInSocket;
	}

	@action
	setShowFilters() {
		this.showFilters = !this.showFilters;
	}

	@action
	async loadFerryTrip(id: string) {
		this.ferryTripId = id;

		const cache = this.getCheckInCache();
		if (!cache.isOnline) {
			await cache.connect();
			// load from the cache instead of the server
			await cache.loadCachedData(this);

			runInAction(() => {
				this.skipFerryHasDepartedModal = (new Date()) > this.ferryTrip.departureDateTime;
			});
			return;
		}

		const response = await CheckInUtils.fetchCheckInData(id);
		if (response) {
			runInAction(() => {
				this.ferryTrip = new FerryTripDto(response.ferryTripDto);
				this.bookings = response.bookingSummaries.filter(x => new CheckInBookingOverviewDto(x));
				this.skipFerryHasDepartedModal = (new Date()) > this.ferryTrip.departureDateTime;
			});

			this.createCheckInSocket();

			// If this ferry trip is cached and there are no offline changes,
			// we can clear the cache and re-cache the updated data
			const isCached = await this.isFerryTripCached();
			if (isCached) {
				await cache.clearCachedData(); // Assuming this does not clear booking changes
				await cache.cacheData(this);
			}
		}
	}

	@action
	setBookings(x: CheckInBookingOverviewDto[]) {
		this.bookings = x;
	}

	@action
	confirmCheckInForDepartedTrip() {
		this.skipFerryHasDepartedModal = false;
	}

	@action
	setFormState(booking?: CheckInBookingOverviewDto) {
		if (booking) {
			if (this.usingAddFlow) {
				return;
			}
			if (
				booking.id && this.formState && this.formState.bookingToEdit
				&& this.formState.bookingToEdit === booking.id
			) {
				// While switching between edit and review pages, we don't want to reset the formState
				return;
			}
			this.formState = new BookingFormState(transformBookingToBookingFormState(booking));
			return;
		}
		const blankBooking = CheckInBookingOverviewDto.createBlank();
		blankBooking.bookedSummary!.ferryTrip = new FerryTripEntity(this.ferryTrip);
		this.formState = new BookingFormState(transformBookingToBookingFormState(blankBooking));
	}

	@action
	async reviewBooking(
		mode: BookingFormModeMutation,
		state: IBookingFormState,
		hasVehicle: boolean,
		hasTowOn: boolean,
		hasAddOns: boolean,
		applyToReturn = false,
	) {
		this.formState = state;
		this.formState.forEftpos = true;
		//
		// Remove all vehicle details
		// Otherwise assign correct vehicle details
		//
		if (!hasVehicle) {
			const vehicleFields = [
				'driverFirstName',
				'driverLastName',
				'driverPhone',
				'lengthTypeId',
				'weightTypeId',
				'cargoMake',
				'cargoModel',
				'cargoTypeId',
				'cargoIdentification',
				'hiredVehicle',
			];
			runInAction(() => {
				for (const field of vehicleFields) {
					this.formState[field] = undefined;
				}
			});
		} else {
			//
			// 2 things
			//
			//     cargoTypeId must match make and model
			//     hiredVehicle must be assigned a value
			//
			const cargoTypes = await this.getAllVehicleTypes();
			const cargoType = cargoTypes.find(x => (
				x.cargoMake === this.formState.cargoMake
				&& x.cargoModel === this.formState.cargoModel
			));
			runInAction(() => {
				if (cargoType) {
					this.formState.cargoTypeId = cargoType.id;
				}
				this.formState.hiredVehicle = false;
			});
		}

		// Remove all tow on details
		if (!hasVehicle || !hasTowOn) {
			runInAction(() => {
				this.formState.trailerLengthId = undefined;
				this.formState.trailerTypeId = undefined;
			});
		}

		// Remove all add ons
		if (!hasAddOns) {
			runInAction(() => {
				this.formState.departingTripOptions = [];
			});
		}

		if (!applyToReturn) {
			runInAction(() => {
				this.formState.returningTicketId = undefined;
			});
		}

		const locationState: CheckInLocationState = { mode, applyToReturn: String(applyToReturn) };
		store.routerHistory.push(`/check-in/${this.ferryTripId}/review`, locationState);
	}

	@action
	async checkInBooking(id: string, checkedIn: boolean, options: checkInBookingOptions = {}) {
		const {
			refresh = false,
			triggerAlert = true,
			skipUpdateServer = false,
		} = options;

		let booking: CheckInBookingOverviewDto;
		let added = false;
		const bookingIndex = this.bookings.findIndex(x => x.id === id);
		const isOffline = this.isOffline();
		const isFerryTripCached = await this.isFerryTripCached();

		if (isOffline && !isFerryTripCached) {
			alertToast('Unable to check in booking while offline. Please save data for offline use.', 'error');
			return;
		}

		if (bookingIndex === -1) {
			//
			// Fetch booking from server and add to list
			//
			const newBooking = await getBooking(id);
			added = true;
			booking = new CheckInBookingOverviewDto(newBooking.data);
		} else if (refresh && !isOffline) {
			//
			// Refetch same booking for latest data
			//
			const sameBooking = await getBooking(id);
			const newBookingData = new CheckInBookingOverviewDto(sameBooking.data);
			runInAction(() => {
				this.bookings[bookingIndex] = newBookingData;
			});

			if (isFerryTripCached) {
				await this.getCheckInCache().updateBooking(newBookingData);
			}

			booking = this.bookings[bookingIndex];
		} else {
			//
			// Use existing booking
			//
			booking = this.bookings[bookingIndex];
		}

		// Update checkedIn status if required
		booking.checkedIn = checkedIn;
		if (!skipUpdateServer && !isOffline) {
			await updateCheckedInStatus({
				bookingId: booking.id,
				checkedIn: checkedIn,
				ferryTripId: this.ferryTripId,
			});

			if (isFerryTripCached) {
				await this.getCheckInCache().updateBooking(booking);
			}
		}

		if (isOffline && isFerryTripCached) {
			await this.getCheckInCache().setBookingCheckInStatus(id, checkedIn);
		}

		// Re-render list
		if (added) {
			this.setBookings([...this.bookings, booking].sort(CheckInUtils.sortByFullName()));
		} else {
			this.setBookings([...checkInStore.bookings]);
		}

		if (triggerAlert) {
			CheckInUtils.checkInAlert(booking, booking.checkedIn);
		}
	}

	removeBooking(id: string) {
		this.setBookings(this.bookings.filter(x => x.id !== id));
	}

	async getAllVehicleTypes() {
		if (this.allVehicleTypes) {
			return Promise.resolve(this.allVehicleTypes);
		}

		const result = await getCargoTypesOptimized();
		this.allVehicleTypes = result;

		return result;
	}

	async getAllTowOnTypes() {
		if (this.allTowOnTypes) {
			return Promise.resolve(this.allTowOnTypes);
		}

		const result = await getTowOnTypesOptimized();
		this.allTowOnTypes = result;

		return result;
	}

	async getAllAdditionalBookingSections() {
		if (this.allAdditionalBookingSections) {
			return Promise.resolve(this.allAdditionalBookingSections);
		}

		if (isNullOrUndefined(this.ferryTrip.routeId) || isNullOrUndefined(this.ferryTrip.ferryId)) {
			return [];
		}
		const result = await getAdditionalBookingSections({
			ferryIds: [this.ferryTrip.ferryId],
			routeIds: [this.ferryTrip.routeId],
		});
		this.allAdditionalBookingOptions = result.flatMap(x => x.additionalBookingOptions);
		return result;
	}

	private async createCheckInSocket() {
		if (this.checkInSocket) {
			// Do nothing if we already have a socket for this ferry trip
			if (this.checkInSocket.getFerryTripId() === this.ferryTripId) {
				return;
			}

			await this.checkInSocket.closeConnection();
		}
		this.checkInSocket = new FerryCheckInSocket(this);
	}
}

/**
 * This should only be used in the root of the component tree that uses CheckInStoreContext.Provider.
 *
 * Otherwise, use useCheckInStore hook to access check-in related data.
 */
export const checkInStore: ICheckInStore = new CheckInStore();
export const CheckInStoreContext = createContext<ICheckInStore>(checkInStore);
