import { LocationEntity, RouteEntity } from 'Models/Entities';
import { RefObject } from 'react';
import RouteMapCanvas from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapCanvas';
import RouteMapRenderableObject
	from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapRenderableObject';
import RouteMapBackground from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapBackground';
import RouteMapLine from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapLine';
import RouteMapProjection from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapProjection';
import RouteMapPoint from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapPoint';
import RouteMapDestination from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapDestination';
import RouteMapDeparture from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapDeparture';
import RouteMapIcon from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapIcon';
import { isNullOrUndefined } from 'Util/TypeGuards';

export interface ILocationAndRoutes {
	locations: LocationEntity[];
	routes: RouteEntity[];
}

export interface RouteData {
	from: RouteMapPoint;
	to: RouteMapPoint;
	image: RouteMapPoint;
}

const resolution = 1;

const getRouteData = (route: RouteEntity): RouteData | undefined => {
	try {
		const data = JSON.parse(route.mapCanvasCoordinates);
		const { from, to, image } = data;

		return {
			from: new RouteMapPoint(from.x, from.y),
			to: new RouteMapPoint(to.x, to.y),
			image: new RouteMapPoint(image.x, image.y),
		};
	} catch (e) {
		return undefined;
	}
};

export default class MapHandler {
	private readonly backgroundCanvas: RouteMapCanvas;
	private readonly foregroundCanvas: RouteMapCanvas;
	private readonly projection: RouteMapProjection;

	private readonly routesAndLocations: ILocationAndRoutes;
	private currentRoute: RouteEntity;
	private routeData: RouteData;

	private readonly deviceResolution: number = window.devicePixelRatio * resolution;

	private renderObjects: RouteMapRenderableObject[] = [];

	private initialised: boolean = false;
	private isRendering: boolean = false;
	private isAnotherFrameRequested: boolean = false;
	private renderRequestedTimeout: any;

	// Handling the error state
	public isError = false;
	private errorTimeout: any;

	constructor(
		locations: LocationEntity[],
		routes: RouteEntity[],
		backgroundCanvasRef: RefObject<HTMLCanvasElement>,
		foregroundCanvasRef: RefObject<HTMLCanvasElement>) {
		this.routesAndLocations = {
			locations: locations,
			routes: routes,
		};
		this.backgroundCanvas = new RouteMapCanvas(backgroundCanvasRef);
		this.foregroundCanvas = new RouteMapCanvas(foregroundCanvasRef);
		this.projection = new RouteMapProjection(this.backgroundCanvas, this.deviceResolution);
	}

	public setRoute(routeId: string) {
		const newRoute = this.routesAndLocations.routes.find(r => r.id === routeId);

		if (newRoute === undefined) {
			throw new Error('Route not found');
		}

		const routeData = getRouteData(newRoute);

		if (routeData === undefined || isNullOrUndefined(newRoute.mapId)) {
			this.setError();
			return;
		}

		this.currentRoute = newRoute;
		this.routeData = routeData;

		if (!this.initialised) {
			return;
		}

		this.setCanvasSize();

		if (this.renderObjects.length === 0) {
			this.createRenderableObjects();
		}

		this.projection.updateImageSize(this.routeData.image);

		this.update();
	}

	public setError() {
		this.isError = true;
		this.backgroundCanvas.clear();
		this.foregroundCanvas.clear();

		if (this.errorTimeout) {
			clearTimeout(this.errorTimeout);
		}

		document.querySelector('.map__container')?.classList.add('error');
	}

	public clearError() {
		if (!this.isError) {
			this.requestRender();
			return;
		}

		document.querySelector('.map__container')?.classList.remove('error');
		this.errorTimeout = setTimeout(() => {
			this.isError = false;

			this.requestRender();
		}, 100);
	}

	public init() {
		this.backgroundCanvas.init();
		this.foregroundCanvas.init();

		this.setCanvasSize();
		this.startEventListening();

		// Do first update and render
		this.initialised = true;
		this.update();
		this.render();
	}

	public dispose() {
		this.stopEventListening();
	}

	public getProjection(): RouteMapProjection {
		return this.projection;
	}

	public getBackgroundCanvas(): RouteMapCanvas {
		return this.backgroundCanvas;
	}

	public getForegroundCanvas(): RouteMapCanvas {
		return this.foregroundCanvas;
	}

	public requestRender() {
		this.setCanvasSize();

		// Prevent multiple render requests if we are already rendering
		if (this.isRendering || this.isAnotherFrameRequested) {
			return;
		}

		if (!!this.renderRequestedTimeout) {
			clearTimeout(this.renderRequestedTimeout);
		}

		setTimeout(() => this.render(), 0);
	}

	public clearForegroundCanvas() {
		this.foregroundCanvas.clear();
	}

	private startEventListening() {
		window.addEventListener('resize', this.setCanvasSize);
		window.addEventListener('pointerdown', this.handleWindowClick);
		this.foregroundCanvas.addEventListener('pointermove', this.handleHover);
		this.foregroundCanvas.addEventListener('pointerdown', this.handleClick);
	}

	private stopEventListening() {
		window.removeEventListener('resize', this.setCanvasSize);
		window.removeEventListener('pointerdown', this.handleWindowClick);
		this.foregroundCanvas.removeEventListener('pointermove');
		this.foregroundCanvas.removeEventListener('pointerdown');
	}

	private setCanvasSize = () => {
		if (!this.isReady() && this.isError) {
			return;
		}

		const canvas = this.backgroundCanvas.getCanvas();

		// Set the physical height of the canvases first so that we can correctly calculate the new resolution
		// Assumes that the width will resize with the available space, and we want the height to be the same
		const newPhysicalHeight = canvas.getBoundingClientRect().width;
		this.foregroundCanvas.setStyleHeight(newPhysicalHeight);
		this.backgroundCanvas.setStyleHeight(newPhysicalHeight);

		const { height, width } = canvas.getBoundingClientRect();
		const newCanvasResolution = width * (width / height) * this.deviceResolution;
		this.backgroundCanvas.setDimensions(newCanvasResolution, newCanvasResolution);
		this.foregroundCanvas.setDimensions(newCanvasResolution, newCanvasResolution);

		const s: HTMLElement | null = document.querySelector('.map__container');
		if (s !== null) {
			s.style.height = `${newPhysicalHeight}px`;
		}

		// Now we have the correct resolution, we can update the projection
		this.projection.updateScale();

		// Get each object to update its scale
		this.renderObjects.forEach(r => r.updateScale());

		// After the canvases have been resized, we need to re-render the objects (if they are ready for rendering)
		if (this.initialised) {
			this.foregroundCanvas.setNeedsRerender(true);
			this.backgroundCanvas.setNeedsRerender(true);

			this.render();
		}
	}

	private handleHover = (e: PointerEvent) => {
		if (!this.isReady()) {
			return;
		}
		const point = this.projection.getCanvasCoords(e.clientX, e.clientY);

		this.getIconObjects()
			.forEach(x => {
				// Cast the object to the correct type
				const iconObject = x as unknown as RouteMapIcon;
				if (iconObject.isAtLocation(point.getX(), point.getY())) {
					iconObject.mouseOver();
				} else {
					iconObject.mouseOut();
				}
			});
	}

	private handleClick = (e: PointerEvent) => {
		if (!this.isReady()) {
			return;
		}

		const point = this.projection.getCanvasCoords(e.clientX, e.clientY);

		this.getIconObjects()
			.forEach(x => {
				// Cast the object to the correct type
				const iconObject = x as unknown as RouteMapIcon;
				if (iconObject.isAtLocation(point.getX(), point.getY())) {
					iconObject.click(point);
				} else {
					iconObject.blur();
				}
			});
	}

	private handleWindowClick = (e: PointerEvent) => {
		if (!this.isReady()) {
			return;
		}

		const target = e.target as HTMLElement;

		if (target.tagName === 'CANVAS') {
			return;
		}

		this.getIconObjects()
			.forEach(x => x.blur());
	}

	private update() {
		// Prevent performing unnecessary updates
		if (this.currentRoute === undefined) {
			return;
		}

		this.renderObjects.forEach(r => r.update(this.currentRoute, this.routesAndLocations, this.routeData));
	}

	private render(timestamp?: number) {
		if (!this.isReady() || this.isRendering || this.isError) {
			this.isRendering = false;
			this.isAnotherFrameRequested = false;
			return;
		}

		const now = timestamp ?? performance.now();

		this.isRendering = true;

		// Clear the canvases if there is no route
		if (this.currentRoute === undefined) {
			this.foregroundCanvas.clear();
			this.backgroundCanvas.clear();

			this.isRendering = false;
			return;
		}

		this.foregroundCanvas.clear();

		let needsRerender = false;
		this.renderObjects.forEach(r => {
			needsRerender = r.render(now) || needsRerender;
		});

		// We no longer need to re-render for the purpose of the canvas
		this.foregroundCanvas.setNeedsRerender(false);
		this.backgroundCanvas.setNeedsRerender(false);

		this.isRendering = false;

		this.isAnotherFrameRequested = needsRerender;
		if (needsRerender) {
			requestAnimationFrame(t => this.render(t));
		}
	}

	public isReady() {
		return this.backgroundCanvas.isReady() && this.foregroundCanvas.isReady();
	}

	private createRenderableObjects() {
		this.addRenderableObject(new RouteMapBackground(this));

		this.addRenderableObject(new RouteMapLine(this));

		this.addRenderableObject(new RouteMapDestination(this));
		this.addRenderableObject(new RouteMapDeparture(this));
	}

	private addRenderableObject(renderableObject: RouteMapRenderableObject) {
		this.renderObjects.push(renderableObject);
	}

	private getIconObjects(): RouteMapIcon[] {
		return this.renderObjects
			.filter(x => x.getType() === 'icon') as unknown as RouteMapIcon[];
	}
}
