/* eslint-disable object-curly-newline */

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { journeyElementsStore } from 'Global/JourneyElements/JourneyElementsStore';
import { getRouteById } from 'Global/JourneyElements/Utils/getRouteById';
import useAsync from 'Hooks/useAsync';
import { AlterationEntity, FerryTripEntity, SegmentEntity, StopEntity, TripStopEntity } from 'Models/Entities';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import CalendarDays from 'Modules/MultiStopWizard/Components/Leg/CalendarDays';

dayjs.extend(weekOfYear);

export const useTrip = (tripId?: string) => {
	return useAsync(async () => {
		if (!tripId) {
			return null;
		}
		return FerryTripEntity.fetch<FerryTripEntity>(
			{
				args: [[{ path: 'id', value: tripId, comparison: 'equal' }]],
			},
			`
				tripStops {
					id
					stopId
					arrivalDateTime
					departureDateTime
				}
			`,
			undefined,
			true,
		);
	}, [tripId]);
};

export interface TripInsightPageRouteParams {
	tripId: string;
}

export default function TripInsightPage(props: RouteComponentProps<TripInsightPageRouteParams>) {
	const journeyElements = journeyElementsStore();

	const {
		match: {
			params: { tripId },
		},
	} = props;

	const tripsRequest = useTrip(tripId);

	const trip = React.useMemo(() => {
		if (!tripsRequest.data || journeyElements.isLoading) {
			return null;
		}

		if (tripsRequest.data.length === 0) {
			return null;
		}

		const [item] = tripsRequest.data;

		const route = journeyElements.routes.find(x => x.id === item.routeId);
		if (route) {
			item.route = route;
		}

		// Link stop entities
		for (const tripStop of item.tripStops) {
			const stop = journeyElements.stops.find(x => x.id === tripStop.stopId);
			if (stop) {
				tripStop.stop = stop;
			} else {
				throw new Error(`Missing stop: ${tripStop.stopId}`);
			}
		}

		// Sort by stop order ascending
		item.tripStops = item.tripStops.slice().sort((a, b) => {
			if (a.stop.order > b.stop.order) return 1;
			if (a.stop.order < b.stop.order) return -1;
			return 0;
		});

		// Manually override arrival time based on segment duration
		for (let i = 0; i < item.tripStops.length; i++) {
			const tripStop = item.tripStops[i];
			const segment = tripStop.stop.route.segments.find(x => x.startStopId === tripStop.stopId);
			if (segment) {
				let arrivalDateTime = dayjs(tripStop.departureDateTime);
				arrivalDateTime = arrivalDateTime.add(segment.durationInMinutes, 'minutes');
				tripStop.arrivalDateTime = arrivalDateTime.toDate();
			}
		}

		return item;
	}, [tripsRequest.data, journeyElements]);

	const segments = React.useMemo(() => {
		if (!trip) {
			return [];
		}

		return trip.tripStops.reduce((list, tripStop) => {
			const segment = journeyElements.segments.find(x => x.startStopId === tripStop.stopId);
			if (segment) {
				list.push(segment);
			}
			return list;
		}, new Array<SegmentEntity>());
	}, [trip]);

	if (tripsRequest.type === 'loading') {
		return (
			<SecuredPage groups={SecuredGroups.create.onlySuper().groups}>
				<LottieSpinner />
			</SecuredPage>
		);
	}

	if (tripsRequest.type === 'error' || !trip) {
		return (
			<SecuredPage groups={SecuredGroups.create.onlySuper().groups}>
				<div>
					No trip found.
				</div>
			</SecuredPage>
		);
	}

	const routeName = trip.routeId ? getRouteById(trip.routeId)?.name : tripId;

	// Group items by week and weekday
	const groupedSchedule = trip.tripStops.reduce((acc, item) => {
		if (!item.departureDateTime) {
			return acc;
		}

		const departureDate = dayjs(item.departureDateTime);
		const week = departureDate.week(); // Week of the year
		const day = departureDate.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

		if (!acc[week]) acc[week] = {};
		if (!acc[week][day]) acc[week][day] = [];
		acc[week][day].push(item);

		return acc;
	}, {} as Record<number, Record<number, TripStopEntity[]>>);

	// Get sorted weeks
	const weeks = Object.keys(groupedSchedule)
		.map(Number)
		.sort((a, b) => a - b);

	const lastStopInRoute: StopEntity | undefined = (trip.route?.stops ?? []).reduce((lastStop, stop) => {
		if (stop.order > lastStop.order) {
			return stop;
		}
		return lastStop;
	});

	return (
		<SecuredPage groups={SecuredGroups.create.onlySuper().groups}>
			<div style={{ margin: '1rem', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
				<h1>Trip Insights</h1>
				<h2>Departure times</h2>
				<div>Route: {routeName}</div>
				<table style={{ borderCollapse: 'collapse', width: '100%' }}>
					<thead>
						<tr>
							<th style={{ border: '1px solid black', padding: '5px' }}>Week</th>
							{['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
								<th key={day} style={{ border: '1px solid black', padding: '5px' }}>
									{day}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{weeks.map(week => (
							<tr key={week}>
								<td style={{ border: '1px solid black', padding: '5px' }}>{week}</td>
								{Array.from({ length: 7 })
									.map((_, dayIndex) => dayIndex)
									.map(dayIndex => {
										return (
											<td
												key={dayIndex}
												style={{
													border: '1px solid black',
													padding: '5px',
													verticalAlign: 'top',
												}}
											>
												{groupedSchedule[week][dayIndex]?.map(item => {
													if (item.stopId === lastStopInRoute.id) {
														return <></>;
													}
													return (
														<div key={item.id} style={{ display: 'flex', gap: '0.5rem' }}>
															<strong>
																{item.stop.order} {item.stop.location.shortName}
															</strong>
															<div>
																{dayjs(item.departureDateTime).format('HH:mm')} -{' '}
																{dayjs(item.arrivalDateTime).format('HH:mm')}
																<CalendarDays
																	departureTime={item.departureDateTime}
																	arrivalTime={item.arrivalDateTime}
																/>
															</div>
														</div>
													);
												})}
											</td>
										);
									})}
							</tr>
						))}
					</tbody>
				</table>
				<ReservedAlterationList tripId={tripId} />
			</div>
		</SecuredPage>
	);
}

export const useReservedAlterationsByTrip = (tripId: string) => {
	return useAsync(async () => {
		if (!tripId) {
			return null;
		}
		return AlterationEntity.fetch<AlterationEntity>(
			{
				args: [
					[{ path: 'ferryTripId', value: tripId, comparison: 'equal' }],
					[{ path: 'status', value: 'RESERVED', comparison: 'equal' }],
				],
			},
			`
				tickets {
					amount
					passengerDetails {
						passengerDetail {
							passengerTypeId
						}
					}
				}
			`,
			undefined,
			true,
		);
	}, [tripId]);
};

interface ReservedAlterationListProps {
	tripId: string;
}

function ReservedAlterationList({ tripId }: ReservedAlterationListProps) {
	const alterationListRequest = useReservedAlterationsByTrip(tripId);

	if (alterationListRequest.error) {
		return <div>Error while fetching reserved alterations.</div>;
	}

	if (alterationListRequest.type === 'loading' || !alterationListRequest.data) {
		return <div>Fetching reserved alterations...</div>;
	}

	const { data: reservedAlterations } = alterationListRequest;

	return (
		<>
			<h2>Reserved ({reservedAlterations.length})</h2>
			<div>
				{reservedAlterations.map(x => {
					return <div key={x.id}>{x.created.toString()}</div>;
				})}
			</div>
		</>
	);
}
