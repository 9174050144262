/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsConfigGeneralEntity from 'Models/Security/Acl/VisitorsConfigGeneralEntity';
import UserConfigGeneralEntity from 'Models/Security/Acl/UserConfigGeneralEntity';
import StaffConfigGeneralEntity from 'Models/Security/Acl/StaffConfigGeneralEntity';
import ManagerConfigGeneralEntity from 'Models/Security/Acl/ManagerConfigGeneralEntity';
import AdminConfigGeneralEntity from 'Models/Security/Acl/AdminConfigGeneralEntity';
import InvoicedUserConfigGeneralEntity from 'Models/Security/Acl/InvoicedUserConfigGeneralEntity';
import FixedPriceUserConfigGeneralEntity from 'Models/Security/Acl/FixedPriceUserConfigGeneralEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IConfigGeneralEntityAttributes extends IModelAttributes {
	generalTerms: string;
	vehicleTerms: string;
	passengerTerms: string;
	freightTerms: string;
	defaultEventTerms: string;
	vehiclesEnabled: boolean;
	companyName: string;
	abn: string;
	addressLine1: string;
	addressLine2: string;
	giftCertificateRedeemText: string;
	gstPercentage: number;
	creditCardSurchargePercentage: number;
	vehicleLabel: string;
	trailersEnabled: boolean;
	vehicleBookingRequiresAdult: boolean;
	vehicleMakeLabel: string;
	vehicleMakeInputPlaceholder: string;
	vehicleModelLabel: string;
	vehicleModelInputPlaceholder: string;
	driverDetailsTitle: string;
	driverDetailsSubtitle: string;
	driverDetailsRegoLabel: string;
	driverDetailsRegoPlaceholder: string;
	driverDetailsRegoCheckboxInfo: string;
	passengerTripSelectionButtonLabel: string;
	vehicleTripSelectionButtonLabel: string;
	passengerTripSelectionButtonSubheading: string;
	vehicleTripSelectionButtonSubheading: string;
	previousVehicleSelectionEnabled: boolean;
	customerRegoDisplayEnabled: boolean;
	vehicleShortName: string;
	vehicleLengthEnabled: boolean;
	reportTimezone: string;
	cargoNoteEnabled: boolean;
	cargoBookingNoteLabel: string;
	cargoBookingNotePlaceholder: string;
	passengerNoteEnabled: boolean;
	passengerBookingNoteLabel: string;
	passengerBookingNotePlaceholder: string;
	addOnTabSubtitle: string;
	passengersDefaultToReturn: boolean;
	vehicleDefaultToReturn: boolean;
	showBanner: boolean;
	bannerText: string;
	showTransactionPopUp: boolean;
	defaultBookingType: Enums.defaultBookingType;
	timeFormat: Enums.timeFormat;
	systemOfMeasurement: Enums.systemOfMeasurementType;
	disableCargo: boolean;
	dateFormat: string;
	defaultCurrencyCode: string;
	defaultNumberFormattingCode: string;
	allowUnpaidBookings: boolean;
	allowCashInBookingWizard: boolean;
	hideEvents: boolean;
	hideBulkBookingButton: boolean;
	businessContactNumber: string;

	defaultLanguageId?: string;
	defaultLanguage?: Models.LanguageEntity | Models.ILanguageEntityAttributes;
	defaultRouteId?: string;
	defaultRoute?: Models.RouteEntity | Models.IRouteEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ConfigGeneralEntity', 'Config General')
// % protected region % [Customise your entity metadata here] end
export default class ConfigGeneralEntity extends Model
	implements IConfigGeneralEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsConfigGeneralEntity(),
		new UserConfigGeneralEntity(),
		new StaffConfigGeneralEntity(),
		new ManagerConfigGeneralEntity(),
		new AdminConfigGeneralEntity(),
		new InvoicedUserConfigGeneralEntity(),
		new FixedPriceUserConfigGeneralEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'General Terms'] on begin
	/**
	 * This is the general terms and conditions
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'General terms and conditions',
		description: 'The general terms and conditions. These will appear on both cargo and passenger only bookings.',
		displayType: 'textarea',
		order: 100,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public generalTerms: string;
	// % protected region % [Modify props to the crud options here for attribute 'General Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Terms'] on begin
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo terms and conditions',
		description: 'The cargo terms and conditions. These will only appear for cargo bookings.',
		displayType: 'textarea',
		order: 110,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleTerms: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Terms'] on begin
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Passenger terms and conditions',
		description: 'The passenger terms and conditions. These will only appear for passenger only bookings.',
		displayType: 'textarea',
		order: 120,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public passengerTerms: string;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Freight Terms'] on begin
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Freight terms',
		displayType: 'textarea',
		order: 130,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public freightTerms: string;
	// % protected region % [Modify props to the crud options here for attribute 'Freight Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Default Event Terms'] on begin
	/**
	 * The default event terms and conditions to pre-fill on a new event.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Default event terms and conditions',
		description: 'The default terms and conditions for an event. This can be over-written by each event.',
		displayType: 'textarea',
		order: 130,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public defaultEventTerms: string;
	// % protected region % [Modify props to the crud options here for attribute 'Default Event Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicles Enabled'] on begin
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Vehicles enabled',
		displayType: 'hidden',
		order: 500,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public vehiclesEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicles Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Company Name'] on begin
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Company name',
		description: 'The company name will appear in the tax invoice and emails.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public companyName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Company Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Abn'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Business number',
		description: 'The business number will appear on tax invoices.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abn: string;
	// % protected region % [Modify props to the crud options here for attribute 'Abn'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address Line 1'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Address line 1',
		description: 'Line 1 of the address that will appear in tax invoices.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public addressLine1: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address Line 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address Line 2'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Address line 2',
		description: 'Line 2 of the address that will appear in tax invoices.',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public addressLine2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address Line 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gift Certificate Redeem Text'] on begin
	/**
	 * The text that will display and explain to the user how to redeem a gift certificate
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Credit voucher redeem instructions',
		description: 'Instructions for customers on how they can redeem their gift certificate. It will appear on '
			+ 'the booking success page or the cancel booking success page if the user received a credit voucher.',
		displayType: 'textarea',
		order: 200,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public giftCertificateRedeemText: string;
	// % protected region % [Modify props to the crud options here for attribute 'Gift Certificate Redeem Text'] end

	// % protected region % [Modify props to the crud options here for attribute 'GST Percentage'] on begin
	/**
	 * The GST that is applied throughout the application.
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<ConfigGeneralEntity, number>()
	@CRUD({
		name: 'GST percentage',
		description: 'The percentage of GST that will apply throughout the application.',
		displayType: 'textfield',
		order: 50,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public gstPercentage: number;
	// % protected region % [Modify props to the crud options here for attribute 'GST Percentage'] end

	// % protected region % [Modify props to the crud options here for attribute 'Credit Card Surcharge Percentage'] on begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<ConfigGeneralEntity, number>()
	@CRUD({
		name: 'Credit card surcharge percentage',
		description: 'The credit card surcharge you wish to charge customers when they create a booking and pay '
			+ 'using card online.',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public creditCardSurchargePercentage: number;
	// % protected region % [Modify props to the crud options here for attribute 'Credit Card Surcharge Percentage'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Label'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo label',
		description: 'The label that will be used to describe your cargo throughout the website. '
			+ 'E.g. `Vehicle` or `Package`.',
		displayType: 'textfield',
		order: 300,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trailers Enabled'] on begin
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Trailers enabled',
		description: 'If checked, trailers will be enabled in the application, allowing users to add a towOn to '
			+ 'their vehicle.',
		displayType: 'checkbox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public trailersEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Trailers Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Booking Requires Adult'] on begin
	/**
	 * When enabled, a vehicle booking will require at least 1 adult ticket to be bought with it.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Cargo booking requires one adult',
		description: 'When a user is creating a cargo booking, whether or not they require to have at least'
			+ 'one adult in the booking.',
		displayType: 'checkbox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public vehicleBookingRequiresAdult: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Booking Requires Adult'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Make Label'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo make label',
		description: 'The label that will show for the cargo "make" input on the vehicle tab. E.g. `Vehicle make`.',
		displayType: 'textfield',
		order: 310,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleMakeLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Make Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Make Input Placeholder'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo make input placeholder',
		description: 'The placeholder that will show for the cargo "make" input on the vehicle tab. E.g. '
			+ '`Enter vehicle make`.',
		displayType: 'textfield',
		order: 320,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleMakeInputPlaceholder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Make Input Placeholder'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Model Label'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo model label',
		description: 'The label that will show for the cargo "model" input on the vehicle tab. E.g. `Vehicle model`.',
		displayType: 'textfield',
		order: 330,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleModelLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Model Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Model Input Placeholder'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo model input placeholder',
		description: 'The placeholder that will show for the cargo "model" input on the vehicle tab. E.g. '
			+ '`Enter vehicle model`.',
		displayType: 'textfield',
		order: 340,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleModelInputPlaceholder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Model Input Placeholder'] end

	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Title'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo primary contact section title',
		description: 'The title of the cargo primary contact details section on the vehicle tab of the booking wizard.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public driverDetailsTitle: string;
	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Subtitle'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo primary contact details section description',
		description: 'The description of the the cargo primary contact section on the vehicle tab of the booking '
			+ 'wizard.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public driverDetailsSubtitle: string;
	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Subtitle'] end

	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Rego Label'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo identification label',
		description: 'The label that will appear above the cargo identification input field on the vehicle tab '
			+ 'of the booking wizard. E.g. `Vehicle rego`.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public driverDetailsRegoLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Rego Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Rego Placeholder'] on begin
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo identification placeholder',
		description: 'The placeholder that will appear in the cargo identification input field on the vehicle tab '
			+ 'of the booking wizard. E.g. `Enter rego`.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public driverDetailsRegoPlaceholder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Rego Placeholder'] end

	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Rego Checkbox Info'] on begin
	/**
	 * Text placed next to the checkbox found next to the rego checkbox.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo identification checkbox label',
		description: 'The label that appears on the cargo identification checkbox on the vehicle tab of the '
			+ 'booking wizard. E.g. `I do not know my vehicle rego`.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public driverDetailsRegoCheckboxInfo: string;
	// % protected region % [Modify props to the crud options here for attribute 'Driver Details Rego Checkbox Info'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Trip Selection Button Label'] on begin
	/**
	 * The text of the passenger button found on the sidebar of the booking wizard.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Passenger button label',
		description: 'The label that will appear on the passenger button on the homepage of the application '
			+ 'and in the booking wizard ferry filter sidebar.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public passengerTripSelectionButtonLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Trip Selection Button Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Trip Selection Button Label'] on begin
	/**
	 * The text of the vehicle button found on the sidebar of the booking wizard.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Vehicle button label',
		description: 'The label that will appear on the vehicle (or cargo) button on the homepage of the '
			+ 'application and in the booking wizard ferry filter sidebar.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleTripSelectionButtonLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Trip Selection Button Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Trip Selection Button Subheading'] on begin
	/**
	 * The text displayed in the sidebar when the passenger button is selected.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Passenger button subheading',
		description: 'The description that will appear when a user selects the passenger booking button '
			+ 'during the booking wizard. E.g. `Select walk-on passengers`.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public passengerTripSelectionButtonSubheading: string;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Trip Selection Button Subheading'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Trip Selection Button Subheading'] on begin
	/**
	 * The text displayed in the sidebar when the vehicle button is selected.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Vehicle button subheading',
		description: 'The description that will appear when a user selects the vehicle (or cargo) booking button '
			+ 'during the booking wizard. E.g. `Driver included in vehicle`.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleTripSelectionButtonSubheading: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Trip Selection Button Subheading'] end

	// % protected region % [Modify props to the crud options here for attribute 'Previous Vehicle Selection Enabled'] on begin
	/**
	 * Display the option to select a customer's last used vehicle for a booking.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Previous cargo selection enabled',
		description: 'If checked, users will see a list of their previous cargo details to select from and '
			+ 'pre-fill the vehicle tab.',
		displayType: 'checkbox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public previousVehicleSelectionEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Previous Vehicle Selection Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Customer Rego Display Enabled'] on begin
	/**
	 * Display the customer's last used vehicle rego when selected in the booking wizard.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Customer previous cargo identification shown for staff',
		description: 'If checked, staff will be able to see previous cargo identification numbers for the '
			+ 'selected customer (on the customer tab in the booking wizard). ',
		displayType: 'checkbox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public customerRegoDisplayEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Customer Rego Display Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Short Name'] on begin
	/**
	 * This is the short name for the vehicle equivalent (e.g. VH) on the staff bookings page.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo short name',
		description: 'A short version of the cargo descriptor used in areas of the app with limit space. '
			+ 'E.g. bookings table for staff.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public vehicleShortName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Short Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Length Enabled'] on begin
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Cargo length enabled',
		description: 'If checked, users will be able to see their vehicle length and select it from a dropdown '
			+ 'menu on the vehicle tab in the booking wizard.',
		displayType: 'checkbox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public vehicleLengthEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Length Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Report Timezone'] on begin
	/**
	 * Timezone used to evaluate dates in reports.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Report timezone',
		description: 'The timezone that reports will download in.',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportTimezone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Report Timezone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cargo Note Enabled'] off begin
	/**
	 * Whether or not the booking note will show for cargo bookings.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Cargo Note Enabled',
		displayType: 'checkbox',
		order: 350,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public cargoNoteEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Cargo Note Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cargo Booking Note Label'] off begin
	/**
	 * The label that will show for the cargo booking notes section.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo Booking Note Label',
		displayType: 'textfield',
		order: 360,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cargoBookingNoteLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Cargo Booking Note Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cargo Booking Note Placeholder'] off begin
	/**
	 * The placeholder for the cargo booking notes section.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Cargo Booking Note Placeholder',
		displayType: 'textfield',
		order: 370,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cargoBookingNotePlaceholder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Cargo Booking Note Placeholder'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Note Enabled'] on begin
	/**
	 * Show or hide booking additional details for the application.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Passenger booking note enabled',
		description: 'If checked, there will be an additional input field in the booking wizard when creating a '
			+ 'passenger only booking.',
		displayType: 'checkbox',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public passengerNoteEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Note Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Booking Note Label'] off begin
	/**
	 * The label that will show for the passenger booking notes section.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Passenger Booking Note Label',
		displayType: 'textfield',
		order: 390,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public passengerBookingNoteLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Booking Note Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Booking Note Placeholder'] off begin
	/**
	 * The placeholder for the passenger booking notes section.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Passenger Booking Note Placeholder',
		displayType: 'textfield',
		order: 400,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public passengerBookingNotePlaceholder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Booking Note Placeholder'] end

	// % protected region % [Modify props to the crud options here for attribute 'Add On Tab Subtitle'] on begin
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Add on tab subtitle',
		description: 'The description that will show on the add-on tab during the booking wizard.',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public addOnTabSubtitle: string;
	// % protected region % [Modify props to the crud options here for attribute 'Add On Tab Subtitle'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passengers Default To Return'] on begin
	/**
	 * During the booking wizard whether the passenger booking defaults to return or one-way.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Passengers default to return booking',
		description: 'If checked, a passenger booking will default to a return booking when creating a new booking '
			+ 'in the booking wizard. If unchecked, it will default to one-way.',
		displayType: 'checkbox',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public passengersDefaultToReturn: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Passengers Default To Return'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Default To Return'] on begin
	/**
	 * During the booking wizard whether the vehicle booking defaults to return or one-way.
	 */
	@Validators.Required()
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Cargo default to return booking',
		description: 'If checked, a cargo booking will default to a return booking when creating a new booking '
			+ 'in the booking wizard. If unchecked, it will default to one-way.',
		displayType: 'checkbox',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public vehicleDefaultToReturn: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Default To Return'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show Banner'] on begin
	/**
	 * Whether or not to show the banner on the ferry schedule.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Show marketing banner',
		description: 'Whether or not you would like the marketing banner to show on the frontend. It will only '
			+ 'display on the ferry schedule and homepage. Note: Staff cannot see it when logged in.',
		displayType: 'checkbox',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showBanner: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show Banner'] end

	// % protected region % [Modify props to the crud options here for attribute 'Banner Text'] on begin
	/**
	 * The text that will display in the banner on the ferry schedule page.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Marketing banner content',
		description: 'The content that will appear in the marketing banner. You can style it using HTML. You '
			+ 'can also use ascii icons.',
		displayType: 'textarea',
		order: 230,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public bannerText: string;
	// % protected region % [Modify props to the crud options here for attribute 'Banner Text'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show Transaction Pop Up'] on begin
	/**
	 * Whether or not a confirmation pop-up will appear during check-in on the check-in review page.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Show transaction confirmation pop-up in check-in',
		description: 'If checked, a confirmation will appear during check-in to confirm if the staff member has taken '
			+ 'the payment before completing the booking and checking the customer in. If unchecked, '
			+ 'there will be no confirmation pop-up.',
		displayType: 'checkbox',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showTransactionPopUp: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show Transaction Pop Up'] end

	// % protected region % [Modify props to the crud options here for attribute 'Default Booking Type'] on begin
	/**
	 * The booking type that will default throughout the application, e.g. passenger or vehicle.
	 */
	@observable
	@attribute<ConfigGeneralEntity, Enums.defaultBookingType>()
	@CRUD({
		name: 'Default booking type',
		description: 'The booking type that the booking wizard, ferry schedule and check-in will default to.',
		displayType: 'enum-combobox',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.defaultBookingTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.defaultBookingTypeOptions),
		displayFunction: (attr: Enums.defaultBookingType) => Enums.defaultBookingTypeOptions[attr],
	})
	public defaultBookingType: Enums.defaultBookingType;
	// % protected region % [Modify props to the crud options here for attribute 'Default Booking Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Time Format'] on begin
	/**
	 * The time format that will be used throughout the application.
	 */
	@observable
	@attribute<ConfigGeneralEntity, Enums.timeFormat>()
	@CRUD({
		name: 'Time format',
		description: 'Time will display in 24hr time or 12hr time throughout the application.',
		displayType: 'enum-combobox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.timeFormatOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.timeFormatOptions),
		displayFunction: (attr: Enums.timeFormat) => Enums.timeFormatOptions[attr],
	})
	public timeFormat: Enums.timeFormat;
	// % protected region % [Modify props to the crud options here for attribute 'Time Format'] end

	// % protected region % [Modify props to the crud options here for attribute 'System Of Measurement'] on begin
	/**
	 * The measurement system used by the application.
	 */
	@observable
	@attribute<ConfigGeneralEntity, Enums.systemOfMeasurementType>()
	@CRUD({
		name: 'System of measurement',
		description: 'What system of measurement would you like the system to use? Imperial or metric?',
		displayType: 'enum-combobox',
		order: 540,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.systemOfMeasurementTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.systemOfMeasurementTypeOptions),
		displayFunction: (attr: Enums.systemOfMeasurementType) => Enums.systemOfMeasurementTypeOptions[attr],
	})
	public systemOfMeasurement: Enums.systemOfMeasurementType;
	// % protected region % [Modify props to the crud options here for attribute 'System Of Measurement'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disable Cargo'] on begin
	/**
	 * Whether or not the application will allow cargo.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Disable cargo',
		displayType: 'hidden',
		order: 550,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disableCargo: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disable Cargo'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date Format'] on begin
	/**
	 * The format that will be used by dates in the application.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Date format',
		description: 'How would you like your date format to appear around the application. E.g. dd/MM/yyyy.',
		displayType: 'textfield',
		order: 560,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public dateFormat: string;
	// % protected region % [Modify props to the crud options here for attribute 'Date Format'] end

	// % protected region % [Modify props to the crud options here for attribute 'Default Currency Code'] on begin
	/**
	 * The default currency used on the website.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Default currency code',
		description: 'The default currency code will be used to format currency in the application.',
		displayType: 'textfield',
		order: 480,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public defaultCurrencyCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Default Currency Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Default Number Formatting Code'] on begin
	/**
	 * The code for the default number formatting in the application.
	 */
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Default number formatting code',
		description: 'This will be used to format numbers according the formatting that the application requires.',
		displayType: 'textfield',
		order: 490,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public defaultNumberFormattingCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Default Number Formatting Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Allow Unpaid Bookings'] off begin
	/**
	 * Whether or not to allow unpaid bookings in the system.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Allow Unpaid Bookings',
		displayType: 'checkbox',
		order: 540,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public allowUnpaidBookings: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Allow Unpaid Bookings'] end

	// % protected region % [Modify props to the crud options here for attribute 'Allow Cash In Booking Wizard'] on begin
	/**
	 * Whether or not to allow staff to take cash in the booking wizard.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Allow cash in booking wizard (staff only)',
		description: 'Whether or not to allow staff to take cash in the booking wizard.',
		displayType: 'checkbox',
		order: 510,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public allowCashInBookingWizard: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Allow Cash In Booking Wizard'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hide Events'] off begin
	/**
	 * Whether or not to hide events from the application.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Hide Events',
		displayType: 'checkbox',
		order: 560,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hideEvents: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hide Events'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hide Bulk Booking Button'] off begin
	/**
	 * Whether or not to hide the bulk booking button from the user interface.
	 */
	@observable
	@attribute<ConfigGeneralEntity, boolean>()
	@CRUD({
		name: 'Hide Bulk Booking Button',
		displayType: 'checkbox',
		order: 570,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hideBulkBookingButton: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hide Bulk Booking Button'] end

	// % protected region % [Modify props to the crud options here for attribute 'Business Contact Number'] off begin
	@observable
	@attribute<ConfigGeneralEntity, string>()
	@CRUD({
		name: 'Business Contact Number',
		displayType: 'textfield',
		order: 580,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public businessContactNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Business Contact Number'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Default Language'] on begin
		name: 'Default language',
		description: 'The default language will be the language that the application defaults to when it first loads.',
		displayType: 'reference-combobox',
		order: 520,
		referenceTypeFunc: () => Models.LanguageEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'defaultLanguages',
			oppositeEntity: () => Models.LanguageEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Default Language'] end
	})
	public defaultLanguageId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public defaultLanguage: Models.LanguageEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Default Route'] on begin
		name: 'Default route',
		description: 'The initial route that will show on the ferry schedule when it first loads.',
		displayType: 'reference-combobox',
		order: 150,
		referenceTypeFunc: () => Models.RouteEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'defaultRoutes',
			oppositeEntity: () => Models.RouteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Default Route'] end
	})
	public defaultRouteId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public defaultRoute: Models.RouteEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IConfigGeneralEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IConfigGeneralEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.generalTerms !== undefined) {
				this.generalTerms = attributes.generalTerms;
			}
			if (attributes.vehicleTerms !== undefined) {
				this.vehicleTerms = attributes.vehicleTerms;
			}
			if (attributes.passengerTerms !== undefined) {
				this.passengerTerms = attributes.passengerTerms;
			}
			if (attributes.freightTerms !== undefined) {
				this.freightTerms = attributes.freightTerms;
			}
			if (attributes.defaultEventTerms !== undefined) {
				this.defaultEventTerms = attributes.defaultEventTerms;
			}
			if (attributes.vehiclesEnabled !== undefined) {
				this.vehiclesEnabled = attributes.vehiclesEnabled;
			}
			if (attributes.companyName !== undefined) {
				this.companyName = attributes.companyName;
			}
			if (attributes.abn !== undefined) {
				this.abn = attributes.abn;
			}
			if (attributes.addressLine1 !== undefined) {
				this.addressLine1 = attributes.addressLine1;
			}
			if (attributes.addressLine2 !== undefined) {
				this.addressLine2 = attributes.addressLine2;
			}
			if (attributes.giftCertificateRedeemText !== undefined) {
				this.giftCertificateRedeemText = attributes.giftCertificateRedeemText;
			}
			if (attributes.gstPercentage !== undefined) {
				this.gstPercentage = attributes.gstPercentage;
			}
			if (attributes.creditCardSurchargePercentage !== undefined) {
				this.creditCardSurchargePercentage = attributes.creditCardSurchargePercentage;
			}
			if (attributes.vehicleLabel !== undefined) {
				this.vehicleLabel = attributes.vehicleLabel;
			}
			if (attributes.trailersEnabled !== undefined) {
				this.trailersEnabled = attributes.trailersEnabled;
			}
			if (attributes.vehicleBookingRequiresAdult !== undefined) {
				this.vehicleBookingRequiresAdult = attributes.vehicleBookingRequiresAdult;
			}
			if (attributes.vehicleMakeLabel !== undefined) {
				this.vehicleMakeLabel = attributes.vehicleMakeLabel;
			}
			if (attributes.vehicleMakeInputPlaceholder !== undefined) {
				this.vehicleMakeInputPlaceholder = attributes.vehicleMakeInputPlaceholder;
			}
			if (attributes.vehicleModelLabel !== undefined) {
				this.vehicleModelLabel = attributes.vehicleModelLabel;
			}
			if (attributes.vehicleModelInputPlaceholder !== undefined) {
				this.vehicleModelInputPlaceholder = attributes.vehicleModelInputPlaceholder;
			}
			if (attributes.driverDetailsTitle !== undefined) {
				this.driverDetailsTitle = attributes.driverDetailsTitle;
			}
			if (attributes.driverDetailsSubtitle !== undefined) {
				this.driverDetailsSubtitle = attributes.driverDetailsSubtitle;
			}
			if (attributes.driverDetailsRegoLabel !== undefined) {
				this.driverDetailsRegoLabel = attributes.driverDetailsRegoLabel;
			}
			if (attributes.driverDetailsRegoPlaceholder !== undefined) {
				this.driverDetailsRegoPlaceholder = attributes.driverDetailsRegoPlaceholder;
			}
			if (attributes.driverDetailsRegoCheckboxInfo !== undefined) {
				this.driverDetailsRegoCheckboxInfo = attributes.driverDetailsRegoCheckboxInfo;
			}
			if (attributes.passengerTripSelectionButtonLabel !== undefined) {
				this.passengerTripSelectionButtonLabel = attributes.passengerTripSelectionButtonLabel;
			}
			if (attributes.vehicleTripSelectionButtonLabel !== undefined) {
				this.vehicleTripSelectionButtonLabel = attributes.vehicleTripSelectionButtonLabel;
			}
			if (attributes.passengerTripSelectionButtonSubheading !== undefined) {
				this.passengerTripSelectionButtonSubheading = attributes.passengerTripSelectionButtonSubheading;
			}
			if (attributes.vehicleTripSelectionButtonSubheading !== undefined) {
				this.vehicleTripSelectionButtonSubheading = attributes.vehicleTripSelectionButtonSubheading;
			}
			if (attributes.previousVehicleSelectionEnabled !== undefined) {
				this.previousVehicleSelectionEnabled = attributes.previousVehicleSelectionEnabled;
			}
			if (attributes.customerRegoDisplayEnabled !== undefined) {
				this.customerRegoDisplayEnabled = attributes.customerRegoDisplayEnabled;
			}
			if (attributes.vehicleShortName !== undefined) {
				this.vehicleShortName = attributes.vehicleShortName;
			}
			if (attributes.vehicleLengthEnabled !== undefined) {
				this.vehicleLengthEnabled = attributes.vehicleLengthEnabled;
			}
			if (attributes.reportTimezone !== undefined) {
				this.reportTimezone = attributes.reportTimezone;
			}
			if (attributes.cargoNoteEnabled !== undefined) {
				this.cargoNoteEnabled = attributes.cargoNoteEnabled;
			}
			if (attributes.cargoBookingNoteLabel !== undefined) {
				this.cargoBookingNoteLabel = attributes.cargoBookingNoteLabel;
			}
			if (attributes.cargoBookingNotePlaceholder !== undefined) {
				this.cargoBookingNotePlaceholder = attributes.cargoBookingNotePlaceholder;
			}
			if (attributes.passengerNoteEnabled !== undefined) {
				this.passengerNoteEnabled = attributes.passengerNoteEnabled;
			}
			if (attributes.passengerBookingNoteLabel !== undefined) {
				this.passengerBookingNoteLabel = attributes.passengerBookingNoteLabel;
			}
			if (attributes.passengerBookingNotePlaceholder !== undefined) {
				this.passengerBookingNotePlaceholder = attributes.passengerBookingNotePlaceholder;
			}
			if (attributes.addOnTabSubtitle !== undefined) {
				this.addOnTabSubtitle = attributes.addOnTabSubtitle;
			}
			if (attributes.passengersDefaultToReturn !== undefined) {
				this.passengersDefaultToReturn = attributes.passengersDefaultToReturn;
			}
			if (attributes.vehicleDefaultToReturn !== undefined) {
				this.vehicleDefaultToReturn = attributes.vehicleDefaultToReturn;
			}
			if (attributes.showBanner !== undefined) {
				this.showBanner = attributes.showBanner;
			}
			if (attributes.bannerText !== undefined) {
				this.bannerText = attributes.bannerText;
			}
			if (attributes.showTransactionPopUp !== undefined) {
				this.showTransactionPopUp = attributes.showTransactionPopUp;
			}
			if (attributes.defaultBookingType !== undefined) {
				this.defaultBookingType = attributes.defaultBookingType;
			}
			if (attributes.timeFormat !== undefined) {
				this.timeFormat = attributes.timeFormat;
			}
			if (attributes.systemOfMeasurement !== undefined) {
				this.systemOfMeasurement = attributes.systemOfMeasurement;
			}
			if (attributes.disableCargo !== undefined) {
				this.disableCargo = attributes.disableCargo;
			}
			if (attributes.dateFormat !== undefined) {
				this.dateFormat = attributes.dateFormat;
			}
			if (attributes.defaultCurrencyCode !== undefined) {
				this.defaultCurrencyCode = attributes.defaultCurrencyCode;
			}
			if (attributes.defaultNumberFormattingCode !== undefined) {
				this.defaultNumberFormattingCode = attributes.defaultNumberFormattingCode;
			}
			if (attributes.allowUnpaidBookings !== undefined) {
				this.allowUnpaidBookings = attributes.allowUnpaidBookings;
			}
			if (attributes.allowCashInBookingWizard !== undefined) {
				this.allowCashInBookingWizard = attributes.allowCashInBookingWizard;
			}
			if (attributes.hideEvents !== undefined) {
				this.hideEvents = attributes.hideEvents;
			}
			if (attributes.hideBulkBookingButton !== undefined) {
				this.hideBulkBookingButton = attributes.hideBulkBookingButton;
			}
			if (attributes.businessContactNumber !== undefined) {
				this.businessContactNumber = attributes.businessContactNumber;
			}
			if (attributes.defaultLanguageId !== undefined) {
				this.defaultLanguageId = attributes.defaultLanguageId;
			}
			if (attributes.defaultLanguage !== undefined) {
				if (attributes.defaultLanguage === null) {
					this.defaultLanguage = attributes.defaultLanguage;
				} else if (attributes.defaultLanguage instanceof Models.LanguageEntity) {
					this.defaultLanguage = attributes.defaultLanguage;
					this.defaultLanguageId = attributes.defaultLanguage.id;
				} else {
					this.defaultLanguage = new Models.LanguageEntity(attributes.defaultLanguage);
					this.defaultLanguageId = this.defaultLanguage.id;
				}
			}
			if (attributes.defaultRouteId !== undefined) {
				this.defaultRouteId = attributes.defaultRouteId;
			}
			if (attributes.defaultRoute !== undefined) {
				if (attributes.defaultRoute === null) {
					this.defaultRoute = attributes.defaultRoute;
				} else if (attributes.defaultRoute instanceof Models.RouteEntity) {
					this.defaultRoute = attributes.defaultRoute;
					this.defaultRouteId = attributes.defaultRoute.id;
				} else {
					this.defaultRoute = new Models.RouteEntity(attributes.defaultRoute);
					this.defaultRouteId = this.defaultRoute.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		defaultLanguage {
			${Models.LanguageEntity.getAttributes().join('\n')}
		}
		defaultRoute {
			${Models.RouteEntity.getAttributes().join('\n')}
			${Models.RouteEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'defaultLanguage',
							'defaultRoute',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ConfigGeneralEntity.prototype, 'created');
CRUD(modifiedAttr)(ConfigGeneralEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
