import * as React from 'react';
import { observer } from 'mobx-react';
import InputCounter from './InputCounter';
import { action } from 'mobx';
import { getDefaultPassengerInfo } from '../FerryTripBookingWizard/BookingWizardData';

export interface InputCounterStatefulProps {
	model: any;
	modelProperty: string;
	title: string;
	subtitle?: string;
	tooltip?: string;
	onAfterChange?: () => void;
	minCount?: number;
	maxCount?: number;
	disabled: boolean;
}

function InputCounterStateful({
	model,
	modelProperty,
	title,
	subtitle,
	tooltip,
	onAfterChange,
	minCount = 0,
	maxCount,
	disabled,
}: InputCounterStatefulProps) {
	const onAdd = action(() => {
		model[modelProperty]++;
		if (onAfterChange) {
			onAfterChange();
		}
	});
	const onSubtract = action(() => {
		model[modelProperty]--;
		if (onAfterChange) {
			onAfterChange();
		}
	});

	return (
		<div className="input-counter--box">
			<InputCounter
				title={title}
				subtitle={subtitle}
				tooltip={tooltip}
				amount={model[modelProperty]}
				subtractDisabled={disabled || model[modelProperty] <= minCount}
				addDisabled={disabled
				|| (maxCount !== undefined
					? model[modelProperty] >= maxCount
					: false)}
				onAdd={onAdd}
				onSubtract={onSubtract}
			/>
		</div>
	);
}

export default observer(InputCounterStateful);
