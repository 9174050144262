import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import useAsync from 'Hooks/useAsync';
import alertToast from 'Util/ToastifyUtils';
import { BookingEntity, LocationEntity, RouteEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import Tabs from 'Views/Components/Tabs/Tabs';
import BoatImg from 'images/no-results.png';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { Bookings, FetchUserBookings } from 'Util/_HumanWritten/Bookings/FetchUserBookings';
import { UserInvoiceListComponent } from 'Views/Components/_HumanWritten/UserInvoiceList/UserInvoiceListComponent';
import { BookingCard } from 'Views/Components/_HumanWritten/Bookings/BookingCards/BookingCard';

function BookingCardList() {
	return (
		<div className="booking-cards-container">
			<div className="bookings-header">
				<h1>Bookings</h1>
			</div>
			<Tabs
				tabs={[
					{
						component: <BookingListTab key="upcoming-trips" upcoming />,
						name: 'Upcoming',
						key: 'upcoming-trips',
					},
					{
						component: <BookingListTab key="past-trips" upcoming={false} />,
						name: 'Past',
						key: 'past-trips',
					},
					{
						component: <UserInvoiceListComponent key="invoices" userId={store.userId} />,
						name: 'Invoices',
						key: 'invoices',
					},
				]}
			/>
		</div>
	);
}

export default observer(BookingCardList);

async function fetchBookings(skip: number, upcoming: boolean): Promise<Bookings> {
	const customerId = !!store.userId ? store.userId : '';
	return FetchUserBookings({
		customerId,
		skip,
		upcoming,
		expandString: `
			${BookingEntity.getAttributes().join('\n')}
			bookingStatus
			transactionStatus
			isEventBooking
			returnBookingForId
			returnBooking {
				id
			}
			bookingListSummary {
				id
				humanReadableId
				status
				route {
					${RouteEntity.getAttributes().join('\n')}
					departure {
						${LocationEntity.getAttributes().join('\n')}
					}
					destination {
						${LocationEntity.getAttributes().join('\n')}
					}
				}
				tripDateTime
				totalCost
				createdDate
				name
				email
				bookingType
				paidPrice
				eventName
				iconId
				cargoDriverDetailsString
				cargoRegoAndTrailerDetailsString
				passengerDetailsString
				startStopId
				endStopId
			}
		`,
	});
}

function BookingListTab({ upcoming }: { upcoming: boolean }) {
	const [bookingList, setBookingList] = useState<BookingEntity[]>([]);
	const [seeMoreButton, setSeeMoreButton] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);

	const fetchSomeBookings = async () => {
		setLoading(true);
		const newBookings = await fetchBookings(bookingList.length, upcoming);
		setBookingList(bookings => [...bookings, ...newBookings.bookings]);
		setLoading(false);
		if (((newBookings.totalBookingCount - (bookingList.length + newBookings.bookings.length)) <= 0) && seeMoreButton) {
			setSeeMoreButton(false);
		}
	};

	const result = useAsync(async () => {
		await fetchSomeBookings();
	}, []);

	if (result.type === 'loading') {
		return <LottieSpinner />;
	}

	if (result.type === 'error') {
		alertToast('Error fetching bookings', 'error');
	}

	if (bookingList.length > 0) {
		return (
			<>
				{bookingList.map((booking: BookingEntity) => (
					<BookingCard booking={booking} editUrl={`/bookings/${booking.id}`} key={booking.id} />
				))}
				{seeMoreButton && (
					<Button
						key="button"
						className="see-more-button"
						onClick={fetchSomeBookings}
						display={Display.Solid}
						colors={Colors.Secondary}
						disabled={loading}
					>
						{loading ? 'Loading...' : 'See more'}
					</Button>
				)}
			</>
		);
	}

	return (
		<div className="no-bookings-to-show">
			<img src={BoatImg} alt="no-results" />
			<h6>Nothing to see here</h6>
		</div>
	);
}
