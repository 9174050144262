import React from 'react';

import { LocationEntity, RouteEntity } from 'Models/Entities';
import { TripDetails } from 'Modules/TripDetails/TripDetails';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { FerryFilterPassengers } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/PassengerDetails/FerryFilterPassengers';
import If from '../../../../If/If';
import RouteMap from '../../../../RouteMap/RouteMap';
import useDeviceDetect from '../../../../../../Hooks/useDeviceDetect';
import { journeyElementsStore } from '../../../../../../Global/JourneyElements/JourneyElementsStore';
import { isNotNullOrUndefined } from '../../../../../../Util/TypeGuards';
import classNames from 'classnames';
import { isIP } from '../../../../../../Validators/Functions/IpAddress';
import { Button } from '../../../../Button/Button';

export interface FerryFiltersProps {
	wizardData: BookingWizardData;
	onUpdateFilters: (wizardData: BookingWizardData) => void;
	/**
	 * @deprecated
	 */
	route?: RouteEntity | null;
	/**
	 * @deprecated
	 */
	setRoute?: (route: RouteEntity | null) => void;
	/**
	 * @deprecated
	 */
	locations?: LocationEntity[];
}

export function FerryFilters({
	onUpdateFilters,
	wizardData,
}: FerryFiltersProps) {
	const { isIpad } = useDeviceDetect();
	const { locations, routes, isLoading } = journeyElementsStore();
	const [currentRoute, setCurrentRoute] = React.useState<RouteEntity>();
	const [minimise, setMinimise] = React.useState(isIpad);

	// TODO fix multi-stop maps - temp solution
	React.useEffect(() => {
		// Only return a route if it has matching departure and destination locations
		// OR if the departure location is before the destination location in the order of the stops on the route
		const newRoute = routes
			.filter(r => (r.departureId === wizardData.fromLocationId
					&& r.destinationId === wizardData.toLocationId)
				|| ((r.stops.find(s => s.locationId === wizardData.fromLocationId)?.order ?? 0) < (r.stops.find(s => s.locationId === wizardData.toLocationId)?.order ?? 0)));

		// We only want to set the route and show the image/map if there is only one route matching the departure and destination locations
		if (newRoute.length === 1 && isNotNullOrUndefined(newRoute)) {
			setCurrentRoute(newRoute[0]);
		}
	}, [wizardData.fromLocationId, wizardData.toLocationId]);

	return (
		<div className="trip-filters__container">
			<TripDetails
				model={{
					tripTypeTabSelected: wizardData.tripType,
					fromLocation: wizardData.fromLocationId,
					toLocation: wizardData.toLocationId,
					startDate: wizardData.departureTrip === false
						? wizardData.associatedTripDateTime ?? wizardData.startDate
						: wizardData.startDate,
					endDate: wizardData.departureTrip === true
						? wizardData.associatedTripDateTime ?? wizardData.endDate
						: wizardData.endDate,
					route: new RouteEntity(),
					wizardData: wizardData,
				}}
				locations={locations}
				departureTrip={wizardData.departureTrip}
				typeSelected={wizardData.tripType}
				onTabSelected={newTripType => {
					const newData: BookingWizardData = { ...wizardData };
					newData.tripType = newTripType;
					if (newData.tripType === 'one way') {
						newData.returningTicketId = '';
					}
					onUpdateFilters(newData);
				}}
				onDatesChanged={(date, departureDate, updateOtherDate) => {
					if (date !== undefined) {
						const newData: BookingWizardData = { ...wizardData };
						if (departureDate || updateOtherDate) {
							newData.startDate = date;
							newData.ticketSelectionStartDate = date;
						}
						if (!departureDate || updateOtherDate) {
							newData.endDate = date;
							newData.ticketSelectionEndDate = date;
						}
						onUpdateFilters(newData);
					}
				}}
				onUpdateRouteDetails={(fromLocation, toLocation, newRoute) => {
					const newData: BookingWizardData = { ...wizardData };
					newData.fromLocationId = fromLocation;
					newData.toLocationId = toLocation;

					// Reset because additional booking options may not be
					// available in the new route/ferry
					newData.departingTripOptions = [];
					newData.returningTripOptions = [];

					onUpdateFilters(newData);
				}}
			/>
			<div className="dashed-border" />
			<If condition={isIpad}>
				<If condition={isNotNullOrUndefined(currentRoute)
					&& currentRoute.stops.length === 2}
				>
					<RouteMap
						currentRoute={currentRoute?.id}
						locations={locations}
						routes={routes}
						isMobile={isIpad}
					/>
				</If>
				<If condition={isNotNullOrUndefined(currentRoute) && currentRoute.stops.length !== 2
					&& isNotNullOrUndefined(currentRoute.mapId)}
				>
					<div
						className={classNames(
							'map__container',
							{ 'map__container--minimised': minimise && isIpad && !isLoading },
						)}
						style={{ display: isLoading ? 'none' : undefined }}
					>
						<If condition={isIpad}>
							<Button
								className="map__button"
								onClick={() => {
									setMinimise(!minimise);
								}}
								icon={{ icon: `${minimise ? 'minimise-2' : 'expand-2'}`, iconPos: 'icon-left' }}
							/>
						</If>
						<img
							className="event-detailed-view__supporting-image"
							src={`/api/files/${currentRoute?.mapId}`}
							key={currentRoute?.mapId}
							alt=""
						/>
					</div>
				</If>
			</If>
			<FerryFilterPassengers
				wizardData={wizardData}
				onUpdateFilters={onUpdateFilters}
			/>
		</div>
	);
}
