/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsAlterationEntity from 'Models/Security/Acl/VisitorsAlterationEntity';
import UserAlterationEntity from 'Models/Security/Acl/UserAlterationEntity';
import StaffAlterationEntity from 'Models/Security/Acl/StaffAlterationEntity';
import ManagerAlterationEntity from 'Models/Security/Acl/ManagerAlterationEntity';
import AdminAlterationEntity from 'Models/Security/Acl/AdminAlterationEntity';
import InvoicedUserAlterationEntity from 'Models/Security/Acl/InvoicedUserAlterationEntity';
import FixedPriceUserAlterationEntity from 'Models/Security/Acl/FixedPriceUserAlterationEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { PassengerTypeKey } from '../Enums';
import { isNotNullOrUndefined } from '../../Util/TypeGuards';
// % protected region % [Add any further imports here] end

export interface IAlterationEntityAttributes extends IModelAttributes {
	status: Enums.bookingStatus;
	legacyId: number;
	note: string;
	isInvoiced: boolean;

	bookingId: string;
	booking: Models.BookingEntity | Models.IBookingEntityAttributes;
	discounts: Array<
		| Models.DiscountAlteration
		| Models.IDiscountAlterationAttributes
	>;
	eventBookingDetailId?: string;
	eventBookingDetail?: Models.EventBookingDetailEntity | Models.IEventBookingDetailEntityAttributes;
	ferryTripId?: string;
	ferryTrip?: Models.FerryTripEntity | Models.IFerryTripEntityAttributes;
	transactionId: string;
	transaction: Models.TransactionEntity | Models.ITransactionEntityAttributes;
	endStopId?: string;
	endStop?: Models.StopEntity | Models.IStopEntityAttributes;
	fees: Array<
		| Models.FeeEntity
		| Models.IFeeEntityAttributes
	>;
	managerBookingDiscountUsages: Array<
		| Models.ManagerBookingDiscountUsagesEntity
		| Models.IManagerBookingDiscountUsagesEntityAttributes
	>;
	managerBookingDiscounts: Array<
		| Models.ManagerBookingDiscountEntity
		| Models.IManagerBookingDiscountEntityAttributes
	>;
	startStopId?: string;
	startStop?: Models.StopEntity | Models.IStopEntityAttributes;
	tickets: Array<
		| Models.TicketEntity
		| Models.ITicketEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AlterationEntity', 'Alteration')
// % protected region % [Customise your entity metadata here] end
export default class AlterationEntity extends Model
	implements IAlterationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAlterationEntity(),
		new UserAlterationEntity(),
		new StaffAlterationEntity(),
		new ManagerAlterationEntity(),
		new AdminAlterationEntity(),
		new InvoicedUserAlterationEntity(),
		new FixedPriceUserAlterationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@observable
	@attribute<AlterationEntity, Enums.bookingStatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.bookingStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.bookingStatusOptions),
		displayFunction: (attr: Enums.bookingStatus) => Enums.bookingStatusOptions[attr],
	})
	public status: Enums.bookingStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<AlterationEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Note'] off begin
	/**
	 * Booking additional details
	 */
	@observable
	@attribute<AlterationEntity, string>()
	@CRUD({
		name: 'Note',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public note: string;
	// % protected region % [Modify props to the crud options here for attribute 'Note'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Invoiced'] off begin
	/**
	 * If the alteration has been invoiced or not.
	 */
	@observable
	@attribute<AlterationEntity, boolean>()
	@CRUD({
		name: 'Is Invoiced',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isInvoiced: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Invoiced'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Booking'] off begin
		name: 'Booking',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.BookingEntity,
		// % protected region % [Modify props to the crud options here for reference 'Booking'] end
	})
	public bookingId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public booking: Models.BookingEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Discount'] off begin
		name: 'Discount',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DiscountAlteration,
		optionEqualFunc: makeJoinEqualsFunc('discountId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'alterationEntity',
			oppositeEntityName: 'discountEntity',
			relationName: 'alteration',
			relationOppositeName: 'discount',
			entity: () => Models.AlterationEntity,
			joinEntity: () => Models.DiscountAlteration,
			oppositeEntity: () => Models.DiscountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Discount'] end
	})
	public discounts: Models.DiscountAlteration[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Event Booking Detail'] off begin
		name: 'Event Booking Detail',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.EventBookingDetailEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'eventBookingDetails',
			oppositeEntity: () => Models.EventBookingDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Event Booking Detail'] end
	})
	public eventBookingDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public eventBookingDetail: Models.EventBookingDetailEntity;

	/**
	 * The relationship between an alteration and a ferry trip
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] on begin
		name: 'Ferry Trip',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.FerryTripEntity,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] end
	})
	public ferryTripId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public ferryTrip: Models.FerryTripEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] off begin
		name: 'Transaction',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.TransactionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] end
	})
	public transactionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public transaction: Models.TransactionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'End Stop'] off begin
		name: 'End Stop',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.StopEntity,
		// % protected region % [Modify props to the crud options here for reference 'End Stop'] end
	})
	public endStopId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public endStop: Models.StopEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Fee'] off begin
		name: 'Fees',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.FeeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'fees',
			oppositeEntity: () => Models.FeeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Fee'] end
	})
	public fees: Models.FeeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Manager booking discount usage'] off begin
		name: 'Manager booking discount usages',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.ManagerBookingDiscountUsagesEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'managerBookingDiscountUsages',
			oppositeEntity: () => Models.ManagerBookingDiscountUsagesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Manager booking discount usage'] end
	})
	public managerBookingDiscountUsages: Models.ManagerBookingDiscountUsagesEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Manager booking discount'] off begin
		name: 'Manager booking discounts',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.ManagerBookingDiscountEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'managerBookingDiscounts',
			oppositeEntity: () => Models.ManagerBookingDiscountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Manager booking discount'] end
	})
	public managerBookingDiscounts: Models.ManagerBookingDiscountEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Start Stop'] off begin
		name: 'Start Stop',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.StopEntity,
		// % protected region % [Modify props to the crud options here for reference 'Start Stop'] end
	})
	public startStopId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public startStop: Models.StopEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] off begin
		name: 'Tickets',
		displayType: 'reference-multicombobox',
		order: 150,
		referenceTypeFunc: () => Models.TicketEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tickets',
			oppositeEntity: () => Models.TicketEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] end
	})
	public tickets: Models.TicketEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAlterationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAlterationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.note !== undefined) {
				this.note = attributes.note;
			}
			if (attributes.isInvoiced !== undefined) {
				this.isInvoiced = attributes.isInvoiced;
			}
			if (attributes.bookingId !== undefined) {
				this.bookingId = attributes.bookingId;
			}
			if (attributes.booking !== undefined) {
				if (attributes.booking === null) {
					this.booking = attributes.booking;
				} else if (attributes.booking instanceof Models.BookingEntity) {
					this.booking = attributes.booking;
					this.bookingId = attributes.booking.id;
				} else {
					this.booking = new Models.BookingEntity(attributes.booking);
					this.bookingId = this.booking.id;
				}
			}
			if (attributes.discounts !== undefined && Array.isArray(attributes.discounts)) {
				for (const model of attributes.discounts) {
					if (model instanceof Models.DiscountAlteration) {
						this.discounts.push(model);
					} else {
						this.discounts.push(new Models.DiscountAlteration(model));
					}
				}
			}
			if (attributes.eventBookingDetailId !== undefined) {
				this.eventBookingDetailId = attributes.eventBookingDetailId;
			}
			if (attributes.eventBookingDetail !== undefined) {
				if (attributes.eventBookingDetail === null) {
					this.eventBookingDetail = attributes.eventBookingDetail;
				} else if (attributes.eventBookingDetail instanceof Models.EventBookingDetailEntity) {
					this.eventBookingDetail = attributes.eventBookingDetail;
					this.eventBookingDetailId = attributes.eventBookingDetail.id;
				} else {
					this.eventBookingDetail = new Models.EventBookingDetailEntity(attributes.eventBookingDetail);
					this.eventBookingDetailId = this.eventBookingDetail.id;
				}
			}
			if (attributes.ferryTripId !== undefined) {
				this.ferryTripId = attributes.ferryTripId;
			}
			if (attributes.ferryTrip !== undefined) {
				if (attributes.ferryTrip === null) {
					this.ferryTrip = attributes.ferryTrip;
				} else if (attributes.ferryTrip instanceof Models.FerryTripEntity) {
					this.ferryTrip = attributes.ferryTrip;
					this.ferryTripId = attributes.ferryTrip.id;
				} else {
					this.ferryTrip = new Models.FerryTripEntity(attributes.ferryTrip);
					this.ferryTripId = this.ferryTrip.id;
				}
			}
			if (attributes.transactionId !== undefined) {
				this.transactionId = attributes.transactionId;
			}
			if (attributes.transaction !== undefined) {
				if (attributes.transaction === null) {
					this.transaction = attributes.transaction;
				} else if (attributes.transaction instanceof Models.TransactionEntity) {
					this.transaction = attributes.transaction;
					this.transactionId = attributes.transaction.id;
				} else {
					this.transaction = new Models.TransactionEntity(attributes.transaction);
					this.transactionId = this.transaction.id;
				}
			}
			if (attributes.endStopId !== undefined) {
				this.endStopId = attributes.endStopId;
			}
			if (attributes.endStop !== undefined) {
				if (attributes.endStop === null) {
					this.endStop = attributes.endStop;
				} else if (attributes.endStop instanceof Models.StopEntity) {
					this.endStop = attributes.endStop;
					this.endStopId = attributes.endStop.id;
				} else {
					this.endStop = new Models.StopEntity(attributes.endStop);
					this.endStopId = this.endStop.id;
				}
			}
			if (attributes.fees !== undefined && Array.isArray(attributes.fees)) {
				for (const model of attributes.fees) {
					if (model instanceof Models.FeeEntity) {
						this.fees.push(model);
					} else {
						this.fees.push(new Models.FeeEntity(model));
					}
				}
			}
			if (attributes.managerBookingDiscountUsages !== undefined && Array.isArray(attributes.managerBookingDiscountUsages)) {
				for (const model of attributes.managerBookingDiscountUsages) {
					if (model instanceof Models.ManagerBookingDiscountUsagesEntity) {
						this.managerBookingDiscountUsages.push(model);
					} else {
						this.managerBookingDiscountUsages.push(new Models.ManagerBookingDiscountUsagesEntity(model));
					}
				}
			}
			if (attributes.managerBookingDiscounts !== undefined && Array.isArray(attributes.managerBookingDiscounts)) {
				for (const model of attributes.managerBookingDiscounts) {
					if (model instanceof Models.ManagerBookingDiscountEntity) {
						this.managerBookingDiscounts.push(model);
					} else {
						this.managerBookingDiscounts.push(new Models.ManagerBookingDiscountEntity(model));
					}
				}
			}
			if (attributes.startStopId !== undefined) {
				this.startStopId = attributes.startStopId;
			}
			if (attributes.startStop !== undefined) {
				if (attributes.startStop === null) {
					this.startStop = attributes.startStop;
				} else if (attributes.startStop instanceof Models.StopEntity) {
					this.startStop = attributes.startStop;
					this.startStopId = attributes.startStop.id;
				} else {
					this.startStop = new Models.StopEntity(attributes.startStop);
					this.startStopId = this.startStop.id;
				}
			}
			if (attributes.tickets !== undefined && Array.isArray(attributes.tickets)) {
				for (const model of attributes.tickets) {
					if (model instanceof Models.TicketEntity) {
						this.tickets.push(model);
					} else {
						this.tickets.push(new Models.TicketEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		discounts {
			${Models.DiscountAlteration.getAttributes().join('\n')}
			discount {
				${Models.DiscountEntity.getAttributes().join('\n')}
			}
		}
		booking {
			${Models.BookingEntity.getAttributes().join('\n')}
		}
		eventBookingDetail {
			${Models.EventBookingDetailEntity.getAttributes().join('\n')}
		}
		ferryTrip {
			${Models.FerryTripEntity.getAttributes().join('\n')}
			${Models.FerryTripEntity.getFiles().map(f => f.name).join('\n')}
		}
		transaction {
			${Models.TransactionEntity.getAttributes().join('\n')}
		}
		endStop {
			${Models.StopEntity.getAttributes().join('\n')}
		}
		fees {
			${Models.FeeEntity.getAttributes().join('\n')}
		}
		managerBookingDiscountUsages {
			${Models.ManagerBookingDiscountUsagesEntity.getAttributes().join('\n')}
		}
		managerBookingDiscounts {
			${Models.ManagerBookingDiscountEntity.getAttributes().join('\n')}
		}
		startStop {
			${Models.StopEntity.getAttributes().join('\n')}
		}
		tickets {
			${Models.TicketEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			discounts: {},
			fees: {},
			managerBookingDiscountUsages: {},
			managerBookingDiscounts: {},
			tickets: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'discounts',
							'eventBookingDetail',
							'fees',
							'tickets',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public hasCargo() {
		return this.tickets.some(x => isNotNullOrUndefined(x.cargoDetailId)
			|| isNotNullOrUndefined(x.cargoDetail));
	}

	public getCargo() {
		return this.getCargoDetails()?.cargo ?? null;
	}

	public getCargoPrice() {
		return this.tickets.find(x => isNotNullOrUndefined(x.cargoDetailId)
			|| isNotNullOrUndefined(x.cargoDetail))?.price ?? 0;
	}

	public getCargoDetails() {
		return this.tickets.find(x => isNotNullOrUndefined(x.cargoDetailId)
			|| isNotNullOrUndefined(x.cargoDetail))?.cargoDetail ?? null;
	}

	public getContactFirstName() {
		return this.getCargoDetails()?.contactFirstName ?? null;
	}

	public getContactLastName() {
		return this.getCargoDetails()?.contactLastName ?? null;
	}

	public getContactPhone() {
		return this.getCargoDetails()?.contactPhone ?? null;
	}

	public hasTowOn() {
		return this.tickets.some(x => isNotNullOrUndefined(x.towOnDetailId) || isNotNullOrUndefined(x.towOnDetail));
	}

	public getTowOn() {
		return this.getTowOnDetails()?.towOn ?? null;
	}

	public getTowOnPrice() {
		return this.tickets
			.find(x => isNotNullOrUndefined(x.towOnDetailId)
				|| isNotNullOrUndefined(x.towOnDetail))?.price ?? 0;
	}

	public getTowOnDetails() {
		return this.tickets
			.find(x => isNotNullOrUndefined(x.towOnDetailId)
				|| isNotNullOrUndefined(x.towOnDetail))?.towOnDetail ?? null;
	}

	public getPassengerTickets() {
		return this.tickets
			.filter(x => isNotNullOrUndefined(x.passengerDetails)
				&& x.passengerDetails.length > 0) ?? null;
	}

	public getPassengerTicket(passengerTypeKey: PassengerTypeKey) {
		const passengerTickets = this.getPassengerTickets();
		return passengerTickets?.find(x => x.passengerDetails
			.some(y => y.passengerDetail?.passengerType?.key === passengerTypeKey));
	}

	public getPassengerTicketCount(passengerTypeKey: PassengerTypeKey) {
		let totalPassengers = 0;
		totalPassengers += this.getPassengerTicket(passengerTypeKey)?.amount ?? 0;
		return totalPassengers;
	}

	public getTotalPassengerCountFromAlteration() {
		let totalPassengers = 0;
		this.getPassengerTickets()?.forEach(x => {
			totalPassengers += x.amount;
		});
		return totalPassengers;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AlterationEntity.prototype, 'created');
CRUD(modifiedAttr)(AlterationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
