import { whiteLabelStore } from '../../Models/WhiteLabelStore';
import { isNotNullOrUndefined } from '../TypeGuards';
import passengerTypeStore from '../../Models/PassengerTypeStore';
import { BookingWizardData } from '../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export function formatTripFilterInfo(wizardData: BookingWizardData): string {
	const itemsArray = [];
	const {
		tabSelected,
		trailerCheckboxSelected,
		adultTickets,
		childTickets,
		infantTickets,
		passengerDTickets,
		passengerETickets,
		passengerFTickets,
		passengerGTickets,
		passengerHTickets,
	} = wizardData;

	if (tabSelected === 'vehicle') {
		itemsArray.push(
			`1 ${whiteLabelStore.vehicleLabelLowerCase}`,
		);
	}
	if (trailerCheckboxSelected) {
		itemsArray.push(
			'1 trailer',
		);
	}
	if (isNotNullOrUndefined(adultTickets) && adultTickets.length !== 0) {
		itemsArray.push(
			adultTickets.length > 1
				? `${adultTickets.length} ${passengerTypeStore.getPluralName('A')}`
				: `${adultTickets.length} ${passengerTypeStore.getSingularName('A')}`,
		);
	}
	if (isNotNullOrUndefined(childTickets) && childTickets.length !== 0) {
		itemsArray.push(
			childTickets.length > 1
				? `${childTickets.length} ${passengerTypeStore.getPluralName('B')}`
				: `${childTickets.length} ${passengerTypeStore.getSingularName('B')}`,
		);
	}
	if (isNotNullOrUndefined(infantTickets) && infantTickets.length !== 0) {
		itemsArray.push(
			infantTickets.length > 1
				? `${infantTickets.length} ${passengerTypeStore.getPluralName('C')}`
				: `${infantTickets.length} ${passengerTypeStore.getSingularName('C')}`,
		);
	}

	if (isNotNullOrUndefined(passengerDTickets) && passengerDTickets?.length !== 0) {
		itemsArray.push(
			passengerDTickets?.length > 1
				? `${passengerDTickets?.length} ${passengerTypeStore.getPluralName('D')}`
				: `${passengerDTickets?.length} ${passengerTypeStore.getSingularName('D')}`,
		);
	}
	if (isNotNullOrUndefined(passengerETickets) && passengerETickets?.length !== 0) {
		itemsArray.push(
			passengerETickets?.length > 1
				? `${passengerETickets?.length} ${passengerTypeStore.getPluralName('E')}`
				: `${passengerETickets?.length} ${passengerTypeStore.getSingularName('E')}`,
		);
	}
	if (isNotNullOrUndefined(passengerFTickets) && passengerFTickets?.length !== 0) {
		itemsArray.push(
			passengerFTickets?.length > 1
				? `${passengerFTickets?.length} ${passengerTypeStore.getPluralName('F')}`
				: `${passengerFTickets?.length} ${passengerTypeStore.getSingularName('F')}`,
		);
	}
	if (isNotNullOrUndefined(passengerGTickets) && passengerGTickets?.length !== 0) {
		itemsArray.push(
			passengerGTickets?.length > 1
				? `${passengerGTickets?.length} ${passengerTypeStore.getPluralName('G')}`
				: `${passengerGTickets?.length} ${passengerTypeStore.getSingularName('G')}`,
		);
	}
	if (isNotNullOrUndefined(passengerHTickets) && passengerHTickets?.length !== 0) {
		itemsArray.push(
			passengerHTickets?.length > 1
				? `${passengerHTickets?.length} ${passengerTypeStore.getPluralName('H')}`
				: `${passengerHTickets?.length} ${passengerTypeStore.getSingularName('H')}`,
		);
	}
	return itemsArray.join(', ').toLowerCase();
}
