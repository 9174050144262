/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminSegmentEntity from 'Models/Security/Acl/AdminSegmentEntity';
import FixedPriceUserSegmentEntity from 'Models/Security/Acl/FixedPriceUserSegmentEntity';
import InvoicedUserSegmentEntity from 'Models/Security/Acl/InvoicedUserSegmentEntity';
import ManagerSegmentEntity from 'Models/Security/Acl/ManagerSegmentEntity';
import StaffSegmentEntity from 'Models/Security/Acl/StaffSegmentEntity';
import UserSegmentEntity from 'Models/Security/Acl/UserSegmentEntity';
import VisitorsSegmentEntity from 'Models/Security/Acl/VisitorsSegmentEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getStopById } from 'Global/JourneyElements/Utils/getStopById';
import { getRouteById } from 'Global/JourneyElements/Utils/getRouteById';
// % protected region % [Add any further imports here] end

export interface ISegmentEntityAttributes extends IModelAttributes {
	durationInMinutes: number;

	endStopId: string;
	endStop: Models.StopEntity | Models.IStopEntityAttributes;
	routeId: string;
	route: Models.RouteEntity | Models.IRouteEntityAttributes;
	startStopId: string;
	startStop: Models.StopEntity | Models.IStopEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SegmentEntity', 'Segment')
// % protected region % [Customise your entity metadata here] end
export default class SegmentEntity extends Model
	implements ISegmentEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminSegmentEntity(),
		new FixedPriceUserSegmentEntity(),
		new InvoicedUserSegmentEntity(),
		new ManagerSegmentEntity(),
		new StaffSegmentEntity(),
		new UserSegmentEntity(),
		new VisitorsSegmentEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Duration In Minutes'] off begin
	@Validators.Integer()
	@observable
	@attribute<SegmentEntity, number>()
	@CRUD({
		name: 'Duration In Minutes',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public durationInMinutes: number;
	// % protected region % [Modify props to the crud options here for attribute 'Duration In Minutes'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'End Stop'] on begin
		name: 'End Stop',
		displayType: 'reference-combobox',
		referenceTypeFunc: () => Models.StopEntity,
		// Custom
		order: 40,
		// % protected region % [Modify props to the crud options here for reference 'End Stop'] end
	})
	public endStopId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public endStop: Models.StopEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Route'] on begin
		name: 'Route [start.order, end.order]',
		displayType: 'reference-combobox',
		referenceTypeFunc: () => Models.RouteEntity,
		// Custom
		order: 20,
		headerColumn: true,
		displayFunction: (routeId, model) => {
			console.log();
			const route = getRouteById(routeId);
			let result = route?.name ?? '';

			// @ts-ignore
			if ('startStopId' in model && 'endStopId' in model && typeof model.startStopId === 'string' && typeof model.endStopId === 'string') {
				// @ts-ignore
				const startStop = getStopById(model.startStopId);
				// @ts-ignore
				const endStop = getStopById(model.endStopId);
				if (startStop && endStop) {
					result += ` [${startStop.order}, ${endStop.order}]`;
				}
			}

			return result;
		},
		// % protected region % [Modify props to the crud options here for reference 'Route'] end
	})
	public routeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public route: Models.RouteEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Start Stop'] on begin
		name: 'Start Stop',
		displayType: 'reference-combobox',
		// Custom
		order: 30,
		referenceTypeFunc: () => Models.StopEntity,
		// % protected region % [Modify props to the crud options here for reference 'Start Stop'] end
	})
	public startStopId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public startStop: Models.StopEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISegmentEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISegmentEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.durationInMinutes !== undefined) {
				this.durationInMinutes = attributes.durationInMinutes;
			}
			if (attributes.endStopId !== undefined) {
				this.endStopId = attributes.endStopId;
			}
			if (attributes.endStop !== undefined) {
				if (attributes.endStop === null) {
					this.endStop = attributes.endStop;
				} else if (attributes.endStop instanceof Models.StopEntity) {
					this.endStop = attributes.endStop;
					this.endStopId = attributes.endStop.id;
				} else {
					this.endStop = new Models.StopEntity(attributes.endStop);
					this.endStopId = this.endStop.id;
				}
			}
			if (attributes.routeId !== undefined) {
				this.routeId = attributes.routeId;
			}
			if (attributes.route !== undefined) {
				if (attributes.route === null) {
					this.route = attributes.route;
				} else if (attributes.route instanceof Models.RouteEntity) {
					this.route = attributes.route;
					this.routeId = attributes.route.id;
				} else {
					this.route = new Models.RouteEntity(attributes.route);
					this.routeId = this.route.id;
				}
			}
			if (attributes.startStopId !== undefined) {
				this.startStopId = attributes.startStopId;
			}
			if (attributes.startStop !== undefined) {
				if (attributes.startStop === null) {
					this.startStop = attributes.startStop;
				} else if (attributes.startStop instanceof Models.StopEntity) {
					this.startStop = attributes.startStop;
					this.startStopId = attributes.startStop.id;
				} else {
					this.startStop = new Models.StopEntity(attributes.startStop);
					this.startStopId = this.startStop.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		startStop {
			${Models.StopEntity.getAttributes().join('\n')}
			location {
				shortName
			}
		}
		endStop {
			${Models.StopEntity.getAttributes().join('\n')}
			location {
				shortName
			}
		}
		route {
			${Models.RouteEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return `${this.route.name} | (${this.startStop.order}) ${this.startStop.location.shortName} to (${this.endStop.order}) ${this.endStop.location.shortName}`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SegmentEntity.prototype, 'created');
CRUD(modifiedAttr)(SegmentEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
