import React from 'react';

import useAddOns from 'Hooks/useAddOns';
import useAddOnsContext from 'Hooks/useAddOnsContext';
import addOnStore from 'Models/AddOnStore';
import { AdditionalBookingSectionEntity } from 'Models/Entities';
import { getFerryAndRouteIds } from 'Util/_HumanWritten/BookingWizard/BookingWizardWrapUtils';
import { compareFnByNumberAsc } from 'Util/_HumanWritten/SortUtils';
import { AddOnSection } from './AddOnSection';
import { useAddOnRouteTitle } from './Hooks/useAddOnRouteTitle';

export interface AddOnsCollectionProps {
	isVehicleOnly?: boolean;
	userId?: string;
}

export function AddOnsCollection({ isVehicleOnly, userId }: AddOnsCollectionProps) {
	const { selectedTrips } = useAddOnsContext();

	useAddOns(userId, true);

	const departing = filterAddOnSections(
		addOnStore.getAddOnSectionsByFerriesAndRoutes(getFerryAndRouteIds(selectedTrips, 'departing')),
		isVehicleOnly ?? false,
	);

	const returning = filterAddOnSections(
		addOnStore.getAddOnSectionsByFerriesAndRoutes(getFerryAndRouteIds(selectedTrips, 'returning')),
		isVehicleOnly ?? false,
	);

	const { departingName, returningName } = useAddOnRouteTitle();

	return (
		<div>
			{selectedTrips.departingTrip && departing.length > 0 && (
				<div>
					<h5>{departingName}</h5>
					{departing.map(x => <AddOnSection key={x.id} addOnSection={x} direction="DEPARTURE" />)}
				</div>
			)}
			{selectedTrips.returningTrip && returning.length > 0 && (
				<div className="mt-md">
					<h5>{returningName}</h5>
					{returning.map(x => <AddOnSection key={x.id} addOnSection={x} direction="RETURN" />)}
				</div>
			)}
		</div>
	);
}

function filterAddOnSections(addOnSections: AdditionalBookingSectionEntity[], isVehicleOnly: boolean) {
	return addOnSections
		.filter(
			x => x.additionalBookingOptions
				.find(y => isVehicleOnly ? y.isvehicleonly === true : y.isvehicleonly !== true) !== undefined,
		)
		.sort(compareFnByNumberAsc(x => x?.order));
}
