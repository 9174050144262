/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFreightDetailFreightHandlingAttributes extends IModelAttributes {
	freightDetailId: string;
	freightHandlingId: string;

	freightDetail: Models.FreightDetailEntity | Models.IFreightDetailEntityAttributes;
	freightHandling: Models.FreightHandlingEntity | Models.IFreightHandlingEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('FreightDetailFreightHandling')
export default class FreightDetailFreightHandling
	extends Model
	implements IFreightDetailFreightHandlingAttributes {
	public static acls: IAcl[] = [
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public freightDetailId: string;

	@observable
	@attribute()
	public freightHandlingId: string;

	@observable
	@attribute({ isReference: true })
	public freightDetail: Models.FreightDetailEntity;

	@observable
	@attribute({ isReference: true })
	public freightHandling: Models.FreightHandlingEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IFreightDetailFreightHandlingAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.freightDetailId) {
				this.freightDetailId = attributes.freightDetailId;
			}
			if (attributes.freightHandlingId) {
				this.freightHandlingId = attributes.freightHandlingId;
			}

			if (attributes.freightDetail) {
				if (attributes.freightDetail instanceof Models.FreightDetailEntity) {
					this.freightDetail = attributes.freightDetail;
					this.freightDetailId = attributes.freightDetail.id;
				} else {
					this.freightDetail = new Models.FreightDetailEntity(attributes.freightDetail);
					this.freightDetailId = this.freightDetail.id;
				}
			} else if (attributes.freightDetailId !== undefined) {
				this.freightDetailId = attributes.freightDetailId;
			}

			if (attributes.freightHandling) {
				if (attributes.freightHandling instanceof Models.FreightHandlingEntity) {
					this.freightHandling = attributes.freightHandling;
					this.freightHandlingId = attributes.freightHandling.id;
				} else {
					this.freightHandling = new Models.FreightHandlingEntity(attributes.freightHandling);
					this.freightHandlingId = this.freightHandling.id;
				}
			} else if (attributes.freightHandlingId !== undefined) {
				this.freightHandlingId = attributes.freightHandlingId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
