import dayjs from 'dayjs';

import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { devLog } from 'Util/_HumanWritten/ConsoleLogUtils';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { useIsPaxSpaceExceeded } from './useIsPaxSpaceExceeded';
import { useIsSoldOut } from './useIsSoldOut';
import { useIsVehicleSpaceExceeded } from './useIsVehicleSpaceExceeded';

export const useIsLegVisuallyDisabled = (trip: TicketsTabTrip, wizardData: BookingWizardData) => {
	const isSoldOut = useIsSoldOut(wizardData.tabSelected, trip.passengerSpacesAvailable, trip.vehicleSpacesAvailable);

	const tripHasDeparted = trip.departureDateTime <= new Date();
	const tripIsClosed = trip.isClosed;

	const isVehicleBooking = wizardData.tabSelected === 'vehicle';
	const exceedsAvailablePaxSpace = useIsPaxSpaceExceeded(trip.passengerSpacesAvailable, wizardData);
	const exceedsAvailableVehicleSpace = useIsVehicleSpaceExceeded(trip.vehicleSpacesAvailable, wizardData);

	const formattedDepartureTime = dayjs(trip.departureDateTime).format('HH:mm, MMM D, ddd');

	if (isSoldOut) {
		devLog(formattedDepartureTime, 'isSoldOut');
		return true;
	}

	// ???
	// if (isInvalidAlterationTicket) {
	// 	return false;
	// }

	if (tripHasDeparted) {
		devLog(formattedDepartureTime, 'tripHasDeparted');
		return true;
	}

	if (tripIsClosed) {
		devLog(formattedDepartureTime, 'tripIsClosed');
		return true;
	}

	if (exceedsAvailablePaxSpace) {
		devLog(formattedDepartureTime, 'exceedsAvailablePaxSpace');
		return true;
	}

	if (isVehicleBooking && exceedsAvailableVehicleSpace) {
		devLog(formattedDepartureTime, 'isVehicleBooking && exceedsAvailableVehicleSpace');
		return true;
	}

	return false;
};
