/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminFreightDetailEntity from 'Models/Security/Acl/AdminFreightDetailEntity';
import ManagerFreightDetailEntity from 'Models/Security/Acl/ManagerFreightDetailEntity';
import StaffFreightDetailEntity from 'Models/Security/Acl/StaffFreightDetailEntity';
import UserFreightDetailEntity from 'Models/Security/Acl/UserFreightDetailEntity';
import FixedPriceUserFreightDetailEntity from 'Models/Security/Acl/FixedPriceUserFreightDetailEntity';
import InvoicedUserFreightDetailEntity from 'Models/Security/Acl/InvoicedUserFreightDetailEntity';
import VisitorsFreightDetailEntity from 'Models/Security/Acl/VisitorsFreightDetailEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFreightDetailEntityAttributes extends IModelAttributes {
	description: string;
	width: number;
	height: number;
	length: number;
	weight: number;
	cubic: number;

	freightCategoryId?: string;
	freightCategory?: Models.FreightCategoryEntity | Models.IFreightCategoryEntityAttributes;
	freightTypeId?: string;
	freightType?: Models.FreightTypeEntity | Models.IFreightTypeEntityAttributes;
	freightHandlings: Array<
		| Models.FreightDetailFreightHandling
		| Models.IFreightDetailFreightHandlingAttributes
	>;
	freightNotifyContactId?: string;
	freightNotifyContact?: Models.FreightContactEntity | Models.IFreightContactEntityAttributes;
	freightReceiverContactId?: string;
	freightReceiverContact?: Models.FreightContactEntity | Models.IFreightContactEntityAttributes;
	tickets: Array<
		| Models.TicketEntity
		| Models.ITicketEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FreightDetailEntity', 'Freight Detail')
// % protected region % [Customise your entity metadata here] end
export default class FreightDetailEntity extends Model
	implements IFreightDetailEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminFreightDetailEntity(),
		new ManagerFreightDetailEntity(),
		new StaffFreightDetailEntity(),
		new UserFreightDetailEntity(),
		new FixedPriceUserFreightDetailEntity(),
		new InvoicedUserFreightDetailEntity(),
		new VisitorsFreightDetailEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * A free text field for the customer to enter a description about their freight.
	 */
	@observable
	@attribute<FreightDetailEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Width'] off begin
	/**
	 * The width of the freight.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightDetailEntity, number>()
	@CRUD({
		name: 'Width',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public width: number;
	// % protected region % [Modify props to the crud options here for attribute 'Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Height'] off begin
	/**
	 * The height of the freight.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightDetailEntity, number>()
	@CRUD({
		name: 'Height',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public height: number;
	// % protected region % [Modify props to the crud options here for attribute 'Height'] end

	// % protected region % [Modify props to the crud options here for attribute 'Length'] off begin
	/**
	 * The length of the freight.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightDetailEntity, number>()
	@CRUD({
		name: 'Length',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public length: number;
	// % protected region % [Modify props to the crud options here for attribute 'Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Weight'] off begin
	/**
	 * The weight of the freight (per item).
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightDetailEntity, number>()
	@CRUD({
		name: 'Weight',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public weight: number;
	// % protected region % [Modify props to the crud options here for attribute 'Weight'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cubic'] off begin
	/**
	 * The cubic volume of the freight (per item).
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightDetailEntity, number>()
	@CRUD({
		name: 'Cubic',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public cubic: number;
	// % protected region % [Modify props to the crud options here for attribute 'Cubic'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Category'] off begin
		name: 'Freight Category',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.FreightCategoryEntity,
		// % protected region % [Modify props to the crud options here for reference 'Freight Category'] end
	})
	public freightCategoryId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public freightCategory: Models.FreightCategoryEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Type'] off begin
		name: 'Freight Type',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.FreightTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Freight Type'] end
	})
	public freightTypeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public freightType: Models.FreightTypeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Handling'] off begin
		name: 'Freight Handling',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FreightDetailFreightHandling,
		optionEqualFunc: makeJoinEqualsFunc('freightHandlingId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'freightDetailEntity',
			oppositeEntityName: 'freightHandlingEntity',
			relationName: 'freightDetail',
			relationOppositeName: 'freightHandling',
			entity: () => Models.FreightDetailEntity,
			joinEntity: () => Models.FreightDetailFreightHandling,
			oppositeEntity: () => Models.FreightHandlingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Freight Handling'] end
	})
	public freightHandlings: Models.FreightDetailFreightHandling[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Notify Contact'] off begin
		name: 'Freight Notify Contact',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.FreightContactEntity,
		// % protected region % [Modify props to the crud options here for reference 'Freight Notify Contact'] end
	})
	public freightNotifyContactId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public freightNotifyContact: Models.FreightContactEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Receiver Contact'] off begin
		name: 'Freight Receiver Contact',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.FreightContactEntity,
		// % protected region % [Modify props to the crud options here for reference 'Freight Receiver Contact'] end
	})
	public freightReceiverContactId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public freightReceiverContact: Models.FreightContactEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] off begin
		name: 'Tickets',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.TicketEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tickets',
			oppositeEntity: () => Models.TicketEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] end
	})
	public tickets: Models.TicketEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFreightDetailEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFreightDetailEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.width !== undefined) {
				this.width = attributes.width;
			}
			if (attributes.height !== undefined) {
				this.height = attributes.height;
			}
			if (attributes.length !== undefined) {
				this.length = attributes.length;
			}
			if (attributes.weight !== undefined) {
				this.weight = attributes.weight;
			}
			if (attributes.cubic !== undefined) {
				this.cubic = attributes.cubic;
			}
			if (attributes.freightCategoryId !== undefined) {
				this.freightCategoryId = attributes.freightCategoryId;
			}
			if (attributes.freightCategory !== undefined) {
				if (attributes.freightCategory === null) {
					this.freightCategory = attributes.freightCategory;
				} else if (attributes.freightCategory instanceof Models.FreightCategoryEntity) {
					this.freightCategory = attributes.freightCategory;
					this.freightCategoryId = attributes.freightCategory.id;
				} else {
					this.freightCategory = new Models.FreightCategoryEntity(attributes.freightCategory);
					this.freightCategoryId = this.freightCategory.id;
				}
			}
			if (attributes.freightTypeId !== undefined) {
				this.freightTypeId = attributes.freightTypeId;
			}
			if (attributes.freightType !== undefined) {
				if (attributes.freightType === null) {
					this.freightType = attributes.freightType;
				} else if (attributes.freightType instanceof Models.FreightTypeEntity) {
					this.freightType = attributes.freightType;
					this.freightTypeId = attributes.freightType.id;
				} else {
					this.freightType = new Models.FreightTypeEntity(attributes.freightType);
					this.freightTypeId = this.freightType.id;
				}
			}
			if (attributes.freightHandlings !== undefined && Array.isArray(attributes.freightHandlings)) {
				for (const model of attributes.freightHandlings) {
					if (model instanceof Models.FreightDetailFreightHandling) {
						this.freightHandlings.push(model);
					} else {
						this.freightHandlings.push(new Models.FreightDetailFreightHandling(model));
					}
				}
			}
			if (attributes.freightNotifyContactId !== undefined) {
				this.freightNotifyContactId = attributes.freightNotifyContactId;
			}
			if (attributes.freightNotifyContact !== undefined) {
				if (attributes.freightNotifyContact === null) {
					this.freightNotifyContact = attributes.freightNotifyContact;
				} else if (attributes.freightNotifyContact instanceof Models.FreightContactEntity) {
					this.freightNotifyContact = attributes.freightNotifyContact;
					this.freightNotifyContactId = attributes.freightNotifyContact.id;
				} else {
					this.freightNotifyContact = new Models.FreightContactEntity(attributes.freightNotifyContact);
					this.freightNotifyContactId = this.freightNotifyContact.id;
				}
			}
			if (attributes.freightReceiverContactId !== undefined) {
				this.freightReceiverContactId = attributes.freightReceiverContactId;
			}
			if (attributes.freightReceiverContact !== undefined) {
				if (attributes.freightReceiverContact === null) {
					this.freightReceiverContact = attributes.freightReceiverContact;
				} else if (attributes.freightReceiverContact instanceof Models.FreightContactEntity) {
					this.freightReceiverContact = attributes.freightReceiverContact;
					this.freightReceiverContactId = attributes.freightReceiverContact.id;
				} else {
					this.freightReceiverContact = new Models.FreightContactEntity(attributes.freightReceiverContact);
					this.freightReceiverContactId = this.freightReceiverContact.id;
				}
			}
			if (attributes.tickets !== undefined && Array.isArray(attributes.tickets)) {
				for (const model of attributes.tickets) {
					if (model instanceof Models.TicketEntity) {
						this.tickets.push(model);
					} else {
						this.tickets.push(new Models.TicketEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		freightHandlings {
			${Models.FreightDetailFreightHandling.getAttributes().join('\n')}
			freightHandling {
				${Models.FreightHandlingEntity.getAttributes().join('\n')}
				${Models.FreightHandlingEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		freightCategory {
			${Models.FreightCategoryEntity.getAttributes().join('\n')}
		}
		freightType {
			${Models.FreightTypeEntity.getAttributes().join('\n')}
		}
		freightNotifyContact {
			${Models.FreightContactEntity.getAttributes().join('\n')}
		}
		freightReceiverContact {
			${Models.FreightContactEntity.getAttributes().join('\n')}
		}
		tickets {
			${Models.TicketEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			freightHandlings: {},
			tickets: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'freightHandlings',
							'tickets',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FreightDetailEntity.prototype, 'created');
CRUD(modifiedAttr)(FreightDetailEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
