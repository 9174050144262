import * as React from 'react';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import useStore from 'Hooks/useStore';
import { showCheckInAddMoveModal } from 'Views/Components/_HumanWritten/Modal/CheckInAddMoveModalContents';
import useCheckInStore from 'Hooks/useCheckInStore';
import { observer } from 'mobx-react';
import If from 'Views/Components/If/If';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import LinkAsButton from 'Views/Components/_HumanWritten/LinkAsButton/LinkAsButton';
import useScreenSize from 'Hooks/useScreenSize';
import { formatDateDayMonth } from 'Util/_HumanWritten/TimeUtils';
import { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { CheckInActionButtons } from './CheckInButtons/CheckInActionButtons';
import { upperCaseFirst } from 'Util/StringUtils';
import { useFilterAddOn } from './CheckInView';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { ContextMenu } from '../../../ContextMenu/ContextMenu';
import { contextMenu } from 'react-contexify';
import CustomSpinner from '../../../Spinner/CustomSpinner';
import { ConfirmOverwritePreviousDataModal, SaveDataExplanationModal } from './Helpers/OfflineModals';
import { CheckInStore } from './context/CheckInContext';

export interface CheckInTopNavProps {
	showButtons?: boolean;
	/**
	 * state must be provided if showButtons is true.
	 */
	state?: {
		showCheckedIn: boolean;
	};
}

const isContextDisplayed = () => {
	return document.getElementsByClassName('react-contexify').length > 0;
};

function CheckInMoreOptions() {
	const checkInStore = useCheckInStore();
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);

	if (checkInStore.isOffline()) {
		return null;
	}

	return (
		<>
			<Icon name="refresh-cw" classname={loading ? 'loading' : 'hidden'} />

			<If condition={saving}>
				<CustomSpinner />
			</If>

			<Button
				className="more-options"
				display={Display.Text}
				colors={Colors.Secondary}
				onClick={e => {
					if (isContextDisplayed()) {
						contextMenu.hideAll();
					} else {
						contextMenu.show({ id: 'more-check-in-options', event: e });
					}
				}}
			>
				<Icon name="more-vertical" />
			</Button>
			<ContextMenu
				ref={isContextDisplayed}
				menuId="more-check-in-options"
				location="frontend"
				actions={[{
					label: 'Refresh',
					icon: 'refresh-cw',
					onClick: async () => {
						await runInAction(async () => {
							const animationTime = 2000;
							setLoading(true);

							const start = Date.now();
							await checkInStore.loadFerryTrip(checkInStore.ferryTripId);
							const end = Date.now();

							setTimeout(
								() => setLoading(false),
								animationTime - ((end - start) % animationTime),
							);
						});
					},
				}, {
					label: 'Save for offline',
					icon: 'save',
					onClick: async () => {
						const offlineStore = checkInStore
							.getCheckInCache();

						setSaving(true);

						try {
							const result = await SaveDataExplanationModal();
							if (!result) {
								return; // the user cancelled the modal
							}

							if (await offlineStore.hasCheckInCached()) {
								const overwriteData = await ConfirmOverwritePreviousDataModal();
								if (!overwriteData) {
									return; // the user cancelled the modal
								}
								await offlineStore.clearCachedData();
							}

							// cache the data for offline use
							await offlineStore
								.cacheData(checkInStore as CheckInStore);
						} finally {
							setSaving(false);
						}
					},
				}]}
			/>
		</>
	);
}

function CheckInTopNav({ state, showButtons = false }: CheckInTopNavProps) {
	const store = useStore();
	const routes = useCheckInRoutes();
	const checkInStore = useCheckInStore();
	const isOffline = checkInStore.isOffline();
	const { ferryTrip } = checkInStore;
	const { isIpadMiniLandscape, isMobile } = useScreenSize();
	const [toRoute, setToRoute] = useState<string>('/ferry-schedule');

	const {
		addOnsModel,
		setFilteredAddOns,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
		setFilteredAddOns: x.setFilteredAddOns,
	}));

	useEffect(() => {
		const { pathname } = store.routerHistory.location;
		if (addOnsModel.filteredAddOns.length > 0) {
			setToRoute(routes.base);
		} else if (pathname === routes.base || pathname.includes('booking')) {
			setToRoute(routes.schedule);
		} else {
			setToRoute(routes.base);
		}
	}, [store.routerHistory.location, store.routerHistory.location.search, addOnsModel]);

	const onAddBooking = () => {
		showCheckInAddMoveModal({
			onCancel: () => {
				store.routerHistory.push(routes.selectCustomer);
			},
			onConfirm: () => {
				store.routerHistory.push(routes.move);
			},
		});
	};

	const departName = isMobile
		? ferryTrip.departureShortName
		: ferryTrip.departureLongName;
	const arrivalName = isMobile
		? ferryTrip.destinationShortName
		: ferryTrip.destinationLongName;
	const departTime = ferryTrip?.departTimeFormatted();
	const arrivalTime = ferryTrip?.arrivalTimeFormatted();

	const onClickSort = () => {
		checkInStore.setShowFilters(!checkInStore.showFilters);
	};

	return (
		<div className="check-in__top-nav flex-end">
			<div className="check-in__top-nav__back flex">
				<LinkAsButton
					to={toRoute}
					display={Display.Text}
					colors={Colors.Black}
					onClick={() => {
						setFilteredAddOns([]);
					}}
				>
					<Icon name="chevron-left" classname="mb-xs" />
					<div className="check-in__top-nav__back__text">
						<span className="route-name">
							{upperCaseFirst(departName.toLowerCase())} to {upperCaseFirst(arrivalName.toLowerCase())}
						</span>
						<span className="text--grey">
							{formatDateDayMonth(ferryTrip.departureDateTime)}, {departTime}
							<If condition={!isMobile}>
								{` - ${arrivalTime}`}
							</If>
						</span>
					</div>
				</LinkAsButton>
			</div>
			<If condition={showButtons}>
				<div className="check-in__top-nav__actions flex">
					<CheckInMoreOptions />

					<If condition={!isIpadMiniLandscape}>
						<Button
							className="check-in__action-btn__filter-btn"
							display={Display.Text}
							colors={Colors.Secondary}
							sizes={Sizes.Medium}
							onClick={onClickSort}
						>
							Sort
						</Button>
						<If condition={addOnsModel.filteredAddOns.length === 0}>
							<Button
								buttonProps={{ id: 'checked-in-toggle-button' }}
								className={`check-in__action-btn__toggle-check-in ${state?.showCheckedIn ? 'checked' : 'unchecked'}`}
								display={Display.Text}
								colors={Colors.Secondary}
								sizes={Sizes.ExtraLarge}
								onClick={() => {
									if (state) {
										runInAction(() => {
											state.showCheckedIn = !state?.showCheckedIn;
										});
									}
								}}
								icon={{ icon: 'check-box', iconPos: 'icon-left' }}
							/>
							<If condition={!isOffline}>
								<div className="border__wrapper">
									<div className="border" />
								</div>
								<Button
									className="check-in__action-btn__add-booking"
									buttonProps={{
										id: 'add-move-booking',
									}}
									display={Display.Text}
									colors={Colors.Secondary}
									sizes={Sizes.Medium}
									onClick={onAddBooking}
								>
									Add booking
								</Button>
								<LinkAsButton
									to={routes.scan}
									className="check-in__action-btn__camera"
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Medium}
								>
									Scan QR code
								</LinkAsButton>
							</If>
						</If>
					</If>
				</div>
				<If condition={isIpadMiniLandscape}>
					<CheckInActionButtons state={state} />
				</If>
			</If>
		</div>
	);
}

export default observer(CheckInTopNav);
