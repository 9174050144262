/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminFreightHandlingEntity from 'Models/Security/Acl/AdminFreightHandlingEntity';
import ManagerFreightHandlingEntity from 'Models/Security/Acl/ManagerFreightHandlingEntity';
import StaffFreightHandlingEntity from 'Models/Security/Acl/StaffFreightHandlingEntity';
import UserFreightHandlingEntity from 'Models/Security/Acl/UserFreightHandlingEntity';
import FixedPriceUserFreightHandlingEntity from 'Models/Security/Acl/FixedPriceUserFreightHandlingEntity';
import InvoicedUserFreightHandlingEntity from 'Models/Security/Acl/InvoicedUserFreightHandlingEntity';
import VisitorsFreightHandlingEntity from 'Models/Security/Acl/VisitorsFreightHandlingEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFreightHandlingEntityAttributes extends IModelAttributes {
	label: string;
	colour: string;
	iconId: string;
	icon: Blob;
	abbreviation: string;
	showInCheckIn: boolean;
	disabled: boolean;

	freightDetails: Array<
		| Models.FreightDetailFreightHandling
		| Models.IFreightDetailFreightHandlingAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FreightHandlingEntity', 'Freight Handling')
// % protected region % [Customise your entity metadata here] end
export default class FreightHandlingEntity extends Model
	implements IFreightHandlingEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminFreightHandlingEntity(),
		new ManagerFreightHandlingEntity(),
		new StaffFreightHandlingEntity(),
		new UserFreightHandlingEntity(),
		new FixedPriceUserFreightHandlingEntity(),
		new InvoicedUserFreightHandlingEntity(),
		new VisitorsFreightHandlingEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Label'] off begin
	/**
	 * The display label for the freight handling.
	 */
	@Validators.Required()
	@observable
	@attribute<FreightHandlingEntity, string>()
	@CRUD({
		name: 'Label',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public label: string;
	// % protected region % [Modify props to the crud options here for attribute 'Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Colour'] off begin
	/**
	 * The colour of the tag for the freight handling.
	 */
	@observable
	@attribute<FreightHandlingEntity, string>()
	@CRUD({
		name: 'Colour',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public colour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon'] off begin
	/**
	 * The icon for the freight handling.
	 */
	@observable
	@attribute<FreightHandlingEntity, string>({ file: 'icon' })
	@CRUD({
		name: 'Icon',
		displayType: 'file',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'icon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="The icon for the freight handling." style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public iconId: string;

	@observable
	public icon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Icon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Abbreviation'] off begin
	/**
	 * The abbreviation for the freight handling label.
	 */
	@observable
	@attribute<FreightHandlingEntity, string>()
	@CRUD({
		name: 'Abbreviation',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abbreviation: string;
	// % protected region % [Modify props to the crud options here for attribute 'Abbreviation'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show In Check In'] off begin
	/**
	 * Whether or not to show the freight handling tag in check-in.
	 */
	@observable
	@attribute<FreightHandlingEntity, boolean>()
	@CRUD({
		name: 'Show In Check In',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showInCheckIn: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show In Check In'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	/**
	 * Whether or not this fright handling option is disabled.
	 */
	@observable
	@attribute<FreightHandlingEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Detail'] off begin
		name: 'Freight Detail',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FreightDetailFreightHandling,
		optionEqualFunc: makeJoinEqualsFunc('freightDetailId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'freightHandlingEntity',
			oppositeEntityName: 'freightDetailEntity',
			relationName: 'freightHandling',
			relationOppositeName: 'freightDetail',
			entity: () => Models.FreightHandlingEntity,
			joinEntity: () => Models.FreightDetailFreightHandling,
			oppositeEntity: () => Models.FreightDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Freight Detail'] end
	})
	public freightDetails: Models.FreightDetailFreightHandling[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFreightHandlingEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFreightHandlingEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.label !== undefined) {
				this.label = attributes.label;
			}
			if (attributes.colour !== undefined) {
				this.colour = attributes.colour;
			}
			if (attributes.icon !== undefined) {
				this.icon = attributes.icon;
			}
			if (attributes.iconId !== undefined) {
				this.iconId = attributes.iconId;
			}
			if (attributes.abbreviation !== undefined) {
				this.abbreviation = attributes.abbreviation;
			}
			if (attributes.showInCheckIn !== undefined) {
				this.showInCheckIn = attributes.showInCheckIn;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.freightDetails !== undefined && Array.isArray(attributes.freightDetails)) {
				for (const model of attributes.freightDetails) {
					if (model instanceof Models.FreightDetailFreightHandling) {
						this.freightDetails.push(model);
					} else {
						this.freightDetails.push(new Models.FreightDetailFreightHandling(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		freightDetails {
			${Models.FreightDetailFreightHandling.getAttributes().join('\n')}
			freightDetail {
				${Models.FreightDetailEntity.getAttributes().join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			freightDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'freightDetails',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FreightHandlingEntity.prototype, 'created');
CRUD(modifiedAttr)(FreightHandlingEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
