import * as React from 'react';
import useCheckInStore from 'Hooks/useCheckInStore';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLeavePageConfirmation } from 'Util/_HumanWritten/LeavePageConfirmation';

enum BannerState {
	Offline,
	Online,
	OnlineWithChanges,
	Syncing,
	FinishedSyncing,
	Hidden,
}

const BANNER_TIMEOUT = 2 * 1000;

const getText = (state: BannerState) => {
	switch (state) {
		case BannerState.Offline:
			return 'You are in offline mode';
		case BannerState.Online:
			return 'You are online!';
		case BannerState.OnlineWithChanges:
			return 'You are online. Click here to sync your changes';
		case BannerState.Syncing:
			return 'Syncing... Stay on this page';
		case BannerState.FinishedSyncing:
			return 'Syncing complete';
		default:
			return '';
	}
};

const getClass = (state: BannerState) => {
	switch (state) {
		case BannerState.Offline:
			return 'offline';
		case BannerState.Online:
			return 'online';
		case BannerState.OnlineWithChanges:
			return 'needs-syncing';
		case BannerState.Syncing:
			return 'syncing';
		case BannerState.FinishedSyncing:
			return 'finished-syncing';
		case BannerState.Hidden:
			return 'hidden';
		default:
			return '';
	}
};

export const CheckInOfflineBanner = observer(() => {
	const { getCheckInCache, isOffline, ferryTripId } = useCheckInStore();
	const checkInCache = getCheckInCache();
	const offline = isOffline();
	const [hasOfflineChanges, setHasOfflineChanges] = useState(false);
	const [bannerState, setBannerState] = useState<BannerState>(BannerState.Hidden);

	// Prevent the user from leaving the page if they are offline
	useLeavePageConfirmation(
		isOffline(),
		'You are offline',
		<div>Are you sure you want to leave?</div>,
		{ allowList: ['/check-in'] },
	);

	const checkForOfflineChanges = () => {
		checkInCache.hasCheckInChanges().then(hasChanges => {
			setHasOfflineChanges(hasChanges);
		});
	};

	useEffect(() => {
		if (!offline) {
			checkForOfflineChanges();
		}
	}, [offline]);

	useEffect(() => {
		if (offline) {
			setBannerState(BannerState.Offline);
		} else if (bannerState === BannerState.FinishedSyncing) {
			// Wait a second before hiding the banner
			setTimeout(() => setBannerState(BannerState.Hidden), BANNER_TIMEOUT);
		} else if (bannerState === BannerState.Online) {
			// Wait a second before hiding the banner
			setTimeout(() => setBannerState(BannerState.Hidden), BANNER_TIMEOUT);
		} else if (hasOfflineChanges && !offline && bannerState !== BannerState.Syncing) {
			setBannerState(BannerState.OnlineWithChanges);
		} else if (bannerState === BannerState.Offline && !offline) {
			setBannerState(BannerState.Online);
		}
	}, [bannerState, offline, hasOfflineChanges]);

	const syncChanges = async () => {
		if (bannerState === BannerState.OnlineWithChanges) {
			setBannerState(BannerState.Syncing);
			setTimeout(async () => {
				await checkInCache.syncChangedData(ferryTripId);
				setHasOfflineChanges(false);

				setTimeout(() => {
					setBannerState(BannerState.FinishedSyncing);

					// Update the offline changes status if for some reason the request failed
					checkForOfflineChanges();
				}, 2000);
			}, 0);
		}
	};

	return (
		<div
			className={classNames('check-in__offline-banner', getClass(bannerState))}
			onClick={syncChanges}
			onKeyDown={async e => { if (e.key === 'Enter') { await syncChanges(); } }}
			role="button"
			tabIndex={0}
		>
			<p className="check-in__offline-banner__text">
				{getText(bannerState)}
			</p>
		</div>
	);
});
