import React from 'react';
import classNames from 'classnames';

import { AvailabilityStatus } from 'Modules/MultiStopWizard/Types/AvailabilityStatus';
import { LegOptionContext } from './Contexts/LegOptionContext';

interface LegOptionContainerProps {
	children: React.ReactNode;
	isMobile?: boolean;
}

/**
 * Handles left border color.
 */
export default function LegOptionContainer({ children, isMobile }: LegOptionContainerProps) {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionContainer');
		return null;
	}

	// eslint-disable-next-line object-curly-newline
	const { isSelectable, isSelected, isVisuallyDisabled, availabilityStatus, onClick } = context;

	const className = (() => {
		if (isMobile) {
			return classNames('leg-option--mobile', {
				'leg-option__capacity-indicator--selected': isSelected, // 'selected' variant overrides availability statuses
			});
		}
		return undefined;
	})();

	return (
		<LegOptionContainerInner
			className={className}
			isSelected={isSelected}
			isSelectable={isSelectable}
			isVisuallyDisabled={isVisuallyDisabled}
			availabilityStatus={availabilityStatus}
			onClick={onClick}
		>
			{children}
		</LegOptionContainerInner>
	);
}

interface LegOptionContainerInnerProps {
	children: React.ReactNode;
	className?: string;
	isSelected?: boolean;
	isSelectable?: boolean;
	isVisuallyDisabled?: boolean;
	availabilityStatus?: AvailabilityStatus;
	onClick?: () => void;
}

export function LegOptionContainerInner({
	children,
	className,
	isSelected,
	isSelectable,
	isVisuallyDisabled,
	availabilityStatus,
	onClick,
}: LegOptionContainerInnerProps) {
	return (
		<div
			className={classNames(
				'leg-option leg-option__capacity-indicator',
				{
					'leg-option--selected': isSelected,
					'leg-option--disabled': !isSelectable,
					'leg-option--visually-disabled': !isSelected && isVisuallyDisabled,
					'leg-option__capacity-indicator--selected': isSelected, // 'selected' variant overrides availability statuses
					'leg-option__capacity-indicator--high-availability': availabilityStatus === 'high-availability',
					'leg-option__capacity-indicator--low-availability': availabilityStatus === 'low-availability',
					'leg-option__capacity-indicator--no-availability': availabilityStatus === 'no-availability',
					'leg-option__capacity-indicator--departed': availabilityStatus === 'departed',
					'leg-option__capacity-indicator--closed': availabilityStatus === 'closed',
					'leg-option__capacity-indicator--hidden': availabilityStatus === 'hidden',
				},
				className,
			)}
			onClick={isSelectable ? onClick : undefined}
			// props below is to satisfy eslint due to using onClick in a div element
			onKeyDown={() => { }}
			role="button"
			tabIndex={0}
		>
			{children}
		</div>
	);
}
