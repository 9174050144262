import { useStopById } from 'Global/JourneyElements/Hooks/useStopsById';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';

export const useLocationNamesFromBookingSummary = (summary?: BookingSummary) => {
	if (!summary && process.env.NODE_ENV === 'development') {
		console.warn('useLocationNamesFromBookingSummary hook should not have an undefined summary parameter.');
	}

	const startLocationName = useStopById(summary?.startStopId ?? '')?.location.name;
	const endLocationName = useStopById(summary?.endStopId ?? '')?.location.name;

	return { startLocationName, endLocationName };
};
