export class AppError extends Error {
	data: any;

	constructor(message: string, data: any) {
		super(message);
		this.name = 'AppError';
		this.data = data;
	}
}

export const handleAppError = (error: unknown) => {
	if (error instanceof AppError) {
		console.error(`Error: ${error.message}`);
		console.error(error.data);
	}
};
