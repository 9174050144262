import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import NavigationWrapper from '../Components/Navigation/NavigationWrapper';
import {
	HOME_SEARCH_PAGE_TITLE,
} from '../../ConstantPageNames';
import * as queryString from 'querystring';
// import HomeSearch from '../Components/_HumanWritten/Home/HomeSearch';
import HomeSearch from 'Modules/Home/Components/HomeSearch';
import SecuredPage from '../Components/Security/SecuredPage';
import { PoweredByFerryHawk } from '../Components/_HumanWritten/PoweredByFerryHawk';
import { store } from '../../Models/Store';
import MarketingBanner from '../Components/_HumanWritten/MarketingBanner';

export interface HomeSearchPageProps extends RouteComponentProps {
}

@observer
class HomeSearchPage extends React.Component<HomeSearchPageProps> {
	render() {
		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;

		document.title = HOME_SEARCH_PAGE_TITLE;
		const queryParams = location.search.substring(1);
		const params = queryString.parse(queryParams);
		const type = params.type?.toString();

		return (
			<SecuredPage>
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div className="body-content">
					<HomeSearch type={type} />
					<div className="absolute-position">
						<PoweredByFerryHawk />
						{!store.isManager
							&& (
								<MarketingBanner />
							)}
					</div>
				</div>
			</SecuredPage>
		);
	}
}

export default HomeSearchPage;
