import React, { useEffect } from 'react';
import { UserEntity } from 'Models/Entities';
import CustomerSelection from 'Views/Components/_HumanWritten/CustomerSelection/CustomerSelection';
import { observer, useLocalStore } from 'mobx-react';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	CustomLocationState,
	isRegisterCustomerLocationState,
} from 'Models/_HumanWritten/LocationState';
import {
	BookingWizardData,
	WizardErrors,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { stringNotEmpty } from 'Util/TypeGuards';

export interface CustomerTabProps {
	wizardData: BookingWizardData;
	onUpdateData: (wizardData: BookingWizardData) => void;
	errors: WizardErrors<BookingWizardData>;
	locationState?: CustomLocationState;
	onUpdateErrors: (newErrors: WizardErrors<BookingWizardData>) => void;
}

function CustomerTab({
	wizardData,
	errors,
	locationState,
	onUpdateErrors,
	onUpdateData,
}: CustomerTabProps) {
	const userId = useLocalStore<{ value: string }>(() => {
		// Check if the previous route was from RegisterCustomer component
		if (locationState && isRegisterCustomerLocationState(locationState)	&& locationState.userId) {
			if (wizardData.userId !== locationState.userId) {
				// onUpdateData is unnecessary because wizardData.userId can be updated without re-render
				wizardData.userId = locationState.userId;
			}
		}
		return { value: wizardData.userId };
	});

	// If the staff member is altering an existing booking or has already added bookings to the cart,
	// then they will no longer be able to change the customer details
	// (unless they remove all the bookings from the cart)
	const isDisabled = (wizardData.wizardMode === 'ALTERATION' || getFerryBookingTransactionIdFromStorage() !== null) && stringNotEmpty(wizardData.userId);

	useEffect(() => {
		if (wizardData.userId !== userId.value) {
			// onUpdateData is unncessary because wizardData.userId can be updated without re-render
			wizardData.userId = userId.value;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, userId.value]);

	return (
		<div className="booking-wizard-tab customer-selection">
			<h2 className="booking-wizard__tab-header">{isDisabled
				? 'Customer details'
				: 'Who are you booking for?'}
			</h2>
			<CustomerSelection
				model={userId}
				modelProperty="value"
				backUrl="/booking-wizard/customer"
				disabled={isDisabled}
				combobox={{
					errors: errors.userId,
					inputProps: {
						upward: false,
					},
					onAfterChange: () => {
						const newErrors = { ...errors };
						delete newErrors.userId;
						onUpdateErrors(newErrors);
					},
				}}
				onSelect={(customer: UserEntity) => {
					if (wizardData.wizardMode === 'CREATE') {
						const newData = { ...wizardData };
						newData.cargoIdentification = '';
						newData.cargoTypeId = '';
						newData.cargoMake = '';
						newData.cargoModel = '';
						newData.vehicleLengthId = '';
						newData.vehicleWeightId = '';
						newData.trailerLengthId = '';
						newData.trailerTypeId = 'NO_TRAILER';
						onUpdateData(newData);
					}
				}}
				wizardData={wizardData}
				onUpdateData={onUpdateData}
			/>
		</div>
	);
}

export default observer(CustomerTab);
