import { useEffect } from 'react';
import { devLog } from 'Util/_HumanWritten/ConsoleLogUtils';

import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * De-select return ticket if it is before the departure ticket.
 */
export const useTicketDeselectionForInvalidReturn = (
	wizardData: BookingWizardData,
	saveChanges: (newData: BookingWizardData) => void,
	selectedDepartingDate?: Date,
	selectedReturningDate?: Date,
) => {
	useEffect(() => {
		if (!(selectedDepartingDate && selectedReturningDate)) {
			return;
		}

		// De-select return ticket if it is before the departure ticket
		if (selectedDepartingDate >= selectedReturningDate) {
			const newData = { ...wizardData };
			newData.returningTicketId = '';
			newData.returningJourney = undefined;
			saveChanges(newData);
			devLog('DEV0009: Deselect returning ticket');
		}
	}, [
		selectedDepartingDate,
		selectedReturningDate,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
	]);
};
