import { IBookingFormState } from '../context/BookingFormState';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import passengerTypeStore from 'Models/PassengerTypeStore';
import {
	getMeasurementLabelFromId,
	getMeasurementValueFromId,
} from 'Util/_HumanWritten/MeasurementUtils';
import addOnStore from 'Models/AddOnStore';
import * as React from 'react';
import { toPlural } from '../../CheckInScanner/Utils/toPlural';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';

export function GetModalContentForNewBookingCheckIn(formState: IBookingFormState): JSX.Element[] {
	if (isNullOrUndefined(formState)) {
		return [];
	}
	const message = [];
	// Passengers
	const passengers = passengerTypeStore.getPassengerInfoFromFormState(formState, true);
	if (stringNotEmpty(passengers)) {
		message.push(<p><strong>Passengers:</strong> {passengers}</p>);
	}
	// Vehicle
	const vehicle = formState.cargoTypeId;
	if (isNotNullOrUndefined(vehicle) && formState.vehicleLengthId) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Vehicle:</strong> {`${getMeasurementLabelFromId(formState.vehicleLengthId)} (${getMeasurementValueFromId(formState.vehicleLengthId)})`}</p>);
	}
	// Trailer
	const trailer = stringNotEmpty(formState.trailerTypeId);
	if (trailer && formState.trailerLengthId) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Trailer:</strong> {`${getMeasurementLabelFromId(formState.trailerLengthId)} (${getMeasurementValueFromId(formState.trailerLengthId)})`}</p>);
	}

	// Add ons
	const addOns = formState.departingTripOptions;

	if (isNotNullOrUndefined(addOns) && addOns.length > 0) {
		addOns
			?.filter(x => x.amount > 0)
			?.map(x => {
				const addOn = addOnStore.findAddOn(x.optionId);
				const name = addOn?.name.toLowerCase() ?? '';
				return message.push((
					<p>
						<span><strong>{x.amount}</strong></span>
						{` ${x.amount > 1 ? toPlural(name) : name}`}
					</p>
				));
			});
	}

	return message;
}

export function GetModalContentForCheckIn(booking?: CheckInBookingOverviewDto): JSX.Element[] {
	if (isNullOrUndefined(booking)) {
		return [];
	}
	const message = [];

	// Passengers
	const passengers = passengerTypeStore.getPassengerInfoFromBookingSummary(
		booking.bookedSummary ?? null,
		true,
	);
	if (stringNotEmpty(passengers)) {
		message.push(<p><strong>Passengers:</strong> {passengers}</p>);
	}

	// Vehicle
	const cargo = booking.bookedSummary?.cargoInfo;
	if (isNotNullOrUndefined(cargo)) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Vehicle:</strong> {`${cargo?.selectedLength?.label} (${cargo?.selectedLength?.value})`}</p>);
	}

	// Trailer
	const towOn = booking.bookedSummary?.towOnInfo;
	if (isNotNullOrUndefined(towOn)) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Trailer:</strong> {`${towOn?.selectedLength?.label} (${towOn?.selectedLength?.value})`}</p>);
	}

	// Add ons
	const addOns = booking.bookedSummary?.additionalBookingOptions;

	if (isNotNullOrUndefined(addOns) && addOns.length > 0) {
		addOns?.map(x => {
			const name = x.option.name.toLowerCase();
			return message.push((
				<p>
					<span><strong>{x.quantity}</strong></span>
					{` ${x.quantity > 1 ? toPlural(name) : name}`}
				</p>
			));
		});
	}

	return message;
}
