import dayjs from 'dayjs';
import { action } from 'mobx';
import moment from 'moment';

import { isNullOrUndefined } from '../TypeGuards';
import { FerryTripEntity } from 'Models/Entities';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';

// checks if the two dates are the same
export function isSameDate(date1: Date, date2: Date) {
	const d1 = dayjs(date1);
	const d2 = dayjs(date2);
	return d1.date() === d2.date() && d1.month() === d2.month() && d1.year() === d2.year();
}

/**
 * Returns the new date (if it not a date before today)
 */
export const getNewDate = action((date: Date, increase: boolean, amount: number, isStaff: boolean) : Date => {
	// Non staff can't see before today
	if (!isStaff && !increase && checkLeftDate(date, amount)) {
		return dayjs().startOf('day').toDate();
	}

	if (increase) {
		return moment(date).add(amount, 'days').toDate();
	}

	return moment(date).subtract(amount, 'days').toDate();
});

/**
 * Checks that the left date is a valid date (not before today's date)
 */
export const checkLeftDate = action((date: Date, decrementByNumber: number) => {
	return moment(date).subtract(decrementByNumber, 'days').isBefore(new Date(), 'days');
});

// determines if a column contains any ferry trips
export const checkDateColumnClassName = (dateOfDay: Date, result?: (FerryTripEntity | TicketsTabTrip)[]) => {
	if (isNullOrUndefined(result) || result.length === 0) {
		return false;
	}
	const len = result.filter(filteredTrip => {
		return isSameDate(filteredTrip.startDate, dateOfDay);
	}).length;
	return len > 0;
};
