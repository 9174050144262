import { BookingEntity } from '../../../Models/Entities';

export function getTotalPassengerCountFromBooking(booking: BookingEntity) {
	const adults = booking.bookedSummary?.adultPassengerTickets?.length ?? 0;
	const children = booking.bookedSummary?.childPassengerTickets?.length ?? 0;
	const infants = booking.bookedSummary?.infantPassengerTickets?.length ?? 0;
	const passengerD = booking.bookedSummary?.passengerDTickets?.length ?? 0;
	const passengerE = booking.bookedSummary?.passengerETickets?.length ?? 0;
	const passengerF = booking.bookedSummary?.passengerFTickets?.length ?? 0;
	const passengerG = booking.bookedSummary?.passengerGTickets?.length ?? 0;
	const passengerH = booking.bookedSummary?.passengerHTickets?.length ?? 0;

	return adults + children + infants + passengerD + passengerE + passengerF + passengerG + passengerH;
}
