import * as React from 'react';
import { SingleActionModalContent } from './Base/SingleActionModalContent';
import { store } from 'Models/Store';
import { showModal } from './Base/BaseModalContents';

export function CheckInAlreadyModalContents() {
	const message = React.useMemo(() => {
		return 'This booking has already been checked in.';
	}, []);

	return (
		<SingleActionModalContent
			title="Error"
			body={message}
			onConfirm={() => store.modal.hide()}
			confirmContent="Ok"
		/>
	);
}

export function showCheckInAlreadyModal() {
	showModal({
		key: 'boarding-already-checked-in',
		content: <CheckInAlreadyModalContents />,
		modalProps: {
			shouldCloseOnOverlayClick: false,
		},
	});
}
