import React from 'react';
import { observer } from 'mobx-react';

import { FEATURE_IMAGE_1_URL, VEHICLE_ICON_DARK_URL } from 'Constants';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { RouteEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { TripDetails } from 'Modules/TripDetails/TripDetails';
import { journeyElementsStore } from 'Global/JourneyElements/JourneyElementsStore';
import {
	BookingWizardData,
	getBookingWizardData,
	saveBookingWizardDataToLocalStorage,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { PassengerTypeInputs } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/PassengerDetails/PassengerTypeInputs';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import {
	//
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { useHomeStore } from '../Stores/HomeStore';
import RouteMap from 'Views/Components/RouteMap/RouteMap';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';

export interface HomeSearchProps {
	type: string;
}

/**
 * Feature: Multi-stop - use stop locations
 */
function HomeSearch({ type }: HomeSearchProps) {
	const { startLocationId, endLocationId, setState } = useHomeStore();
	const { locations, isLoading, routes } = journeyElementsStore();

	const [currentRoute, setCurrentRoute] = React.useState<RouteEntity>();

	usePassengerTypes();

	const { vehicleBookingRequiresAdult } = whiteLabelStore.config;

	const [wizardData, setWizardData] = React.useState<BookingWizardData>(() => {
		// Set persisted locations
		const data = getBookingWizardData(null);
		data.fromLocationId = startLocationId;
		data.toLocationId = endLocationId;
		// Note: Locations will be overriden by TripSelector if not valid
		return data;
	});

	const { tabSelected } = wizardData;
	const onVehicleTab = tabSelected === 'vehicle';
	const onPassengerTab = tabSelected === 'passenger';

	const updateDataCallback = (bookingWizardData: BookingWizardData) => {
		saveBookingWizardDataToLocalStorage(bookingWizardData);
		setWizardData(bookingWizardData);
	};

	React.useEffect(() => {
		if (store.loggedIn && !store.isStaff && wizardData.userId === '') {
			const newData = { ...wizardData };
			newData.userId = store.userId ?? '';
			updateDataCallback(newData);
		}
	}, [store.loggedIn, store.isStaff, wizardData.userId]);

	// TODO fix multi-stop maps - temp solution
	React.useEffect(() => {
		// Only return a route if it has matching departure and destination locations
		// OR if the departure location is before the destination location in the order of the stops on the route
		const newRoute = routes
			.filter(r => (r.departureId === startLocationId
				&& r.destinationId === endLocationId)
				|| ((r.stops.find(s => s.locationId === startLocationId)?.order ?? 0) < (r.stops.find(s => s.locationId === endLocationId)?.order ?? 0)));

		// We only want to set the route and show the image/map if there is only one route matching the departure and destination locations
		if (newRoute.length === 1 && isNotNullOrUndefined(newRoute)) {
			setCurrentRoute(newRoute[0]);
		}
	}, [startLocationId, endLocationId]);

	const { isMobile } = useDeviceDetect();

	if (isMobile) {
		store.routerHistory.push('/booking-wizard/search');
		return <></>;
	}

	if (isLoading) {
		return <LottieSpinner />;
	}

	const cargoIcon = <img alt="cargo-icon" className="booking-type__icon vehicle" src={VEHICLE_ICON_DARK_URL} />;
	const passengerIcon = <Icon name="person" classname="booking-type__icon person" />;

	return (
		<>
			<div className="homepage-background">
				<img className="background-img" src={FEATURE_IMAGE_1_URL} alt="bg-img" />
				<div className="colour-overlay" />
			</div>
			<div className="home-search-page__container">
				<div className="main-content__container">
					<div className="search-form__container">
						<div className="search-form__top-container">
							<div className="search-form__header">
								<h5>
									{type === 'cargo' ? cargoIcon : passengerIcon}
									{type === 'cargo'
										? `Taking a ${whiteLabelStore.vehicleLabelLowerCase}`
										: 'Walk on passenger'}
								</h5>
								<Button
									className="change-booking-type-btn"
									onClick={() => {
										store.routerHistory.push('/home');
									}}
									colors={Colors.Secondary}
									display={Display.Text}
								>
									Change booking type
								</Button>
							</div>
							<TripDetails
								model={{
									tripTypeTabSelected: wizardData.tripType,
									fromLocation: wizardData.fromLocationId,
									toLocation: wizardData.toLocationId,
									startDate:
										wizardData.departureTrip === false
											? wizardData.associatedTripDateTime ?? wizardData.startDate
											: wizardData.startDate,
									endDate:
										wizardData.departureTrip === true
											? wizardData.associatedTripDateTime ?? wizardData.endDate
											: wizardData.endDate,
									route: new RouteEntity(),
									wizardData: wizardData,
								}}
								departureTrip={wizardData.departureTrip}
								typeSelected={wizardData.tripType}
								onTabSelected={newTripType => {
									const newData: BookingWizardData = { ...wizardData };
									newData.tripType = newTripType;
									if (newData.tripType === 'one way') {
										newData.returningTicketId = '';
										newData.returningJourney = undefined;
									}
									updateDataCallback(newData);
								}}
								onDatesChanged={(date, departureDate, updateOtherDate) => {
									if (date !== undefined) {
										const newData: BookingWizardData = { ...wizardData };
										if (departureDate || updateOtherDate) {
											newData.startDate = date;
											newData.ticketSelectionStartDate = date;
										}
										if (!departureDate || updateOtherDate) {
											newData.endDate = date;
											newData.ticketSelectionEndDate = date;
										}
										updateDataCallback(newData);
									}
								}}
								onUpdateRouteDetails={(fromLocation, toLocation) => {
									setState({ startLocationId: fromLocation, endLocationId: toLocation });

									const newData: BookingWizardData = { ...wizardData };
									newData.fromLocationId = fromLocation;
									newData.toLocationId = toLocation;

									// Reset because additional booking options may not be
									// available in the new route/ferry
									newData.departingTripOptions = [];
									newData.returningTripOptions = [];

									updateDataCallback(newData);
								}}
							/>
							<div className="dashed-border" />
						</div>
						<div className="search-form__bottom-container">
							<If condition={onPassengerTab || (vehicleBookingRequiresAdult && onVehicleTab)}>
								<div className="search-form-passengers__container">
									<PassengerTypeInputs wizardData={wizardData} onUpdateFilters={updateDataCallback} />
								</div>
							</If>
							<Button
								className="search-trips__btn"
								onClick={() => {
									store.routerHistory.push('/booking-wizard/tickets');
								}}
								display={Display.Solid}
								sizes={Sizes.Large}
								colors={Colors.Secondary}
							>
								Search trips
							</Button>
						</div>
					</div>
					{/* TODO fix multi-stop maps - temp solution */}
					<If condition={isNotNullOrUndefined(currentRoute)
						&& isNotNullOrUndefined(currentRoute.mapCanvasCoordinates)}
					>
						<RouteMap
							currentRoute={currentRoute?.id}
							locations={locations}
							routes={routes}
							isMobile={isMobile}
						/>
					</If>
					<If condition={isNotNullOrUndefined(currentRoute)
						&& isNotNullOrUndefined(currentRoute.mapId)
						&& isNullOrUndefined(currentRoute.mapCanvasCoordinates)}
					>
						<div
							className="map__container"
							style={{ display: isLoading ? 'none' : undefined }}
						>
							<img
								className="event-detailed-view__supporting-image"
								src={`/api/files/${currentRoute?.mapId}`}
								key={currentRoute?.mapId}
								alt=""
							/>
						</div>
					</If>
				</div>
			</div>
		</>
	);
}

export default observer(HomeSearch);
