import axios from 'axios';
import { History } from 'history';
import { BookingCutoffEntity, BookingEntity } from 'Models/Entities';
import { bookingType, feeType, locationType } from 'Models/Enums';
import { BOOKING_ENTITY_URL, FERRY_BOOKING_URL, SERVER_URL } from 'Constants';
import { cutoffType } from 'Models/Enums';
import { buildUrl } from 'Util/FetchUtils';
import useAsync from 'Hooks/useAsync';
import { dispatchResetReservationEvent } from 'Events/HumanWritten/ResetReservationEvent';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { stringNotEmpty } from 'Util/TypeGuards';
import {
	BaseBookingCreationData,
	BookingWizardData, PassengersInfo,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	serialisePassengerDOB,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Passengers/PassengerTabHelpers';

export interface BookingCreationAdditionalOption {
	optionId: string,
	amount: number,
}

export interface AlterationExpirationDto {
	bookingIds: string[],
	systemExpired: boolean
}

export interface BookingCancellationDto {
	bookingId: string,
	applyToReturn?: boolean,
	amountToRefund?: number,
	recommendedRefundAmount?: number,
}

export interface BookingCancellationPopUpDto {
	bookingId: string,
	maximumRefund: number,
	recommendedRefundAmount: number,
	bookingCutoff?: BookingCutoffEntity,
}

export interface FerryBookingCreationDto extends BaseBookingCreationData {
	existingTransactionId?: string,
	bypassSpaceCheck: boolean,
	/**
	 * When true:
	 * - transaction of booking will be set to EFTPOS
	 * - no reductions can be done, it will default to 0, hence no refunds will be produced
	 */
	forEftpos?: boolean;
	location: locationType;
	useBookingForPriceCalculation?: boolean;
}

export interface BookingCutoffAndBookingDto {
	booking: BookingEntity,
	cutoffType: cutoffType,
}

export interface CheckInDto {
	bookingId: string,
	checkedIn: boolean,
	ferryTripId: string,
}

/**
 * Takes data and turns it into an instance of the booking creation dto. This function should take a BookingWizardData,
 * but as this function needs to be called from within a generic component, we can't guarantee that the type will be
 * correct, so we had to accept anything and then provide default values if the fields we need aren't present
 */
export function dataToFerryTripBookingDto(
	data: BookingWizardData,
	bypassSpaceCheck: boolean,
	useBookingForPriceCalculation: boolean = false,
): FerryBookingCreationDto {
	const vehicleBooking: boolean = data.tabSelected === 'vehicle';
	const oneWay: boolean = data.tripType === 'one way';

	return {
		// Feature: Multi-stop - save booking
		departingJourney: data.departingJourney,
		returningJourney: data.returningJourney,

		wizardMode: data.wizardMode.toString(),
		tabSelected: data.tabSelected,
		userId: stringNotEmpty(data.userId) ? data.userId : null,
		bookingToEdit: ((data.bookingToEdit !== '' && data.bookingToEdit !== undefined)
			? data.bookingToEdit
			: null),
		// The server is expecting to see a trip id here, even though it is not used during bulk booking. We don't allow
		// progression to the booking wizard for bulk booking, and if the last one is removed we quit out, so we can
		// assume that there is at least one id here
		// eslint-disable-next-line no-nested-ternary
		departureTicketId: data.bulkBookingTripIds !== undefined
			? data.bulkBookingTripIds[0]
			: (stringNotEmpty(data.departureTicketId) ? data.departureTicketId : null),
		departingTripOptions: data.departingTripOptions ?? null,
		// This will only be set for return trips, so we need to handle the situation where the field isn't set
		// eslint-disable-next-line no-nested-ternary
		returningTicketId: oneWay
			? null
			: (stringNotEmpty(data.returningTicketId) ? data.returningTicketId : null),
		returningTripOptions: oneWay
			? null
			: data.returningTripOptions ?? null,
		bulkBookingTripIds: data.bulkBookingTripIds ?? null,

		note: data.note ?? null,

		// Vehicle details
		driverFirstName: vehicleBooking
			? data.driverFirstName
			: null,
		driverLastName: vehicleBooking
			? data.driverLastName
			: null,
		driverPhone: vehicleBooking
			? data.driverPhone
			: null,
		cargoIdentification: vehicleBooking
			? data.cargoIdentification
			: null,
		hiredVehicle: vehicleBooking
			? data.hiredVehicle
			: null,
		cargoTypeId: vehicleBooking && stringNotEmpty(data.cargoTypeId)
			? data.cargoTypeId
			: null,
		vehicleLengthId: vehicleBooking && stringNotEmpty(data.vehicleLengthId)
			? data.vehicleLengthId
			: null,
		vehicleWeightId: vehicleBooking && stringNotEmpty(data.vehicleWeightId)
			? data.vehicleWeightId
			: null,

		// Trailer details
		// NO_TRAILER is not a data type recognised in the server, so we need to make sure that value can't be passed up
		trailerTypeId: data.trailerTypeId !== 'NO_TRAILER' && stringNotEmpty(data.trailerTypeId)
			? data.trailerTypeId
			: null,
		trailerLengthId: data.trailerTypeId !== 'NO_TRAILER' && stringNotEmpty(data.trailerLengthId)
			? data.trailerLengthId
			: null,

		// Passengers
		adultTickets: serialisePassengerDOB(data.adultTickets),
		childTickets: serialisePassengerDOB(data.childTickets),
		infantTickets: serialisePassengerDOB(data.infantTickets),
		passengerDTickets: serialisePassengerDOB(data.passengerDTickets),
		passengerETickets: serialisePassengerDOB(data.passengerETickets),
		passengerFTickets: serialisePassengerDOB(data.passengerFTickets),
		passengerGTickets: serialisePassengerDOB(data.passengerGTickets),
		passengerHTickets: serialisePassengerDOB(data.passengerHTickets),

		// Required by the server
		location: 'BOOKING_WIZARD',
		existingTransactionId: getFerryBookingTransactionIdFromStorage() ?? null,
		bypassSpaceCheck: bypassSpaceCheck,
		useBookingForPriceCalculation: useBookingForPriceCalculation,
	} as FerryBookingCreationDto;
}

export async function createBulkBooking(bookingInformation: FerryBookingCreationDto) {
	return axios.post(
		`${FERRY_BOOKING_URL}/new-bulk-booking`,
		bookingInformation,
		{
			validateStatus: () => true,
		},
	);
}

export async function editCartBulkBooking(bookingInformation: FerryBookingCreationDto) {
	return axios.post(
		`${FERRY_BOOKING_URL}/edit-cart-bulk-booking`,
		bookingInformation,
		{
			validateStatus: () => true,
		},
	);
}

// Create a new booking end-point
export async function createFerryBooking(bookingInformation: FerryBookingCreationDto) {
	return axios.post(
		`${FERRY_BOOKING_URL}/new-booking`,
		bookingInformation,
		{
			validateStatus: () => true,
		},
	);
}

// Alter a booking end-point
export async function alterBooking(bookingInformation: FerryBookingCreationDto) {
	return axios.post(
		`${FERRY_BOOKING_URL}/alter-booking`,
		bookingInformation,
		{
			validateStatus: () => true,
		},
	);
}

// Cancel a booking end-point
export async function cancelBooking(bookingInfo: BookingCancellationDto) {
	return axios.post(
		`${FERRY_BOOKING_URL}/cancel-booking`,
		bookingInfo,
		{
			validateStatus: () => true,
		},
	);
}

export async function getBookingCancellationRules(bookingId: string, cancelReturn: boolean) {
	const results = await axios.get(
		buildUrl(`${FERRY_BOOKING_URL}/cancellation-rules`, {
			bookingId,
			cancelReturn: cancelReturn.toString(),
		}),
	);
	return results.data as BookingCancellationPopUpDto;
}

/**
 * Update a booking's alterations from RESERVED to EXPIRED RESERVATION.
 */
export async function removeBookingFromCart(bookingId: string) {
	return axios.post(
		`${FERRY_BOOKING_URL}/expired-reservation/${bookingId}`,
	);
}

export async function resetReservationTimer(transactionId: string) {
	await axios.post(
		`${FERRY_BOOKING_URL}/extend-reservation?transactionId=${transactionId}`,
		{
			validateStatus: () => true,
		},
	);

	dispatchResetReservationEvent();
}

/**
 * Returns true if user can navigate straight to the cart. Otherwise, return false.
 *
 * At the moment this is only valid when a user has an existing reserved booking in local storage.
 */
export function canNavigateToCartStep(history: History): boolean {
	const { pathname } = history.location;
	const existingTransaction = getFerryBookingTransactionIdFromStorage();

	return existingTransaction !== null && (
		pathname === '/booking-wizard/cart' || pathname === '/booking-wizard/t-and-cs'
	);
}

/**
 * Returns true when user can navigate to the payment step. Otherwise, return false.
 *
 * This will allow a user to have an incomplete booking in the wizard and navigate to the payment step if there is at
 * least one booking in the cart.
 */
export function canNavigateToPaymentStep(history: History): boolean {
	const { pathname } = history.location;
	const existingTransaction = getFerryBookingTransactionIdFromStorage();

	return existingTransaction !== null && pathname === '/booking-wizard/payment';
}

/**
 * Returns true when user can navigate to the post payment step. Otherwise, return false.
 *
 * This will allow a user to have an incomplete booking in the wizard and navigate to the post payment step if there is
 * at least one booking in the cart.
 */
export function canNavigateToPostPaymentStep(history: History): boolean {
	const { pathname } = history.location;
	const existingTransaction = getFerryBookingTransactionIdFromStorage();

	return existingTransaction !== null && pathname === '/booking-wizard/post-payment';
}

/**
 * Returns a booking cut-off entity that is used to determine the fees incurred when editing or cancelling a booking.
 */
export async function getBookingCutOff(booking: BookingEntity, cutOffType: cutoffType, bookingTypeType: bookingType) {
	const newBookingCutoffDto = {
		booking: booking,
		cutoffType: cutOffType,
		bookingType: bookingTypeType,
	} as BookingCutoffAndBookingDto;
	return axios.post(
		`${SERVER_URL}/api/entity/BookingCutoffEntity/get-booking-cutoff`,
		newBookingCutoffDto,
		{
			validateStatus: () => true,
		},
	);
}

/**
 * Returns the number of alterations that have been discarded as the user cancels an alteration in the front end.
 * @param alterationExpiryInfo
 */
export async function discardAlterations(alterationExpiryInfo: AlterationExpirationDto) {
	try {
		const result = await axios.post(
			`${SERVER_URL}/api/entity/AlterationEntity/cancel-alteration`,
			alterationExpiryInfo,
		);
	} catch (e) {
		console.log('Server error', e);
	}
	return -1;
}

// Remove a towOn removal fee from alteration
export async function removeFee(transactionId: string, feeToRemove: feeType) {
	return axios.post(
		`${FERRY_BOOKING_URL}/remove-fee/${transactionId}?feeType=${feeToRemove}`,
	).then(({ data }) => data);
}

// Adds a fee to a transaction
export async function addFeeToTransaction(transactionId: string, feeOptionId: string) {
	return axios.post(
		`${FERRY_BOOKING_URL}/add-fee-to-transaction/${transactionId}?feeOptionId=${feeOptionId}`,
	).then(({ data }) => data);
}

export async function removeFeeFromTransaction(transactionId: string, feeOptionId: string) {
	return axios.post(
		`${FERRY_BOOKING_URL}/remove-fee-from-transaction/${transactionId}?feeId=${feeOptionId}`,
	).then(({ data }) => data);
}

export async function FetchBookingForBookingDetailedView(
	bookingId: string,
	userId: string | null,
): Promise<BookingEntity> {
	const results = await axios.get(
		buildUrl(`${BOOKING_ENTITY_URL}/fetch-booked-booking-by-id`, {
			bookingId,
			userId: userId ?? '',
		}),
	);
	return new BookingEntity(results.data[0]);
}

export async function FetchFullBookingById(
	bookingId: string,
	userId: string | null,
	fetchReturnBooking: boolean = false,
): Promise<BookingEntity> {
	const results = await axios.get(
		buildUrl(`${FERRY_BOOKING_URL}/fetch-full-booking-by-id`, {
			bookingId,
			userId: userId ?? '',
			fetchReturnBooking: fetchReturnBooking.toString(),
		}),
	);
	return new BookingEntity(results.data[0]);
}

/**
 * Fetches bookings to be displayed on the cart
 *
 * @param transactionId - The transaction id to fetch the bookings for
 * @param userId - The user who the bookings are for
 */
export async function FetchBookingsForCart(
	transactionId: string | null,
	userId?: string | null,
): Promise<BookingEntity[]> {
	if (transactionId === null || transactionId === '') {
		return [];
	}
	try {
		const result = await axios.get(
			buildUrl(`${FERRY_BOOKING_URL}/fetch-bookings-for-cart`, {
				transactionId,
				userId: userId ?? '',
			}),
		);
		return result.data.map((x: Partial<BookingEntity> | undefined) => new BookingEntity(x));
	} catch (e) {
		console.error(e);
		return [];
	}
}

export async function CheckBookingCanBeModified(bookingId: string) {
	try {
		const results = await axios.get(
			buildUrl(`${FERRY_BOOKING_URL}/check-booking`, { bookingId }),
		);
		return '';
	} catch (e: any) {
		return e?.response?.data ?? 'error';
	}
}

export function FetchBookingsForCartAsync(
	transactionId: string | null,
	userId?: string | null,
) {
	return useAsync(() => FetchBookingsForCart(
		transactionId,
		userId,
	), []);
}
