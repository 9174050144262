import React from 'react';
import { store } from 'Models/Store';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { BookingWizardData, getOldFerryBookingWizardData } from './BookingWizardData';
import handleNavigation from './HandleNavigation';

const validPaths = [
	'/booking-wizard/vehicle',
	'/booking-wizard/passengers',
	'/booking-wizard/tickets',
	'/home',
	'/booking-wizard/t-and-cs',
	'/booking-wizard/payment',
	'/booking-wizard/cart',
	'/booking-wizard/reservation',
	'/booking-wizard/search',
	'/booking-wizard/post-payment',
	'/booking-success',
	'/booking-wizard/customer',
	'/booking-wizard/add-ons',
];

const pathsBeforePayment = [
	'/booking-wizard/vehicle',
	'/booking-wizard/passengers',
	'/booking-wizard/add-ons',
	'/booking-wizard/tickets',
	'/home',
	'/booking-wizard/t-and-cs',
	'/booking-wizard/customer',
	'/booking-wizard/cart',
	'/booking-wizard/reservation',
	'/booking-wizard/search',
	'/booking-success',
];

export interface useBookingWizardWatchProps {
	bookingWizardData: BookingWizardData;
	paymentDetailsEntered: boolean;
}

export function useBookingWizardWatch({ bookingWizardData, paymentDetailsEntered }: useBookingWizardWatchProps) {
	const bookingForAlteration = bookingWizardData.wizardMode === 'ALTERATION';

	const goToAwaitingPayment = (params: {
		previousLocationPath: string;
		nextLocationPath: string;
	}) => {
		const { previousLocationPath, nextLocationPath } = params;

		if (!(
			paymentDetailsEntered
			&& previousLocationPath.includes('/booking-wizard/payment')
			&& pathsBeforePayment.find(x => nextLocationPath.includes(x))
		)) {
			return false;
		}

		const transactionId = getFerryBookingTransactionIdFromStorage();
		if (isNullOrUndefined(transactionId)) {
			return false;
		}

		return true;
	};

	const handleTabClose = (event: BeforeUnloadEvent) => {
		event.preventDefault();
		event.returnValue = '';
		return ('Are you sure you want to leave?');
	};

	const handleRouterHistory = () => {
		if (window.location.href.includes('/booking-wizard/post-payment')) {
			return;
		}

		const previousLocationPath = store.routerHistory.location.pathname;
		const unblock = store.routerHistory.block(nextLocation => {
			const { pathname } = nextLocation;

			// Typically triggered when user clicks 'Pay now' but accidentally refresh
			if (goToAwaitingPayment({ previousLocationPath, nextLocationPath: pathname })) {
				unblock();
				store.routerHistory.replace('/awaiting-payment');
				return false;
			}

			// Allow users to navigate to a valid pathname
			// OR
			// If they are in alteration mode, allow them to navigate to a bookings/ID
			const oldData = getOldFerryBookingWizardData();
			if (
				validPaths.find(x => pathname.includes(x))
				|| (
					pathname.includes('/bookings/')
					&& bookingWizardData.wizardMode === 'ALTERATION'
					&& isNullOrUndefined(oldData)
				)
			) {
				//
				// We want to allow the user to navigate to the new pathname. Calling unblock will
				// unsubscribe listening to routerHistory.
				//
				unblock();
				//
				// Important to use history.replace so that when the user clicks back on the browser, they will
				// be navigated to previous visible tab.
				//
				if (previousLocationPath.includes('/booking-wizard/reservation')) {
					store.routerHistory.replace(nextLocation);
					return;
				}
				store.routerHistory.push(nextLocation);
			} else {
				handleNavigation(bookingWizardData.bookingToEdit ?? '').then(result => {
					if (result) {
						unblock();
						store.routerHistory.push(nextLocation);
					}
				});
			}

			return false;
		});

		store.routerHistory.listen(unblock);
	};

	React.useEffect(() => {
		if (
			!bookingForAlteration
			&& !(paymentDetailsEntered && window.location.href.includes('/booking-wizard/payment'))) {
			// Only pass this guard if
			// - wizard is in alteration mode
			// - or doing payment
			return undefined;
		}

		if (!window.location.href.includes('/booking-wizard/payment')) {
			// We don't have a beforeunload during payment tab because submitting the payment form will have to leave
			// the page, which we don't want to prevent
			window.addEventListener('beforeunload', handleTabClose);
		}

		handleRouterHistory();

		return () => {
			window.removeEventListener('beforeunload', handleTabClose);
		};
	}, [store.routerHistory.location, paymentDetailsEntered]);
}
