/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminFreightTypeEntity from 'Models/Security/Acl/AdminFreightTypeEntity';
import ManagerFreightTypeEntity from 'Models/Security/Acl/ManagerFreightTypeEntity';
import StaffFreightTypeEntity from 'Models/Security/Acl/StaffFreightTypeEntity';
import UserFreightTypeEntity from 'Models/Security/Acl/UserFreightTypeEntity';
import FixedPriceUserFreightTypeEntity from 'Models/Security/Acl/FixedPriceUserFreightTypeEntity';
import InvoicedUserFreightTypeEntity from 'Models/Security/Acl/InvoicedUserFreightTypeEntity';
import VisitorsFreightTypeEntity from 'Models/Security/Acl/VisitorsFreightTypeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFreightTypeEntityAttributes extends IModelAttributes {
	label: string;
	minimumCubic: number;
	minimumWeight: number;
	standardWidth: number;
	standardHeight: number;
	standardLength: number;
	disabled: boolean;

	freightDetails: Array<
		| Models.FreightDetailEntity
		| Models.IFreightDetailEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FreightTypeEntity', 'Freight Type')
// % protected region % [Customise your entity metadata here] end
export default class FreightTypeEntity extends Model
	implements IFreightTypeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminFreightTypeEntity(),
		new ManagerFreightTypeEntity(),
		new StaffFreightTypeEntity(),
		new UserFreightTypeEntity(),
		new FixedPriceUserFreightTypeEntity(),
		new InvoicedUserFreightTypeEntity(),
		new VisitorsFreightTypeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Label'] off begin
	/**
	 * The display label for the freight type (e.g. pallet, container).
	 */
	@Validators.Required()
	@observable
	@attribute<FreightTypeEntity, string>()
	@CRUD({
		name: 'Label',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public label: string;
	// % protected region % [Modify props to the crud options here for attribute 'Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Minimum Cubic'] off begin
	/**
	 * The minimum cubic for the freight type.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightTypeEntity, number>()
	@CRUD({
		name: 'Minimum Cubic',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minimumCubic: number;
	// % protected region % [Modify props to the crud options here for attribute 'Minimum Cubic'] end

	// % protected region % [Modify props to the crud options here for attribute 'Minimum Weight'] off begin
	/**
	 * The minimum weight for the freight type.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightTypeEntity, number>()
	@CRUD({
		name: 'Minimum Weight',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minimumWeight: number;
	// % protected region % [Modify props to the crud options here for attribute 'Minimum Weight'] end

	// % protected region % [Modify props to the crud options here for attribute 'Standard Width'] off begin
	/**
	 * The standard width of the freight type.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightTypeEntity, number>()
	@CRUD({
		name: 'Standard Width',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public standardWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Standard Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Standard Height'] off begin
	/**
	 * The standard height of the freight type.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightTypeEntity, number>()
	@CRUD({
		name: 'Standard Height',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public standardHeight: number;
	// % protected region % [Modify props to the crud options here for attribute 'Standard Height'] end

	// % protected region % [Modify props to the crud options here for attribute 'Standard Length'] off begin
	/**
	 * The standard length of the freight type.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FreightTypeEntity, number>()
	@CRUD({
		name: 'Standard Length',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public standardLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Standard Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	/**
	 * Whether or not this freight type is disabled.
	 */
	@observable
	@attribute<FreightTypeEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Freight Detail'] off begin
		name: 'Freight Details',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.FreightDetailEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'freightDetails',
			oppositeEntity: () => Models.FreightDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Freight Detail'] end
	})
	public freightDetails: Models.FreightDetailEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFreightTypeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFreightTypeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.label !== undefined) {
				this.label = attributes.label;
			}
			if (attributes.minimumCubic !== undefined) {
				this.minimumCubic = attributes.minimumCubic;
			}
			if (attributes.minimumWeight !== undefined) {
				this.minimumWeight = attributes.minimumWeight;
			}
			if (attributes.standardWidth !== undefined) {
				this.standardWidth = attributes.standardWidth;
			}
			if (attributes.standardHeight !== undefined) {
				this.standardHeight = attributes.standardHeight;
			}
			if (attributes.standardLength !== undefined) {
				this.standardLength = attributes.standardLength;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.freightDetails !== undefined && Array.isArray(attributes.freightDetails)) {
				for (const model of attributes.freightDetails) {
					if (model instanceof Models.FreightDetailEntity) {
						this.freightDetails.push(model);
					} else {
						this.freightDetails.push(new Models.FreightDetailEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		freightDetails {
			${Models.FreightDetailEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			freightDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'freightDetails',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FreightTypeEntity.prototype, 'created');
CRUD(modifiedAttr)(FreightTypeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
