import React from 'react';

import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { LegOptionContext } from './Contexts/LegOptionContext';

export default function LegOptionCargoCapacity() {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionCargoCapacity');
		return null;
	}

	const { wizardData } = context;

	if (wizardData.tabSelected !== 'vehicle') {
		return null;
	}

	const { cargoSpaces } = context;
	return <LegOptionCargoCapacityInner space={cargoSpaces} />;
}

interface LegOptionCargoCapacityInnerProps {
	space?: number;
}

function LegOptionCargoCapacityInner({ space }: LegOptionCargoCapacityInnerProps) {
	return (
		<div className="leg-option__space-tag">
			<div className={whiteLabelStore.vehicleIconDarkClassName} />
			<div className="leg-option__space-text">{space}</div>
		</div>
	);
}
