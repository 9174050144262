import dayjs from 'dayjs';

import useStore from 'Hooks/useStore';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { useIsLegVisuallyDisabled } from './useIsLegVisuallyDisabled';

export const useIsLegSelectable = (
	trip: TicketsTabTrip,
	wizardData: BookingWizardData,
	legType: 'departure' | 'return',
) => {
	const store = useStore();
	const isDisabled = useIsLegVisuallyDisabled(trip, wizardData);

	// No one can select a departure trip that is after the return trip
	// No one can select a return trip that is before the departure trip

	if (legType === 'departure') {
		if (
			wizardData.returningJourney?.tripId === trip.id
			&& wizardData.returningJourney?.startStopId === trip.startStopId
			&& wizardData.returningJourney?.endStopId === trip.endStopId
		) {
			return false;
		}
		if (
			wizardData.returningJourney?.departureDateTime
			&& trip.departureDateTime >= wizardData.returningJourney.departureDateTime
			&& trip.id !== wizardData.returningJourney.tripId
		) {
			return false;
		}
	}

	if (legType === 'return') {
		if (
			wizardData.departureTrip !== false // altering only return leg of two way booking uses departing journey
			&& wizardData.departingJourney?.tripId === trip.id
			&& wizardData.departingJourney?.startStopId === trip.startStopId
			&& wizardData.departingJourney?.endStopId === trip.endStopId
		) {
			return false;
		}
		if (
			wizardData.departingJourney?.departureDateTime
			&& trip.departureDateTime <= wizardData.departingJourney.departureDateTime
			&& trip.id !== wizardData.departingJourney.tripId
		) {
			return false;
		}
	}

	if (store.isManager) return true;

	if (store.isStaff) {
		// Staff can only overbook if trip is in the past
		const isBeforeNow = dayjs(trip.departureDateTime).isBefore(dayjs());
		if (isBeforeNow) {
			return true;
		}
	}

	return !isDisabled;

	// if (wizardData.departureTicketId === ticketsTabTrip.id || wizardData.returningTicketId === ticketsTabTrip.id) {
	// 	return true;
	// }
	// if (isInvalidAlterationTicket) {
	// 	return false;
	// }
	// // Even though staff and managers have their trips that they are allowed to select, we need to ensure that they
	// // don't select a returning trip that leaves before the departing trip, as we don't want the wizard to allow
	// // this for anyone
	// if (!departure && wizardData.departureTicketId !== ''
	// 	&& ticketsTabTrip.startDate <= (departureTripTime ?? wizardData.ticketSelectionStartDate)) {
	// 	setIsValid(false);
	// 	return false;
	// }
	// // Staff can select any departed trip regardless of space capacity or departed status
	// if ((isStaff && disabledFerryTrip) || store.isManager) {
	// 	return true;
	// }
	// if (disabledFerryTrip && !isStaff) {
	// 	return false;
	// }
	// if (availabilityCss === 'no-availability' && !store.isManager) {
	// 	return false;
	// }
	// if (wizardData.tabSelected === 'vehicle' && (vehicleLengthExceeded || exceedsPassengerAvailable)) {
	// 	return false;
	// }
	// if (wizardData.tabSelected === 'passenger' && exceedsPassengerAvailable) {
	// 	return false;
	// }
	// return !isInvalidAlterationTicket;
};
