import dayjs from 'dayjs';
import { useEffect } from 'react';

import useStore from 'Hooks/useStore';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { calculateTotalPassengers } from 'Util/_HumanWritten/CapacityCalculationUtils';
import { devLog } from 'Util/_HumanWritten/ConsoleLogUtils';
import {
	BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export const useTicketDeselectionWhenPaxExceeds = (
	wizardData: BookingWizardData,
	saveChanges: (newData: BookingWizardData) => void,
	selectedDepartingTrip?: TicketsTabTrip,
	selectedReturningTrip?: TicketsTabTrip,
) => {
	const { isStaff, isManager } = useStore();
	const oldWizardData = getOldFerryBookingWizardData();

	const isValid = (passengerCount: number, capacity: number, departureDateTime: Date) => {
		if (isManager) {
			return true; // manager+ has the power! no validation required.
		}

		if (passengerCount <= capacity) {
			return true; // pax can fit
		}

		// At this point, no space for passengers

		if (!isStaff) {
			return false; // visitor/customer cannot overbook
		}

		// Staff can only overbook if trip is in the past
		const isBeforeNow = dayjs(departureDateTime).isBefore(dayjs());
		return isBeforeNow;
	};

	useEffect(() => {
		const newData = { ...wizardData };
		const paxCount = calculateTotalPassengers(newData);

		let changeDetected = false;
		// TODO : Having multiple hooks that uses saveChanges might lead to tricky debugging in the future,
		//        because other hooks can override the saveChanges state to be updated
		//
		// i.e. firstHook calls saveChanges, and secondHook calls saveChanges, the secondHook will take priority
		// since it is later in the hook cycle process... especially since it doesn't use the current state in
		// callback i.e setState(currentState => { /* update based on currentState */ })

		if (selectedDepartingTrip) {
			if (newData.departureTicketId !== '') {
				let paxSpaceLeft = selectedDepartingTrip.passengerSpacesAvailable;
				if (oldWizardData && newData.departureTicketId === oldWizardData.departureTicketId) {
					// Why add?
					paxSpaceLeft += calculateTotalPassengers(oldWizardData);
				}

				if (!isValid(paxCount, paxSpaceLeft, selectedDepartingTrip.departureDateTime)) {
					changeDetected = true;
					newData.departureTicketId = '';
					newData.departingJourney = undefined;
					devLog('DEV0010: Deselect departing ticket');
				}
			}
		}

		if (selectedReturningTrip) {
			if (newData.returningTicketId !== '') {
				let paxSpaceLeft = selectedReturningTrip.passengerSpacesAvailable;
				if (oldWizardData && newData.returningTicketId === oldWizardData.returningTicketId) {
					paxSpaceLeft += calculateTotalPassengers(oldWizardData);
				}

				if (!isValid(paxCount, paxSpaceLeft, selectedReturningTrip.departureDateTime)) {
					console.error('selected returningTicketId is invalid');
					changeDetected = true;
					newData.returningTicketId = '';
					newData.returningJourney = undefined;
					devLog('DEV0011: Deselect returning ticket');
				}
			}
		}

		// Saving the changes to the wizard data so that the cart is in sync.
		if (changeDetected) {
			saveChanges(newData);
		}
	}, [
		selectedDepartingTrip,
		selectedReturningTrip,
		wizardData.fromLocationId,
		wizardData.toLocationId,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
		wizardData.adultTickets?.length,
		wizardData.childTickets?.length,
		wizardData.infantTickets?.length,
		wizardData.passengerDTickets?.length,
		wizardData.passengerETickets?.length,
		wizardData.passengerFTickets?.length,
		wizardData.passengerGTickets?.length,
		wizardData.passengerHTickets?.length,
	]);
};
