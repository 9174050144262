import React from 'react';

import { IRouteEntityAttributes, LocationEntity, RouteEntity } from 'Models/Entities';
import { wizardModeOptions } from 'Models/Enums';
import { isNullOrUndefined } from 'Util/TypeGuards';
import TripSelector from 'Views/Components/_HumanWritten/TripSelector/TripSelector';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { TripTypeButtons } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/TripDetails/TripTypeButtons';
import { DateRangePicker } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/TripDetails/DateRangePicker';

export interface TripDetailsModel {
	tripTypeTabSelected: 'one way' | 'return';
	endDate: Date;
	fromLocation: string;
	toLocation: string;
	startDate: Date;
	route: RouteEntity | IRouteEntityAttributes;
	wizardData: BookingWizardData;
}

export interface TripDetailsProps {
	model: TripDetailsModel;
	onTabSelected: (direction: 'one way' | 'return') => void;
	typeSelected: string;
	onDatesChanged: (startDate: Date, departureDate: boolean, updateOtherDate: boolean) => void;
	onUpdateRouteDetails: (fromLocation: string, toLocation: string, route: RouteEntity) => void;
	departureTrip: boolean | undefined;
	/**
	 * @deprecated
	 */
	locations?: LocationEntity[];
}

/**
 * Caution: uses multi-stop components.
 */
export function TripDetails({
	model,
	onTabSelected,
	typeSelected,
	onDatesChanged,
	onUpdateRouteDetails,
	departureTrip,
}: TripDetailsProps) {
	const changeBothDates = isNullOrUndefined(departureTrip);

	return (
		<div className="trip-details__container">
			<TripTypeButtons typeSelected={typeSelected} onTabSelected={onTabSelected} model={model} />
			<TripSelector
				startLocationId={model.fromLocation}
				endLocationId={model.toLocation}
				setLocations={({ startLocationId, endLocationId }) => {
					onUpdateRouteDetails(startLocationId, endLocationId, new RouteEntity());
				}}
				disabled={model.wizardData.wizardMode === wizardModeOptions.ALTERATION}
			/>
			<DateRangePicker
				model={model}
				onDatesChanged={onDatesChanged}
				changeBothDates={changeBothDates}
				departureTrip={departureTrip}
			/>
		</div>
	);
}
