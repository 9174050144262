/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPassengerDetailPassengerAttributes extends IModelAttributes {
	passengerDetailId: string;
	passengerId: string;

	passengerDetail: Models.PassengerDetailEntity | Models.IPassengerDetailEntityAttributes;
	passenger: Models.PassengerEntity | Models.IPassengerEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('PassengerDetailPassenger')
export default class PassengerDetailPassenger
	extends Model
	implements IPassengerDetailPassengerAttributes {
	public static acls: IAcl[] = [
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public passengerDetailId: string;

	@observable
	@attribute()
	public passengerId: string;

	@observable
	@attribute({ isReference: true })
	public passengerDetail: Models.PassengerDetailEntity;

	@observable
	@attribute({ isReference: true })
	public passenger: Models.PassengerEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IPassengerDetailPassengerAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.passengerDetailId) {
				this.passengerDetailId = attributes.passengerDetailId;
			}
			if (attributes.passengerId) {
				this.passengerId = attributes.passengerId;
			}

			if (attributes.passengerDetail) {
				if (attributes.passengerDetail instanceof Models.PassengerDetailEntity) {
					this.passengerDetail = attributes.passengerDetail;
					this.passengerDetailId = attributes.passengerDetail.id;
				} else {
					this.passengerDetail = new Models.PassengerDetailEntity(attributes.passengerDetail);
					this.passengerDetailId = this.passengerDetail.id;
				}
			} else if (attributes.passengerDetailId !== undefined) {
				this.passengerDetailId = attributes.passengerDetailId;
			}

			if (attributes.passenger) {
				if (attributes.passenger instanceof Models.PassengerEntity) {
					this.passenger = attributes.passenger;
					this.passengerId = attributes.passenger.id;
				} else {
					this.passenger = new Models.PassengerEntity(attributes.passenger);
					this.passengerId = this.passenger.id;
				}
			} else if (attributes.passengerId !== undefined) {
				this.passengerId = attributes.passengerId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
