import * as React from 'react';
import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import useAsync from 'Hooks/useAsync';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import alertToast from 'Util/ToastifyUtils';
import { action } from 'mobx';
import EventSideBarWrapper from 'Views/Components/_HumanWritten/Events/EventSideBar/EventSideBarWrapper';
import { EventCard } from 'Views/Components/_HumanWritten/Events/EventCards/EventCard';
import { FetchEvents } from 'Util/_HumanWritten/Events/FetchEvents';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import {
	Button,
	Colors,
	Display,
} from 'Views/Components/Button/Button';
import {
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from 'react-router';
import {
	CustomTicketTypeEntity,
	EventDetailEntity,
	FerryTripEntity,
} from 'Models/Entities';
import { isNullOrUndefined } from '../../../../../Util/TypeGuards';
import { RouteComponentProps } from 'react-router-dom';
import { whiteLabelStore } from '../../../../../Models/WhiteLabelStore';

interface EventListProps extends RouteComponentProps {
	upcoming: boolean;
}

function EventCardList({ ...props }: EventListProps) {
	return (
		<div className="event-page__container">
			<div className="events-header">
				<h1>Upcoming events</h1>
			</div>
			<EventList {...props} upcoming />
		</div>
	);
}

export default observer(EventCardList);

async function fetchEvents(skip: number): Promise<EventDetailEntity[]> {
	return FetchEvents(skip);
}

function EventList({ upcoming }: EventListProps) {
	const { isIpad } = useDeviceDetect();
	const [eventList, setEventList] = useState<EventDetailEntity[]>([]);
	const [seeMoreButton, setSeeMoreButton] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const eventToEdit = useRef<EventDetailEntity>(new EventDetailEntity());

	const match = useRouteMatch();
	const history = useHistory();
	const path = match.path === '/' ? '' : match.path;

	// Set document title
	document.title = 'Upcoming events';

	const fetchSomeEvents = async (refresh: boolean = false) => {
		setLoading(true);
		if (refresh) {
			setEventList([]);
		}
		const newEvents = await fetchEvents(refresh ? 0 : eventList.length);
		setEventList(events => [...events, ...newEvents]);
		setLoading(false);
		if (newEvents.length < 5 && seeMoreButton) {
			setSeeMoreButton(false);
		}
	};

	const onClickHandler = action((event: React.MouseEvent<HTMLButtonElement>) => {
		eventToEdit.current = new EventDetailEntity({
			name: '',
			description: '',
			hidden: false,
			termsAndConditions: whiteLabelStore.eventTerms ?? '',
			totalCapacity: 0,
			eventLabelId: undefined,
			ferryTrip: new FerryTripEntity({
				startDate: new Date(),
				startTime: new Date(),
				endDate: new Date(),
				endTime: new Date(),
				adultPassengerPrice: 0,
				childPassengerPrice: 0,
				infantPassengerPrice: 0,
				passengerOverbookingAllowance: 0,
				vehicleOverbookingAllowance: 0,
			}),
			customTicketTypes: [
				new CustomTicketTypeEntity(
					{
						singularName: 'Adult',
						pluralName: 'Adults',
						price: 35,
						maxCapacity: 10,
						quantity: 1,
					},
				),
			],
		});

		history.push(`${path}/create`);
	});

	const result = useAsync(async () => {
		await fetchSomeEvents(true);
	}, []);

	if (result.type === 'loading') {
		return <LottieSpinner />;
	}

	if (result.type === 'error') {
		alertToast('Error fetching events', 'error');
	}

	return (
		<>
			<If condition={store.isManager && !isIpad}>
				<Button
					className="add-event-button"
					display={Display.Outline}
					colors={Colors.None}
					onClick={e => onClickHandler(e)}
				>
					+
				</Button>
			</If>
			<If condition={isNullOrUndefined(eventList) || eventList.length === 0}>
				<div className="no-events-to-show">
					{/* <img src={BoatImg} alt="no-results" /> */}
					<h6>No upcoming events</h6>
				</div>
			</If>
			<If condition={eventList.length > 0}>
				{eventList.map((event: EventDetailEntity, index: number) => (
					<EventCard
						event={event}
						index={index}
						eventToEdit={eventToEdit}
					/>
				))}
				{seeMoreButton && (
					<Button
						key="button"
						className="see-more-button"
						onClick={() => fetchSomeEvents(false)}
						display={Display.Solid}
						colors={Colors.Secondary}
						disabled={loading}
					>
						{loading ? 'Loading...' : 'See more'}
					</Button>
				)}
			</If>
			<Switch>
				<Route
					path={`${path}/create`}
					render={() => <EventSideBarWrapper event={eventToEdit.current} refetch={result?.refresh} />}
				/>
				<Route
					path={`${path}/edit/:id`}
					render={props => {
						if (eventToEdit.current?.id) {
							return <EventSideBarWrapper {...props} event={eventToEdit.current} refetch={result?.refresh} />;
						}

						// eslint-disable-next-line react/prop-types
						eventToEdit.current = new EventDetailEntity(eventList?.find(x => x.id === props.match.params.id));
						return <EventSideBarWrapper {...props} event={eventToEdit.current} refetch={result?.refresh} />;
					}}
				/>
			</Switch>
		</>
	);
}
