import { BookingWizardData, PassengersInfo } from '../../BookingWizardData';
import { appConfigStore } from 'Models/AppConfigStore';
import { IBookingFormState } from '../../../CheckIn/FerryCheckIn/context/BookingFormState';
import { checkIfInvalidName } from 'Util/StringUtils';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';
import { isEmail } from 'Validators/Functions/Email';
import { serialiseDate } from 'Util/AttributeUtils';
import {
	isNotNullOrUndefined,
	isNullOrUndefined,
	stringIsEmpty,
	stringNotEmpty,
} from 'Util/TypeGuards';

/**
 * This function will return the TOTAL passenger count from the booking wizard data that is passed into the function.
 * @param wizardData - The booking wizard data to get the passenger count from.
 */
export const getTotalPassengerCountFromWizardData = (
	wizardData: BookingWizardData,
) => {
	return wizardData.adultTickets.length
		+ wizardData.childTickets.length
		+ wizardData.infantTickets.length
		+ (wizardData.passengerDTickets?.length ?? 0)
		+ (wizardData.passengerETickets?.length ?? 0)
		+ (wizardData.passengerFTickets?.length ?? 0)
		+ (wizardData.passengerGTickets?.length ?? 0)
		+ (wizardData.passengerHTickets?.length ?? 0);
};

/**
 * This function will return the TOTAL passenger count from the booking wizard data that is passed into the function.
 * @param wizardData - The booking wizard data to get the passenger count from.
 */
export const combineAllPassengerTickets = (
	wizardData: BookingWizardData | IBookingFormState,
): PassengersInfo[] => {
	return [...(wizardData.adultTickets ?? []),
		...(wizardData.childTickets ?? []),
		...(wizardData.infantTickets ?? []),
		...(wizardData.passengerDTickets ?? []),
		...(wizardData.passengerETickets ?? []),
		...(wizardData.passengerFTickets ?? []),
		...(wizardData.passengerGTickets ?? []),
		...(wizardData.passengerHTickets ?? [])];
};

export const filterPassengerTicketsByCompleteness = (passengerTickets: PassengersInfo[], returnCompleted: boolean) => {
	const errors = validatePassengers(passengerTickets);
	return passengerTickets.filter(x => {
		const isCompleted = errors?.find(y => y.id === x.id)?.errors === undefined;
		return returnCompleted ? isCompleted : !isCompleted;
	});
};

export const completedPassengerTickets = (
	passengerTickets: PassengersInfo[],
): PassengersInfo[] => {
	return filterPassengerTicketsByCompleteness(passengerTickets, true);
};

export const incompletePassengerTickets = (
	passengerTickets: PassengersInfo[],
): PassengersInfo[] => {
	return filterPassengerTicketsByCompleteness(passengerTickets, false);
};

export const passengerTicketIsEmpty = (passenger: PassengersInfo) => {
	return (
		stringIsEmpty(passenger.firstName)
		&& stringIsEmpty(passenger.lastName)
		&& stringIsEmpty(passenger.phone)
		&& stringIsEmpty(passenger.email)
		&& isNullOrUndefined(passenger.age)
		&& stringIsEmpty(passenger.address)
		&& stringIsEmpty(passenger.gender)
		&& isNullOrUndefined(passenger.dateOfBirth)
	);
};

export interface PassengerError {
	id: string;
	errors: {
		[key: string]: string; // Key-value pairs for field and error message
	};
}

export function validatePassengers(passengers: PassengersInfo[]): PassengerError[] | null {
	const allErrors: PassengerError[] = [];

	passengers.forEach(passenger => {
		const passengerErrors = validatePassenger(passenger);

		if (passengerErrors) {
			allErrors.push(passengerErrors);
		}
	});

	// Return the errors only if there are any
	return allErrors.length > 0 ? allErrors : null;
}

export function validatePassenger(passenger: PassengersInfo): PassengerError | null {
	const errors: { [key: string]: string } = {};
	// Validation logic
	if (appConfigStore.passengerFirstNameEnabled) {
		if (stringIsEmpty(passenger.firstName)
			&& appConfigStore.passengerFirstNameRequired) {
			errors.firstName = 'Required field';
		}

		if (checkIfInvalidName(passenger.firstName ?? '')) {
			errors.firstName = 'No spaces. A-Z and \'-\' only.';
		}
	}

	if (appConfigStore.passengerLastNameEnabled) {
		if (stringIsEmpty(passenger.lastName)
			&& appConfigStore.passengerLastNameRequired) {
			errors.lastName = 'Required field';
		}

		if (checkIfInvalidName(passenger.lastName ?? '')) {
			errors.lastName = 'No spaces. A-Z and \'-\' only.';
		}
	}

	if (appConfigStore.passengerPhoneEnabled) {
		if (stringIsEmpty(passenger.phone)
			&& appConfigStore.passengerPhoneRequired) {
			errors.phone = 'Required field';
		} else if (stringNotEmpty(passenger.phone)
			&& !IsValidPhone(passenger.phone)) {
			errors.phone = 'Enter a valid phone number';
		}
	}

	if (appConfigStore.passengerEmailEnabled) {
		if (stringIsEmpty(passenger.email)
			&& appConfigStore.passengerEmailRequired
			&& appConfigStore.passengerEmailEnabled) {
			errors.email = 'Required field';
		}

		if (stringNotEmpty(passenger.email) && !isEmail(passenger.email)) {
			errors.email = 'Enter a valid email address (xx@xx.xx)';
		}
	}

	if (isNullOrUndefined(passenger.age)
		&& appConfigStore.passengerAgeRequired
		&& appConfigStore.passengerAgeEnabled) {
		errors.age = 'Required field';
	}

	if (stringIsEmpty(passenger.address)
		&& appConfigStore.passengerAddressRequired
		&& appConfigStore.passengerAddressEnabled) {
		errors.address = 'Required field';
	}

	if (stringIsEmpty(passenger.gender)
		&& appConfigStore.passengerGenderRequired
		&& appConfigStore.passengerGenderEnabled) {
		errors.gender = 'Required field';
	}

	if (isNullOrUndefined(passenger.dateOfBirth)
		&& appConfigStore.passengerDateOfBirthRequired
		&& appConfigStore.passengerDateOfBirthEnabled) {
		errors.dateOfBirth = 'Required field';
	}

	return Object.keys(errors).length > 0
		? { id: passenger.id, errors }
		: null;
}

/**
 * This function will return the list of passengers with serialised date of birth.
 * @param passengers - The list of passengers to serialise the date of birth for.
 */
export const serialisePassengerDOB = (passengers?: PassengersInfo[]) => {
	if (isNullOrUndefined(passengers) || passengers.length === 0) {
		return [];
	}
	return passengers.map(ticket => ({
		...ticket,
		dateOfBirth: isNotNullOrUndefined(ticket.dateOfBirth) ? serialiseDate(ticket.dateOfBirth) : null,
	})) ?? [];
};
