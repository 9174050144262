import * as React from 'react';
import {
	DualActionModalContent,
	DualActionModalContentProps,
} from 'Views/Components/_HumanWritten/Modal/Base/DualActionModalContent';
import { showModal } from 'Views/Components/_HumanWritten/Modal/Base/BaseModalContents';
import { formatShortDate, formatShortTime } from 'Util/_HumanWritten/TimeUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { store } from 'Models/Store';

export interface CheckInMoveRequestModalContentsProps extends Pick<DualActionModalContentProps, 'onConfirm'> {
	departureDateTime?: Date;
	checkedIn?: boolean;
	autoClose?: boolean;
}

export function CheckInMoveRequestModalContents({
	onConfirm,
	departureDateTime,
	checkedIn,
	autoClose = true,
}: CheckInMoveRequestModalContentsProps) {
	const body = isNotNullOrUndefined(departureDateTime) && isNotNullOrUndefined(checkedIn)
		? `This booking is for ${formatShortTime(departureDateTime)} ${formatShortDate(departureDateTime)} and has
		 ${checkedIn ? '' : 'not '}been checked in. Do you want to move it the current trip?`
		: 'This booking belongs to a future trip. Do you want to move it the current trip?';

	return (
		<DualActionModalContent
			title="Early bird"
			body={body}
			onConfirm={onConfirm}
			onCancel={() => store.modal.hide()}
			fixedCancelWidth
			confirmContent="Move to current"
			autoClose={autoClose}
		/>
	);
}

export function showCheckInMoveRequestModal(props: CheckInMoveRequestModalContentsProps) {
	showModal({
		key: 'boarding-future-trip',
		content: <CheckInMoveRequestModalContents {...props} />,
		modalProps: {
			shouldCloseOnOverlayClick: false,
		},
	});
}
