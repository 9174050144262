import React from 'react';
import classNames from 'classnames';

import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import Skeleton from 'Views/Components/_HumanWritten/Skeleton/Skeleton';
import { LegOptionContext } from './Contexts/LegOptionContext';

interface LegOptionPriceProps {
	className?: string;
}

export default function LegOptionPrice({ className }: LegOptionPriceProps) {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionPrice');
		return null;
	}

	const { isLoading, price, wizardData } = context;

	const displayedPrice = price === undefined ? undefined : formatPriceDisplay(price, wizardData.wizardMode === 'ALTERATION');

	return <LegOptionPriceInner className={className} isLoading={isLoading} displayedPrice={displayedPrice} />;
}

interface LegOptionPriceInnerProps {
	className?: string;
	isLoading?: boolean;
	displayedPrice?: string;
}

function LegOptionPriceInner({ className, isLoading, displayedPrice }: LegOptionPriceInnerProps) {
	return (
		<div className={classNames('leg-option__price-container', className)}>
			{(isLoading || displayedPrice === undefined) && <Skeleton width="5rem" height="2rem" />}
			{!isLoading && (
				<div className="leg-option__price-text">{displayedPrice}</div>
			)}
		</div>
	);
}
