import { useMemo } from 'react';

import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { AvailabilityStatus } from '../Types/AvailabilityStatus';
import { useAvailability } from './useAvailability';

export const useAvailabilityStatus = (
	trip: TicketsTabTrip,
	ticketType: 'vehicle' | 'passenger' = 'passenger',
): AvailabilityStatus => {
	const availability = useAvailability(trip);
	const availabilityPercent = useMemo(() => {
		if (ticketType === 'vehicle') {
			return (availability.vehicle / availability.totalVehicle) * 100;
		}
		return (availability.passenger / trip.passengerSpacesTotal) * 100;
	}, [
		ticketType,
		availability.vehicle,
		availability.totalVehicle,
		availability.passenger,
		trip.passengerSpacesTotal,
	]);

	if (trip.departureDateTime < new Date()) {
		return 'departed';
	}

	if (trip.isClosed) {
		return 'closed';
	}

	if (trip.isHidden) {
		return 'hidden';
	}

	if (availabilityPercent === 0) {
		return 'no-availability';
	}

	if (ticketType === 'passenger' && availability.passenger === 0) {
		return 'no-availability';
	}

	if (ticketType === 'vehicle' && availability.vehicle < 1) {
		return 'no-availability';
	}

	if (availabilityPercent >= 50) {
		return 'high-availability';
	}

	return 'low-availability';
};
