/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminPassengerEntity from 'Models/Security/Acl/AdminPassengerEntity';
import ManagerPassengerEntity from 'Models/Security/Acl/ManagerPassengerEntity';
import StaffPassengerEntity from 'Models/Security/Acl/StaffPassengerEntity';
import UserPassengerEntity from 'Models/Security/Acl/UserPassengerEntity';
import FixedPriceUserPassengerEntity from 'Models/Security/Acl/FixedPriceUserPassengerEntity';
import InvoicedUserPassengerEntity from 'Models/Security/Acl/InvoicedUserPassengerEntity';
import VisitorsPassengerEntity from 'Models/Security/Acl/VisitorsPassengerEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPassengerEntityAttributes extends IModelAttributes {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	gender: string;
	dateOfBirth: Date;
	address: string;
	age: number;
	removed: boolean;

	passengerDetails: Array<
		| Models.PassengerDetailPassenger
		| Models.IPassengerDetailPassengerAttributes
	>;
	userId: string;
	user: Models.UserEntity | Models.IUserEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PassengerEntity', 'Passenger')
// % protected region % [Customise your entity metadata here] end
export default class PassengerEntity extends Model
	implements IPassengerEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminPassengerEntity(),
		new ManagerPassengerEntity(),
		new StaffPassengerEntity(),
		new UserPassengerEntity(),
		new FixedPriceUserPassengerEntity(),
		new InvoicedUserPassengerEntity(),
		new VisitorsPassengerEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	/**
	 * The first name of the passenger.
	 */
	@observable
	@attribute<PassengerEntity, string>()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	/**
	 * The last name of the passenger.
	 */
	@observable
	@attribute<PassengerEntity, string>()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	/**
	 * The contact email for the passenger.
	 */
	@observable
	@attribute<PassengerEntity, string>()
	@CRUD({
		name: 'Email',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * The contact phone for the passenger.
	 */
	@observable
	@attribute<PassengerEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gender'] off begin
	/**
	 * The gender of the passenger.
	 */
	@observable
	@attribute<PassengerEntity, string>()
	@CRUD({
		name: 'Gender',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public gender: string;
	// % protected region % [Modify props to the crud options here for attribute 'Gender'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] off begin
	/**
	 * The date of birth of the passenger.
	 */
	@observable
	@attribute<PassengerEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Date of Birth',
		displayType: 'datepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address'] off begin
	/**
	 * The address of the passenger.
	 */
	@observable
	@attribute<PassengerEntity, string>()
	@CRUD({
		name: 'Address',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Age'] off begin
	/**
	 * The age of the passenger.
	 */
	@Validators.Integer()
	@observable
	@attribute<PassengerEntity, number>()
	@CRUD({
		name: 'Age',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public age: number;
	// % protected region % [Modify props to the crud options here for attribute 'Age'] end

	// % protected region % [Modify props to the crud options here for attribute 'Removed'] off begin
	@observable
	@attribute<PassengerEntity, boolean>()
	@CRUD({
		name: 'Removed',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public removed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Removed'] end

	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Passenger Detail'] off begin
		name: 'Passenger Detail',
		displayType: 'reference-multicombobox',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PassengerDetailPassenger,
		optionEqualFunc: makeJoinEqualsFunc('passengerDetailId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'passengerEntity',
			oppositeEntityName: 'passengerDetailEntity',
			relationName: 'passenger',
			relationOppositeName: 'passengerDetail',
			entity: () => Models.PassengerEntity,
			joinEntity: () => Models.PassengerDetailPassenger,
			oppositeEntity: () => Models.PassengerDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Passenger Detail'] end
	})
	public passengerDetails: Models.PassengerDetailPassenger[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public userId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public user: Models.UserEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPassengerEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPassengerEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.gender !== undefined) {
				this.gender = attributes.gender;
			}
			if (attributes.dateOfBirth !== undefined) {
				if (attributes.dateOfBirth === null) {
					this.dateOfBirth = attributes.dateOfBirth;
				} else {
					this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
				}
			}
			if (attributes.address !== undefined) {
				this.address = attributes.address;
			}
			if (attributes.age !== undefined) {
				this.age = attributes.age;
			}
			if (attributes.removed !== undefined) {
				this.removed = attributes.removed;
			}
			if (attributes.passengerDetails !== undefined && Array.isArray(attributes.passengerDetails)) {
				for (const model of attributes.passengerDetails) {
					if (model instanceof Models.PassengerDetailPassenger) {
						this.passengerDetails.push(model);
					} else {
						this.passengerDetails.push(new Models.PassengerDetailPassenger(model));
					}
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		passengerDetails {
			${Models.PassengerDetailPassenger.getAttributes().join('\n')}
			passengerDetail {
				${Models.PassengerDetailEntity.getAttributes().join('\n')}
			}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
			${Models.UserEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			passengerDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'passengerDetails',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PassengerEntity.prototype, 'created');
CRUD(modifiedAttr)(PassengerEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
