import { BookingCutoffEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import * as React from 'react';
import { store } from 'Models/Store';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'Views/Components/Button/Button';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import { getFormattedHoursDays } from 'Util/StringUtils';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import If from 'Views/Components/If/If';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { cancelBooking } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	isNotNullOrUndefined,
	isNullOrUndefined,
	stringIsEmpty,
	stringNotEmpty,
} from 'Util/TypeGuards';

export interface ManagerModalProps {
	maxRefundAmount: number;
	refundModel: {
		refundAmount: number,
		refundAmountErrors: string,
	};
	recommendedRefundAmount: number;
}
export interface CustomerModalProps {
	departureBookingCutoff: BookingCutoffEntity | null;
	recommendedRefundAmount: number;
}

export const ManagerModalContent = observer(({
	maxRefundAmount,
	refundModel,
	recommendedRefundAmount,
}: ManagerModalProps) => {
	return (
		<div className="manager-cancel-booking-modal-content">
			<h5>The maximum value of credit is
				<span><strong> {displayAsAud(maxRefundAmount)}</strong>. </span>
				You can update the amount of credit to refund.
			</h5>
			<p className="input-label">Credit voucher</p>
			<div className="custom-number-input-field">
				<Button
					className="dollar-icon"
					icon={{ icon: 'money', iconPos: 'icon-right' }}
				/>
				<NumberTextField
					className="price-refund-textfield"
					id="cancellation-refund-textfield"
					model={refundModel}
					modelProperty="refundAmount"
					placeholder={recommendedRefundAmount.toFixed(2)}
					onAfterChange={action(() => { refundModel.refundAmountErrors = ''; })}
					showErrors={false}
				/>
			</div>
			<If condition={stringNotEmpty(refundModel.refundAmountErrors)}>
				<p className="error-message">{refundModel.refundAmountErrors}</p>
			</If>
		</div>
	);
});

export const CustomerModalContent = observer(({
	departureBookingCutoff,
	recommendedRefundAmount,
}: CustomerModalProps) => {
	let firstParagraph = '';
	let secondParagraph = '';
	// If there is a booking cut-off entity
	if (isNotNullOrUndefined(departureBookingCutoff)) {
		firstParagraph = `You can cancel your trip time up to ${
			getFormattedHoursDays(departureBookingCutoff?.hoursBeforeDeparture)
		} before your departure.`;
		secondParagraph = `You will receive a ferry voucher with a credited value of $${
			recommendedRefundAmount.toFixed(2)
		}.`;
	} else if (isNullOrUndefined(departureBookingCutoff) && recommendedRefundAmount > 0) {
		// In the scenario if there is no booking cancellation entity that applies
		// The user will receive their max booking cost as a refund.
		firstParagraph = `You will receive a ferry voucher with a credited value of $${
			recommendedRefundAmount.toFixed(2)
		}.`;
		secondParagraph = '';
	}
	if (recommendedRefundAmount <= 0) {
		// If the booking cutoff fee is 100%
		secondParagraph = 'You will not be eligible for a refund or a voucher.';
	}
	return (
		<div>
			<h5>{firstParagraph}</h5>
			<h5>{secondParagraph}</h5>
		</div>
	);
});

export const RenderCancelBookingModal = async (
	bookingId: string,
	userFirstName: string,
	userLastName:string,
	cancelReturn: boolean,
	departureBookingCutoffEntity: BookingCutoffEntity | null,
	recommendedRefundAmount: number,
	maxRefundAmount: number,
	refresh: () => void,
) => {
	const { isManager } = store;

	const refundModel = observable({
		refundAmount: Number(recommendedRefundAmount.toFixed(2)),
		refundAmountErrors: '',
	});

	let modalContent: JSX.Element;
	let modalTitle: string;
	let cancelButtonText: string = 'Back';
	let confirmButtonText: string = 'Confirm';

	if (isManager) {
		const canGiveRefund = maxRefundAmount > 0;
		modalTitle = 'Cancel booking?';
		cancelButtonText = 'Close';
		confirmButtonText = canGiveRefund ? 'Send voucher' : 'Cancel booking';

		const ObservableManagerModalContent = observer(() => {
			return (
				<ManagerModalContent
					maxRefundAmount={maxRefundAmount}
					refundModel={refundModel}
					recommendedRefundAmount={recommendedRefundAmount}
				/>
			);
		});
		const noRefundMessage = <h5>This booking is not eligible for a ferry voucher as it was invoiced.</h5>;
		modalContent = canGiveRefund ? <ObservableManagerModalContent /> : noRefundMessage;
	} else {
		modalTitle = 'Are you sure?';
		modalContent = (
			<CustomerModalContent
				departureBookingCutoff={departureBookingCutoffEntity}
				recommendedRefundAmount={recommendedRefundAmount}
			/>
		);
	}

	const confirmed = await modalWithCustomInput(
		modalTitle,
		modalContent,
		{
			cancelText: cancelButtonText,
			confirmText: confirmButtonText,
			actionClassName: `cancel-booking-modal ${isManager ? 'staff' : ''}`,
			resolveOnCancel: true,
			onConfirm: action(() => {
				if (refundModel.refundAmount >= 0 && refundModel.refundAmount <= maxRefundAmount) {
					refundModel.refundAmountErrors = '';
					return true;
				}

				refundModel.refundAmountErrors = `Please enter a number between $0 and ${
					displayAsAud(maxRefundAmount)}`;

				return false;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	const response = await cancelBooking({
		bookingId: bookingId,
		applyToReturn: cancelReturn,
		amountToRefund: refundModel.refundAmount,
		recommendedRefundAmount: recommendedRefundAmount,
	});
	switch (response.status) {
		// Status will be 200 if the booking was successfully cancelled
		case 200:
			if (stringIsEmpty(response.data)) {
				// throw new Error('Invalid transaction id');
				return alertToast('Failed to cancel booking', 'error');
			}

			return store.routerHistory
				// eslint-disable-next-line max-len
				.push(`/booking-cancelled-success?id=${response.data}&userFirstName=${userFirstName.trim()}&userLastName=${userLastName.trim()}`);
		// Bad request... either user is not authorized or the cancellation failed
		case 400:
			return alertToast('Unable to cancel booking', 'error');
		// Conflict... Will only be returned if the booking has already been cancelled
		case 409:
			await refresh();
			return alertToast('Booking has already been cancelled. Refreshing page...', 'error');
	}
};
