import { getMeasurementValueFromId } from 'Util/_HumanWritten/MeasurementUtils';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export const useIsTrailerSpaceExceeded = (trailerSpaceAvailable: number, wizardData: BookingWizardData) => {
	if (wizardData.tabSelected !== 'vehicle' || !wizardData.trailerCheckboxSelected) {
		return false;
	}

	const spaceToReserve = getMeasurementValueFromId(wizardData.trailerLengthId, true);
	return spaceToReserve > trailerSpaceAvailable;
};
