import { getMeasurementValueFromId } from 'Util/_HumanWritten/MeasurementUtils';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export const useIsVehicleSpaceExceeded = (vehicleSpacesAvailable: number, wizardData: BookingWizardData) => {
	if (wizardData.tabSelected !== 'vehicle') {
		return false;
	}

	const vehicleSpaceToReserve = getMeasurementValueFromId(wizardData.vehicleLengthId);
	const trailerSpaceToReserve = wizardData.trailerCheckboxSelected
		? getMeasurementValueFromId(wizardData.trailerLengthId, true)
		: 0;

	const totalSpaceToReserve = vehicleSpaceToReserve + trailerSpaceToReserve;

	return totalSpaceToReserve > vehicleSpacesAvailable;
};
