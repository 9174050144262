import {
	BookingCreationAdditionalOption,
	FerryBookingCreationDto,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { getMeasurementValueFromId } from 'Util/_HumanWritten/MeasurementUtils';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { IBookingFormGroupHeaders } from 'Hooks/useGroupHeaders';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { IBookingFormState } from '../context/BookingFormState';
import { AdditionalOption } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * IMPORTANT: This links an external button to a form element in BookingFormEdit component.
 */
export const BOOKING_FORM_ID = 'booking-form-id';

/**
 * IMPORTANT: This links an external button to a form element in BookingFormEdit component.
 */
export const CLOSE_BOOKING_FORM_ID = 'booking-form-id-onClose';

/**
 * Ensure `booking` has all the dependencies to produce FerryBookingCreationDto.
 *
 * Please see function implementation to see deconstruction of `booking`.
 */
export function transformBookingToBookingCreationDto(booking: CheckInBookingOverviewDto): FerryBookingCreationDto {
	const {
		id: bookingId,
		user: {
			id: userId,
			firstName,
			lastName,
			phone,
		},
		bookedSummary: {
			ferryTrip: { id: tripId },
			adultPassengerTickets,
			childPassengerTickets,
			infantPassengerTickets,
			driverFirstName,
			driverLastName,
			driverPhone,
			cargoInfo,
			towOnInfo,
			additionalBookingOptions,
			passengerDTickets,
			passengerETickets,
			passengerFTickets,
			passengerGTickets,
			passengerHTickets,
			note,
		},
		returnBooking,
	} = booking;

	return {
		bookingToEdit: bookingId,
		userId,
		departureTicketId: tripId,
		returningTicketId: returnBooking?.bookedSummary?.ferryTrip.id,
		adultTickets: adultPassengerTickets,
		childTickets: childPassengerTickets,
		infantTickets: infantPassengerTickets,
		passengerDTickets: passengerDTickets,
		passengerETickets: passengerETickets,
		passengerFTickets: passengerFTickets,
		passengerGTickets: passengerGTickets,
		passengerHTickets: passengerHTickets,
		driverFirstName: cargoInfo ? driverFirstName : firstName,
		driverLastName: cargoInfo ? driverLastName : lastName,
		driverPhone: cargoInfo ? driverPhone : phone,
		cargoIdentification: cargoInfo?.cargoIdentification,
		hiredVehicle: cargoInfo?.tbc,
		cargoTypeId: cargoInfo?.cargoTypeId,
		vehicleLengthId: cargoInfo?.selectedLengthId,
		vehicleWeightId: cargoInfo?.selectedWeightId,
		trailerTypeId: towOnInfo?.towOnTypeId,
		trailerLengthId: towOnInfo?.selectedLengthId,
		departingTripOptions: additionalBookingOptions
			.map(x => ({ optionId: x.option.id, amount: x.quantity } as AdditionalOption)),
		returningTripOptions: returnBooking?.bookedSummary?.additionalBookingOptions
			.map(x => ({ optionId: x.option.id, amount: x.quantity } as AdditionalOption)) ?? [],
		bypassSpaceCheck: true,
		note,
		location: 'CHECK_IN',

		// Add departingJourney if departing trip is available
		...(booking.bookedSummary && {
			departingJourney: {
				tripId: booking.bookedSummary.ferryTrip.id,
				startStopId: booking.bookedSummary.startStopId,
				endStopId: booking.bookedSummary.endStopId,
			},
		}),

		// Add returningJourney if returning trip is available
		...(returnBooking && returnBooking.bookedSummary && {
			returningJourney: {
				tripId: returnBooking.bookedSummary.ferryTrip.id,
				startStopId: returnBooking.bookedSummary.startStopId,
				endStopId: returnBooking.bookedSummary.endStopId,
			},
		}),
	};
}

export function transformBookingToBookingFormState(booking: CheckInBookingOverviewDto): IBookingFormState {
	const {
		user: {
			email,
		},
		bookedSummary: {
			cargoInfo,
			towOnInfo,
		},
	} = booking;

	return {
		...transformBookingToBookingCreationDto(booking),
		email: email,
		cargoMake: cargoInfo?.cargoType?.cargoMake ?? '',
		cargoModel: cargoInfo?.cargoType?.cargoModel ?? '',
		vehicleLengthId: cargoInfo?.selectedLengthId,
		trailerLengthId: towOnInfo?.selectedLengthId,
		user: booking.user,
		giftCertificateUsages: [],
		transactionFees: [],
		forEftpos: true,
	};
}

/**
 * Returns true if any differences have been detected between add ons in both actual and expected lists.
 * False otherwise.
 */
export function compareAddOnOptions(
	expected: BookingCreationAdditionalOption[],
	actual: BookingCreationAdditionalOption[],
): boolean {
	if (expected.length !== actual.length) {
		return true;
	}

	const expectedIds = expected.map(x => x.optionId);
	const actualIds = actual.map(x => x.optionId);
	if (expectedIds.filter(x => actualIds.includes(x)).length !== expected.length) {
		return true;
	}

	return expected.some(x => {
		return x.amount !== actual.find(y => y.optionId === x.optionId)?.amount;
	});
}

/**
 * Returns true when a property of initial and current state does not match. Returns false otherwise.
 * @param initialState The booking form state before any changes.
 * @param currentState The booking form state after any changes.
 */
export function bookingFormHasChanged(
	initialState: IBookingFormState,
	currentState: IBookingFormState,
) {
	// Key hold object/list values
	// No need to check for equality as they have corresponding id field (e.g. userId) or is used for meta data
	const ignoreKeys = [
		'giftCertificateUsages',
		'returningOptions',
		'bookingToEdit',
		'user',
	];
	const hasChanged = Object
		.keys(currentState)
		.some(currentKey => {
			if (ignoreKeys.includes(currentKey)) {
				return false;
			}
			if (currentKey === 'departingOptions') {
				// A typical value of key in BookingFormState is a javascript literal (string, bool, etc).
				// departingOptions is a list of elements that requires custom comparison.
				//
				// Function returns true if a change is detected.
				return compareAddOnOptions(currentState.departingTripOptions, initialState.departingTripOptions);
			}
			// Uncomment below for debugging
			// console.log({
			// 	key: currentKey,
			// 	og: initialState[currentKey],
			// 	val: currentState[currentKey],
			// 	res: currentState[currentKey] !== initialState[currentKey],
			// });
			return currentState[currentKey] !== initialState[currentKey];
		});

	return hasChanged;
}

/**
 * Returns true if vehicle or towOn values of both states are not the same. Returns false otherwise.
 */
export function groupHeaderHasChanged(
	initialState: IBookingFormGroupHeaders,
	currentState: IBookingFormGroupHeaders,
) {
	const hasChanged = (
		initialState.vehicles.show !== currentState.vehicles.show
		|| initialState.trailers.show !== currentState.trailers.show
		|| initialState.addOns.show !== currentState.addOns.show
	);

	return hasChanged;
}

export function vehicleTrailerLength(x :IBookingFormState) {
	let newLength = 0;
	if (x.vehicleLengthId !== undefined && x.vehicleLengthId !== '') {
		newLength += getMeasurementValueFromId(x.vehicleLengthId);
		if (x.trailerLengthId !== undefined && x.trailerLengthId !== '') {
			newLength += getMeasurementValueFromId(x.trailerLengthId);
		}
	}
	return newLength;
}

export function passengerCount(x: IBookingFormState): number {
	return passengerTypeStore.getTotalFromBookingDto(x);
}
