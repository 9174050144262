import axios from 'axios';
import dayjs from 'dayjs';

import { WIZARD_DATA_API } from 'Constants';
import { serialiseDate } from 'Util/AttributeUtils';
import { CargoEntity, CargoTypeEntity, TowOnTypeEntity } from 'Models/Entities';
import { FerryBookingCreationDto } from './BookingService/FerryTripBookingService';
import { PassengersInfo } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { stringIsEmpty } from 'Util/TypeGuards';
import { buildUrl } from 'Util/FetchUtils';

export interface TicketsTabTrip {
	id: string;
	isWaitListed: boolean,
	isHidden: boolean | null,
	isClosed: boolean | null,
	startDate: Date;
	tripTimeTakenMinutes: number;

	// Ferry trip Capacity
	passengerSpacesAvailable: number;
	passengerSpacesTotal: number;
	vehicleSpacesAvailable: number;
	vehicleSpacesTotal: number;
	trailerSpotsAvailable: number;
	trailerSpotsTotal: number;
	weightAvailable: number;
	totalWeight: number;

	// Feature: Multi-stop - ticket tab trip dto
	departureDateTime: Date;
	arrivalDateTime: Date;
	startStopId: string;
	endStopId: string;
}

export interface TicketsTabData {
	departureTickets: TicketsTabTrip[];
	returningTickets: TicketsTabTrip[];
}

export interface CargoTabDto {
	cargoTypes: CargoTypeEntity[],
	towOnTypes: TowOnTypeEntity[],
	cargoMeasurementPrices: { [key: string]: number },
	towOnMeasurementPrices: { [key: string]: number },
	previousCargos?: CargoEntity[],
}

export interface TicketsTabStateData {
	departureTickets: TicketsTabTrip[] | null;
	returningTickets: TicketsTabTrip[] | null;
}

export async function GetTicketsTabData(
	signal: AbortSignal,
	departureLocationId: string,
	destinationLocationId: string,
	dateToFetchNoFormat: Date,
	takeDeparture: boolean,
	oneWayAlteration: boolean,
	bookingBeingEditedId: string | null,
	userId?: string,
): Promise<TicketsTabData> {
	// Must be date time unspecified timezone
	const dateToFetch = `${dayjs(dateToFetchNoFormat).format('YYYY-MM-DD')}T00:00:00`;

	const { data } = await axios.get<TicketsTabData>(
		`${WIZARD_DATA_API}/tickets-tab`,
		{
			params: {
				departureLocationId,
				destinationLocationId,
				dateToFetch,
				takeDeparture,
				bookingBeingEditedId,
				userId,
			},
			signal,
		},
	);

	const tripsToReturn = takeDeparture ? data.departureTickets : data.returningTickets;
	tripsToReturn.forEach(x => {
		x.startDate = new Date(x.startDate);
		x.departureDateTime = new Date(x.departureDateTime);
		x.arrivalDateTime = new Date(x.arrivalDateTime);
	});
	tripsToReturn.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	if (oneWayAlteration) {
		return {
			departureTickets: tripsToReturn,
			returningTickets: [],
		};
	}

	data.departureTickets.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	data.departureTickets = data.departureTickets.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	data.returningTickets.forEach(x => {
		x.startDate = new Date(x.startDate);
		x.departureDateTime = new Date(x.departureDateTime);
		x.arrivalDateTime = new Date(x.arrivalDateTime);
	});
	data.returningTickets = data.returningTickets.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	return data;
}

export async function FetchCargoTabData(
	request: FerryBookingCreationDto,
): Promise<CargoTabDto | null> {
	try {
		const result = await axios.post(
			`${WIZARD_DATA_API}/get-vehicle-tab-data`, request);
		return {
			cargoTypes: result.data.cargoTypes.map((x: CargoTypeEntity) => new CargoTypeEntity(x)),
			towOnTypes: result.data.towOnTypes.map((x: TowOnTypeEntity) => new TowOnTypeEntity(x)),
			cargoMeasurementPrices: result.data.cargoMeasurementPrices,
			towOnMeasurementPrices: result.data.towOnMeasurementPrices,
			previousCargos: result.data.previousCargos.map((x: CargoEntity) => new CargoEntity(x)),
		} as CargoTabDto;
	} catch (e) {
		console.error(e);
		return null;
	}
}

export async function FetchPassengerTabData(
	userId: string,
): Promise<PassengersInfo[]> {
	try {
		if (stringIsEmpty(userId)) {
			return [];
		}

		const result = await axios.get(
			buildUrl(`${WIZARD_DATA_API}/get-passenger-tab-data`, { userId }),
		);
		return result.data as PassengersInfo[];
	} catch (e) {
		console.error(e);
		return [];
	}
}
