/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsPassengerDetailEntity from 'Models/Security/Acl/VisitorsPassengerDetailEntity';
import UserPassengerDetailEntity from 'Models/Security/Acl/UserPassengerDetailEntity';
import StaffPassengerDetailEntity from 'Models/Security/Acl/StaffPassengerDetailEntity';
import ManagerPassengerDetailEntity from 'Models/Security/Acl/ManagerPassengerDetailEntity';
import AdminPassengerDetailEntity from 'Models/Security/Acl/AdminPassengerDetailEntity';
import InvoicedUserPassengerDetailEntity from 'Models/Security/Acl/InvoicedUserPassengerDetailEntity';
import FixedPriceUserPassengerDetailEntity from 'Models/Security/Acl/FixedPriceUserPassengerDetailEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPassengerDetailEntityAttributes extends IModelAttributes {
	placeholder: string;

	passengerTypeId: string;
	passengerType: Models.PassengerTypeEntity | Models.IPassengerTypeEntityAttributes;
	passengers: Array<
		| Models.PassengerDetailPassenger
		| Models.IPassengerDetailPassengerAttributes
	>;
	tickets: Array<
		| Models.PassengerDetailTicket
		| Models.IPassengerDetailTicketAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PassengerDetailEntity', 'Passenger Detail')
// % protected region % [Customise your entity metadata here] end
export default class PassengerDetailEntity extends Model
	implements IPassengerDetailEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPassengerDetailEntity(),
		new UserPassengerDetailEntity(),
		new StaffPassengerDetailEntity(),
		new ManagerPassengerDetailEntity(),
		new AdminPassengerDetailEntity(),
		new InvoicedUserPassengerDetailEntity(),
		new FixedPriceUserPassengerDetailEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Placeholder'] off begin
	/**
	 * Will add more attributes in the future.
	 */
	@observable
	@attribute<PassengerDetailEntity, string>()
	@CRUD({
		name: 'Placeholder',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public placeholder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Placeholder'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Passenger Type'] off begin
		name: 'Passenger Type',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.PassengerTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Passenger Type'] end
	})
	public passengerTypeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public passengerType: Models.PassengerTypeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Passenger'] off begin
		name: 'Passenger',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PassengerDetailPassenger,
		optionEqualFunc: makeJoinEqualsFunc('passengerId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'passengerDetailEntity',
			oppositeEntityName: 'passengerEntity',
			relationName: 'passengerDetail',
			relationOppositeName: 'passenger',
			entity: () => Models.PassengerDetailEntity,
			joinEntity: () => Models.PassengerDetailPassenger,
			oppositeEntity: () => Models.PassengerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Passenger'] end
	})
	public passengers: Models.PassengerDetailPassenger[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] off begin
		name: 'Ticket',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PassengerDetailTicket,
		optionEqualFunc: makeJoinEqualsFunc('ticketId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'passengerDetailEntity',
			oppositeEntityName: 'ticketEntity',
			relationName: 'passengerDetail',
			relationOppositeName: 'ticket',
			entity: () => Models.PassengerDetailEntity,
			joinEntity: () => Models.PassengerDetailTicket,
			oppositeEntity: () => Models.TicketEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] end
	})
	public tickets: Models.PassengerDetailTicket[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPassengerDetailEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPassengerDetailEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.placeholder !== undefined) {
				this.placeholder = attributes.placeholder;
			}
			if (attributes.passengerTypeId !== undefined) {
				this.passengerTypeId = attributes.passengerTypeId;
			}
			if (attributes.passengerType !== undefined) {
				if (attributes.passengerType === null) {
					this.passengerType = attributes.passengerType;
				} else if (attributes.passengerType instanceof Models.PassengerTypeEntity) {
					this.passengerType = attributes.passengerType;
					this.passengerTypeId = attributes.passengerType.id;
				} else {
					this.passengerType = new Models.PassengerTypeEntity(attributes.passengerType);
					this.passengerTypeId = this.passengerType.id;
				}
			}
			if (attributes.passengers !== undefined && Array.isArray(attributes.passengers)) {
				for (const model of attributes.passengers) {
					if (model instanceof Models.PassengerDetailPassenger) {
						this.passengers.push(model);
					} else {
						this.passengers.push(new Models.PassengerDetailPassenger(model));
					}
				}
			}
			if (attributes.tickets !== undefined && Array.isArray(attributes.tickets)) {
				for (const model of attributes.tickets) {
					if (model instanceof Models.PassengerDetailTicket) {
						this.tickets.push(model);
					} else {
						this.tickets.push(new Models.PassengerDetailTicket(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		passengers {
			${Models.PassengerDetailPassenger.getAttributes().join('\n')}
			passenger {
				${Models.PassengerEntity.getAttributes().join('\n')}
			}
		}
		tickets {
			${Models.PassengerDetailTicket.getAttributes().join('\n')}
			ticket {
				${Models.TicketEntity.getAttributes().join('\n')}
			}
		}
		passengerType {
			${Models.PassengerTypeEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			passengers: {},
			tickets: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'passengers',
							'tickets',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PassengerDetailEntity.prototype, 'created');
CRUD(modifiedAttr)(PassengerDetailEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
