import React from 'react';
import classNames from 'classnames';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { LegOptionContext } from './Contexts/LegOptionContext';

interface LegOptionSelectedIndicatorProps {
	className?: string;
}

export default function LegOptionSelectedIndicator({
	className,
}: LegOptionSelectedIndicatorProps) {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionSelectedIndicator');
		return null;
	}

	const { isSelectable, isSelected } = context;

	return (
		<LegOptionSelectedIndicatorInner
			className={className}
			isSelected={isSelected}
			isSelectable={isSelectable}
		/>
	);
}

interface LegOptionSelectedIndicatorInnerProps {
	className?: string;
	isSelected?: boolean;
	isSelectable?: boolean;
}

function LegOptionSelectedIndicatorInner({
	className,
	isSelected,
	isSelectable,
}: LegOptionSelectedIndicatorInnerProps) {
	return (
		<div className={classNames('leg-option__selection-container', className)}>
			{isSelectable && (
				<div
					className={classNames('leg-option__selected-indicator', {
						'leg-option__selected-indicator--checked': isSelected,
						'leg-option__selected-indicator--unchecked': !isSelected,
						'leg-option__selected-indicator--show': isSelectable,
						'leg-option__selected-indicator--hide': !isSelectable,
					})}
				>
					{isSelected && <Icon name="tick" />}
				</div>
			)}
		</div>
	);
}
