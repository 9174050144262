/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from 'Views/Components/Helpers/Common';
import SecurityService from 'Services/SecurityService';
import { getModelDisplayName } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] on begin
import { store } from '../../../Models/Store';
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] on begin
	adminCanSee?: boolean;
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for AddOnsPriceEntity here] on begin
		path: '/admin/addonspriceentity',
		label: getModelDisplayName(Models.AddOnsPriceEntity),
		entity: Models.AddOnsPriceEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for AddOnsPriceEntity here] end
	},
	{
		// % protected region % [Override navigation link for AdditionalBookingOptionEntity here] on begin
		path: '/admin/additionalbookingoptionentity',
		label: getModelDisplayName(Models.AdditionalBookingOptionEntity),
		entity: Models.AdditionalBookingOptionEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for AdditionalBookingOptionEntity here] end
	},
	{
		// % protected region % [Override navigation link for AdditionalBookingSectionEntity here] on begin
		path: '/admin/additionalbookingsectionentity',
		label: getModelDisplayName(Models.AdditionalBookingSectionEntity),
		entity: Models.AdditionalBookingSectionEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for AdditionalBookingSectionEntity here] end
	},
	{
		// % protected region % [Override navigation link for AdminEntity here] on begin
		path: '/admin/adminentity',
		label: getModelDisplayName(Models.AdminEntity),
		entity: Models.AdminEntity,
		isMember: true,
		adminCanSee: true,
		// % protected region % [Override navigation link for AdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for AlterationEntity here] on begin
		path: '/admin/alterationentity',
		label: getModelDisplayName(Models.AlterationEntity),
		entity: Models.AlterationEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for AlterationEntity here] end
	},
	{
		// % protected region % [Override navigation link for ApplicationConfigEntity here] on begin
		path: '/admin/applicationconfigentity',
		label: getModelDisplayName(Models.ApplicationConfigEntity),
		entity: Models.ApplicationConfigEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for ApplicationConfigEntity here] end
	},
	{
		// % protected region % [Override navigation link for BackgroundJobConfigEntity here] on begin
		path: '/admin/backgroundjobconfigentity',
		label: getModelDisplayName(Models.BackgroundJobConfigEntity),
		entity: Models.BackgroundJobConfigEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for BackgroundJobConfigEntity here] end
	},
	{
		// % protected region % [Override navigation link for BookingCutoffEntity here] on begin
		path: '/admin/bookingcutoffentity',
		label: getModelDisplayName(Models.BookingCutoffEntity),
		entity: Models.BookingCutoffEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for BookingCutoffEntity here] end
	},
	{
		// % protected region % [Override navigation link for BookingEntity here] on begin
		path: '/admin/bookingentity',
		label: getModelDisplayName(Models.BookingEntity),
		entity: Models.BookingEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for BookingEntity here] end
	},
	{
		// % protected region % [Override navigation link for CargoDetailEntity here] on begin
		path: '/admin/cargodetailentity',
		label: getModelDisplayName(Models.CargoDetailEntity),
		entity: Models.CargoDetailEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for CargoDetailEntity here] end
	},
	{
		// % protected region % [Override navigation link for CargoEntity here] on begin
		path: '/admin/cargoentity',
		label: getModelDisplayName(Models.CargoEntity),
		entity: Models.CargoEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for CargoEntity here] end
	},
	{
		// % protected region % [Override navigation link for CargoTypeEntity here] on begin
		path: '/admin/cargotypeentity',
		label: getModelDisplayName(Models.CargoTypeEntity),
		entity: Models.CargoTypeEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for CargoTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for CommunicationsRecordEntity here] on begin
		path: '/admin/communicationsrecordentity',
		label: getModelDisplayName(Models.CommunicationsRecordEntity),
		entity: Models.CommunicationsRecordEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for CommunicationsRecordEntity here] end
	},
	{
		// % protected region % [Override navigation link for ConfigGeneralEntity here] on begin
		path: '/admin/configgeneralentity',
		label: getModelDisplayName(Models.ConfigGeneralEntity),
		entity: Models.ConfigGeneralEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for ConfigGeneralEntity here] end
	},
	{
		// % protected region % [Override navigation link for ConfigThemeEntity here] on begin
		path: '/admin/configthemeentity',
		label: getModelDisplayName(Models.ConfigThemeEntity),
		entity: Models.ConfigThemeEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for ConfigThemeEntity here] end
	},
	{
		// % protected region % [Override navigation link for CustomTicketTypeEntity here] on begin
		path: '/admin/customtickettypeentity',
		label: getModelDisplayName(Models.CustomTicketTypeEntity),
		entity: Models.CustomTicketTypeEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for CustomTicketTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for DefaultPricingEntity here] on begin
		path: '/admin/defaultpricingentity',
		label: getModelDisplayName(Models.DefaultPricingEntity),
		entity: Models.DefaultPricingEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for DefaultPricingEntity here] end
	},
	{
		// % protected region % [Override navigation link for DiscountEntity here] on begin
		path: '/admin/discountentity',
		label: getModelDisplayName(Models.DiscountEntity),
		entity: Models.DiscountEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for DiscountEntity here] end
	},
	{
		// % protected region % [Override navigation link for DynamicPriceEntity here] on begin
		path: '/admin/dynamicpriceentity',
		label: getModelDisplayName(Models.DynamicPriceEntity),
		entity: Models.DynamicPriceEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for DynamicPriceEntity here] end
	},
	{
		// % protected region % [Override navigation link for EmailComponentEntity here] on begin
		path: '/admin/emailcomponententity',
		label: getModelDisplayName(Models.EmailComponentEntity),
		entity: Models.EmailComponentEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for EmailComponentEntity here] end
	},
	{
		// % protected region % [Override navigation link for EmailTemplateEntity here] on begin
		path: '/admin/emailtemplateentity',
		label: getModelDisplayName(Models.EmailTemplateEntity),
		entity: Models.EmailTemplateEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for EmailTemplateEntity here] end
	},
	{
		// % protected region % [Override navigation link for EventBookingDetailEntity here] on begin
		path: '/admin/eventbookingdetailentity',
		label: getModelDisplayName(Models.EventBookingDetailEntity),
		entity: Models.EventBookingDetailEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for EventBookingDetailEntity here] end
	},
	{
		// % protected region % [Override navigation link for EventDetailEntity here] on begin
		path: '/admin/eventdetailentity',
		label: getModelDisplayName(Models.EventDetailEntity),
		entity: Models.EventDetailEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for EventDetailEntity here] end
	},
	{
		// % protected region % [Override navigation link for EventLabelEntity here] on begin
		path: '/admin/eventlabelentity',
		label: getModelDisplayName(Models.EventLabelEntity),
		entity: Models.EventLabelEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for EventLabelEntity here] end
	},
	{
		// % protected region % [Override navigation link for FeeEntity here] on begin
		path: '/admin/feeentity',
		label: getModelDisplayName(Models.FeeEntity),
		entity: Models.FeeEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for FeeEntity here] end
	},
	{
		// % protected region % [Override navigation link for FeeOptionEntity here] on begin
		path: '/admin/feeoptionentity',
		label: getModelDisplayName(Models.FeeOptionEntity),
		entity: Models.FeeOptionEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for FeeOptionEntity here] end
	},
	{
		// % protected region % [Override navigation link for FerryEntity here] on begin
		path: '/admin/ferryentity',
		label: getModelDisplayName(Models.FerryEntity),
		entity: Models.FerryEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for FerryEntity here] end
	},
	{
		// % protected region % [Override navigation link for FerryTripEntity here] on begin
		path: '/admin/ferrytripentity',
		label: getModelDisplayName(Models.FerryTripEntity),
		entity: Models.FerryTripEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for FerryTripEntity here] end
	},
	{
		// % protected region % [Override navigation link for FixedPriceUserEntity here] on begin
		path: '/admin/fixedpriceuserentity',
		label: getModelDisplayName(Models.FixedPriceUserEntity),
		entity: Models.FixedPriceUserEntity,
		isMember: true,
		adminCanSee: true,
		// % protected region % [Override navigation link for FixedPriceUserEntity here] end
	},
	{
		// % protected region % [Override navigation link for FreightCategoryEntity here] on begin
		path: '/admin/freightcategoryentity',
		label: getModelDisplayName(Models.FreightCategoryEntity),
		entity: Models.FreightCategoryEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for FreightCategoryEntity here] end
	},
	{
		// % protected region % [Override navigation link for FreightContactEntity here] on begin
		path: '/admin/freightcontactentity',
		label: getModelDisplayName(Models.FreightContactEntity),
		entity: Models.FreightContactEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for FreightContactEntity here] end
	},
	{
		// % protected region % [Override navigation link for FreightDetailEntity here] on begin
		path: '/admin/freightdetailentity',
		label: getModelDisplayName(Models.FreightDetailEntity),
		entity: Models.FreightDetailEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for FreightDetailEntity here] end
	},
	{
		// % protected region % [Override navigation link for FreightHandlingEntity here] on begin
		path: '/admin/freighthandlingentity',
		label: getModelDisplayName(Models.FreightHandlingEntity),
		entity: Models.FreightHandlingEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for FreightHandlingEntity here] end
	},
	{
		// % protected region % [Override navigation link for FreightTypeEntity here] on begin
		path: '/admin/freighttypeentity',
		label: getModelDisplayName(Models.FreightTypeEntity),
		entity: Models.FreightTypeEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for FreightTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for GiftCertificateEntity here] on begin
		path: '/admin/giftcertificateentity',
		label: getModelDisplayName(Models.GiftCertificateEntity),
		entity: Models.GiftCertificateEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for GiftCertificateEntity here] end
	},
	{
		// % protected region % [Override navigation link for GiftCertificateUsageEntity here] on begin
		path: '/admin/giftcertificateusageentity',
		label: getModelDisplayName(Models.GiftCertificateUsageEntity),
		entity: Models.GiftCertificateUsageEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for GiftCertificateUsageEntity here] end
	},
	{
		// % protected region % [Override navigation link for HazardousFerryRuleEntity here] on begin
		path: '/admin/hazardousferryruleentity',
		label: getModelDisplayName(Models.HazardousFerryRuleEntity),
		entity: Models.HazardousFerryRuleEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for HazardousFerryRuleEntity here] end
	},
	{
		// % protected region % [Override navigation link for InvoicedUserEntity here] on begin
		path: '/admin/invoiceduserentity',
		label: getModelDisplayName(Models.InvoicedUserEntity),
		entity: Models.InvoicedUserEntity,
		isMember: true,
		adminCanSee: true,
		// % protected region % [Override navigation link for InvoicedUserEntity here] end
	},
	{
		// % protected region % [Override navigation link for LanguageEntity here] on begin
		path: '/admin/languageentity',
		label: getModelDisplayName(Models.LanguageEntity),
		entity: Models.LanguageEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for LanguageEntity here] end
	},
	{
		// % protected region % [Override navigation link for LocationEntity here] on begin
		path: '/admin/locationentity',
		label: getModelDisplayName(Models.LocationEntity),
		entity: Models.LocationEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for LocationEntity here] end
	},
	{
		// % protected region % [Override navigation link for ManagerBookingDiscountEntity here] on begin
		path: '/admin/managerbookingdiscountentity',
		label: getModelDisplayName(Models.ManagerBookingDiscountEntity),
		entity: Models.ManagerBookingDiscountEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for ManagerBookingDiscountEntity here] end
	},
	{
		// % protected region % [Override navigation link for ManagerBookingDiscountUsagesEntity here] on begin
		path: '/admin/managerbookingdiscountusagesentity',
		label: getModelDisplayName(Models.ManagerBookingDiscountUsagesEntity),
		entity: Models.ManagerBookingDiscountUsagesEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for ManagerBookingDiscountUsagesEntity here] end
	},
	{
		// % protected region % [Override navigation link for ManagerEntity here] on begin
		path: '/admin/managerentity',
		label: getModelDisplayName(Models.ManagerEntity),
		entity: Models.ManagerEntity,
		isMember: true,
		adminCanSee: true,
		// % protected region % [Override navigation link for ManagerEntity here] end
	},
	{
		// % protected region % [Override navigation link for MeasurementEntity here] on begin
		path: '/admin/measuremententity',
		label: getModelDisplayName(Models.MeasurementEntity),
		entity: Models.MeasurementEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for MeasurementEntity here] end
	},
	{
		// % protected region % [Override navigation link for NotesEntity here] on begin
		path: '/admin/notesentity',
		label: getModelDisplayName(Models.NotesEntity),
		entity: Models.NotesEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for NotesEntity here] end
	},
	{
		// % protected region % [Override navigation link for PassengerDetailEntity here] on begin
		path: '/admin/passengerdetailentity',
		label: getModelDisplayName(Models.PassengerDetailEntity),
		entity: Models.PassengerDetailEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for PassengerDetailEntity here] end
	},
	{
		// % protected region % [Override navigation link for PassengerEntity here] on begin
		path: '/admin/passengerentity',
		label: getModelDisplayName(Models.PassengerEntity),
		entity: Models.PassengerEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for PassengerEntity here] end
	},
	{
		// % protected region % [Override navigation link for PassengerTypeEntity here] on begin
		path: '/admin/passengertypeentity',
		label: getModelDisplayName(Models.PassengerTypeEntity),
		entity: Models.PassengerTypeEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for PassengerTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for PopupEntity here] on begin
		path: '/admin/popupentity',
		label: getModelDisplayName(Models.PopupEntity),
		entity: Models.PopupEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for PopupEntity here] end
	},
	{
		// % protected region % [Override navigation link for PriceEntity here] on begin
		path: '/admin/priceentity',
		label: getModelDisplayName(Models.PriceEntity),
		entity: Models.PriceEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for PriceEntity here] end
	},
	{
		// % protected region % [Override navigation link for RouteEntity here] on begin
		path: '/admin/routeentity',
		label: getModelDisplayName(Models.RouteEntity),
		entity: Models.RouteEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for RouteEntity here] end
	},
	{
		// % protected region % [Override navigation link for SegmentEntity here] off begin
		path: '/admin/segmententity',
		label: getModelDisplayName(Models.SegmentEntity),
		entity: Models.SegmentEntity,
		isMember: false,
		// % protected region % [Override navigation link for SegmentEntity here] end
	},
	{
		// % protected region % [Override navigation link for SmsConfigEntity here] on begin
		path: '/admin/smsconfigentity',
		label: getModelDisplayName(Models.SmsConfigEntity),
		entity: Models.SmsConfigEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for SmsConfigEntity here] end
	},
	{
		// % protected region % [Override navigation link for SmsDeliveryRecordEntity here] on begin
		path: '/admin/smsdeliveryrecordentity',
		label: getModelDisplayName(Models.SmsDeliveryRecordEntity),
		entity: Models.SmsDeliveryRecordEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for SmsDeliveryRecordEntity here] end
	},
	{
		// % protected region % [Override navigation link for SmsTemplateEntity here] on begin
		path: '/admin/smstemplateentity',
		label: getModelDisplayName(Models.SmsTemplateEntity),
		entity: Models.SmsTemplateEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for SmsTemplateEntity here] end
	},
	{
		// % protected region % [Override navigation link for SpacingAddOnEntity here] on begin
		path: '/admin/spacingaddonentity',
		label: getModelDisplayName(Models.SpacingAddOnEntity),
		entity: Models.SpacingAddOnEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for SpacingAddOnEntity here] end
	},
	{
		// % protected region % [Override navigation link for StaffEntity here] on begin
		path: '/admin/staffentity',
		label: getModelDisplayName(Models.StaffEntity),
		entity: Models.StaffEntity,
		isMember: true,
		adminCanSee: true,
		// % protected region % [Override navigation link for StaffEntity here] end
	},
	{
		// % protected region % [Override navigation link for StopEntity here] off begin
		path: '/admin/stopentity',
		label: getModelDisplayName(Models.StopEntity),
		entity: Models.StopEntity,
		isMember: false,
		// % protected region % [Override navigation link for StopEntity here] end
	},
	{
		// % protected region % [Override navigation link for TicketEntity here] on begin
		path: '/admin/ticketentity',
		label: getModelDisplayName(Models.TicketEntity),
		entity: Models.TicketEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for TicketEntity here] end
	},
	{
		// % protected region % [Override navigation link for TowOnDetailEntity here] on begin
		path: '/admin/towondetailentity',
		label: getModelDisplayName(Models.TowOnDetailEntity),
		entity: Models.TowOnDetailEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for TowOnDetailEntity here] end
	},
	{
		// % protected region % [Override navigation link for TowOnEntity here] on begin
		path: '/admin/towonentity',
		label: getModelDisplayName(Models.TowOnEntity),
		entity: Models.TowOnEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for TowOnEntity here] end
	},
	{
		// % protected region % [Override navigation link for TowOnTypeEntity here] on begin
		path: '/admin/towontypeentity',
		label: getModelDisplayName(Models.TowOnTypeEntity),
		entity: Models.TowOnTypeEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for TowOnTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for TransactionEntity here] on begin
		path: '/admin/transactionentity',
		label: getModelDisplayName(Models.TransactionEntity),
		entity: Models.TransactionEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for TransactionEntity here] end
	},
	{
		// % protected region % [Override navigation link for TripStopEntity here] off begin
		path: '/admin/tripstopentity',
		label: getModelDisplayName(Models.TripStopEntity),
		entity: Models.TripStopEntity,
		isMember: false,
		// % protected region % [Override navigation link for TripStopEntity here] end
	},
	{
		// % protected region % [Override navigation link for UploadedImageEntity here] on begin
		path: '/admin/uploadedimageentity',
		label: getModelDisplayName(Models.UploadedImageEntity),
		entity: Models.UploadedImageEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for UploadedImageEntity here] end
	},
	{
		// % protected region % [Override navigation link for UserDisplayNameEntity here] on begin
		path: '/admin/userdisplaynameentity',
		label: getModelDisplayName(Models.UserDisplayNameEntity),
		entity: Models.UserDisplayNameEntity,
		isMember: false,
		adminCanSee: true,
		// % protected region % [Override navigation link for UserDisplayNameEntity here] end
	},
	{
		// % protected region % [Override navigation link for UserEntity here] on begin
		path: '/admin/userentity',
		label: getModelDisplayName(Models.UserEntity),
		entity: Models.UserEntity,
		isMember: true,
		adminCanSee: true,
		// % protected region % [Override navigation link for UserEntity here] end
	},
	{
		// % protected region % [Override navigation link for WaitlistItemEntity here] on begin
		path: '/admin/waitlistitementity',
		label: getModelDisplayName(Models.WaitlistItemEntity),
		entity: Models.WaitlistItemEntity,
		isMember: false,
		adminCanSee: false,
		// % protected region % [Override navigation link for WaitlistItemEntity here] end
	},
	// % protected region % [Add any extra page links here] off begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return (
			<Navigation
				className="nav__admin"
				orientation={Orientation.VERTICAL}
				linkGroups={this.getAdminNavLinks()}
				{...this.props}
			/>
		);
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		const userLinks = links.filter(link => link.isMember).filter(this.filter);
		let entityLinks = links.filter(link => !link.isMember).filter(this.filter);
		const linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] on begin
		userLinks.length = 0;

		if (!store.isSuper) {
			entityLinks = entityLinks.filter(x => x.adminCanSee);
		}
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{
				path: '/admin',
				label: 'Home',
				icon: 'home',
				iconPos: 'icon-left',
			},
			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{ path: '/admin/user', label: 'All Users' },
						...userLinks.map(link => ({ path: link.path, label: link.label })),
					],
				},
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						};
					}),
				},
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] on begin
		entityLinkGroup.unshift(
			{
				path: '/admin/user',
				label: 'Users',
				icon: 'person-group',
				iconPos: 'icon-left',
			},
		);
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];

		// % protected region % [Add any additional links to otherLinkGroup here] off begin
		// % protected region % [Add any additional links to otherLinkGroup here] end

		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			// % protected region % [Modify the utility link group here] on begin
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false,
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
				],
			},
			// % protected region % [Modify the utility link group here] end
		);

		// % protected region % [Update the logout link here] off begin
		bottomlinkGroup.push(
			{
				path: '/logout',
				label: 'Logout',
				icon: 'room',
				iconPos: 'icon-left',
			},
		);
		// % protected region % [Update the logout link here] end

		// % protected region % [Add any additional links to bottomlinkGroup here] off begin
		// % protected region % [Add any additional links to bottomlinkGroup here] end

		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] off begin
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}
