import * as React from 'react';
import { useState } from 'react';
import passengerTypeStore from 'Models/PassengerTypeStore';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';
import { PassengerTypeKey } from 'Models/Enums';
import { isNotNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { appConfigStore } from 'Models/AppConfigStore';
import { PassengersInfo } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import moment from 'moment';
import If from 'Views/Components/If/If';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';

export function PassengerInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	usePassengerTypes();

	const [openItems, setOpenItems] = useState({});
	const [showMore, setShowMore] = useState(false);

	const toggleCollapse = (index: number) => {
		setOpenItems(prevState => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const description = passengerTypeStore.getPassengerInfoFromBookingSummary(booking.bookedSummary);
	const passengers = booking.GetAllPassengerInfosFromBookedSummary();

	const hasMoreInfo = ((passenger: PassengersInfo) => {
		return isNotNullOrUndefined(passenger.age)
			|| stringNotEmpty(passenger.email)
			|| stringNotEmpty(passenger.phone)
			|| isNotNullOrUndefined(passenger.dateOfBirth)
			|| stringNotEmpty(passenger.address)
			|| stringNotEmpty(passenger.gender);
	});

	const sectionContent = (
		<p className="passengers-on-trip">
			{description}
			{passengers.length > 0 && appConfigStore.passengerInfoEnabled && (
				<div className="passenger-info-list__container">
					{passengers.map((passenger, index) => (
						<If condition={showMore || index < 3}>
							<div className="passenger-info__container">
								<Button
									className="booking-detail-section__expand-button"
									onClick={() => toggleCollapse(index)}
									icon={{
										// eslint-disable-next-line no-nested-ternary
										icon: hasMoreInfo(passenger)
											? (openItems[index]
												? 'chevron-up'
												: 'chevron-down')
											: undefined,
										iconPos: 'icon-bottom',
									}}
									display={Display.Text}
									colors={Colors.Black}
									sizes={Sizes.ExtraSmall}
								/>
								<div className="passenger-info__content">
									<p className="passenger-info__name">
										{passenger.firstName} {passenger.lastName} <span>{passengerTypeStore.getSingularName(passenger.key as PassengerTypeKey)}</span>
									</p>
									{openItems[index] && (
										<div className="passenger-info__more-details">
											<p>{isNotNullOrUndefined(passenger.age) ? `${passenger.age} years old` : null}</p>
											<p>{passenger.email}</p>
											<p>{passenger.phone}</p>
											<p>{isNotNullOrUndefined(passenger.dateOfBirth)
												? moment(passenger.dateOfBirth).format('DD/MM/YYYY')
												: null}
											</p>
											<p>{passenger.address}</p>
											<p>{passenger.gender}</p>
										</div>
									)}
								</div>
							</div>
						</If>
					))}
					<If condition={passengers.length > 3}>
						<Button
							className="see-more__button"
							onClick={() => setShowMore(!showMore)}
							display={Display.Text}
							colors={Colors.Secondary}
						>
							{showMore ? 'Show less' : 'Show more'}
						</Button>
					</If>
				</div>
			)}
		</p>
	);

	return (
		<BookingDetailSection
			sectionName="Passengers"
			sectionContent={sectionContent}
			sectionClassName="passengers"
			icon={{
				name: 'person',
				classname: 'person',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'passenger',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
