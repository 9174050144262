import React from 'react';
import classNames from 'classnames';

import { LegOptionContext, LegOptionContextProps } from './Contexts/LegOptionContext';
import LegOptionCargoCapacity from './LegOptionCargoCapacity';
import LegOptionChangeSelection from './LegOptionChangeSelection';
import LegOptionContainer from './LegOptionContainer';
import LegOptionJourneyInfo from './LegOptionJourneyInfo';
import LegOptionPaxCapacity from './LegOptionPaxCapacity';
import LegOptionPrice from './LegOptionPrice';
import LegOptionSelectedIndicator from './LegOptionSelectedIndicator';
import LegOptionTrailerCapacity from './LegOptionTrailerCapacity';
import LegOptionWaitlist from './LegOptionWaitlist';
import LegOptionVoyageNumber from './LegOptionVoyageNumber';

export default function LegOptionWeb() {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionWeb');
		return null;
	}

	const { isSelected, errorMessage } = context;

	return <LegOptionWebInner isSelected={isSelected} errorMessage={errorMessage} />;
}

interface LegOptionWebInnerProps extends Pick<LegOptionContextProps, 'isSelected' | 'errorMessage'> {}

export function LegOptionWebInner({ isSelected, errorMessage }: LegOptionWebInnerProps) {
	return (
		<>
			<LegOptionContainer>
				<div
					className={classNames('leg-option__journey-container', {
						'leg-option__journey-container--with-bg-gradient': isSelected,
						'leg-option__journey-container--with-bg-grey': !isSelected,
					})}
				>
					{/* FUTURE : Voyage number needs to be populated and enabled as a feature */}
					{/* <LegOptionVoyageNumber>FY 01417</LegOptionVoyageNumber> */}
					<LegOptionJourneyInfo />
				</div>
				<div className="leg-option__info-container">
					<div className="leg-option__space-container">
						{!!errorMessage && (
							<>
								<div className="leg-option__error-msg">{errorMessage}</div>
								<LegOptionWaitlist />
							</>
						)}
						{!errorMessage && (
							<>
								<div className="leg-option__space-text">Spaces left:</div>
								<div className="leg-option__space-tag-container">
									<LegOptionPaxCapacity />
									<LegOptionCargoCapacity />
									<LegOptionTrailerCapacity />
								</div>
							</>
						)}
					</div>
					<LegOptionPrice />
					<LegOptionSelectedIndicator className="leg-option__selection-container--web" />
				</div>
			</LegOptionContainer>
			<LegOptionChangeSelection />
		</>
	);
}
