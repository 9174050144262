import React from 'react';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import If from 'Views/Components/If/If';
import { wizardModeOptions } from 'Models/Enums';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { BookingTypeButtons } from './BookingTypeButtons';
import { PassengerTypeInputs } from './PassengerTypeInputs';
import { FerryFilterSubheading } from './FerryFilterSubheading';

export type UpdateWizardData = (wizardData: BookingWizardData) => void;

export interface FerryFilterPassengersProps {
	wizardData: BookingWizardData;
	onUpdateFilters: UpdateWizardData;
	excludeBookingTypeButton?: boolean;
}

export function FerryFilterPassengers({
	wizardData,
	onUpdateFilters,
	excludeBookingTypeButton,
}: FerryFilterPassengersProps) {
	const {
		vehicleTripSelectionButtonLabel: vehicleButtonLabel,
		passengerTripSelectionButtonLabel: passengerButtonLabel,
		vehicleTripSelectionButtonSubheading: vehicleButtonSubheading,
		passengerTripSelectionButtonSubheading: passengerButtonSubheading,
		trailersEnabled,
		vehicleBookingRequiresAdult,
	} = whiteLabelStore.config;

	const {
		tabSelected,
		wizardMode,
		trailerCheckboxSelected,
	} = wizardData;

	const editMode = wizardMode === wizardModeOptions.ALTERATION;
	const onVehicleTab = tabSelected === 'vehicle';
	const onPassengerTab = tabSelected === 'passenger';

	return (
		<div className="passenger-details__container">
			<If condition={excludeBookingTypeButton !== true}>
				<BookingTypeButtons
					wizardData={wizardData}
					onVehicleTab={onVehicleTab}
					onPassengerTab={onPassengerTab}
					onUpdateFilters={onUpdateFilters}
					vehicleButtonLabel={vehicleButtonLabel}
					passengerButtonLabel={passengerButtonLabel}
					editMode={editMode}
				/>
			</If>
			<FerryFilterSubheading
				wizardData={wizardData}
				onUpdateFilters={onUpdateFilters}
				onPassengerTab={onPassengerTab}
				onVehicleTab={onVehicleTab}
				passengerButtonSubheading={passengerButtonSubheading}
				vehicleButtonSubheading={vehicleButtonSubheading}
				vehicleBookingRequiresAdult={vehicleBookingRequiresAdult}
				trailersEnabled={trailersEnabled}
				trailerCheckboxSelected={trailerCheckboxSelected}
			/>
			<If condition={onPassengerTab || (vehicleBookingRequiresAdult && onVehicleTab)}>
				<PassengerTypeInputs
					wizardData={wizardData}
					onUpdateFilters={onUpdateFilters}
				/>
			</If>
		</div>
	);
}
