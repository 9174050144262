import { whiteLabelStore } from 'Models/WhiteLabelStore';

export const useIsSoldOut = (bookingType: 'vehicle' | 'passenger', paxSpaces: number, vehicleSpaces: number) => {
	if (bookingType === 'vehicle') {
		if (vehicleSpaces < 1) {
			return true;
		}
		if (whiteLabelStore.minAdultsForVehicle === 0) {
			return true;
		}
	}

	return paxSpaces < 1;
};
