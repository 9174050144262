import { useMemo } from 'react';

import { StopEntity } from 'Models/Entities';
import { journeyElementsStore } from 'Global/JourneyElements/JourneyElementsStore';
import { sortByOrder } from 'Global/JourneyElements/Utils/sortByOrder';

interface StopsBetween {
	stops: StopEntity[];
	count: number;
}

export const useStopsBetween = (startStopId: string, endStopId: string): StopsBetween => {
	const { isLoading, stops } = journeyElementsStore();

	const stopsBetween = useMemo<StopEntity[]>(() => {
		if (stops.length === 0 || isLoading) {
			return [];
		}

		const startStop = stops.find(x => x.id === startStopId);
		const endStop = stops.find(x => x.id === endStopId);

		if (!startStop || !endStop) {
			console.error('Cannot find stop', { startStopId, endStopId });
			return [];
		}

		if (startStop.routeId !== endStop.routeId) {
			console.error('Route does not match for start/end stop', { startStopId, endStopId });
			return [];
		}

		const routeStops = stops
			.filter(x => x.routeId === startStop.routeId)
			.slice()
			.sort(sortByOrder());

		return routeStops.filter(x => x.order > startStop.order && x.order < endStop.order);
	}, [startStopId, endStopId, stops, isLoading]);

	return { stops: stopsBetween, count: stopsBetween.length };
};
