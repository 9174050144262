import React from 'react';

import './skeleton.scss';

interface SkeletonProps {
	width?: string;
	height?: string;
	borderRadius?: string;
}

export default function Skeleton({ width, height, borderRadius }: SkeletonProps) {
	return (
		<div
			className="skeleton"
			style={{
				width: width || '100%',
				height: height || '20px',
				borderRadius: borderRadius || '4px',
			}}
		/>
	);
}
