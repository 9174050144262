import * as React from 'react';
import { observer } from 'mobx-react';
import { FerryTripEntity } from 'Models/Entities';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import PassengerTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/PassengerTable';
import MeasurementsTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/MeasurementsTable';
import If from 'Views/Components/If/If';
import { useEffect, useState } from 'react';
import { observable } from 'mobx';
import { stringNotEmpty } from 'Util/TypeGuards';
import { Toggle } from 'Views/Components/_HumanWritten/Toggle/Toggle';
import { appConfigStore } from '../../../../../Models/AppConfigStore';

export interface IPriceTabProps {
	ferryTrip: FerryTripEntity;
	isDisabled: boolean;
}

export const vehicleTrailerTitle = () => {
	let title = `${whiteLabelStore.vehicleLabelPascalCase}`;
	if (whiteLabelStore.config.trailersEnabled) {
		title += ' / Trailer';
	}
	return title;
};

function FerryTripPriceTabComponent({
	ferryTrip,
	isDisabled,
}: IPriceTabProps) {
	usePassengerTypes();
	const [incrementalPricing, setIncrementalPricing] = useState<boolean>(false);

	const [state] = useState(() => observable({
		model: {
			useIncrementPricing: false,
			lengthBasePrice: '',
			lengthIncrementPrice: '',
			weightBasePrice: '',
			weightIncrementPrice: '',
		},
	}));

	// This useEffect will apply all the pricing to each measurement type
	// if the conditions are met for incremental pricing.
	useEffect(() => {
		if (stringNotEmpty(state.model.lengthBasePrice) && stringNotEmpty(state.model.lengthIncrementPrice)) {
			let price = parseInt(state.model.lengthBasePrice, 10);
			ferryTrip.prices
				.filter(x => x.measurement.measurementType === 'LENGTH')
				.sort((a, b) => a.measurement.value - b.measurement.value)
				.forEach(x => {
					x.amount = price;
					price += parseInt(state.model.lengthIncrementPrice, 10);
				});
		}

		if (stringNotEmpty(state.model.weightBasePrice) && stringNotEmpty(state.model.weightIncrementPrice)) {
			let price = parseInt(state.model.weightBasePrice, 10);
			ferryTrip.prices
				.filter(x => x.measurement.measurementType === 'WEIGHT')
				.sort((a, b) => a.measurement.value - b.measurement.value)
				.forEach(x => {
					x.amount = price;
					price += parseInt(state.model.weightIncrementPrice, 10);
				});
		}
	}, [
		state.model.lengthBasePrice,
		state.model.lengthIncrementPrice,
		state.model.weightBasePrice,
		state.model.weightIncrementPrice,
	]);

	return (
		<div className="custom-sidebar__tab-content pricing-tab">
			<h6>Passenger</h6>
			<PassengerTable model={ferryTrip} isReadOnly={isDisabled} />
			<If condition={appConfigStore.vehicleEnabled}>
				<div className="custom-sidebar__tab-content__header">
					<h6 className="vehicle-trailer-title">{vehicleTrailerTitle()}</h6>
					<If condition={!isDisabled}>
						<Toggle
							className="ferry-trip-pricing-toggle"
							id="ferry-trip-pricing-toggle"
							model={state.model}
							modelProperty="useIncrementPricing"
							labelVisible
							labelOn="Increment pricing"
							labelOff="Standard pricing"
							onAfterChecked={(e, checked) => {
								setIncrementalPricing(checked);
							}}
						/>
					</If>
				</div>
				<If condition={!incrementalPricing}>
					<MeasurementsTable model={ferryTrip} isReadOnly={isDisabled} />
				</If>
				<If condition={incrementalPricing}>
					<div className="increment-pricing-section">
						<NumberTextField
							id="length-base-price"
							model={state.model}
							modelProperty="lengthBasePrice"
							label="Length base price"
							isDisabled={isDisabled}
							placeholder="0"
						/>
						<NumberTextField
							id="length-increment-amount"
							model={state.model}
							modelProperty="lengthIncrementPrice"
							label="Length increment amount"
							isDisabled={isDisabled}
							placeholder="0"
						/>
					</div>
					<div className="increment-pricing-section">
						<NumberTextField
							id="weight-base-price"
							model={state.model}
							modelProperty="weightBasePrice"
							label="Weight base price"
							isDisabled={isDisabled}
							placeholder="0"
						/>
						<NumberTextField
							id="weight-increment-amount"
							model={state.model}
							modelProperty="weightIncrementPrice"
							label="Weight increment amount"
							isDisabled={isDisabled}
							placeholder="0"
						/>
					</div>
				</If>
			</If>
		</div>
	);
}

export default observer(FerryTripPriceTabComponent);
