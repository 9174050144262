import * as React from 'react';
import { store } from 'Models/Store';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { PassengersInfo } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import If from 'Views/Components/If/If';
import { Combobox } from 'Views/Components/Combobox/Combobox';

export interface PassengerRemovalModalProps {
	model: {
		passengerToRemoveId: string;
		error: string;
	};
	passengers: PassengersInfo[];
}

export const PassengerRemovalModalContent = observer(({
	model,
	passengers,
}: PassengerRemovalModalProps) => {
	return (
		<div className="passenger-removal-modal-content">
			<p>Choose the passenger you would like to remove from the booking.</p>
			<Combobox
				label="Passenger to remove"
				placeholder="Search for a passenger"
				model={model}
				modelProperty="passengerToRemoveId"
				options={passengers.map(x => {
					return {
						display: `${x.firstName} ${x.lastName}`,
						value: x.id,
					};
				})}
				isRequired
			/>
			<If condition={!stringIsEmpty(model.error)}>
				<div className="error-message">{model.error}</div>
			</If>
		</div>
	);
});

export const RenderPassengerRemovalModal = async (
	passengers: PassengersInfo[],
): Promise<string | null> => {
	const { isManager } = store;

	const model = observable({
		passengerToRemoveId: '',
		error: '',
	});

	const confirmed = await modalWithCustomInput(
		'Remove passenger',
		(
			<PassengerRemovalModalContent
				model={model}
				passengers={passengers}
			/>
		),
		{
			cancelText: 'Back',
			confirmText: 'Confirm',
			actionClassName: 'passenger-removal-modal',
			resolveOnCancel: true,
			onConfirm: action(() => {
				if (stringNotEmpty(model.passengerToRemoveId)) {
					model.error = '';
					return true;
				}

				model.error = 'Please select a passenger.';
				return false;
			}),
		},
	);

	if (!confirmed) {
		return null;
	}

	return model.passengerToRemoveId;
};
