import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Redirect } from 'react-router';
import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { upperCaseFirst } from 'Util/StringUtils';
import alertToast from 'Util/ToastifyUtils';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { WhiteBackground } from 'Views/Components/_HumanWritten/WhiteBackground';
import If from 'Views/Components/If/If';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import {
	Button,
} from 'Views/Components/Button/Button';
import {
	BOOKING_DETAILED_VIEW_PAGE_STAFF_TITLE,
	BOOKING_DETAILED_VIEW_PAGE_CUSTOMER_TITLE,
} from 'ConstantPageNames';
import {
	getTotalPassengerCountFromBooking,
} from 'Util/_HumanWritten/BookingEntity/GetTotalPassengerCount';
import { Link } from 'react-router-dom';
import { canEditBooking } from './Helpers/CanEditBooking';
import { GetInformationBox, StateOfBooking } from './Helpers/GetInformationBox';
import { CustomerInfoSection } from './SharedSections/CustomerInfoSection';
import BookingDetailsQrCode from './BookingDetailsQrCode';
import { EventDateTimeInfoSection } from './EventBookingDetailedView.tsx/Sections/EventDateTimeInfoSection';
import { TripTimeInfoSection } from './SharedSections/TripTimeInfoSection';
import { onEdit } from './Helpers/OnEdit';
import { PassengerInfoSection } from './FerryBookingDetailedView/Sections/PassengerInfoSection';
import {
	CustomTicketInfoSection,
	getCustomTicketInfo,
} from './EventBookingDetailedView.tsx/Sections/CustomTicketInfoSection';
import { VehicleInfoSection } from './FerryBookingDetailedView/Sections/VehicleInfoSection';
import { AdditionalNotesInfoSection } from './FerryBookingDetailedView/Sections/AdditionalNotesInfoSection';
import {
	AdditionalBookingOptionsInfoSection,
} from './FerryBookingDetailedView/Sections/AdditionalBookingOptionsInfoSection';
import { BookingNotesModal } from './NotesModal/BookingNotesModal';
import BookingActionButtons from './BookingActionButtons';
import { useLocationNamesFromBookingSummary } from './Hooks/useLocationNamesFromBookingSummary';

export interface BookingDetailedViewInnerProps {
	booking: BookingEntity;
	refresh: () => void;
}

function BookingDetailedViewInner({
	booking,
	refresh,
}: BookingDetailedViewInnerProps) {
	// Set document title
	document.title = BOOKING_DETAILED_VIEW_PAGE_CUSTOMER_TITLE;
	if (store.isStaff) {
		document.title = BOOKING_DETAILED_VIEW_PAGE_STAFF_TITLE;
	}

	const { isIpad } = useDeviceDetect();
	const { isInvoiced } = booking;

	const { startLocationName, endLocationName } = useLocationNamesFromBookingSummary(booking.bookedSummary);

	// IMPORTANT - we need booked summary for this component to work
	if (isNullOrUndefined(booking.bookedSummary)) {
		alertToast(
			'Booking not found',
			'error',
			undefined,
			{ autoClose: 3000 },
		);
		return <Redirect to="/404" />;
	}

	const isEventBooking = isNotNullOrUndefined(booking.bookedSummary.eventId);
	const canEdit = canEditBooking(booking);
	const isDeparted = booking.bookedSummary.ferryTrip.departureDateTime < new Date();
	const isClosed = booking.bookedSummary.ferryTrip.closed;
	const { checkedIn } = booking;

	let stateOfBooking: StateOfBooking = 'standard';
	if (checkedIn) {
		stateOfBooking = 'checked-in';
	} else if (isInvoiced) {
		stateOfBooking = 'invoiced';
	} else if (isClosed) {
		stateOfBooking = 'closed';
	}

	// True if the current booking is the departure leg of a two way booking.
	// False means current booking is the return leg of a two way booking OR has no associated booking.
	const bookingIsDepartureOfTwoWayBooking = (
		isNotNullOrUndefined(booking.returnBooking)
		&& isNullOrUndefined(booking.returnBookingFor)
	) || (
		isNullOrUndefined(booking.returnBooking)
		&& isNullOrUndefined(booking.returnBookingFor)
	);

	const returnBookingId = booking.returnBooking?.id ?? booking.returnBookingForId;
	const ferryTrip = new FerryTripEntity(booking.bookedSummary.ferryTrip);

	return (
		<WhiteBackground
			content={(
				<div className={`booking-detailed-view__container ${store.isStaff ? 'bottom-padding' : ''}`}>
					<div className="navigation-header">
						<Button
							className="back-navigation hide-underline icon-chevron-left icon-left booking-detailed-view__back-button"
							icon={{ icon: 'arrow-left', iconPos: 'icon-left' }}
							onClick={() => {
								// TODO fix this (or it navigates back to booking wizard
								store.routerHistory.goBack();
							}}
						>
							Back
						</Button>
					</div>
					<div className="booking-detailed-view__header">
						<h6 className={`booking-status ${booking.transactionStatus?.toLowerCase()}-status`}>
							{booking.transactionStatus && upperCaseFirst(booking.transactionStatus === 'PAID'
								? 'BOOKED'.toLowerCase()
								: booking.transactionStatus.toLowerCase())}
						</h6>
						<If condition={!isEventBooking}>
							<h5 className={`${
								(booking.returnBooking !== null || booking.returnBookingForId === null)
									? 'icon-next'
									: 'icon-back'} icon-left`}
							>
								{startLocationName} to {endLocationName}
							</h5>
						</If>
						<If condition={isEventBooking}>
							<h5 className="event-booking__header">
								<img
									className="event-icon"
									src={`/api/files/${ferryTrip.eventDetail?.eventLabel?.iconId}`}
									alt="event-icon"
								/>
								{ferryTrip?.getEventName()}
							</h5>
						</If>
						<p className="booking-created-date">
							{`Booking created on ${moment(booking.created).format('DD MMM YYYY')}`}
						</p>
					</div>
					<div className="booking-details__breakdown">
						<If condition={!isDeparted && (checkedIn || isClosed || isInvoiced)
							&& stringNotEmpty(stateOfBooking)}
						>
							{GetInformationBox(stateOfBooking)}
						</If>
						<div className="booking-detailed-view__subheader">
							<h6 className="booking-detailed-view__booking-id">ID #{booking?.humanReadableId}</h6>
							<h6 className="booking-detailed-view__price">
								{formatPriceDisplay(booking?.bookedSummary.totalCost, false)}
							</h6>
						</div>
						<If condition={store.isStaff || store.isManager || store.isAdmin}>
							<CustomerInfoSection booking={booking} canEdit={false} onEdit={() => {}} />
						</If>
						<If condition={booking.bookingStatus !== undefined && booking.bookingStatus !== 'CANCELLED'}>
							<BookingDetailsQrCode
								bookingId={booking.id}
								isEventBooking={isEventBooking}
							/>
						</If>
						<If condition={isNotNullOrUndefined(returnBookingId)}>
							<Link
								className="view-return-ticket__button icon-issue"
								to={`/bookings/${returnBookingId}`}
								onClick={() => refresh()}
							>
								{`View ${isNotNullOrUndefined(booking.returnBooking)
									? 'returning'
									: 'departing'
								} ticket`}
							</Link>
						</If>
						<If condition={isEventBooking}>
							<EventDateTimeInfoSection
								booking={booking}
								canEdit={false}
								onEdit={() => {}}
							/>
						</If>
						<If condition={!isEventBooking}>
							<TripTimeInfoSection
								booking={booking}
								canEdit={canEdit}
								onEdit={editType => onEdit(
									booking,
									editType,
									bookingIsDepartureOfTwoWayBooking,
									isIpad,
								)}
								isInvoiced={isInvoiced}
							/>
						</If>
						<If condition={!isEventBooking && getTotalPassengerCountFromBooking(booking) > 0}>
							<PassengerInfoSection
								booking={booking}
								canEdit={canEdit}
								onEdit={editType => onEdit(
									booking,
									editType,
									bookingIsDepartureOfTwoWayBooking,
									isIpad,
								)}
								isInvoiced={isInvoiced}
							/>
						</If>
						<If condition={isEventBooking && getCustomTicketInfo(booking.bookedSummary) !== null}>
							<CustomTicketInfoSection
								booking={booking}
								canEdit={false}
								onEdit={() => {}}
								isInvoiced={isInvoiced}
							/>
						</If>
						<If condition={isNotNullOrUndefined(booking.bookedSummary.cargoInfo)}>
							<VehicleInfoSection
								booking={booking}
								canEdit={canEdit}
								onEdit={editType => onEdit(
									booking,
									editType,
									bookingIsDepartureOfTwoWayBooking,
									isIpad,
								)}
								isInvoiced={isInvoiced}
							/>
						</If>
						<If condition={whiteLabelStore.bookingNoteEnabled && stringNotEmpty(booking.bookedSummary?.note)}>
							<AdditionalNotesInfoSection
								booking={booking}
								canEdit={canEdit}
								onEdit={editType => onEdit(
									booking,
									editType,
									bookingIsDepartureOfTwoWayBooking,
									isIpad,
								)}
							/>
						</If>
						<If
							condition={
								isNotNullOrUndefined(booking.bookedSummary.additionalBookingOptions)
								&& booking.bookedSummary.additionalBookingOptions
									.filter(x => x.quantity > 0).length > 0
							}
						>
							<AdditionalBookingOptionsInfoSection
								booking={booking}
								canEdit={canEdit}
								onEdit={editType => onEdit(
									booking,
									editType,
									bookingIsDepartureOfTwoWayBooking,
									isIpad,
								)}
								isInvoiced={isInvoiced}
							/>
						</If>
					</div>
					<BookingActionButtons
						booking={booking}
						canEdit={canEdit}
						customStatus={booking.transactionStatus}
						refresh={refresh}
						isEventBooking={isEventBooking}
					/>
					<If condition={store.isStaff || store.isInvoicedUser}>
						<BookingNotesModal booking={booking} />
					</If>
				</div>
			)}
		/>
	);
}

export default observer(BookingDetailedViewInner);
