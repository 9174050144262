/* eslint-disable object-curly-newline */
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

import { devLog } from 'Util/_HumanWritten/ConsoleLogUtils';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

const isSameDay = (d1: Date, d2: Date) => {
	const date1 = dayjs(d1).format('YYYY-MM-DD');
	const date2 = dayjs(d2).format('YYYY-MM-DD');
	return date1 === date2;
};

/**
 * Handles ticket deselection when ferry filter dates (depart/return) are changed.
 * Ensures the selected ticket aligns with the selected calendar tab.
 */
export const useTicketDeselectionForFilterDates = (
	wizardData: BookingWizardData,
	saveChanges: (newData: BookingWizardData) => void,
	selectedDepartingDate?: Date,
	selectedReturningDate?: Date,
) => {
	const { startDate, endDate, ticketSelectionStartDate, ticketSelectionEndDate } = wizardData;

	const firstTime = useRef(true);

	useEffect(() => {
		if (firstTime.current) {
			return;
		}
		const newData = { ...wizardData };

		if (newData.departureTicketId) {
			if (selectedDepartingDate) {
				// const d1 = dayjs(ticketSelectionStartDate).format('YYYY-MM-DD');
				// const d2 = dayjs(selectedDepartingDate).format('YYYY-MM-DD');
				// console.log('d1', d1, 'd2', d2);
				if (wizardData.departureTrip === false) {
					// altering only return leg of two way booking uses departing journey
					// need to look at return date
					if (!isSameDay(ticketSelectionEndDate, selectedDepartingDate)) {
						newData.departureTicketId = '';
						newData.departingJourney = undefined;
						devLog('DEV0001: Deselected departing ticket');
					}
				} else if (!isSameDay(ticketSelectionStartDate, selectedDepartingDate)) {
					newData.departureTicketId = '';
					newData.departingJourney = undefined;
					devLog('DEV0002: Deselected departing ticket');
				}
			}
		}

		if (newData.returningTicketId) {
			if (selectedReturningDate) {
				// const d1 = dayjs(ticketSelectionStartDate).format('YYYY-MM-DD');
				// const d2 = dayjs(selectedDepartingDate).format('YYYY-MM-DD');
				// console.log('d1', d1, 'd2', d2);
				if (!isSameDay(ticketSelectionEndDate, selectedReturningDate)) {
					newData.returningTicketId = '';
					newData.returningJourney = undefined;
					devLog('DEV0003: Deselected returning ticket');
				}
			}
		}
		saveChanges(newData);
	}, [startDate, endDate, selectedDepartingDate, selectedReturningDate, ticketSelectionEndDate]);

	useEffect(() => {
		firstTime.current = false;
	}, []);
};

// Original

// This effect will un-select departing/returning ferry trips that are no longer valid
// when the ferry filters are changed (start and end date only)
// React.useEffect(() => {
// 	const newData = { ...wizardData };
// 	// If DEPARTURE ticket date is not the same as start date, unselect the ticket
// 	// OR if altering the return ticket of a return trip ONLY,
// 	// then unselect the ticket if the date is not the same as the endDate
// 	if ((newData.departureTrip !== false
// 			&& newData.departureTicketId !== ''
// 			&& !isSameDate(wizardData, selectedTrips, true)
// 			&& selectedTrips.departingTrip?.startDate !== undefined)
// 		|| (newData.departureTrip === false
// 			&& !(moment(selectedTrips.departingTrip?.startDate).format('YYYY-MM-DD')
// 				=== moment(wizardData.endDate).format('YYYY-MM-DD'))
// 			&& selectedTrips.departingTrip?.startDate !== undefined)
// 	) {
// 		newData.departureTicketId = '';
// 		// newData.departingJourney = undefined;
// 	}

// 	// If DESTINATION ticket date is not the same as end date, unselect the ticket
// 	if (newData.returningTicketId !== '' && selectedTrips.returningTrip !== undefined
// 		&& !isSameDate(wizardData, selectedTrips, false)
// 	) {
// 		newData.returningTicketId = '';
// 		newData.returningJourney = undefined;
// 	}
// 	// Saving the changes to the wizard data so that the cart is in sync.
// 	saveChanges(newData);
// }, [
// 	saveChanges,
// 	wizardData.startDate,
// 	wizardData.endDate,
// ]);
