import { IApplicationConfigEntityAttributes } from './Entities';
import { upperCaseFirst } from '../Util/StringUtils';
import { whiteLabelStore } from './WhiteLabelStore';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export class AppConfigStore {
	config: IApplicationConfigEntityAttributes[];

	constructor(config: IApplicationConfigEntityAttributes[]) { this.config = config; }

	public isConfigEnabled(key: string) {
		return this.config?.find(x => x.key === key)?.value === 'true';
	}

	public get passengerInfoEnabled() {
		return this.isConfigEnabled('Passenger.passengerInfo.enabled') && (
			this.isConfigEnabled('Passenger.firstname.enabled')
			|| this.isConfigEnabled('Passenger.lastname.enabled')
			|| this.isConfigEnabled('Passenger.phone.enabled')
			|| this.isConfigEnabled('Passenger.email.enabled')
			|| this.isConfigEnabled('Passenger.age.enabled')
			|| this.isConfigEnabled('Passenger.dateofbirth.enabled')
			|| this.isConfigEnabled('Passenger.address.enabled')
			|| this.isConfigEnabled('Passenger.gender.enabled')
		);
	}

	public showPassengerTab(wizardData: BookingWizardData) {
		const { tabSelected } = wizardData;
		const onVehicleTab = tabSelected === 'vehicle';
		const onPassengerTab = tabSelected === 'passenger';
		const { vehicleBookingRequiresAdult } = whiteLabelStore.config;

		return this.passengerInfoEnabled && (onPassengerTab || (vehicleBookingRequiresAdult && onVehicleTab));
	}

	public get passengerFirstNameEnabled() {
		return this.isConfigEnabled('Passenger.firstname.enabled');
	}

	public get passengerFirstNameRequired() {
		return this.isConfigEnabled('Passenger.firstname.required');
	}

	public get passengerLastNameEnabled() {
		return this.isConfigEnabled('Passenger.lastname.enabled');
	}

	public get passengerLastNameRequired() {
		return this.isConfigEnabled('Passenger.lastname.required');
	}

	public get passengerPhoneEnabled() {
		return this.isConfigEnabled('Passenger.phone.enabled');
	}

	public get passengerPhoneRequired() {
		return this.isConfigEnabled('Passenger.phone.required');
	}

	public get passengerEmailEnabled() {
		return this.isConfigEnabled('Passenger.email.enabled');
	}

	public get passengerEmailRequired() {
		return this.isConfigEnabled('Passenger.email.required');
	}

	public get passengerAgeEnabled() {
		return this.isConfigEnabled('Passenger.age.enabled');
	}

	public get passengerAgeRequired() {
		return this.isConfigEnabled('Passenger.age.required');
	}

	public get passengerGenderEnabled() {
		return this.isConfigEnabled('Passenger.gender.enabled') && this.passengerGenderOptions.length > 0;
	}

	public get passengerGenderRequired() {
		return this.isConfigEnabled('Passenger.gender.required');
	}

	public get passengerGenderOptions() {
		return this.config
			?.find(x => x.key === 'Passenger.gender.options')?.value.split(',')
			.map(y => upperCaseFirst(y.toLowerCase())) ?? [];
	}

	public get passengerAddressEnabled() {
		return this.isConfigEnabled('Passenger.address.enabled');
	}

	public get passengerAddressRequired() {
		return this.isConfigEnabled('Passenger.address.required');
	}

	public get passengerDateOfBirthEnabled() {
		return this.isConfigEnabled('Passenger.dateofbirth.enabled');
	}

	public get passengerDateOfBirthRequired() {
		return this.isConfigEnabled('Passenger.dateofbirth.required');
	}

	public get vehicleEnabled() {
		return this.isConfigEnabled('Vehicles.enabled');
	}

	public get oddNumberOfBookingTypes() {
		let count = 1;
		if (this.vehicleEnabled) {
			count++;
		}
		return count % 2 === 1;
	}
}

export const appConfigStore = new AppConfigStore(window.configData);
