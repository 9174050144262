import { Button } from 'Views/Components/Button/Button';
import * as React from 'react';
import { VEHICLE_ICON_DARK_URL } from 'Constants';
import { store } from 'Models/Store';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { TabSelected } from '../FerryTripBookingWizard/BookingWizardData';
import { whiteLabelStore } from 'Models/WhiteLabelStore';

export interface VehicleBookingButtonProps {
	onSearch: (type: TabSelected) => void;
}

export function VehicleBookingButton({
	onSearch,
}: VehicleBookingButtonProps) {
	const { isMobile } = useDeviceDetect();

	const onVehicleClick = () => {
		onSearch('vehicle');

		if (isMobile) {
			store.routerHistory.push('/booking-wizard?type=vehicle');
		} else {
			store.routerHistory.push('/home/search?type=cargo');
		}
	};

	const cargoIcon = (
		<img
			alt="cargo-icon"
			className="cargo__icon"
			src={VEHICLE_ICON_DARK_URL}
		/>
	);

	return (
		<Button
			key="vehicle-booking-button"
			className="booking-type__button hide-underline"
			onClick={onVehicleClick}
		>
			<h5>
				{cargoIcon}
				{whiteLabelStore.vehicleLabelPascalCase ?? 'Vehicle'}
			</h5>
		</Button>
	);
}
