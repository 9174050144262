/* eslint-disable max-len */
import React from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

import { useLocationById } from 'Global/JourneyElements/Hooks/useLocationById';
import { useAbortController } from 'Hooks/useAbortController';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import useStore from 'Hooks/useStore';
import { wizardModeOptions } from 'Models/Enums';
import { store } from 'Models/Store';
import LegOption from 'Modules/MultiStopWizard/Components/Leg/LegOption';
import { useTripSelectionCalendarTabState } from 'Modules/MultiStopWizard/Hooks/useTripSelectionCalendarTabState';
import {
	dataToFerryTripBookingDto,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	GetTicketsTabData,
	TicketsTabStateData,
	TicketsTabTrip,
} from 'Services/Api/_HumanWritten/BookingWizardDataService';
import {
	BasePriceFerryTripsDto,
	FetchBasePriceForFerryTrips,
} from 'Services/Api/_HumanWritten/PricingService/PricingService';
import alertToast from 'Util/ToastifyUtils';
import { isNotNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { formatTripFilterInfo } from 'Util/_HumanWritten/BookingWizardDataUtils';
import { devLog } from 'Util/_HumanWritten/ConsoleLogUtils';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import {
	BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import {
	TripSelectionCalendar,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/TripSelectionCalendar';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { showModal } from 'Views/Components/_HumanWritten/Modal/Base/BaseModalContents';
import { SingleActionModalContent } from 'Views/Components/_HumanWritten/Modal/Base/SingleActionModalContent';
import { useTicketDeselectionForFilterDates } from './Hooks/useTicketDeselectionForFilterDates';
import { useTicketDeselectionForFilterLocations } from './Hooks/useTicketDeselectionForFilterLocations';
import { useTicketDeselectionForInvalidReturn } from './Hooks/useTicketDeselectionForInvalidReturn';
import { useTicketDeselectionWhenPaxExceeds } from './Hooks/useTicketDeselectionWhenPaxExceeds';

export interface TicketSelectionTabProps {
	wizardData: BookingWizardData;
	saveChanges: (newData: BookingWizardData) => void;
	selectedTrips: SelectedTrips;
}

export function TicketSelectionTab({
	wizardData,
	saveChanges,
	selectedTrips,
}: TicketSelectionTabProps) {
	const startLocation = useLocationById(wizardData.fromLocationId);
	const endLocation = useLocationById(wizardData.toLocationId);

	const { isIpad } = useDeviceDetect();
	const { isStaff, isManager } = useStore();
	const abortControllerRef = useAbortController();

	const vehicleTrip = wizardData.tabSelected === 'vehicle';
	const oldWizardData = getOldFerryBookingWizardData();

	const [trips, setTrips] = React.useState<TicketsTabStateData>({
		departureTickets: null,
		returningTickets: null,
	});

	const [priceByDepartureFerryTrip, setPriceByDepartureFerryTrip] = React.useState<{ [key: string]: number }>({});
	const [priceByReturnFerryTrip, setPriceByReturnFerryTrip] = React.useState<{ [key: string]: number }>({});

	const [departurePriceLoading, setDeparturePriceLoading] = React.useState<boolean>(true);
	const [destinationPriceLoading, setDestinationPriceLoading] = React.useState<boolean>(true);

	const [departureTripsLoading, setDepartureTripsLoading] = React.useState<boolean>(true);
	const [destinationTripsLoading, setDestinationTripsLoading] = React.useState<boolean>(true);

	const currentStartDate = React.useRef(wizardData.startDate);
	const currentEndDate = React.useRef(wizardData.endDate);
	const currentFromLocation = React.useRef(wizardData.fromLocationId);
	const currentToLocation = React.useRef(wizardData.toLocationId);
	const firstRender = React.useRef(true);
	const onlyAlteringReturn = wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip === false;

	// Clear all vehicle data from the booking wizard if it is no longer a vehicle ticket
	// Or else the pricing will be affected
	React.useEffect(() => {
		if (!vehicleTrip) {
			const newData = { ...wizardData };
			newData.cargoTypeId = '';
			newData.cargoMake = '';
			newData.vehicleLengthId = '';
			newData.trailerTypeId = 'NO_TRAILER';
			newData.trailerLengthId = '';
			newData.departingTripOptions = [];
			newData.returningTripOptions = [];
			saveChanges(newData);
		}

		// We only want to execute this logic when the tabSelected value changes (vehicle or passenger)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wizardData.tabSelected]);

	React.useEffect(() => {
		if (store?.redirectedFromWaitlistEmail === true && trips.departureTickets !== null && trips.returningTickets !== null) {
			if (wizardData.departureTicketId === '') {
				showModal({
					key: 'waitlist-availability-modal',
					content: <SingleActionModalContent
						title="You've just missed out"
						body={<h5>Spaces fill up quick, sorry about that. You&apos;re still on the waitlist so we&apos;ll email you when space becomes available.</h5>}
						confirmContent="OK"
					/>,
				});
			} else {
				showModal({
					key: 'waitlist-availability-modal',
					content: <SingleActionModalContent
						title="Spaces available!"
						body={<h5>Good news - there is space available on this ferry, so we&apos;ve preselected your trip for you to book with ease.</h5>}
						confirmContent="Let's go"
					/>,
				});
			}
			store.setRedirectedFromWaitlistEmail(false);
		}
	}, [trips.departureTickets, trips.returningTickets]);

	// Helper function to fetch trips
	const fetchTripsForDateRange = async (
		startDate: Date,
		endDate: Date,
		isDeparture: boolean,
	) => {
		const dates = [];
		const currentDate = new Date(startDate);

		// Collect all dates in the range
		while (currentDate <= endDate) {
			dates.push(dayjs(currentDate).startOf('day').toDate()); // Store each date
			currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
		}

		// Let's say the 3rd date is the one the user is focused on initially
		const focusedIndex = 2; // 3rd date (0-indexed)
		const reorderedDates = [];

		// Add the 3rd date first
		if (dates[focusedIndex]) {
			reorderedDates.push(dates[focusedIndex]);
		}

		// Add the surrounding dates alternately from the center (focusedIndex)
		let leftIndex = focusedIndex - 1;
		let rightIndex = focusedIndex + 1;

		while (leftIndex >= 0 || rightIndex < dates.length) {
			if (leftIndex >= 0) {
				reorderedDates.push(dates[leftIndex]);
				leftIndex--;
			}
			if (rightIndex < dates.length) {
				reorderedDates.push(dates[rightIndex]);
				rightIndex++;
			}
		}

		// Check if there's an existing controller and abort the previous request
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		// Create a new AbortController for the current fetch
		abortControllerRef.current = new AbortController();
		const { signal } = abortControllerRef.current;

		// Fetch data in the new order
		for (const dateToFetch of reorderedDates) {
			// eslint-disable-next-line no-await-in-loop
			const data = await GetTicketsTabData(
				signal,
				wizardData.fromLocationId,
				wizardData.toLocationId,
				dateToFetch,
				isDeparture,
				onlyAlteringReturn,
				wizardData.bookingToEdit ?? null,
				store.isStaff ? wizardData.userId : store.userId,
			);

			setTrips(prevTrips => {
				if (isDeparture) {
					return {
						...prevTrips,
						departureTickets: [...(prevTrips.departureTickets || []), ...(data.departureTickets || [])],
					};
				}

				return {
					...prevTrips,
					returningTickets: [...(prevTrips.returningTickets || []), ...(data.returningTickets || [])],
				};
			});
		}
	};

	const updateTrips = async (updateDeparture: boolean, updateReturn: boolean) => {
		// Always update departure trips if updateDeparture is true
		const shouldUpdateDeparture = updateDeparture
			&& !(wizardData.wizardMode === 'ALTERATION'
				&& wizardData.departureTrip === false
				&& updateReturn);

		// Only update return trips if:
		// 1. updateReturn is true
		// 2. In CREATE mode AND the trip type is `return`
		// OR in ALTERATION mode AND the user is altering the return trip or both directions
		const shouldUpdateReturn = updateReturn
			&& ((wizardData.wizardMode === 'CREATE' && wizardData.tripType === 'return')
				|| (wizardData.wizardMode === 'ALTERATION'
					&& wizardData.departureTrip !== true));

		setTrips({
			departureTickets: shouldUpdateDeparture || onlyAlteringReturn ? null : trips.departureTickets,
			returningTickets: shouldUpdateReturn && !onlyAlteringReturn ? null : trips.returningTickets,
		});

		try {
			if (shouldUpdateDeparture) {
				// const startDate = new Date(moment(wizardData.startDate).add(-2, 'day').format('YYYY-MM-DD'));
				// const endDate = new Date(moment(wizardData.startDate).add(2, 'day').format('YYYY-MM-DD'));

				// Track UTC time
				const startDate = dayjs(wizardData.startDate).add(-2, 'day').toDate();
				const endDate = dayjs(wizardData.startDate).add(2, 'day').toDate();

				// Fetch departure trips sequentially
				setDepartureTripsLoading(true);
				await fetchTripsForDateRange(startDate, endDate, true);
				setDepartureTripsLoading(false);
			}

			// Date ranges for return trips
			if (shouldUpdateReturn) {
				// const startDate = new Date(moment(wizardData.endDate).add(-2, 'day').format('YYYY-MM-DD'));
				// const endDate = new Date(moment(wizardData.endDate).add(2, 'day').format('YYYY-MM-DD'));

				// Track UTC time
				const startDate = dayjs(wizardData.endDate).add(-2, 'day').toDate();
				const endDate = dayjs(wizardData.endDate).add(2, 'day').toDate();

				// Fetch return trips sequentially
				setDestinationTripsLoading(true);
				await fetchTripsForDateRange(startDate, endDate, onlyAlteringReturn);
				setDestinationTripsLoading(false);

				if (onlyAlteringReturn) {
					setDepartureTripsLoading(false);
				}
			}
		} catch (error: any) {
			if (error?.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
				// Do nothing
			} else {
				setTrips({
					departureTickets: shouldUpdateDeparture || onlyAlteringReturn ? [] : trips.departureTickets,
					returningTickets: shouldUpdateReturn && !onlyAlteringReturn ? [] : trips.returningTickets,
				});
				alertToast('Could not fetch trips', 'error');
			}
		}
	};

	React.useEffect(() => {
		if (!onlyAlteringReturn && (
			firstRender.current
			|| wizardData.fromLocationId !== currentFromLocation.current
			|| wizardData.toLocationId !== currentToLocation.current
			|| (wizardData.startDate !== currentStartDate.current && wizardData.endDate !== currentEndDate.current)
		)
		) {
			updateTrips(true, true);
			currentStartDate.current = wizardData.startDate;
			currentEndDate.current = wizardData.endDate;
			currentFromLocation.current = wizardData.fromLocationId;
			currentToLocation.current = wizardData.toLocationId;
		}
	}, [wizardData.fromLocationId, wizardData.toLocationId, wizardData.startDate, wizardData.endDate, wizardData.bookingToEdit]);

	React.useEffect(() => {
		if (!firstRender.current && currentStartDate.current !== wizardData.startDate) {
			updateTrips(true, false).then(() => {
				currentStartDate.current = wizardData.startDate;
			});
		}
	}, [wizardData.startDate]);

	React.useEffect(() => {
		if ((!firstRender.current && currentEndDate.current !== wizardData.endDate) || (onlyAlteringReturn && firstRender.current)) {
			updateTrips(false, true).then(() => {
				if (wizardData.wizardMode !== 'ALTERATION') {
					currentEndDate.current = wizardData.endDate;
				}
			});
		}
	}, [wizardData.endDate]);

	// We want to update the return trips when the trip type changes
	// The `updateTrips` function will only update the return trips if it meets the criteria
	React.useEffect(() => {
		if (!firstRender.current) {
			updateTrips(false, true).then(() => {
				currentEndDate.current = wizardData.endDate;
			});
		}
	}, [wizardData.tripType]);

	// Previously there was effect code here to preselect a trip based on the query parameters. this is not needed in
	// the new version of the code so the effect has been removed

	React.useEffect(() => {
		firstRender.current = false;
	}, []);

	// We need 2 different debounced pricing requests because the departure and return pricing requests are different
	// and if we don't separate them out, the return pricing request will be triggered when the departure pricing
	// request is in progress and result in it being ignored
	const debouncedDeparturePricingRequest = React.useCallback(
		AwesomeDebouncePromise(
			(allTickets: TicketsTabTrip[], departure: boolean, wizData: BookingWizardData) => {
				return FetchBasePriceForFerryTrips({
					bookingCreationDto: dataToFerryTripBookingDto(wizData, false),
					ferryTripIds: allTickets.map(x => x.id),
					departureBooking: departure,
				} as BasePriceFerryTripsDto);
			},
			500,
		),
		[],
	);

	const debouncedReturnPricingRequest = React.useCallback(
		AwesomeDebouncePromise(
			(allTickets: TicketsTabTrip[], departure: boolean, wizData: BookingWizardData) => {
				return FetchBasePriceForFerryTrips({
					bookingCreationDto: dataToFerryTripBookingDto(wizData, false),
					ferryTripIds: allTickets.map(x => x.id),
					departureBooking: departure,
				} as BasePriceFerryTripsDto);
			},
			500,
		),
		[],
	);

	React.useEffect(() => {
		setDeparturePriceLoading(true);
		if (departureTripsLoading) {
			return;
		}

		if (isNotNullOrUndefined(trips.departureTickets) && trips.departureTickets.length > 0) {
			debouncedDeparturePricingRequest(trips.departureTickets, true, wizardData).then(x => {
				setPriceByDepartureFerryTrip(x);
				setDeparturePriceLoading(false);
			});
		} else {
			setPriceByDepartureFerryTrip({});
			setDeparturePriceLoading(false);
		}
	}, [
		trips.departureTickets,
		departureTripsLoading,
		wizardData.tabSelected,
		wizardData.adultTickets?.length,
		wizardData.childTickets?.length,
		wizardData.infantTickets?.length,
		wizardData.passengerDTickets?.length,
		wizardData.passengerETickets?.length,
		wizardData.passengerFTickets?.length,
		wizardData.passengerGTickets?.length,
		wizardData.passengerHTickets?.length,
	]);

	React.useEffect(() => {
		setDestinationPriceLoading(true);
		if (destinationTripsLoading) {
			return;
		}

		if (isNotNullOrUndefined(trips.returningTickets) && trips.returningTickets.length > 0 && wizardData.tripType === 'return') {
			debouncedReturnPricingRequest(trips.returningTickets, false, wizardData).then(x => {
				setPriceByReturnFerryTrip(x);
				setDestinationPriceLoading(false);
			});
		} else {
			setPriceByReturnFerryTrip({});
			setDestinationPriceLoading(false);
		}
	}, [
		trips.returningTickets,
		destinationTripsLoading,
		wizardData.tabSelected,
		wizardData.adultTickets?.length,
		wizardData.childTickets?.length,
		wizardData.infantTickets?.length,
		wizardData.passengerDTickets?.length,
		wizardData.passengerETickets?.length,
		wizardData.passengerFTickets?.length,
		wizardData.passengerGTickets?.length,
		wizardData.passengerHTickets?.length,
	]);

	const selectedDepartingTrip = trips.departureTickets?.find(x => x.id === wizardData.departureTicketId);
	const selectedReturningTrip = trips.returningTickets?.find(x => x.id === wizardData.returningTicketId);

	if (selectedDepartingTrip && wizardData.departingJourney) {
		wizardData.departingJourney.departureDateTime = selectedDepartingTrip.departureDateTime;
	}

	if (selectedReturningTrip && wizardData.returningJourney) {
		wizardData.returningJourney.departureDateTime = selectedReturningTrip.departureDateTime;
	}

	const {
		departingCurrentTab,
		returningCurrentTab,
		setDepartingCurrentTab,
		setReturningCurrentTab,
	} = useTripSelectionCalendarTabState(
		wizardData.startDate,
		wizardData.endDate,
		selectedDepartingTrip?.departureDateTime,
		selectedReturningTrip?.departureDateTime,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
	);

	useTicketDeselectionForFilterDates(
		wizardData,
		saveChanges,
		selectedDepartingTrip?.departureDateTime,
		selectedReturningTrip?.departureDateTime,
	);

	useTicketDeselectionForInvalidReturn(
		wizardData,
		saveChanges,
		selectedDepartingTrip?.departureDateTime,
		selectedReturningTrip?.departureDateTime,
	);

	useTicketDeselectionForFilterLocations(wizardData, saveChanges);

	useTicketDeselectionWhenPaxExceeds(
		wizardData,
		saveChanges,
		selectedDepartingTrip,
		selectedReturningTrip,
	);

	// This will handle the scenario where the user clicks the book now button on a trip that
	if (
		stringIsEmpty(wizardData.bookingToEdit)
		&& !isManager
		&& selectedDepartingTrip !== undefined
		&& (
			(wizardData.tabSelected === 'vehicle' && (selectedDepartingTrip?.vehicleSpacesAvailable ?? 0) < 1)
			|| (wizardData.tabSelected === 'passenger' && (selectedDepartingTrip?.passengerSpacesAvailable ?? 0) === 0)
		)) {
		const newData = { ...wizardData };
		newData.departureTicketId = '';
		newData.departingJourney = undefined;
		devLog('DEV0012: Deselected departing ticket');
		saveChanges(newData);
	}

	return (
		<div className="booking-wizard-tab ticket-selection-tab-container">
			<h2 className="booking-wizard__tab-header ticket-tab-text">
				<If condition={wizardData.wizardMode === wizardModeOptions.CREATE}>
					Select your ferry tickets
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION && wizardData.departureTrip !== undefined}>
					{wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip ? 'Edit your departing ferry ticket' : 'Edit your returning ferry ticket'}
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION && wizardData.departureTrip === undefined}>
					Edit your ferry tickets
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
					<div>
						<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
							<Icon name="cross" classname="icon" />
						</Link>
					</div>
				</If>
			</h2>
			<div className="ticket-tab__info inline-button-container ticket-tab-text">
				<If condition={isIpad}>
					<p className="ticket-tab__info__text">
						For {
							formatTripFilterInfo(wizardData)
						}
					</p>
					<Button
						className="edit-filter-button"
						colors={Colors.Alternate}
						display={Display.Text}
						onClick={() => store.routerHistory.push('/booking-wizard/search')}
					>
						Edit
					</Button>
				</If>
			</div>
			<h4 className="ticket-tab__sub-header ticket-tab-text">
				{wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip === false ? 'Returning' : 'Departing'} Trip
			</h4>
			<h6 className="ticket-tab-text sub-text">
				{startLocation?.name} to {endLocation?.name},&nbsp;
				{moment(wizardData.wizardMode === 'ALTERATION'
					&& wizardData.departureTrip === false
					? wizardData.ticketSelectionEndDate
					: wizardData.ticketSelectionStartDate)
					.format('DD MMM YYYY')}
			</h6>
			{!selectedDepartingTrip && !wizardData.departureTicketId && (
				<TripSelectionCalendar
					wizardData={wizardData}
					ferryTrips={trips.departureTickets}
					departure={wizardData.departureTrip !== false}
					saveChanges={saveChanges}
					selectedTrips={selectedTrips}
					isStaff={isStaff}
					refreshTrips={() => updateTrips(true, true)}
					priceByFerryTrip={priceByDepartureFerryTrip}
					loading={departurePriceLoading}
					currentTab={departingCurrentTab}
					setCurrentTab={setDepartingCurrentTab}
				/>
			)}
			{!!wizardData.departureTicketId && (
				<>
					{!selectedDepartingTrip && (
						<div className="spinner-container">
							<LottieSpinner className="relative" />
						</div>
					)}
					{selectedDepartingTrip && (
						<LegOption
							wizardData={wizardData}
							isLoading={departurePriceLoading}
							saveChanges={saveChanges}
							trip={selectedDepartingTrip}
							isSelected
							price={priceByDepartureFerryTrip[selectedDepartingTrip.id]}
							legType={wizardData.departureTrip !== false ? 'departure' : 'return'}
						/>
					)}
				</>
			)}
			<If condition={wizardData.tripType === 'return'}>
				<div className="ticket-tab__sub-header return-booking inline-button-container ticket-tab-text">
					<h4 className="">Returning Trip</h4>
					<If condition={wizardData.wizardMode !== 'ALTERATION'}>
						<Button
							className="edit-filter-button"
							colors={Colors.Red}
							display={Display.Text}
							onClick={() => {
								const newData = { ...wizardData };
								newData.tripType = 'one way';
								newData.returningTicketId = '';
								newData.returningJourney = undefined;
								saveChanges(newData);
							}}
						>
							Remove
						</Button>
					</If>
				</div>
				<h6 className="ticket-tab-text sub-text">
					{endLocation?.name} to {startLocation?.name},&nbsp;
					{moment(wizardData.ticketSelectionEndDate).format('DD MMM YYYY')}
				</h6>
				{!selectedReturningTrip && !wizardData.returningTicketId && (
					<TripSelectionCalendar
						wizardData={wizardData}
						ferryTrips={trips.returningTickets}
						departure={false}
						saveChanges={saveChanges}
						selectedTrips={selectedTrips}
						isStaff={isStaff}
						refreshTrips={() => updateTrips(true, true)}
						priceByFerryTrip={priceByReturnFerryTrip}
						loading={destinationPriceLoading}
						currentTab={returningCurrentTab}
						setCurrentTab={setReturningCurrentTab}
					/>
				)}
				{!!wizardData.returningTicketId && (
					<>
						{!selectedReturningTrip && (
							<div className="spinner-container">
								<LottieSpinner className="relative" />
							</div>
						)}
						{selectedReturningTrip && (
							<LegOption
								wizardData={wizardData}
								saveChanges={saveChanges}
								isLoading={destinationPriceLoading}
								legType="return"
								trip={selectedReturningTrip}
								isSelected
								price={priceByReturnFerryTrip[selectedReturningTrip.id]}
							/>
						)}
					</>
				)}
			</If>
		</div>
	);
}
