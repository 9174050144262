import React from 'react';

import useDeviceDetect from 'Hooks/useDeviceDetect';
import { useAvailability } from 'Modules/MultiStopWizard/Hooks/useAvailability';
import { useAvailabilityStatus } from 'Modules/MultiStopWizard/Hooks/useAvailabilityStatus';
import { useIsLegSelectable } from 'Modules/MultiStopWizard/Hooks/useIsLegSelectable';
import { useIsLegVisuallyDisabled } from 'Modules/MultiStopWizard/Hooks/useIsLegVisuallyDisabled';
import { useLegErrorMessage } from 'Modules/MultiStopWizard/Hooks/useLegErrorMessage';
import { useLegOptionClick } from 'Modules/MultiStopWizard/Hooks/useLegOptionClick';
import { useStopsBetween } from 'Modules/MultiStopWizard/Hooks/useStopsBetween';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { LegOptionContext, LegOptionContextProps } from './Contexts/LegOptionContext';
import LegOptionMobile from './LegOptionMobile';
import LegOptionWeb from './LegOptionWeb';

interface LegOptionProps {
	trip: TicketsTabTrip;
	price?: number;
	isSelected?: boolean;
	isLoading?: boolean;
	legType: 'departure' | 'return';
	//
	wizardData: BookingWizardData;
	refreshTrips?: () => void;
	saveChanges: (newData: BookingWizardData) => void;
}

export default function LegOption({
	trip,
	price,
	isSelected = false,
	isLoading,
	legType,
	//
	wizardData,
	refreshTrips,
	saveChanges,
}: LegOptionProps) {
	const { isIpad } = useDeviceDetect();

	const { count: numberOfStopsBetween } = useStopsBetween(trip.startStopId, trip.endStopId);

	const availability = useAvailability(trip);
	const availabilityStatus = useAvailabilityStatus(trip, wizardData.tabSelected);

	const isSelectable = useIsLegSelectable(trip, wizardData, legType);
	const isVisuallyDisabled = useIsLegVisuallyDisabled(trip, wizardData);
	const onClick = useLegOptionClick(legType, wizardData, trip, true, saveChanges);

	const errorMessage = useLegErrorMessage(wizardData, trip, legType);

	const context: LegOptionContextProps = {
		// Legacy approach
		wizardData,
		trip,

		// Loading
		isLoading,

		// Booking details
		legType,

		// Selection details
		isSelected,
		isSelectable,
		isVisuallyDisabled,

		// Route details
		departureTime: trip.departureDateTime,
		arrivalTime: trip.arrivalDateTime,
		numberOfStopsBetween,

		// Price details
		price,

		// Space details
		availabilityStatus,
		paxSpaces: availability.passenger,
		cargoSpaces: availability.vehicle,

		// Message
		errorMessage,

		// Waitlist
		isWaitlisted: trip.isWaitListed,
		refreshTrips,

		// Actions
		onClick,
	};

	return (
		<LegOptionContext.Provider value={context}>
			{isIpad && <LegOptionMobile />}
			{!isIpad && <LegOptionWeb />}
		</LegOptionContext.Provider>
	);
}

export interface LegOptionInnerProps {
	isSelected?: boolean;
	isSelectable?: boolean;
	errorMessage?: string;
}

// const isPaxSpaceAvailable = availability.passenger > 0;
// const isVehicleSpaceAvailable = availability.vehicle > 0;
// const isTrailerSpaceAvailable = availability.trailer > 0;

// const isPaxSpaceExceeded = calculateTotalPassengers(wizardData) > availability.passenger;
// const isVehicleSpaceExceeded = useIsVehicleSpaceExceeded(trip.vehicleSpacesAvailable, wizardData);
// const isTrailerSpaceExceeded = useIsTrailerSpaceExceeded(trip.trailerSpotsAvailable, wizardData);

// const hasDeparted = trip.departureDateTime < new Date();
// const isDisabled = false;

// WIP ---------------------------------------------------------

// While editing a booking with a return ticket,

// When only editing the departure ticket,
//   the return ticket's departure time
//   must be after the departure ticket's arrival time

// When only editing the return ticket,
//   the departure ticket's arrival time
//   must be before the return ticket's departure time

// TODO : Decide what variable to track here

// const isInvalidAlterationTicket = false;

// WIP ---------------------------------------------------------

// const isSelectable = useIsLegSelectable({
// 	trip,
// 	tripHasDeparted: hasDeparted,
// 	tripIsClosed: trip.isClosed ?? false,
// 	exceedsAvailablePaxSpace: isPaxSpaceExceeded,
// 	isVehicleBooking: bookingType === 'vehicle',
// 	exceedsAvailableVehicleSpace: isVehicleSpaceExceeded,
// });

// const isVisuallyDisabled = useIsLegVisuallyDisabled({
// 	tripHasDeparted: hasDeparted,
// 	tripIsClosed: trip.isClosed ?? false,
// 	exceedsAvailablePaxSpace: isPaxSpaceExceeded,
// 	isVehicleBooking: bookingType === 'vehicle',
// 	exceedsAvailableVehicleSpace: isVehicleSpaceExceeded,
// });
