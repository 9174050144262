import React from 'react';
import { store } from 'Models/Store';
import { runInAction } from 'mobx';
import { route } from 'Util/_HumanWritten/RouterUtils';
import { showCheckInDepartedModal } from 'Views/Components/_HumanWritten/Modal/CheckInDepartedModalContents';
import { checkInStore } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/CheckInContext';
import {
	showCheckInDiscardChangesModal,
} from 'Views/Components/_HumanWritten/Modal/CheckInDiscardChangesModalContents';

export function useCheckInRoutesWatch() {
	React.useEffect(() => {
		// Skip adding the block if the user is offline. The functionality this handles is not accessible when offline.
		// The offline has its own router history block in CheckInOfflineBanner
		if (checkInStore.isOffline()) {
			return;
		}

		//
		// The purpose of this useEffect is to notify the staff that the ferry trip they are boarding has departed
		// (based on the trip's scheduled departure time).
		//
		// When applicable, a popup will appear when the user tries to access the QR scanner. When confirmed, the popup
		// will not re-appear for the lifetime of the component (until they leave the check-in page).
		//
		const previousPathname = store.routerHistory.location.pathname;

		const unblock = store.routerHistory.block((nextLocation, action) => {
			const { pathname } = nextLocation;

			if (pathname.endsWith('/scan') && checkInStore.skipFerryHasDepartedModal) {
				showCheckInDepartedModal({
					onConfirm: () => {
						checkInStore.confirmCheckInForDepartedTrip();
						unblock();
						route(nextLocation, action);
					},
				});
				return false;
			}

			// Check when check in add flow
			// Reset form state when exiting Add flow

			const addFlowRoutes = ['/select-customer', '/add', '/passengers', '/review'];
			if (checkInStore.usingAddFlow && !addFlowRoutes.find(x => pathname.endsWith(x))) {
				runInAction(() => {
					checkInStore.usingAddFlow = false;
				});
				checkInStore.setFormState();
			}

			// When user is navigating back from review to edit page
			// Confirm with user to discard booking in cart

			if (
				!checkInStore.usingAddFlow
				&& checkInStore.formState
				&& checkInStore.formState.bookingToEdit
				&& previousPathname.endsWith('/review')
				&& !pathname.includes(checkInStore.formState.bookingToEdit)
			) {
				showCheckInDiscardChangesModal({
					onConfirm: () => {
						unblock();
						route(nextLocation, action);
					},
				});
				return false;
			}

			// In hindsight, I should have built the add/edit flow in a different way.
			//
			// Instead of having CheckInReview component as a seperate page that uses the store to share data, what I
			// should have done is created two components
			//
			//   1. CheckInAddFlow
			//   2. CheckInEditFlow
			//
			// Each component stores its own form state and passes it through the required components
			//
			// Example
			//
			//   CheckInAddFlow
			//     CheckInAdd
			//     CheckInReview
			//
			// This way, there is never a way for the two flows to accidentally have clashing form state...
			//
			// Lessons learned..
			//
			return undefined;
		});

		// Subscribe to blocker
		store.routerHistory.listen(unblock);

		// Clean up
		return () => {
			unblock();
		};

		// Restart blocker everytime location changes (because unblock is called every time user changes the page)
	}, [store.routerHistory.location, checkInStore.isOffline()]);
}
