import React from 'react';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { LegOptionContext } from './Contexts/LegOptionContext';

export default function LegOptionPaxCapacity() {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionPaxCapacity');
		return null;
	}

	const { paxSpaces } = context;

	return <LegOptionPaxCapacityInner space={paxSpaces} />;
}

interface LegOptionPaxCapacityInnerProps {
	space?: number;
}

function LegOptionPaxCapacityInner({ space }: LegOptionPaxCapacityInnerProps) {
	return (
		<div className="leg-option__space-tag">
			<Icon name="person" />
			<div className="leg-option__space-text">{space}</div>
		</div>
	);
}
