import { confirmModal } from '../../../../Modal/ModalUtils';
import * as React from 'react';
import { Checkbox } from '../../../../Checkbox/Checkbox';

const dontShowModalStorageKey = 'offline-storage-explanation-modal-shown';

export const isModalSetToDoNotShowAgain = () => {
	return localStorage.getItem(dontShowModalStorageKey) === 'true';
};

export const setModalToDoNotShowAgain = (value: boolean) => {
	localStorage.setItem(dontShowModalStorageKey, value ? 'true' : 'false');
};

export const SaveDataExplanationModal = async (): Promise<boolean> => {
	if (isModalSetToDoNotShowAgain()) {
		return true;
	}

	try {
		await confirmModal('Offline Storage', SaveDataExplanationModalContent());

		return true;
	} catch (e) {
		return false;
	}
};

const SaveDataExplanationModalContent = () => {
	const model = {
		value: false,
	};

	return (
		<>
			<p>
				Saving check in data for offline use lets you access and edit the check-in status without an internet
				connection.
			</p>
			<p>
				Any changes made offline will need to be manually synced once you&apos;re back online to
				ensure they’re saved and updated across devices.
			</p>
			<Checkbox
				className="offline-storage-explanation-modal__checkbox"
				model={model}
				modelProperty="value"
				label="Do not show this again?"
				onAfterChecked={(e, checked) => {
					console.log(model.value, checked);
					setModalToDoNotShowAgain(checked);
				}}
			/>
		</>
	);
};

export const ConfirmOverwritePreviousDataModal = async (): Promise<boolean> => {
	try {
		await confirmModal('Offline Storage',
			'There is already check-in data saved for offline use. Do you want to overwrite this data?');

		return true;
	} catch (e) {
		return false;
	}
};

export const ReturnToCheckInPageModal = async (): Promise<boolean> => {
	try {
		await confirmModal('Offline Check In',
			'Do you want to return to the check-in page to continue offline check in?');

		return true;
	} catch (e) {
		return false;
	}
};
