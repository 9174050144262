import { TabSelected } from '../FerryTripBookingWizard/BookingWizardData';
import { Button } from 'Views/Components/Button/Button';
import * as React from 'react';
import Icon from '../Icon/Icon';
import { store } from 'Models/Store';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { whiteLabelStore } from 'Models/WhiteLabelStore';

export interface PassengerBookingButtonProps {
	onSearch: (type: TabSelected) => void;
}

export function PassengerBookingButton({
	onSearch,
}: PassengerBookingButtonProps) {
	const { isMobile } = useDeviceDetect();

	const onPassengerClick = () => {
		onSearch('passenger');

		if (isMobile) {
			store.routerHistory.push('/booking-wizard?type=passenger');
		} else {
			store.routerHistory.push('/home/search?type=passenger');
		}
	};

	const passengerIcon = (
		<Icon name="person" classname="passenger__icon" />
	);

	return (
		<Button
			key="passenger-booking-button"
			className="booking-type__button hide-underline`"
			onClick={onPassengerClick}
		>
			<h5>
				{passengerIcon}
				{whiteLabelStore.passengerLabelPascalCase ?? 'Walk-on'}
			</h5>
		</Button>
	);
}
