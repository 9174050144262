import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { journeyElementsStore } from '../JourneyElementsStore';

export const getDefaultLocationIds = () => {
	const { routes } = journeyElementsStore.getState();

	if (!routes.length) {
		return null;
	}

	const model = {
		startLocationId: routes[0].departureId,
		endLocationId: routes[0].destinationId,
	};

	const { defaultRouteId } = whiteLabelStore;

	if (!defaultRouteId) {
		return model;
	}

	const match = routes.find(x => x.id === defaultRouteId);
	if (!match) {
		return model;
	}

	return {
		startLocationId: match.departureId,
		endLocationId: match.destinationId,
	};
};
