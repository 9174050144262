/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
	Button,
	Display,
	Colors,
	Sizes,
} from 'Views/Components/Button/Button';
import { action, observable } from 'mobx';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { IUserResult, IStore } from 'Models/Store';
import * as queryString from 'querystring';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import Password from 'Views/Components/Password/Password';
import { isEmail } from 'Validators/Functions/Email';
import alertToast from 'Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { useContext } from 'react';
import { TwoFactorContext, TwoFactorMethods } from 'Services/TwoFactor/Common';
import useStore from 'Hooks/useStore';
import { login } from 'Services/Api/AuthorizationService';
import { buildUrl } from 'Util/FetchUtils';
// % protected region % [Add any extra imports here] on begin
import If from 'Views/Components/If/If';
import { FEATURE_IMAGE_3_URL } from '../../../Constants';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { useEffect, useRef } from 'react';
import { checkUserEmail } from 'Services/Api/AccountService';
import { runInAction } from 'mobx';
import { LOGIN_PAGE_TITLE } from '../../../ConstantPageNames';
import { store as globalStore } from 'Models/Store';
// % protected region % [Add any extra imports here] end

// % protected region % [Customise ILoginState here] on begin
interface ILoginState {
	email: string;
	password: string;
	rememberMe: boolean;
	firstName: string;
	lastName: string;
	initials: string;
	errors: {
		email?: string,
		password?: string,
		[attr: string]: string | undefined,
	};
}
// % protected region % [Customise ILoginState here] end

// % protected region % [Customise defaultLoginState here] on begin
const defaultLoginState: ILoginState = {
	email: '',
	password: '',
	firstName: '',
	lastName: '',
	initials: '',
	rememberMe: false,
	errors: {},
};
// % protected region % [Customise defaultLoginState here] end

// % protected region % [Add any extra constants here] off begin
// % protected region % [Add any extra constants here] end

interface LoginPageInternalProps extends RouteComponentProps {
	store: IStore;
	twoFactorMethods: TwoFactorMethods;
	// % protected region % [Add any extra LoginPageInternalProps fields here] off begin
	// % protected region % [Add any extra LoginPageInternalProps fields here] end
}

@observer
// % protected region % [Override class signature here] off begin
class LoginPageInternal extends React.Component<LoginPageInternalProps> {
// % protected region % [Override class signature here] end
	@observable
	private loginState: ILoginState = defaultLoginState;

	// % protected region % [Add any extra fields here] on begin
	passwordRef = React.createRef<HTMLInputElement | null>();
	// % protected region % [Add any extra fields here] end

	// % protected region % [Override render here] on begin
	public render() {
		document.title = LOGIN_PAGE_TITLE;

		const { store } = this.props;
		const welcomeMessage = 'Welcome back,';
		if (store.loggedIn && store.isStaff) {
			return <Redirect to="/ferry-schedule" />;
		}
		if (store.loggedIn) {
			return <Redirect to="/" />;
		}

		return (
			<div className="body-content">
				<div className="login-container">
					<div className="feature-image">
						<img
							className="bg-img"
							alt="bg-img"
							src={FEATURE_IMAGE_3_URL}
						/>
					</div>
					<div className="login">
						<div className="navigation-container">
							<Button
								onClick={() => this.clearLoginState()}
								className={classNames(`icon-chevron-left icon-left back-navigation ${
									this.loginState.firstName !== '' ? 'show' : 'hide'
								}`)}
								display={Display.Text}
							>
								Login
							</Button>
						</div>
						<If condition={this.loginState.firstName === ''}>
							<h3>Login or Sign up</h3>
							<form className="loginForm" onSubmit={this.onContinueClicked}>
								<TextField
									id="login_username"
									className="login-username"
									model={this.loginState}
									modelProperty="email"
									label="Email address"
									placeholder="Email address"
									inputProps={{ autoComplete: 'username', type: 'email' }}
									isRequired
									errors={this.loginState.errors.email}
								/>
								<Link
									to={`/reset-password-request?email=${this.loginState.email}`}
									className="link-forgotten-password link-rm-txt-dec hide-underline"
								>
									Forgot password?
								</Link>
								<ButtonGroup alignment={Alignment.HORIZONTAL} className="login-buttons">
									<Button
										type="submit"
										className="login-submit btn--primary"
										display={Display.Solid}
										sizes={Sizes.Medium}
										buttonProps={{ id: 'login_submit' }}
										disabled={
											!isEmail(this.loginState.email)
											|| (this.loginState.password === '' && this.loginState.firstName !== '')
										}
									>
										Continue
									</Button>
								</ButtonGroup>
							</form>
						</If>
						<If condition={this.loginState.firstName !== ''}>
							<div className="profile-logo-container">
								<div className="profile-logo web">
									{this.loginState.initials.toUpperCase()}
								</div>
							</div>
							<h3>{`${welcomeMessage} ${this.loginState.firstName}`}</h3>
							<form className="loginForm" onSubmit={this.onLoginClicked}>
								<LoginPasswordInput
									loginState={this.loginState}
									errors={this.loginState.errors.password}
								/>
								<Link
									to={`/reset-password-request?email=${this.loginState.email}`}
									className="link-forgotten-password link-rm-txt-dec hide-underline"
								>
									Forgot password?
								</Link>
								<Checkbox
									className="remember-me-checkbox"
									model={this.loginState}
									modelProperty="rememberMe"
									label="Stay logged in"
									onChecked={(event, checked) => {
										this.loginState.rememberMe = checked;
									}}
								/>
								<ButtonGroup alignment={Alignment.HORIZONTAL} className="login-buttons">
									<Button
										type="submit"
										className="login-submit btn--primary"
										display={Display.Solid}
										sizes={Sizes.Medium}
										buttonProps={{ id: 'login_submit' }}
										disabled={
											!isEmail(this.loginState.email)
											|| (this.loginState.password === '' && this.loginState.firstName !== '')
										}
									>
										Login
									</Button>
								</ButtonGroup>
							</form>
						</If>
						<span className="mobile-or-email-login">
							Or&nbsp;
							<Button
								display={Display.Text}
								colors={Colors.Secondary}
								className="link-mobile-login"
							>
								continue with mobile
							</Button>
						</span>
					</div>
				</div>
			</div>
		);
	}
	// % protected region % [Override render here] end

	// % protected region % [Override onLoginClicked here] on begin
	@action
	private onLoginClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!this.loginState.password) {
			this.loginState.errors.password = 'Password is required';
		}

		if (Object.keys(this.loginState.errors).length === 0) {
			login(this.loginState.email, this.loginState.password, this.loginState.rememberMe)
				.then(data => {
					if (data.type === '2fa-required') {
						const validMethods = Object.keys(this.props.twoFactorMethods);
						if (validMethods.indexOf(data.method) > -1) {
							const { redirect } = queryString.parse(this.props.location.search.substring(1));
							const params: { [key: string]: string } = {
								rememberMe: this.loginState.rememberMe.toString(),
							};
							if (typeof redirect === 'string') {
								params.redirect = redirect;
							}
							this.props.history.push(buildUrl(`login/2fa/${data.method.toLocaleLowerCase()}`, params));
						} else {
							alertToast('Invalid 2 factor method', 'error');
						}
					} else {
						this.onLoginSuccess(data);
					}
				})
				.catch(response => {
					const errorMessages = getErrorMessages(response).map((error: any) => {
						const message = typeof error.message === 'object'
							? JSON.stringify(error.message)
							: error.message;
						return (<p>{message}</p>);
					});
					alertToast(errorMessages, 'error', 'Login failed');
				});
		}
	};
	// % protected region % [Override onLoginClicked here] end

	// % protected region % [Override onStartRegisterClicked here] on begin
	@action
	private startRegisterNewUser = () => {
		this.props.store.tempEmail = this.loginState.email;
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		this.props.store.routerHistory?.push(`/register?${!!redirect ? `redirect=${redirect}` : ''}`);
	};
	// % protected region % [Override onStartRegisterClicked here] end

	// % protected region % [Override login success logic here] on begin
	@action
	private onLoginSuccess = (userResult: IUserResult) => {
		this.props.store.setLoggedInUser(userResult);

		const { redirect } = queryString.parse(this.props.location.search.substring(1));

		if (this.props.store.isStaff) {
			// We don't want intercom chat bubble in the right bottom corner to appear for staff+ users
			document.getElementsByClassName('intercom-lightweight-app')[0]?.remove();
		}

		if (redirect && !Array.isArray(redirect)) {
			this.props.store.routerHistory.push(redirect);
		} else if (this.props.store.isStaff) {
			this.props.store.routerHistory.push('/ferry-schedule');
		} else {
			this.props.store.routerHistory.push('/');
		}
	};
	// % protected region % [Override login success logic here] end

	// % protected region % [Override onForgottenPasswordClick here] on begin
	@action
	private onForgottenPasswordClick = () => {
		this.props.store.routerHistory.push(`/reset-password-request?email=${this.loginState.email}`);
	};
	// % protected region % [Override onForgottenPasswordClick here] end

	// % protected region % [Add class methods here] on begin
	@action
	private setFirstAndInitials(firstName: string, initials: string) {
		this.loginState.firstName = firstName;
		this.loginState.initials = initials;
	}

	// Check if the email entered already has an account, if not redirect them to the registration page
	@action
	private onContinueClicked = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.loginState.errors = {};

		if (!this.loginState.email) {
			this.loginState.errors.email = 'Email address is required';
		} else if (!isEmail(this.loginState.email)) {
			this.loginState.errors.email = 'Invalid email address';
		}
		if (this.loginState.errors.email === undefined) {
			const result = await checkUserEmail(this.loginState.email);

			if (result?.isDisabled ?? false) {
				runInAction(() => {
					this.loginState.errors.email = 'Account is disabled. Contact staff for assistance.';
				});
			}
			if (result && !result.isDisabled) {
				if (result.resetRequired) {
					this.props.history.push(`/reset-password-request?email=${this.loginState.email}&forcedReset=true`);
				} else {
					this.setFirstAndInitials(result.firstName, result.initials);
				}
			} else if (!result?.isDisabled) {
				this.startRegisterNewUser();
				alertToast('User does not exist', 'success');
			}
		}
	}

	@action
	private clearLoginState() {
		this.loginState = defaultLoginState;
	}
	// % protected region % [Add class methods here] end
}

// % protected region % [Override LoginPage here] on begin
function LoginPage(props: RouteComponentProps) {
	const twoFactorMethods = useContext(TwoFactorContext);
	const hookStore = useStore();

	const {
		match,
		location,
		history,
		staticContext,
	} = props;

	return (
		<>
			<NavigationWrapper
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<LoginPageInternal {...props} store={hookStore} twoFactorMethods={twoFactorMethods} />
		</>
	);
}
// % protected region % [Override LoginPage here] end

// % protected region % [Override default export here] off begin
export default LoginPage;
// % protected region % [Override default export here] end

// % protected region % [Add additional exports here] on begin
type LoginPasswordInputProps = {
	loginState: { password: string },
	errors?: string,
}

/**
 * Password input that will focus itself when rendered.
 */
function LoginPasswordInput({
	loginState,
	errors,
}: LoginPasswordInputProps) {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, []);

	return (
		<Password
			id="login_password"
			className="login-password"
			model={loginState}
			modelProperty="password"
			label="Password"
			placeholder="Password"
			inputProps={{
				autoComplete: 'current-password',
			}}
			isRequired
			errors={errors}
			inputRef={inputRef}
		/>
	);
}
// % protected region % [Add additional exports here] end
