import React, { useState } from 'react';
import If from 'Views/Components/If/If';
import { appConfigStore } from 'Models/AppConfigStore';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { useIntl } from 'react-intl';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { PassengerTypeKey } from 'Models/Enums';
import { isNotNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { PassengerError, passengerTicketIsEmpty, validatePassenger } from './PassengerTabHelpers';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import {
	IBookingFormState,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/BookingFormState';
import {
	BookingWizardData,
	PassengersInfo,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import classNames from 'classnames';
import { runInAction } from 'mobx';
import { useLocalStore } from 'mobx-react';

interface PassengerDetailFormProps {
	forCheckIn: boolean;
	passenger: PassengersInfo;
	count: number;
	expanded: boolean;
	setExpanded: (id: string, clearExpanded?: boolean) => void;
	wizardData: BookingWizardData | IBookingFormState;
	onUpdateWizardData?: (wizardData: BookingWizardData) => void;
	onClearForm: () => void;
	errors: PassengerError[];
	setErrors: (errors: PassengerError[]) => void;
	showErrors: boolean;
	usingExistingPassenger: boolean;
	resetContinue?: () => void;
}

export function PassengerDetailForm({
	forCheckIn,
	passenger,
	count,
	expanded,
	setExpanded,
	wizardData,
	onUpdateWizardData,
	errors,
	setErrors,
	usingExistingPassenger,
	showErrors,
	onClearForm,
	resetContinue,
}: PassengerDetailFormProps) {
	const intl = useIntl();
	const [overrideShowErrors, setOverrideShowErrors] = useState(showErrors);
	const passengersErrors = isNotNullOrUndefined(errors)
		? errors?.find(y => y.id === passenger.id)?.errors
		: {};
	const updateErrors = (property: keyof PassengersInfo) => {
		if (Object.keys(passengersErrors ?? {}).length === 0) {
			if (resetContinue) {
				runInAction(() => {
					resetContinue();
				});
			}
			return;
		}

		// Get new errors for the passenger
		const newPassengerErrors = validatePassenger(passenger);
		let updatedErrors = [];

		if (newPassengerErrors === null) {
			updatedErrors = [
				...errors.filter(err => err.id !== passenger.id),
			];
			if (resetContinue) {
				runInAction(() => {
					resetContinue();
				});
			}
		} else {
			// Update the errors
			updatedErrors = [
				...errors.filter(err => err.id !== passenger.id),
				newPassengerErrors,
			];
		}

		setErrors(updatedErrors as PassengerError[]);
	};

	const updatePassengerInfo = (id: string, updatedPassenger: PassengersInfo) => {
		const newData = { ...wizardData };

		const passengerTypeProperty = passengerTypeStore.getWizardDataProperty(passenger.key as PassengerTypeKey);
		newData[passengerTypeProperty] = newData[passengerTypeProperty].map((passengerInfo: PassengersInfo) => {
			return passengerInfo.id === id ? updatedPassenger : passengerInfo;
		});

		if (onUpdateWizardData) {
			onUpdateWizardData(newData as BookingWizardData);
		}
	};

	// Need to ensure the date is a Date object for it to work with the DatePicker
	const dateModel = useLocalStore(() => ({
		dateOfBirth: isNotNullOrUndefined(passenger.dateOfBirth) ? new Date(passenger.dateOfBirth) : null,
	}));

	const handleInputChange = (property: keyof PassengersInfo, value: any) => {
		const updatedPassenger = {
			...passenger,
			[property]: value,
		};
		updatePassengerInfo(passenger.id, updatedPassenger);
		updateErrors(property);
	};

	return (
		<div
			className={classNames(
				'passenger-detail__container',
				{ 'check-in': forCheckIn },
				{ 'can-click': !expanded },
			)}
			role="button"
			tabIndex={count}
			onClick={() => {
				if (!expanded) {
					setExpanded(passenger.id, true);
				}
			}}
			onKeyDown={() => {
				if (!expanded) {
					setExpanded(passenger.id, true);
				}
			}}
		>
			<div className="passenger-details__header">
				<div className="header__label">
					<h6>{stringIsEmpty(passenger.firstName)
						? `Passenger ${count}`
						: `${passenger.firstName} ${passenger.lastName ?? ''}`}
					</h6>
					<span className="input-group--error">
						{((Object.keys(passengersErrors ?? {}).length > 0 && (overrideShowErrors || showErrors)))
							? 'Missing or incomplete fields'
							: ''}
					</span>
					<p>
						{passengerTypeStore.getSingularName(passenger.key as PassengerTypeKey)}
					</p>
				</div>
				<If condition={!expanded}>
					<Button
						className="edit-passenger__button"
						colors={Colors.Primary}
						display={Display.Text}
						icon={{ icon: 'edit', iconPos: 'icon-right' }}
					>
						Edit
					</Button>
				</If>
			</div>
			<If condition={expanded}>
				<div className="passenger-details__body">
					<If condition={appConfigStore.passengerFirstNameEnabled}>
						<TextField
							id="passenger-first-name"
							model={passenger}
							modelProperty="firstName"
							isDisabled={usingExistingPassenger}
							label={intl.formatMessage({
								id: 'passenger-tab__first-name__label',
								defaultMessage: 'First name',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__first-name__placeholder',
								defaultMessage: 'Enter your first name',
							})}
							isRequired={appConfigStore.passengerFirstNameRequired}
							onAfterChange={event => handleInputChange(
								'firstName',
								event.target.value,
							)}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.firstName
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerLastNameEnabled}>
						<TextField
							id="passenger-last-name"
							model={passenger}
							modelProperty="lastName"
							isDisabled={usingExistingPassenger}
							label={intl.formatMessage({
								id: 'passenger-tab__last-name__label',
								defaultMessage: 'Last name',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__last-name__placeholder',
								defaultMessage: 'Enter your last name',
							})}
							isRequired={appConfigStore.passengerLastNameRequired}
							onAfterChange={event => handleInputChange(
								'lastName',
								event.target.value,
							)}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.lastName
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerPhoneEnabled}>
						<TextField
							id="passenger-phone"
							model={passenger}
							modelProperty="phone"
							label={intl.formatMessage({
								id: 'passenger-tab__phone__label',
								defaultMessage: 'Phone number',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__phone__placeholder',
								defaultMessage: 'Enter your phone number',
							})}
							isRequired={appConfigStore.passengerPhoneRequired}
							onAfterChange={event => handleInputChange(
								'phone',
								event.target.value,
							)}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.phone
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerEmailEnabled}>
						<TextField
							id="passenger-email"
							model={passenger}
							modelProperty="email"
							label={intl.formatMessage({
								id: 'passenger-tab__email__label',
								defaultMessage: 'Email',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__email__placeholder',
								defaultMessage: 'Enter your email',
							})}
							isRequired={appConfigStore.passengerEmailRequired}
							onAfterChange={event => handleInputChange(
								'email',
								event.target.value,
							)}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.email
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerAgeEnabled}>
						<NumberTextField
							id="passenger-age"
							model={passenger}
							modelProperty="age"
							label={intl.formatMessage({
								id: 'passenger-tab__age__label',
								defaultMessage: 'Age',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__age__placeholder',
								defaultMessage: 'Enter your age',
							})}
							isRequired={appConfigStore.passengerAgeRequired}
							onAfterChange={event => handleInputChange(
								'age',
								event.target.value,
							)}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.age
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerDateOfBirthEnabled}>
						<DatePicker
							id="passenger-dateofbirth"
							model={dateModel}
							modelProperty="dateOfBirth"
							label={intl.formatMessage({
								id: 'passenger-tab__dateofbirth__label',
								defaultMessage: 'Date of birth',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__dateofbirth__placeholder',
								defaultMessage: 'Enter your date of birth',
							})}
							isRequired={appConfigStore.passengerDateOfBirthRequired}
							onAfterChange={date => {
								handleInputChange(
									'dateOfBirth',
									new Date(date[0]),
								);
								if (forCheckIn) {
									// eslint-disable-next-line prefer-destructuring
									passenger.dateOfBirth = date[0];
								}
							}}
							// Necessary for when the date is changed by entering the date manually
							onChangeAndBlur={date => {
								handleInputChange(
									'dateOfBirth',
									new Date(date[0]),
								);
								if (forCheckIn) {
									// eslint-disable-next-line prefer-destructuring
									passenger.dateOfBirth = date[0];
								}
							}}
							flatpickrOptions={{
								maxDate: new Date(),
								allowInput: true,
								dateFormat: 'd/m/Y',
								locale: {
									firstDayOfWeek: 1,
									weekdays: {
										shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
										longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
									},
								},
								disableMobile: true,
							}}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.dateOfBirth
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerAddressEnabled}>
						<TextField
							id="passenger-address"
							model={passenger}
							modelProperty="address"
							label={intl.formatMessage({
								id: 'passenger-tab__address__label',
								defaultMessage: 'Address',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__address__placeholder',
								defaultMessage: 'Enter your address',
							})}
							isRequired={appConfigStore.passengerAddressRequired}
							onAfterChange={event => handleInputChange(
								'address',
								event.target.value ?? null,
							)}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.address
								: undefined}
						/>
					</If>
					<If condition={appConfigStore.passengerGenderEnabled}>
						<Combobox
							model={passenger}
							modelProperty="gender"
							label={intl.formatMessage({
								id: 'passenger-tab__gender__label',
								defaultMessage: 'Gender',
							})}
							placeholder={intl.formatMessage({
								id: 'passenger-tab__gender__placeholder',
								defaultMessage: 'Select your gender',
							})}
							options={appConfigStore.passengerGenderOptions.map(x => (
								{ value: x, display: x }))}
							isRequired={appConfigStore.passengerGenderRequired}
							onAfterChange={((event, data) => handleInputChange(
								'gender',
								data.value ?? null,
							))}
							errors={overrideShowErrors || showErrors
								? passengersErrors?.gender
								: undefined}
						/>
					</If>
				</div>
				<div className="action-buttons__container">
					<Button
						className="add-passenger-button"
						onClick={() => {
							setExpanded(passenger.id, true);
							setOverrideShowErrors(true);
						}}
						colors={Colors.Primary}
						display={Display.Solid}
					>
						Save
					</Button>
					<If condition={usingExistingPassenger || !passengerTicketIsEmpty(passenger)}>
						<Button
							className="clear-passenger-button"
							onClick={() => {
								setExpanded(passenger.id, true);
								onClearForm();
							}}
							colors={Colors.Black}
							display={Display.Outline}
						>
							Clear
						</Button>
					</If>
				</div>
			</If>
		</div>
	);
}
