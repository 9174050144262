import { filterDestinations } from 'Global/JourneyElements/Utils/filterDestinations';
import { getLocationById } from 'Global/JourneyElements/Utils/getLocationById';
import { AppError } from 'Validators/AppError';

const message = 'handleChangeStartLocation: there is no location found using startLocationId.';

export const handleChangeStartLocation = (startLocationId: string, endLocationId: string) => {
	const location = getLocationById(startLocationId);

	if (!location) {
		throw new AppError(message, { startLocationId, endLocationId });
	}

	const possibleDestinationIds = filterDestinations(startLocationId).map(x => x.id);

	if (possibleDestinationIds.includes(endLocationId)) {
		return { startLocationId, possibleDestinationIds, endLocationId };
	}

	return { startLocationId, possibleDestinationIds, endLocationId: possibleDestinationIds[0] };
};
