import React from 'react';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import LegOption from 'Modules/MultiStopWizard/Components/Leg/LegOption';

interface FilteredTripCardsProps {
	list: TicketsTabTrip[];
	wizardData: BookingWizardData;
	saveChanges: (newData: BookingWizardData) => void;
	departure: boolean;
	priceByFerryTrip: { [key: string]: number };
	loading: boolean;
	refreshTrips?: () => void;
}

export const FilteredTripCards = (
	{
		list,
		wizardData,
		departure,
		saveChanges,
		priceByFerryTrip,
		loading,
		refreshTrips,
	}: FilteredTripCardsProps,
) => {
	if (list.length > 0) {
		return (
			<>
				{
					list.filter(x => x.startDate).map((trip, index) => {
						return (
							<React.Fragment key={`${trip.id}-${trip.startStopId}-${trip.endStopId}`}>
								{index > 0 && <div style={{ borderTop: '1px solid #e9e9e9' }} />}
								<LegOption
									legType={departure ? 'departure' : 'return'}
									trip={trip}
									price={priceByFerryTrip[trip.id]}
									isLoading={loading}
									wizardData={wizardData}
									refreshTrips={refreshTrips}
									saveChanges={saveChanges}
								/>
							</React.Fragment>
						);
					})
				}
			</>
		);
	}
	return <div className="no-trips-available">No trips available</div>;
};
