import React from 'react';

import { journeyElementsStore } from 'Global/JourneyElements/JourneyElementsStore';
import { getLocationById } from 'Global/JourneyElements/Utils/getLocationById';
import { isTravelPossible } from 'Global/JourneyElements/Utils/isTravelPossible';
import { handleAppError } from 'Validators/AppError';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import {
	initialState,
	TripSelectorActionEvent,
	TripSelectorActionType,
	tripSelectorReducer,
} from './Reducers/tripSelectorReducer';
import { isNullOrUndefined } from '../../../../Util/TypeGuards';

interface TripSelectorProps {
	startLocationId: string;
	endLocationId: string;
	setLocations(state: { startLocationId: string; endLocationId: string }): void;
	disabled?: boolean;
}

export default function TripSelector({
	startLocationId: startLocationIdParam,
	endLocationId: endLocationIdParam,
	setLocations,
	disabled,
}: TripSelectorProps) {
	const { locations, isLoading } = journeyElementsStore();

	const [state, dispatch] = React.useReducer(
		tripSelectorReducer,
		initialState(startLocationIdParam, endLocationIdParam),
	);

	const { startLocationId, endLocationId, possibleDestinationIds } = state;

	const dispatchWithErrorHandling = (event: TripSelectorActionEvent) => {
		try {
			dispatch(event);
		} catch (error) {
			handleAppError(error);
			if (process.env.NODE_ENV === 'development') {
				throw error; // Rethrow the error for debugging.
			}
		}
	};

	React.useEffect(() => {
		// Sync state with external setLocations function whenever start or end location changes
		setLocations({ startLocationId, endLocationId });
	}, [startLocationId, endLocationId]);

	React.useEffect(() => {
		// Check only when transport data is ready
		if (isLoading) {
			return;
		}
		// Set default locations
		// Also check possible destinations list, since data would not be loaded yet when state was initialised
		if (startLocationIdParam === '' || endLocationIdParam === '' || possibleDestinationIds.length === 0) {
			dispatchWithErrorHandling({
				type: TripSelectorActionType.ResetLocations,
				startLocationId: startLocationIdParam,
				endLocationId: endLocationIdParam,
			});
		}
	}, [isLoading, startLocationIdParam, endLocationIdParam]);

	const departureOptions = locations
		.sort((a, b) => a.name.localeCompare(b.name))
		.map(x => ({ display: x.name, value: x.id }));
	const destinationOptions = possibleDestinationIds
		.map(id => getLocationById(id))
		.sort((a, b) => isNullOrUndefined(a) || isNullOrUndefined(b) ? 0 : a.name.localeCompare(b.name))
		.filter(location => !!location)
		.map(location => ({
			display: location?.name,
			value: location?.id,
		}));

	return (
		<div className="trip-direction-selector__container">
			<Combobox
				className="departure-dropdown"
				model={{ startLocationId }}
				modelProperty="startLocationId"
				label="From"
				options={departureOptions}
				searchable={false}
				onChange={(_, data) => {
					dispatchWithErrorHandling({
						type: TripSelectorActionType.ChangeStartLocation,
						startLocationId: data.value as string,
					});
				}}
				isDisabled={disabled}
			/>
			<div className="flip-direction-button__container">
				<Button
					className="flip-direction-button"
					display={Display.Text}
					colors={Colors.None}
					sizes={Sizes.ExtraLarge}
					icon={{ icon: 'switch', iconPos: 'icon-right' }}
					onClick={() => dispatchWithErrorHandling({ type: TripSelectorActionType.SwitchLocations })}
					disabled={!isTravelPossible(endLocationId, startLocationId) || disabled}
				/>
			</div>
			<Combobox
				className="destination-dropdown"
				model={{ endLocationId }}
				modelProperty="endLocationId"
				label="To"
				options={destinationOptions}
				searchable={false}
				isDisabled={destinationOptions.length < 2 || disabled}
				onChange={(_, data) => {
					dispatchWithErrorHandling({
						type: TripSelectorActionType.ChangeEndLocation,
						endLocationId: data.value as string,
					});
				}}
			/>
		</div>
	);
}
