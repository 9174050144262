import * as React from 'react';

import { useLocationNamesFromBookingSummary } from '../Hooks/useLocationNamesFromBookingSummary';
import { BookingDetailSection, SectionProps } from './BookingDetailSection';
import { useJourneySchedule } from 'Global/Journey/Queries/useJourneySchedule';
import dayjs from 'dayjs';
import Skeleton from 'Views/Components/_HumanWritten/Skeleton/Skeleton';

export function TripTimeInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	const { startLocationName, endLocationName } = useLocationNamesFromBookingSummary(booking.bookedSummary);

	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const ferryTrip = booking?.bookedSummary?.ferryTrip;

	const sectionContent = (
		<>
			<p className="route-name">
				{startLocationName} to {endLocationName}
			</p>
			<TripDateTime
				tripId={ferryTrip.id}
				startStopId={booking.bookedSummary.startStopId}
				endStopId={booking.bookedSummary.endStopId}
			/>
		</>
	);

	return (
		<BookingDetailSection
			sectionName="Trip details"
			sectionContent={sectionContent}
			sectionClassName="trip-time"
			icon={{
				name: 'calendar',
				classname: 'calendar',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'trip-time',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}

interface TripDateTimeProps {
	tripId: string;
	startStopId: string;
	endStopId: string;
}

function TripDateTime({ tripId, startStopId, endStopId }: TripDateTimeProps) {
	const schedule = useJourneySchedule({
		tripId,
		startStopId,
		endStopId,
	});
	return (
		<>
			<p className="trip-date-time">
				{!schedule.data && <Skeleton width="4rem" />}
				Departure: {schedule.data && <span>{dayjs(schedule.data.departureDateTime).format('DD MMM YYYY, h:mma')}</span>}
			</p>
			<p className="trip-date-time">
				{!schedule.data && <Skeleton width="4rem" />}
				Arrival: {schedule.data && <span>{dayjs(schedule.data.arrivalDateTime).format('DD MMM YYYY, h:mma')}</span>}
			</p>
		</>
	);
}
