import React from 'react';

import { LegOptionContext } from './Contexts/LegOptionContext';
import LegOptionCargoCapacity from './LegOptionCargoCapacity';
import LegOptionChangeSelection from './LegOptionChangeSelection';
import LegOptionContainer from './LegOptionContainer';
import LegOptionJourneyInfo from './LegOptionJourneyInfo';
import LegOptionPaxCapacity from './LegOptionPaxCapacity';
import LegOptionPrice from './LegOptionPrice';
import LegOptionSelectedIndicator from './LegOptionSelectedIndicator';
import LegOptionTrailerCapacity from './LegOptionTrailerCapacity';
import LegOptionVoyageNumber from './LegOptionVoyageNumber';
import LegOptionWaitlist from './LegOptionWaitlist';

export default function LegOptionMobile() {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionMobile');
		return null;
	}

	const { isSelected, isSelectable, errorMessage } = context;

	return (
		<>
			<LegOptionContainer isMobile>
				<div className="leg-option__journey-container">
					{/* FUTURE : Voyage number needs to be populated and enabled as a feature */}
					{/* <LegOptionVoyageNumber>FY 01417</LegOptionVoyageNumber> */}
					<LegOptionJourneyInfo isMobile />
				</div>
				<div className="leg-option__info-container">
					<div className="leg-option__space-container leg-option__space-container--row">
						{!!errorMessage && (
							<>
								<div className="leg-option__error-msg">{errorMessage}</div>
								<LegOptionWaitlist />
							</>
						)}
						{!errorMessage && (
							<>
								<div className="leg-option__space-text">Spaces:</div>
								<div className="leg-option__space-tag-container">
									<LegOptionPaxCapacity />
									<LegOptionCargoCapacity />
									<LegOptionTrailerCapacity />
								</div>
							</>
						)}
					</div>
					<LegOptionPrice />
					<LegOptionSelectedIndicator />
				</div>
			</LegOptionContainer>
			<LegOptionChangeSelection />
		</>
	);
}
