import React from 'react';

import { store } from 'Models/Store';
import { eventCheckInStore } from 'Views/Components/_HumanWritten/CheckIn/EventCheckIn/EventContext/EventCheckInContext';
import { checkInStore } from '../Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/CheckInContext';

export function useCheckInRoutes(isEventBooking: boolean = false) {
	const routes = React.useMemo(() => {
		const path = store.routerHistory.location.pathname;
		const base = `/${isEventBooking ? 'event-' : ''}check-in/${
			isEventBooking ? eventCheckInStore.eventId : checkInStore.ferryTripId
		}`;
		return {
			current: path,
			schedule: '/ferry-schedule',
			eventDetailedView: `/event-details/${eventCheckInStore.eventId}`,
			base: base,
			move: `${base}/move`,
			add: `${base}/add`,
			selectCustomer: `${base}/add/select-customer`,
			passengers: `${base}/add/passengers`,
			review: `${base}/review`,
			scan: `${base}/scan`,
			goToEdit(bookingId: string) {
				store.routerHistory.push(`${base}/booking/${bookingId}`);
			},
			goToPassengerEdit(bookingId: string) {
				store.routerHistory.push(`${base}/booking/${bookingId}/passengers`);
			},
		};
	}, [store.routerHistory.location.pathname, checkInStore.ferryTripId]);
	return routes;
}
