import React from 'react';
import dayjs from 'dayjs';

interface CalendarDaysProps {
	departureTime: Date;
	arrivalTime: Date;
}

export default function CalendarDays({ departureTime, arrivalTime }: CalendarDaysProps) {
	// Extract only the date part (ignoring the time)
	const date1 = dayjs(departureTime).startOf('day');
	const date2 = dayjs(arrivalTime).startOf('day');
	const days = date2.diff(date1, 'days');

	if (days === 0) {
		return null;
	}

	return <sup>+{days}</sup>;
}
