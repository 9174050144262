import React from 'react';
import { Link } from 'react-router-dom';
import If from 'Views/Components/If/If';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { PassengerTypeKey, wizardModeOptions } from 'Models/Enums';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { PassengerDetailsList } from './PassengerDetailsList';
import passengerTypeStore from 'Models/PassengerTypeStore';
import {
	BookingWizardData, getDefaultPassengerInfo, PassengersInfo,
	WizardErrors,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	getTotalPassengerCountFromWizardData,
} from './PassengerTabHelpers';

export interface PassengersTabProps {
	wizardData: BookingWizardData;
	onUpdateWizardData: (wizardData: BookingWizardData) => void;
	errors: WizardErrors<BookingWizardData>;
}

export function PassengersTab({
	wizardData,
	onUpdateWizardData,
	errors,
}: PassengersTabProps) {
	const isCreateMode = wizardData.wizardMode === wizardModeOptions.CREATE;

	const onSelectPreviousPassenger = (passenger: PassengersInfo, incompletePassengers: PassengersInfo[]) => {
		const passengerToReplace = incompletePassengers
			.find(x => x.key === passenger.key);
		const passengerAttributeName = passengerTypeStore
			.getBookingDtoProperty(passenger.key as PassengerTypeKey);

		// We need to copy the passenger object because we are going to modify it
		const passengerCopy = { ...passenger };
		const newPassengers = [
			...wizardData[passengerAttributeName]
				.filter((y: PassengersInfo) => y.id !== passengerToReplace?.id),
			passengerCopy,
		];

		if (onUpdateWizardData) {
			onUpdateWizardData({
				...wizardData as BookingWizardData,
				[passengerAttributeName]: newPassengers,
			});
		}
	};

	const onClearForm = (passenger: PassengersInfo) => {
		const newData = { ...wizardData };

		const updatedPassenger = getDefaultPassengerInfo(passenger.key as PassengerTypeKey);

		const passengerTypeProperty = passengerTypeStore.getWizardDataProperty(passenger.key as PassengerTypeKey);
		newData[passengerTypeProperty] = newData[passengerTypeProperty].filter((passengerInfo: PassengersInfo) => {
			return passengerInfo.id !== passenger.id;
		});

		newData[passengerTypeProperty].push(updatedPassenger);

		if (onUpdateWizardData) {
			onUpdateWizardData(newData as BookingWizardData);
		}
	};

	return (
		<SecuredPage groups={SecuredGroups.create.onlyLoggedIn().groups}>
			<div className="booking-wizard-tab passenger-details-tab">
				<div className="passenger-details-tab__container">
					<div className="booking-wizard__tab-header">
						<h2>
							<If condition={isCreateMode}>
								Enter passenger details
							</If>
							<If condition={!isCreateMode}>
								Edit passenger details
							</If>
							<p>{getTotalPassengerCountFromWizardData(wizardData)} passengers</p>
						</h2>
						<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
							<div>
								<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
									<Icon name="cross" classname="icon" />
								</Link>
							</div>
						</If>
					</div>
					<PassengerDetailsList
						wizardData={wizardData}
						onUpdateWizardData={onUpdateWizardData}
						errors={errors}
						onPreviousPassengerSelect={onSelectPreviousPassenger}
						onClearForm={(passenger: PassengersInfo) => onClearForm(passenger)}
					/>
				</div>
			</div>
		</SecuredPage>
	);
}
