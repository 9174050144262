import React from 'react';

import { useIsTrailerSpaceExceeded } from 'Modules/MultiStopWizard/Hooks/useIsTrailerSpaceExceeded';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import classNames from 'classnames';
import { LegOptionContext } from './Contexts/LegOptionContext';

export default function LegOptionTrailerCapacity() {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionTrailerCapacity');
		return null;
	}

	const { wizardData, trip } = context;

	if (!(wizardData.tabSelected === 'vehicle' && wizardData.trailerCheckboxSelected)) {
		return null;
	}

	return (
		<LegOptionTrailerCapacityInner wizardData={wizardData} trailerSpaces={trip.trailerSpotsAvailable} />
	);
}

interface LegOptionTrailerCapacityInnerProps {
	wizardData: BookingWizardData
	trailerSpaces: number;
}

function LegOptionTrailerCapacityInner({ wizardData, trailerSpaces }: LegOptionTrailerCapacityInnerProps) {
	const exceedsAvailableTrailerSpace = useIsTrailerSpaceExceeded(trailerSpaces, wizardData);

	return (
		<div className="leg-option__space-tag">
			<Icon name="chevron-trailer" />
			<div
				className={classNames('leg-option__dot-indicator', {
					'leg-option__dot-indicator--green': !exceedsAvailableTrailerSpace,
					'leg-option__dot-indicator--red': exceedsAvailableTrailerSpace,
				})}
			/>
		</div>
	);
}
