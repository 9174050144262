import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface HomeStoreState {
	startLocationId: string;
	endLocationId: string;
}

interface HomeStoreActions {
	setState(state: Partial<HomeStoreState>): void;
}

export const useHomeStore = create<HomeStoreState & HomeStoreActions, [['zustand/persist', unknown]]>(
	persist(
		set => ({
			startLocationId: '',
			endLocationId: '',
			setState(state) {
				set(state);
			},
		}),
		{
			name: 'home-selection',
			storage: createJSONStorage(() => localStorage),
		},
	),
);
