import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { getStopById } from 'Global/JourneyElements/Utils/getStopById';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { BookingEntity } from 'Models/Entities';
import { isBeforeNow } from 'Util/_HumanWritten/GeneralHelpers/DateTimeHelpers';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { formatAMPM } from 'Util/_HumanWritten/TimeUtils';
import { upperCaseFirst } from 'Util/StringUtils';
import alertToast from 'Util/ToastifyUtils';
import { isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';

export interface BookingCardProps {
	booking: BookingEntity;
	editUrl: string;
}

export function BookingCard(props: BookingCardProps) {
	const { booking, editUrl } = props;

	if (isNullOrUndefined(booking.bookingListSummary)) {
		alertToast('Booking is missing details', 'error', undefined, { autoClose: 3000 });
		throw new Error('Booking is missing details'); // navigates to error boundary
	}

	return (
		<div className="booking-card-container">
			<BookingCardDetails booking={booking} />
			<Link to={editUrl} className="view-booking-detail-section__edit-button hide-underline">
				{isBeforeNow(booking.bookingListSummary.tripDateTime) || booking?.bookingStatus === 'CANCELLED'
					? 'View booking'
					: 'View/Edit booking'}
			</Link>
		</div>
	);
}

function BookingCardDetails({ booking }: { booking: BookingEntity }) {
	const { isMobile, isIpad } = useDeviceDetect();

	if (isNullOrUndefined(booking.bookingListSummary) || isNullOrUndefined(booking.transactionStatus)) {
		alertToast('Booking is missing details', 'error', undefined, { autoClose: 3000 });
		throw new Error('Booking is missing details'); // navigates to error boundary
	}

	const {
		passengerDetailsString,
		cargoDriverDetailsString,
		cargoRegoAndTrailerDetailsString,
		eventName,
		totalCost,
		tripDateTime,
		route,
		iconId,
	} = booking.bookingListSummary;

	const status = booking.transactionStatus;

	const hasVehicleOnDesktop = stringNotEmpty(cargoDriverDetailsString) && !isIpad && !isMobile;
	const regoClass = hasVehicleOnDesktop ? 'booked-trip-vehicle-details' : '';
	const driverClass = hasVehicleOnDesktop ? 'driver-details' : 'driver-details-mobile';

	const title: { bookingType: 'event' | 'ferry', name: string } = React.useMemo(() => {
		if (stringNotEmpty(eventName)) {
			return {
				bookingType: 'event',
				name: eventName,
			};
		}

		let startStopName = route?.departure?.name;
		let endStopName = route?.destination?.name;

		if (booking.bookingListSummary?.startStopId) {
			const stop = getStopById(booking.bookingListSummary?.startStopId);
			if (stop) {
				startStopName = stop.location.name;
			}
		}

		if (booking.bookingListSummary?.endStopId) {
			const stop = getStopById(booking.bookingListSummary?.endStopId);
			if (stop) {
				endStopName = stop.location.name;
			}
		}

		return {
			bookingType: 'ferry',
			name: `${startStopName} to ${endStopName}`,
		};
	}, [eventName, route?.departure?.name, route?.destination?.name]);

	const iconClass = booking?.returnBooking !== null || booking?.returnBookingForId === null ? 'icon-next' : 'icon-back';

	return (
		<div className="booking">
			<div className="booking-header">
				{title.bookingType === 'event' && (
					<h6 className="event-name event-icon">
						<img className="event-icon" src={`/api/files/${iconId}`} alt="event-icon" />
						{title.name}
					</h6>
				)}
				{title.bookingType === 'ferry' && (
					<h6 className={`route-name ${iconClass}  icon-left`}>{title.name}</h6>
				)}
				<div className={`booking-status ${status?.toLowerCase()}-status`}>
					{status && upperCaseFirst(status === 'PAID' ? 'BOOKED'.toLowerCase() : status.toLowerCase())}
				</div>
			</div>
			<div className="ticket-body" key={booking.id}>
				<div className="booked-trip-time-date">
					<p className="bold date">{moment(tripDateTime).format('DD MMM YYYY')}</p>
					<p className="bold time">{`${formatAMPM(tripDateTime) ?? ''}`}</p>
				</div>
				<p className="booking-id-booking-tile">ID #{booking.humanReadableId}</p>
				<p className="booked-trip-passenger-details">
					<>{passengerDetailsString}</>
				</p>
				{stringNotEmpty(cargoDriverDetailsString) && <p className={driverClass}>{cargoDriverDetailsString}</p>}
				{stringNotEmpty(cargoRegoAndTrailerDetailsString) && (
					<p className={regoClass}>{cargoRegoAndTrailerDetailsString}</p>
				)}
				<p className="booked-ticket-cost">{formatPriceDisplay(totalCost, false)}</p>
			</div>
		</div>
	);
}
