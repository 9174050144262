import React from 'react';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { CheckInListRowDetail } from './CheckInListRowDetail';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { BookingEntity } from 'Models/Entities';

export interface CheckInListRowPassengersProps {
	booking: BookingEntity | CheckInBookingOverviewDto;
}

export default function CheckInListRowPassengers({ booking }: CheckInListRowPassengersProps) {
	usePassengerTypes();

	const { bookedSummary } = booking;

	if (isNullOrUndefined(bookedSummary)) {
		return <></>;
	}

	return (
		<>
			{passengerTypeStore.passengerTypes.map(x => {
				const key = x.passengerTypeKey;
				const propertyName = passengerTypeStore.getBookingSummaryProperty(key);

				const value = bookedSummary[propertyName].length;

				if (value === 0) {
					return <React.Fragment key={x.id} />;
				}

				const label = passengerTypeStore.getAbbreviation(key);

				return (
					<CheckInListRowDetail
						key={x.id}
						label={label}
						value={value}
						className={`row-detail row-detail-${key}`}
					/>
				);
			})}
		</>
	);
}
