import React from 'react';
import classNames from 'classnames';

import { formatTime } from 'Modules/Shared/Utils/DateTime/formatTime';
import CalendarDays from './CalendarDays';
import { LegOptionContext } from './Contexts/LegOptionContext';
import StopSeriesVisualiser from './StopSeriesVisualiser';

interface LegOptionJourneyInfoProps {
	isMobile?: boolean;
}

export default function LegOptionJourneyInfo({ isMobile }: LegOptionJourneyInfoProps) {
	const context = React.useContext(LegOptionContext);

	if (!context) {
		console.error('LegOptionContext is missing in LegOptionJourneyInfo');
		return null;
	}

	const {
		isSelected,
		departureTime,
		arrivalTime,
		numberOfStopsBetween,
	} = context;

	return (
		<LegOptionJourneyInfoInner
			isMobile={isMobile}
			isSelected={isSelected}
			departureTime={departureTime}
			arrivalTime={arrivalTime}
			numberOfStopsBetween={numberOfStopsBetween}
		/>
	);
}

interface LegOptionJourneyInfoInnerProps {
	isMobile?: boolean;
	isSelected?: boolean;
	departureTime: Date;
	arrivalTime: Date;
	numberOfStopsBetween?: number;
}

function LegOptionJourneyInfoInner({
	isMobile,
	isSelected,
	departureTime,
	arrivalTime,
	numberOfStopsBetween,
}: LegOptionJourneyInfoInnerProps) {
	const variant = React.useMemo(() => {
		if (isMobile) {
			return 'contrast';
		}
		return isSelected ? 'white' : 'contrast';
	}, []);

	return (
		<div
			className={classNames('leg-option__journey-info', {
				'leg-option__journey-info--margin-top': isMobile && numberOfStopsBetween !== undefined && numberOfStopsBetween > 0,
			})}
		>
			<div
				className={classNames('leg-option__departure-time', { 'leg-option__departure-time--mobile': isMobile })}
			>
				{formatTime(departureTime)}
			</div>
			<StopSeriesVisualiser numberOfStops={numberOfStopsBetween} variant={variant} />
			<div className={classNames('leg-option__arrival-time', { 'leg-option__arrival-time--mobile': isMobile })}>
				{formatTime(arrivalTime)}
				<CalendarDays departureTime={departureTime} arrivalTime={arrivalTime} />
			</div>
		</div>
	);
}
